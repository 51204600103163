import React from 'react';
import './index.less';
import Icon from '../Icon';
import { useNavigate, useLocation } from 'react-router-dom';
import { getTheme } from '@/utils/utils';
import { getRoleFromLocation } from '@/utils/commonUtils';

/**
 * 
 * @param {*} props 
 * @returns 
 */
function TitleBar(props) {
  const { title, subTitle, isShowBack = false, backTitle = false, barCancel, rightContent, rightContentOnclick } = props
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const fromOutside = searchParams.get('fromOutside');
  const theme = getTheme(location.pathname.split('/')[1]);

  const homeRouter = {
    client: '/client/home-v2/main',
    therapist: '/therapist/home/user-manage',
    admin: '/admin/home/main'
  }
  const role = getRoleFromLocation() || 'client';

  // 返回，如果是单独入口进入的界面，直接退出到主页
  function back() {
    if (fromOutside) {
      navigate(homeRouter[role])
      return;
    }
    navigate(-1);
  }


  return (
    <>
      <div className="header-block">&nbsp;</div>
      <div className="header-bar">
        {
          isShowBack ? <div className="icon-back" onClick={() => {
            if (barCancel) {
              barCancel()
            } else {
              back()
            }
          }
          }>
            <Icon iconfont={`back_${theme}`} />
            {backTitle ? <span className={`back-title theme-color-${theme}`}>返回</span> : null}
          </div> : null
        }
        <div className={`theme-color-${theme} ` + (isShowBack ? "title title-showback" : "title")}>
          <div dangerouslySetInnerHTML={{__html: title}}></div>
          {
            subTitle && <div className='sub-title'>{subTitle}</div>
          }
        </div>
        <div className={`title-right theme-color-${theme}`} onClick={rightContentOnclick}>
          {rightContent}
        </div>
      </div>
    </>
  )
}
export default TitleBar;