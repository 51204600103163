import React from 'react';
import Login from "@/pages/doctor/login";
import DoctorCalendar from "@/pages/doctor/calendar";
import UserSetting from "@/pages/doctor/userSetting";
import Home from "@/pages/doctor/home";
import OperatingInformation from '@/pages/doctor/home/operatingInformation';
import DoctorDialogue from "@/pages/doctor/dialogue";
import DoctorDialogueList from "@/pages/doctor/dialogue/list";
import DoctorVideoConsult from "@/pages/doctor/doctorVideoConsult";
import DoctorVideoConsultChatgpt from "@/pages/doctor/doctorVideoConsultChatgpt";
import DoctorLogin from "@/pages/doctor/login";
import DoctorPatientInfo from "@/pages/doctor/patientInfo";
import DoctorPatientBaseInfo from "@/pages/doctor/patientOnlyBaseInfo";
import ConsultList from "@/components/UserInfo/StaffInfo/consultList";
import FormList from "@/components/UserInfo/StaffInfo/formList";
import TaskList from "@/components/UserInfo/StaffInfo/taskList";
import AttachmentList from "@/components/UserInfo/StaffInfo/attachmentList";
import FormLibrary from "@/pages/doctor/formLibrary";
import DoctorUserInfo from "@/pages/doctor/userInfo";
import DoctorApprovedMemo from "@/pages/doctor/approvedMemo";
import SelfIntroAuditPage from '@/pages/doctor/selfIntroAuditPage';
import DoctorFormIframe from '@/pages/doctor/formIframe';
import ScheduleInfo from '@/pages/doctor/scheduleInfo';
import ExternsInfo from '@/pages/doctor/externsInfo';
import QimoUserInfo from '@/pages/doctor/qimoUserInfo';
// 舒辅健康档案
import HealthRecordList from '@/pages/doctor/healthRecordList';
import RecordDetail from '@/pages/doctor/healthRecordList/recordDetail';

import PatientHome from "@/pages/patient/home";
import PsychologyStatusIframe from "@/pages/patient/home/psychologyIframe";
import PositiveThinkingList from "@/pages/patient/positiveThinking";
import PsychologicalTest from "@/pages/patient/psychologicalTest";
import UserFormIframe from "@/pages/patient/psychologicalTest/userFormIframe";
import PositiveThinking from "@/pages/patient/positiveThinking/positiveThinkingIframe";
import PatientMatching from "@/pages/patient/matching";
import PatientConsultantInfo from "@/pages/patient/consultantInfo";
import PatientLogin from "@/pages/patient/login";
import PatientLoginAndBind from "@/pages/patient/login/loginAndBind";
import PatientLoginByStdId from "@/pages/patient/login/loginByStdId";
import PatientSFDALogin from "@/pages/patient/sfdaLogin";
import PatientFenglinLogin from "@/pages/patient/fenglinLogin";
import PatientConsultantList from "@/pages/patient/consultantList";
import PatientCalendar from "@/pages/patient/calendar";
import PatientCalendarOnlyRead from "@/pages/patient/calendar/calendarOnlyRead";
import PatientArticleList from "@/pages/patient/articleList";
import ConsultIntroduction from "@/pages/patient/consultIntroduction";
import ConsultIframe from "@/pages/patient/consultIframe";
import PatientVideoConsult from "@/pages/patient/patientVideoConsult";
import PatientDialogue from "@/pages/patient/dialogue";
import PatientAdminDialogue from "@/pages/patient/dialogueAdmin";
import FirstSetting from '@/pages/patient/login/firstSetting';
import PatientSetting from '@/pages/patient/setting';
import PatientUserInfo from '@/pages/patient/userInfo';
import FormTaskList from '@/pages/patient/formTaskList';
import TaskListIframe from '@/pages/patient/formTaskList/taskListIframe';
import MyEvaluation from '@/pages/patient/myEvaluation';
import ConsultedEvaluation from '@/pages/patient/consultedEvaluation';
import ConsultingEvaluation from '@/pages/patient/consultingEvaluation';
import PatientFormIframe from '@/pages/patient/formIframe';
import ExternalFirstSetting from '@/pages/patient/externalLogin/externalFirstSetting';
import ExternalLogin from '@/pages/patient/externalLogin';
import PatientMultiModalTest from '@/pages/patient/multiModalTest';
import PatientMultiModalTestIframe from '@/pages/patient/multiModalTest/multiModalTestIframe';
import SFDAPatientExclusiveLove from '@/pages/patient/sfdaExclusiveLove';
import SFDAPsychologicalTest from '@/pages/patient/sfdaPsychologicalTest';
import FenglinPsychologicalTest from '@/pages/patient/fenglinPsychologicalTest'
import InitialEvaluation from '@/pages/client/initialEvaluation';
import InitialEvaluationPage from '@/pages/client/initialEvaluationPage';
import SubAccount from '@/pages/client/subAccount';

import ExVideoList from '@/pages/patient/exVideoList';
import ExVideoPlay from '@/pages/patient/exVideoPlay';
import StartConsultForm from '@/pages/patient/userInfo/startConsultForm';
import Jysp from '@/pages/patient/jysp';
import RecommendChat from '@/pages/patient/RecommendChat';

// dataEnc登录
import AnonymousLogin from '@/pages/patient/anonymousLogin';
// 新版首页
import HomeV2 from '@/pages/client/homeV2';
import PatientLoginV2 from "@/pages/client/login";
import PatientLoginWithRegisterV2 from "@/pages/client/login/LoginWithRegister";
// 注册-完善信息界面
import PatientRegisterV2 from "@/pages/client/patientRegister";
// 子账号注册
import SubAccountRegister from "@/pages/client/subAccount/subAccountRegister";

import FirstSettingV2 from '@/pages/client/login/firstSetting';
// 主页
import PatientMain from '@/pages/client/main';
// 资源详情
import ResourceDetail from '@/pages/client/resourceDetail';
// 资源列表
import ResourceList from '@/pages/client/resourceList';
// 提醒设置
import ReminderSetting from '@/pages/client/reminderSetting';
import TransitionPage from '@/pages/client/transitionPage';
// 训练营列表页
import TrainingList from '@/pages/client/trainingList';
// 心理健康报告
import HealthReport from '@/pages/client/healthReport';

// 舒小辅router
import AdminLogin from '@/pages/admin/login';
import AdminHome from '@/pages/admin/home';
import AdminMain from '@/pages/admin/main';
import UserSearch from '@/pages/admin/userSearch';
import ConsultantsManagement from '@/pages/admin/consultants';
import AuditList from '@/pages/admin/auditList';
import EvaluationManagement from '@/pages/admin/evaluationManagement';
import AuditPage from '@/pages/admin/auditPage';
import ConsultantDetailInfo from '@/pages/admin/consultantDetailInfo';
import ConsultingComment from '@/pages/admin/consultingComment';
import AdminConsultantDialogue from '@/pages/admin/dialogue';
import ArrangeCalendar from '@/pages/admin/arrangeCalendar';
import ConsultationRecordForm from '@/pages/admin/consultationRecordForm';
import StaffDetail from '@/pages/admin/staffDetail';
import ChatRecord from "@/pages/admin/chatRecord";
import AdminHealthRecordList from '@/pages/admin/healthRecordList';
import AdminRecordDetail from '@/pages/admin/healthRecordList/recordDetail';

// 功能页面
import IatToChatGpt from '@/pages/func/IatToChatGpt';
// 怡安绿通介绍
import GreenChannelService from '@/pages/static/GreenChannelService';

const routers = [
  { path: '/', component: <Login />, needInterceptor: false},
  { path: '/admin/login', component: <AdminLogin />, needInterceptor: false },
  {
    path: '/admin/home', component: <AdminHome />, children: [
      { path: 'main', component: <AdminMain />, needInterceptor: true },
      { path: 'consultants', component: <ConsultantsManagement />, needInterceptor: true },
      { path: 'visitors', component: <UserSearch />, needInterceptor: true },
      { path: 'user-setting', component: <AdminMain />, needInterceptor: true },
    ]
  },
  { path: '/admin/evaluation-management', component: <EvaluationManagement />, needInterceptor: true },
  { path: '/admin/audit-list', component: <AuditList />, needInterceptor: true },
  { path: '/admin/audit-page', component: <AuditPage />, needInterceptor: true },
  { path: '/admin/consultant-detail-info', component: <ConsultantDetailInfo />, needInterceptor: true },
  { path: '/admin/consultant-comment', component: <ConsultingComment />, needInterceptor: true },
  { path: '/admin/dialogue', component: <AdminConsultantDialogue />, needInterceptor: true },
  { path: '/admin/arrange-calendar', component: <ArrangeCalendar />, needInterceptor: true },
  { path: '/admin/consultation-record-form', component: <ConsultationRecordForm />, needInterceptor: true },
  { path: '/admin/staff-detail', component: <StaffDetail />, needInterceptor: true },
  { path: '/admin/chat-record', component: <ChatRecord />, needInterceptor: true },
  { path: '/admin/health-record-list', component: <AdminHealthRecordList />, needInterceptor: true },
  { path: '/admin/record-detail', component: <AdminRecordDetail />, needInterceptor: true },

  { path: '/therapist/login', component: <DoctorLogin />, needInterceptor: false },
  {
    path: '/therapist/home', component: <Home />, children: [
      { path: 'user-manage', component: <DoctorDialogueList />, needInterceptor: true },
      { path: 'calendar', component: <DoctorCalendar />, needInterceptor: true },
      { path: 'user-setting', component: <UserSetting />, needInterceptor: true },
    ]
  },
  { path: '/therapist/consult-list', component: <ConsultList />, needInterceptor: true },
  { path: '/therapist/form-list', component: <FormList />, needInterceptor: true },
  { path: '/therapist/task-list', component: <TaskList />, needInterceptor: true },
  { path: '/therapist/attachment-list', component: <AttachmentList />, needInterceptor: true },
  { path: '/therapist/operating-information', component: <OperatingInformation />, needInterceptor: true },
  { path: '/therapist/dialogue', component: <DoctorDialogue />, needInterceptor: true },
  { path: '/therapist/doctor-video-consult', component: <DoctorVideoConsult />, needInterceptor: true },
  { path: '/therapist/doctor-video-consult-chatgpt', component: <DoctorVideoConsultChatgpt />, needInterceptor: true },
  { path: '/therapist/client-info', component: <DoctorPatientInfo />, needInterceptor: true },
  { path: '/therapist/client-base-info', component: <DoctorPatientBaseInfo />, needInterceptor: false },
  { path: '/therapist/form-library', component: <FormLibrary />, needInterceptor: true },
  { path: '/therapist/self-info', component: <DoctorUserInfo />, needInterceptor: true },
  { path: '/therapist/approved-memo', component: <DoctorApprovedMemo />, needInterceptor: true },
  { path: '/therapist/self-intro-audit', component: <SelfIntroAuditPage />, needInterceptor: true },
  { path: '/therapist/form-iframe', component: <DoctorFormIframe />, needInterceptor: true },
  { path: '/therapist/scheduleInfo', component: <ScheduleInfo />, needInterceptor: true },
  { path: '/therapist/externsInfo', component: <ExternsInfo />, needInterceptor: true },
  { path: '/therapist/health-record-list', component: <HealthRecordList />, needInterceptor: true },
  // 咨询记录详情
  { path: '/therapist/record-detail', component: <RecordDetail />, needInterceptor: true },


  // 值班咨询师查看七陌用户信息
  { path: '/therapist/qimo-user-info', component: <QimoUserInfo />, needInterceptor: true },

  { path: '/client/login', component: <PatientLogin />, needInterceptor: false },
  // 学号登录页面
  { path: '/client/loginByStdId', component: <PatientLoginByStdId />, needInterceptor: false },
  { path: '/client/loginAndBind', component: <PatientLoginAndBind />, needInterceptor: false },
  { path: '/client/sfdaLogin', component: <PatientSFDALogin />, needInterceptor: false },
  { path: '/client/fenglinLogin', component: <PatientFenglinLogin />, needInterceptor: false },
  { path: '/client/home', component: <PatientHome />, needInterceptor: true },
  { path: '/client/psychology-status', component: <PsychologyStatusIframe />, needInterceptor: true },
  { path: '/client/positive-thinking', component: <PositiveThinkingList />, needInterceptor: true },
  { path: '/client/psychological-test', component: <PsychologicalTest />, needInterceptor: true },
  { path: '/client/psychological-test-user-form', component: <UserFormIframe />, needInterceptor: true },
  { path: '/client/positive-thinking-iframe/:id', component: <PositiveThinking />, needInterceptor: true },
  { path: '/client/dialogue/:chatAuthId', component: <PatientDialogue />, needInterceptor: true },
  { path: '/client/dialogue-admin/:chatAuthId/:consultantId', component: <PatientAdminDialogue />, needInterceptor: true },
  { path: '/client/matching', component: <PatientMatching />, needInterceptor: true },
  { path: '/client/consultant-info', component: <PatientConsultantInfo />, needInterceptor: true },
  { path: '/client/calendar', component: <PatientCalendar />, needInterceptor: true },
  { path: '/client/calendar-read', component: <PatientCalendarOnlyRead />, needInterceptor: true },
  { path: '/client/article', component: <PatientArticleList />, needInterceptor: true },
  { path: '/client/first-setting', component: <FirstSetting />, needInterceptor: true },
  { path: '/client/consultant-list', component: <PatientConsultantList />, needInterceptor: true },
  { path: '/client/consult-introduction', component: <ConsultIntroduction />, needInterceptor: true },
  { path: '/client/consult-iframe', component: <ConsultIframe />, needInterceptor: true },
  { path: '/client/patient-video-consult', component: <PatientVideoConsult />, needInterceptor: true },
  { path: '/client/setting', component: <PatientSetting />, needInterceptor: true },
  { path: '/client/self-info', component: <PatientUserInfo />, needInterceptor: true },
  { path: '/client/form-task-list', component: <FormTaskList />, needInterceptor: true },
  { path: '/client/staff-task-iframe', component: <TaskListIframe />, needInterceptor: true },
  { path: '/client/my-evaluation', component: <MyEvaluation />, needInterceptor: true },
  { path: '/client/consulted-evaluation', component: <ConsultedEvaluation />, needInterceptor: true },
  { path: '/client/consulting-evaluation', component: <ConsultingEvaluation />, needInterceptor: true },
  { path: '/client/start-consult-form', component: <StartConsultForm />, needInterceptor: true },
  { path: '/client/form-iframe', component: <PatientFormIframe />, needInterceptor: true },
  { path: '/client/ex-login', component: <ExternalLogin />, needInterceptor: false },
  { path: '/client/ex-first-setting', component: <ExternalFirstSetting />, needInterceptor: true },
  { path: '/client/multi-modal-test', component: <PatientMultiModalTest />, needInterceptor: true },
  { path: '/client/multi-modal-test-iframe', component: <PatientMultiModalTestIframe />, needInterceptor: true },
  { path: '/client/ex-video-list', component: <ExVideoList />, needInterceptor: true },
  { path: '/client/ex-video-play', component: <ExVideoPlay />, needInterceptor: true },
  { path: '/client/sfda-exclusive-love', component: <SFDAPatientExclusiveLove />, needInterceptor: true },
  { path: '/client/sfda-psychological-test', component: <SFDAPsychologicalTest />, needInterceptor: true },
  { path: '/client/fenglin-psychological-test', component: <FenglinPsychologicalTest />, needInterceptor: true },
  { path: '/client/external-login', component: <AnonymousLogin />, needInterceptor: false },
  // 减压视频
  { path: '/client/jysp', component: <Jysp />, needInterceptor: true },
  // 录音后解析成文字，然后转chatGpt输入结果
  { path: '/iat-to-gpt', component: <IatToChatGpt />, needInterceptor: true },
  { path: '/client/recommend-chat', component: <RecommendChat />, needInterceptor: true },

  { path: '/client/initial-evaluation', component: <InitialEvaluation />, needInterceptor: true },
  { path: '/client/initial-evaluation-page', component: <InitialEvaluationPage />, needInterceptor: true },
  { path: '/client/sub-account', component: <SubAccount />, needInterceptor: true },
  // 新版首页
  {
    path: '/client/home-v2', component: <HomeV2 />, children: [
      { path: 'main', component: <PatientMain />, needInterceptor: true },
      { path: 'training-list', component: <TrainingList />, needInterceptor: true },
      { path: 'resource-list', component: <ResourceList />, needInterceptor: true },
      { path: 'psychological-test', component: <PsychologicalTest />, needInterceptor: true }
    ]
  },
  { path: '/client/login-v2', component: <PatientLoginV2 />, needInterceptor: false },
  { path: '/client/login-with-register', component: <PatientLoginWithRegisterV2 />, needInterceptor: false },
  { path: '/client/first-setting-v2', component: <FirstSettingV2 />, needInterceptor: true },
  { path: '/client/resource-list', component: <ResourceList />, needInterceptor: true },
  { path: '/client/resource-detail', component: <ResourceDetail />, needInterceptor: true },
  { path: '/client/reminder-setting', component: <ReminderSetting />, needInterceptor: true },
  { path: '/client/transition', component: <TransitionPage />, needInterceptor: true },
  { path: '/client/training-list', component: <TrainingList />, needInterceptor: true },
  { path: '/client/training-list', component: <TrainingList />, needInterceptor: true },
  { path: '/client/health-report', component: <HealthReport />, needInterceptor: true },
  // 注册界面
  { path: '/client/register', component: <PatientRegisterV2 />, needInterceptor: false },
  { path: '/client/subAccount-register', component: <SubAccountRegister />, needInterceptor: true },

  // 新增怡安绿通介绍界面---纯静态
  { path: '/static/green-channel', component: <GreenChannelService />, needInterceptor: false },
];

export default routers