import React, {useState, useCallback} from 'react';
import { DatePicker } from 'antd-mobile';
import MomentDiffZone from '@/utils/useMomentDiffZone';

function CustomDatePicker (props) {
  const {showWeek} = props;
  const [selectDate, setSelectDate] = useState(new Date());

  function convertDayToZh (day) {
    let zh = '';
    switch(day) {
      case 0:
        zh = '周日'
        break;
      case 1:
        zh = '周一'
        break;
      case 2:
        zh = '周二'
        break;
      case 3:
        zh = '周三'
        break;
      case 4:
        zh = '周四'
        break;
      case 5:
        zh = '周五'
        break;
      case 6:
        zh = '周六'
        break;
    }
    return zh;
  }

  const labelRenderer = (type, data) => {
    switch (type) {
      case 'year':
        return data + '年'
      case 'month':
        return data + '月'
      case 'day':
        if (showWeek) {
          const date = selectDate.getDate();
          if (date === data) {
            const day = selectDate.getDay();
            return `${data}日(${convertDayToZh(day)})`
          }
        }
        return data + '日'
      case 'hour':
        return data + '时'
      case 'minute':
        return data + '分'
      case 'second':
        return data + '秒'
      default:
        return data
    }
  }
  
  return (
    <DatePicker
        {...props}
        defaultValue={props.defaultValue || MomentDiffZone().toDate()}
        onSelect={(value) => {
          setSelectDate(value)
        }}
        min={new Date('1900')}
        renderLabel={labelRenderer}
      />
  )
}

export default CustomDatePicker