import React from 'react';
import './index.less';
import { checkFuncAuth} from '@/utils/utils';
import { useNavigate } from 'react-router-dom';
import familyValuesFamily from '@/assets/images/FamilyValuesFamily.png';
import { getUrls } from '@/utils/getUrls';
import { getStaffDataEnc } from '@/services/api';

// 首页自助成长模块
function ZizhuchengzhangComponent (props) {
  const {funcAuthList, title = '自助成长', tenantId} = props;
  const navigate = useNavigate();
  const menus = [{id: 'training'}];
  const checkedMenus = checkFuncAuth(funcAuthList, menus);
  const { TRAINING_URL } = getUrls();

  // 获取用户dataEnc
  async function _getStaffDataEnc() {
    const res = await getStaffDataEnc();
    const result = _.get(res, ['result', 'dataEnc'], '');
    return result;
  }

  // 点击训练营直接跳转（获取dataEnc）
  async function onTrainingClick () {
    const dataEnc = await _getStaffDataEnc();
    const referrer = window.location.href;
    const url = TRAINING_URL + `login-by-enc?tid=EAP&dataEnc=${dataEnc}&referrer=${referrer}`
    window.location.href = url;
  }

  if (checkedMenus.length === 0) {
    return null
  }
  const recommendTraining = {
    title: '心理训练营'
  }
  return (
    <div>
        <div className='func-title'>{title}</div>
        <div 
          className='recommend-training' 
          onClick={e => {
              // 防止与父组件点击事件重合
              e.stopPropagation();
              onTrainingClick()
            }}
        >
          <img alt='' src={familyValuesFamily}/>
          <div className='recommonr-training-desc'>
            <div className='recommonr-training-text'>
              <div>{recommendTraining.title}</div>
            </div>
            {/* <div className='recommend-training-more' onClick={(e) => {
              // 防止与父组件点击事件重合
              e.stopPropagation();
              navigate('/client/home-v2/training-list')
            }} >更多成长营</div> */}
          </div>
        </div>
      </div>
  )
}

export default ZizhuchengzhangComponent;