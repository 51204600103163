import React, { useState, useEffect } from 'react';
import './index.less';
import { checkFuncAuth } from '@/utils/utils';
import { useNavigate } from 'react-router-dom';
import { getStaffRecommendList } from '@/services/api';
import { Toast } from 'antd-mobile';
import gtjq from '@/assets/images/gtjq.png';
import gxpb from '@/assets/images/gxpb.png';
import _ from 'lodash';

// 首页资源模块
function ZiyuanComponent(props) {
  const { funcAuthList } = props;
  const navigate = useNavigate();
  const menus = [{ id: 'resource' }];
  const checkedMenus = checkFuncAuth(funcAuthList, menus);

  if (checkedMenus.length === 0) {
    return null;
  }

  // 推荐文章
  const [recommendResources, setRecommendResources] = useState([]);

  // 初始化查询
  useEffect(() => {
    async function fetchRecommendList() {
      try {
        const params = { topN: '2' };
        const res = await getStaffRecommendList(params);

        if (res.errorCode) {
          Toast.show({
            content: res.error,
          });
          return;
        }

        const recommendArticles = _.get(res, ['result'], []);
        setRecommendResources(recommendArticles);
      } catch (error) {
        console.error('Error fetching recommend list:', error);
      }
    }

    fetchRecommendList();
  }, []);

  const title = '资源';
  const more = '更多资源';

  function renderZiyuanItem(list) {
    return (
      <div className="form-ziyuan-content">
        {list.map((item, index) => (
          <div key={index} className="form-ziyuan-item" onClick={() => window.location.href = item.blogUrl}>
            <img alt="" src={index === 0 ? gtjq : gxpb} />
            <div>{item.articleZh}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 30, marginBottom: 15, width: '100%', display: 'inline-block' }}>
      <div className="appoint-sonsult-recommend-text">
        <div className="recommend-desc-large">{title}</div>
        <div className="recommend-more" onClick={() => navigate('/client/home-v2/resource-list')}>{more}</div>
      </div>
      {renderZiyuanItem(recommendResources)}
    </div>
  );
}

export default ZiyuanComponent;
