export const positiveThinkingList = [
  {
    id: 0,
    title: '身体扫描',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_1.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_1.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_1.jpg',
    subhead: '28:41',
    sharedDesc: '放松身心，轻松进入睡眠',
    description: [
      '1.帮助入睡、延长睡眠时长、提高睡眠质量',
      '2.帮助放松',
      '3.帮助改善情绪，心情比较平静、愉悦',
      '4.提高对身体觉察的敏感度',
      '5.练习身体作为锚点来安住身心',
      '6.释放蕴藏在身体中的深层压力',
      '7.长期练习，缓解慢性非器质性的身体疼痛',
      '8.净化身体：气脉通畅',
      '9.提升对自己的接纳',
    ],
  },
  {
    id: 1,
    title: '静坐呼吸基础版',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_2.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_2.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_2.jpg',
    subhead: '4:17',
    sharedDesc: '关注呼吸，最简单，也最有效',
    description: ['通过呼吸来帮助安顿身心，感觉到放松、平静、喜悦'],
  },
  {
    id: 2,
    title: '静坐呼吸完整版',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_3.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_3.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_3.jpg',
    subhead: '6:51',
    sharedDesc: '静坐，连接能量的世界',
    description: [
      '1.通过呼吸来帮助安顿身心，感觉到放松、平静、喜悦',
      '2.提升对自身念头、情绪、身体的觉察能力，增强自我调节',
    ],
  },
  {
    id: 3,
    title: '感官唤醒的练习',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_4.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_4.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_4.jpg',
    subhead: '9:29',
    sharedDesc: '打开我们的五感，体会新奇的世界',
    description: [
      '尝试打开你的五种感官（视觉、听觉、触觉、嗅觉、味觉），找到更多与内外在世界的连接。',
    ],
  },
  {
    id: 4,
    title: '三步呼吸空间回应版',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_5.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_5.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_5.jpg',
    subhead: '9:29',
    sharedDesc: '安放身心，觉察此刻的体验',
    description: [
      '在自己碰到困难之事引发一些身心不适时，可以使用三步呼吸空间应对版。提升对自身情绪、念头、身体的觉察能力，增强自我调节；提高用呼吸作为锚点来安顿自己身心的能力。',
    ],
  },
  {
    id: 5,
    title: '三步呼吸空间基础版',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_6.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_6.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_6.jpg',
    subhead: '6:02',
    sharedDesc: '接纳情绪，如实的关照当下',
    description: [
      '作为常规练习，任何时候均可使用三步呼吸空间基础版。提升对自身情绪、念头、身体的觉察能力，增强自我调节；提高用呼吸作为锚点来安顿自己身心的能力。',
    ],
  },
  {
    id: 6,
    title: '山的冥想',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_7.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_7.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_7.jpg',
    subhead: '11:33',
    sharedDesc: '如大山般沉稳宁静，提升稳定、力量、勇气',
    description: [
      '根据指导语，选择安静不会被打扰的空间，进行练习。体会人的心境，会随着外界环境的变化而变化，就如同山的外貌随四季变化而变化，但内在依然可以拥有山的稳固与安然。',
    ],
  },
  {
    id: 7,
    title: '湖的冥想',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_8.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_8.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_8.jpg',
    subhead: '6:59',
    sharedDesc: '如水一般善纳万物，迎接一切变化',
    description: [
      '根据指导语，选择安静不会被打扰的空间，进行练习。体会人的心境，会随着外界环境的变化而变化，就如同水面景致会随天气周围风光而变化，但心灵深处的深静所在，永远不会被外界所影响，永远陪伴、支持着我们前行。有落水经历者不建议使用湖的冥想。',
    ],
  },
  {
    id: 8,
    title: '腹式呼吸',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_9.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_9.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_9.jpg',
    subhead: '6:12',
    sharedDesc: '一个随时随地，仅需6分钟的放松体验',
    description: [
      '日常生活中，大多数人常常习惯于胸式呼吸，呼吸时腹部被动运动。这容易导致过度换气和其他的一些呼吸困难。腹式呼吸中，处于肺底的腹隔肌是收缩的，这会使腹部膨拢，使空气进人肺部深处。',
    ],
  },
  {
    id: 9,
    title: '屏气呼吸',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_10.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_10.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_10.jpg',
    subhead: '6:38',
    sharedDesc: '入门小练习，快速缓解当下的紧张与焦虑',
    description: [
      '在做屏气呼吸的练习时，我们可以用鼻吸气，在这个过程中你可以在心里从1默数到3，这样会让空气进入肺部深处。屏住呼吸并从1数到3，然后通过嘴唇缝隙吹气的方式呼出气体，同时默念“放……松。',
    ],
  },
  {
    id: 10,
    title: '节律呼吸',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_11.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_11.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_11.jpg',
    subhead: '5:38',
    sharedDesc: '新的未来，从独一无二的呼吸开始',
    description: [
      '在做节律呼吸的练习时，我们在呼和吸之间不需要屏气。我们只需要用鼻子吸气，然后在这个过程中你可以在心里从1默数到3或者6，具体吸气时长可根据我们自我感觉舒服为度。然后以嘴巴缓缓地呼气，在心里默念同样的时间。',
    ],
  },
  {
    id: 11,
    title: '放松训练-12组肌肉群',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_12.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_12.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_12.jpg',
    subhead: '17:13',
    sharedDesc: '深层放松，改善焦虑，拥有清新愉快的感受',
    description: [
      '放松技术是一种以激活副交感神经系统、中和焦虑的生理反应为原理的方法。通过完成完成12组肌肉群的紧绷和放松的动作，激活副交感神经，降低身体的紧张程度，使身体逐渐放松下来，达到缓解焦虑的作用。',
    ],
  },
  {
    id: 12,
    title: '放松训练-8组肌肉群',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_13.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_13.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_13.jpg',
    subhead: '15:54',
    sharedDesc: '通过8组肌肉放松，缓解紧张',
    description: [
      '放松技术是一种以激活副交感神经系统、中和焦虑的生理反应为原理的方法。通过完成完成8组肌肉群的紧绷和放松的动作，激活副交感神经，降低身体的紧张程度，使身体 逐渐放松下来，达到缓解焦虑的作用。',
    ],
  },
  {
    id: 13,
    title: '放松训练-4组肌肉群',
    audio: 'https://image.siuvo.com.cn/mindfulness/voice_14.mp3',
    background: 'https://image.siuvo.com.cn/mindfulness/thinking_cover_14.png',
    cover: 'https://image.siuvo.com.cn/mindfulness/voice_cover_14.jpg',
    subhead: '9:00',
    sharedDesc: '9分钟让你获得放松的肌肉与身心',
    description: [
      '放松技术是一种以激活副交感神经系统、中和焦虑的生理反应为原理的方法。通过完成完成4组肌肉群的紧绷和放松的动作，激活副交感神经，降低身体的紧张程度，使身体 逐渐放松下来，达到缓解焦虑的作用。',
    ],
  },
];
