import React, { useEffect, useState, useCallback, useMemo } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import {Picker} from 'antd-mobile';
import {getStaffSettingInfo, updateStaffSetting} from '@/services/api';

// 提醒设置
const ReminderSetting = (props) => {

  // const [status, setStatus] = useState('saved'); // 提醒设置的状态
  const [selectedType, setSelectedType] = useState('');
  const [visible, setVisible] = useState(false) // picker的显示状态
  const typeOptions = [
    ['每两周一次',
    '每四周一次',
    '不需要',]
  ]

  useEffect(() => {
    _getStaffSettingInfo();
  }, [])

  const getDayFromOption = (option) => {
    switch (option) {
      case '每两周一次':
        return 14;
      case '每四周一次':
        return 28;
      default:
        return 0
    }
  }

  // 查询设置详情
  async function _getStaffSettingInfo () {
    const res = await getStaffSettingInfo();
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    } 
    const evaluationPushCycle = _.get(res, ['result', 'evaluationPushCycle'], '');
    setSelectedType(evaluationPushCycle)
  }
  // 更新设置详情
  async function _updateStaffSetting (evaluationPushCycle) {
    const params = {
      evaluationPushCycle: evaluationPushCycle[0],
      evaluationPushCycleDay: getDayFromOption(evaluationPushCycle[0])
    }
    const res = await updateStaffSetting(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    } 
    setSelectedType(evaluationPushCycle[0]);
    Toast.show({
      content: '提醒设置成功'
    })
  }

  const settingText = useMemo(() => {
    if (!selectedType) {
      return '请点击修改设置'
    }
    return selectedType;
  }, [selectedType])

  function renderSelect () {
    return (
      <>
        <div className='block-select'>{settingText}</div>
        <Picker
          columns={typeOptions}
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          value={selectedType}
          onConfirm={v => {
            _updateStaffSetting(v)
          }}
        />
      </>
    )
  }

  // 渲染按钮（修改提醒--确认修改）
  function renderStatusBtn () {
    // if (status === 'saved') {
      return (
        <div className='block-btn' onClick={() => {
          setVisible(true);
        }}>修改</div>
      )
    // }
    // return (
    //   <div className='block-btn'>确定</div>
    // )
  }

  function renderContent () {
    return (
      <>
        <div className='setting-hint-text'>短信提醒</div>
        <div className='setting-block'>
          <div className='block-text'> 心理健康状态评估：</div>
          <div>
            {renderSelect()}
          </div>
          {renderStatusBtn()}
        </div>
      </>
    )
  }

  return (
    <>
    <TitleBar
      title='提醒设置'
      isShowBack={true}
    />
    <div className='reminder-setting-page'>
      {renderContent()}
    </div>
    </>
  )
}

export default ReminderSetting;