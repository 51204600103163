// 正念练习的列表
import React, { useState, useEffect } from 'react';
import { Image } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTracking from '@/components/WithTracking';
import { positiveThinkingList } from '@/Constant/positiveThinking';

function PositiveThinkingList() {
  const navigate = useNavigate();

  // 更新正念练习的list
  function RenderThinkCard() {
    return (
      <>
        {/* <div className='thinking-card-navigation'>
          <div className='thinking-card-navigation-left'>23分钟</div>
          <div className='thinking-card-navigation-middle'>1次</div>
          <div className='thinking-card-navigation-right'>0分钟</div>
        </div> */}
        <div className="thinking-card-body">
          {positiveThinkingList.map((element, index) => {
            return (
              <div
                className="thinking-card"
                key={index}
                onClick={() => {
                  navigate(`/client/positive-thinking-iframe/${element.id}`);
                }}
              >
                <div className="card-body">
                  <Image
                    src={element.background}
                    className="card-body-image"
                    fit="cover"
                  />
                  <div className="card-body-description">{element.subhead}</div>
                </div>
                <div className="card-title">{element.title}</div>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <>
      <TitleBar title="正念练习" isShowBack={true} />
      <div className="positive-thinking-page">{RenderThinkCard()}</div>
    </>
  )
}

export default WithTracking(
  PositiveThinkingList,
  {
    name: 'visit:positive-thinking',
    props: {
      eventName: '访问正念练习'
    }
  }
);