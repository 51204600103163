// 减压视频
import React, { useState, useEffect } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Image} from 'antd-mobile';
import bdj from './img/bdj.jpg';
import cxlx from './img/cxlx.jpg';
import znlx from './img/znlx.jpg';
import jrfs from './img/jrfs.jpg';
import qxfs from './img/qxfs.jpg';

// 资源列表页---减压视频
function JyspComponent() {
	const navigate = useNavigate();
	const location = useLocation();
	const videoList = [
		{
			title: '正念练习',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/mindfulness.mp4',
			pic: znlx
		},
		{
			title: '八段锦',
			videoUrl: 'https://image.siuvo.com.cn/union_baduanjin/baduanjin_intro.mp4',
			pic: bdj
		},
		{
			title: '正念慈心练习',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/cixin.mp4',
			pic: cxlx
		},
		{
			title: '情绪释放技术',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/ReleaseEmotion.mp4',
			pic: qxfs
		},
		{
			title: '肌肉放松训练',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/relax.mp4',
			pic: jrfs
		},
	]

	function onVideoPlay(item) {
		navigate('/client/ex-video-play', { state: { videoInfo: item } })
	}

	return (
		<div className='jysp-component'>
			{
				videoList.map((item, index) => {
					return (
						<div key={index} className='jysp-item'>
							<Image src={item.pic} className='swiper-img-background' />
							<div className='swiper-text'>{item.title}</div>
						</div>
					)
				})
			}
		</div>
	)
}

export default JyspComponent;