import React from 'react';
import {Image} from 'antd-mobile';
import _ from 'lodash';
import './index.less'

// 通用组件，包含陪伴，咨询等，左边是icon，右边是desc
function ConsultItem (props) {
  const {avatarUrl, title, desc, renderRight=() => {},  handleClick = () => {}} = props;
  return (
    <div className='consultant-item' onClick={handleClick}>
      <Image
        className='item-avatar'
        src={avatarUrl}
        fit='cover'
      />
      <div className='item-info'>
        <div className='item-nick-name'>{title}</div>
        <div className='item-desc'>{desc}</div>
      </div>
      <div className='item-right'>
        {renderRight()}
      </div>
    </div>
  )
}

export default ConsultItem;