// 聊天记录
import React, { useRef } from 'react';
import _ from 'lodash';
import { UserOutline } from 'antd-mobile-icons';
import { PullToRefresh, DotLoading, Avatar } from 'antd-mobile';

import './index.less';
import siuvoRobot from '@/assets/images/avatar_robot.png';

const statusRecord = {
  pulling: '下拉获取更多消息',
  canRelease: '松开吧',
  refreshing: '玩命加载中...',
  complete: '好啦',
};

/**
 * 
 * @param {*} props 
 * messageList 消息列表
 * authId--->用于判断是不是自己（msg中有authid）
 */
export default function Chat(props) {
  const { 
    messageList, 
    isShowLoading, 
    isShowResponseLoading, 
    isDisplayLoading = true, 
    handleVideoClick, 
    onloadMore, 
    authId 
  } = props;
  const pagesNum = useRef();

  function renderRightMessage(content, index, isNlpResponse) {
    return (
      <div className="dialogue-block flex-end" key={index}>
        <div className="dialogue right-message-text">
          <div>{content}</div>
        </div>
        <div className="head">
          <UserOutline />
        </div>
      </div>
    );
  }

  function renderLeftMessage(content, index, isNlpResponse) {
    return (
      <div
        className="dialogue-block flex-start"
        key={index}
      >
        <div className="head">
          {/* <UserOutline />*/}
          <Avatar src={siuvoRobot} style={{ '--size': '32px' }} />
        </div>
        <div className="dialogue left-message-text" style={{ background: isNlpResponse ? 'lavender' : '#e9e9e9' }}>
          <div>
            {content}
            {/* {
              isShowResponseLoading &&
              <DotLoading />
            } */}
          </div>
        </div>
      </div>
    );
  }

  function renderMessageContentItem(item) {
    if (typeof (item) === 'string') {
      return (
        <div dangerouslySetInnerHTML={{ __html: item }}></div>
      );
    };
    if (typeof (item) === 'object') {
      return item.map((element, index) => {
        return (
          <div key={index}>{renderMessageContentItem(element)}</div>
        );
      });
    }
    if (typeof (item) === 'function') {
      return item();
    }
    if (typeof (item) === 'array') {
      return item.map((element, index) => {
        return (
          <div key={index}>{renderMessageContentItem(element)}</div>
        );
      });
    }
  }

  // 解析消息内容 渲染不同的格式
  function renderMessageContent(item, position) {
    const itemText = item.text || item.title_zh || item.message;
    // 如果有videoUrl, 渲染成audio
    if (item.audioUrl) {
      return (
        <div className='audio-message' style={{ zoom: '0.8' }}>
          <audio src={item.audioUrl} controls="controls" />
        </div>
      )
    }
    if (item.videoUrl) {
      return (
        <video
          controls
          width="100%"
          height="200px"
          style={{ borderRadius: '8px' }}
          playsInline={true}
          webkit-playsinline='true'
          onPlay={() => {
            handleVideoClick();
          }}
        >
          <source src={item.videoUrl} type="video/mp4" />
        </video>
      )
    }
    else {
      return renderMessageContentItem(itemText);
    }
  }

  return (
    <>
      <div className="chatting-records-content">
        <PullToRefresh
          onRefresh={onloadMore}
          refreshingText={(text) => {
            return <div>{statusRecord[text]}</div>;
          }}
        >
          <div className='chat-list-content'>
            {
              messageList && messageList.map((item, index) => {
                if (item.title_zh || item.title_zh === '' || item.videoUrl || (item.fromUserId && item.fromUserId !== authId)) {
                  // const isNlpResponse = item['_id']?.includes('nlp_response')
                  const isNlpResponse = item['messageType'] ?? false;
                  return renderLeftMessage(renderMessageContent(item, 'left'), index, isNlpResponse);
                }
                else {
                  // 如果是有明确的字符才显示
                  if (typeof (item.text) === 'string' || typeof (item.text) === 'array' || typeof (item.text) === 'object' || item.fromUserId === authId) {
                    // const isNlpResponse = item['_id']?.includes('nlp_response')
                    const isNlpResponse = item['messageType'] ?? false;
                    return renderRightMessage(renderMessageContent(item, 'right'), index, isNlpResponse);
                  }
                }
              })
            }
          </div>
        </PullToRefresh>
        {
          isDisplayLoading && (
            <div
              className='dialogue-block flex-start fade-in'
              style={{
                visibility: isShowLoading ? 'visible' : 'hidden',
              }}
            >
              <div className='head'>
                <Avatar src={siuvoRobot} style={{ '--size': '32px' }} />
              </div>
              <div className='dialogue left-message-text' style={{ fontSize: '28px' }} >
                <DotLoading />
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}
