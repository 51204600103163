import React, { useEffect, useRef, useState, useCallback } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tabs, Swiper, Checkbox, Button, Toast } from 'antd-mobile'

import './index.less';
import TitleBar from '@/components/TitleBar';
import CustomConfirmDialog from '@/components/CustomConfirmDialog';
import { getQuestionnaireList, createFormTask } from "@/services/api";
import { emit_message_to_server } from '@/actions';
import { CHAT_TO_ONE } from '@/actions/actionType';
import getUUID from '@/utils/getUUID';

function FormLibrary(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const chatAuthId = _.get(location, ['state', 'chatAuthId'], '');
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectForms, setSelectForms] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [tabItems, setTabItems] = useState([]);

  const _getQuestionnaireList = useCallback(async () => {
    let res = await getQuestionnaireList();
    setTabItems(res.result);
  }, [])

  useEffect(() => {
    _getQuestionnaireList();
  }, [])

  // 根据id，删除指定的item 
  function removeChild(arr, id) {
    const res = arr.reduce((pre, cur) => {
      if (cur.id !== id) {
        pre.push(cur);
      }
      return pre;
    }, [])
    return res;
  }
  // 组装后台接口数据
  function combineWfVariablesData(selectList) {
    let wfVariablesResult = [];
    console.log('selectList::', selectList)
    for (let i = 0; i < selectList.length; i++) {
      let pickedItems = [];
      let wholeItems = [];
      pickedItems.push(selectList[i].id);
      wholeItems.push(selectList[i]);
      // let wfVariablesData = JSON.stringify()
      let wfVariablesData = {
        Forms: {
          pickedItems,
          wholeItems
        }
      }
      wfVariablesResult.push(wfVariablesData)
    }
    console.log('wfVariablesResult:', wfVariablesResult)
    const result = wfVariablesResult.reduce((pre, cur) => {
      pre.push(JSON.stringify(cur))
      return pre;
    }, [])
    return result;
  }

  function checkFormItem(form, i) {
    return (
      <Checkbox
        className='item-check-box'
        value={form.id}
        key={i}
        onChange={(value) => {
          if (value) {
            setSelectForms([...selectForms, form]);
          } else {
            const res = removeChild(selectForms, form.id);
            setSelectForms([...res]);
          }
        }}>
        {form['name_zh']}
      </Checkbox>
    )
  }

  function renderFormLibraryContent() {
    return (
      tabItems.length > 0 ? <div className='form-library-content'>
        <Tabs
          className='content-tabs'
          activeKey={tabItems[activeIndex]['name_zh']}
          onChange={key => {
            const index = tabItems.findIndex(item => item['name_zh'] === key)
            setActiveIndex(index)
            swiperRef.current?.swipeTo(index)
          }}
        >
          {tabItems.map(item => {
            return <Tabs.Tab
              title={item['name_zh']}
              key={item['name_zh']}
            />
          })}
        </Tabs>
        <Swiper
          className='swiper-content'
          direction='horizontal'
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={index => {
            setActiveIndex(index)
          }}
        >
          {
            tabItems.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <div className='form-swipe-item-content'>
                    {item.picks.map((form, i) => {
                      return checkFormItem(form, i)
                    })}
                  </div>
                </Swiper.Item>
              )
            })
          }
        </Swiper>
        <div className='send-form-content'>
          <Button
            className='send-form-btn'
            onClick={() => {
              setDialogVisible(true)
            }}>
            发送
          </Button>
        </div>
      </div> : null
    )
  }

  function renderSelectFormsContent(forms) {
    return (
      <>
        {
          forms.map((item, index) => {
            return (
              <div key={index} className='dialog-content-item'>
                {item['name_zh']}
              </div>
            )
          })
        }
      </>
    )
  }

  function renderDialogTitle() {
    return <div className='dialog-title'>请确认您要发送以下量表：</div>
  }

  // 发送量表
  function onMessageSend(task) {
    const taskId = task.taskId;
    const formList = task.taskItem.formList;
    const messages = formList.reduce((pre, cur) => {
      const wfVariables = JSON.parse(cur.wfVariables);
      const message = {
        name: wfVariables.Forms.wholeItems[0]['name_zh'],
        seq: cur.seq,
        taskId: taskId
      }
      pre.push(message);
      return pre
    }, [])
    console.log('message:::', messages)
    const messageObj = {
      chatType: CHAT_TO_ONE,
      message: JSON.stringify(messages),
      toUserId: chatAuthId,
      messageType: 'TASK',
      chatMsgId: getUUID()
    }
    // EMIT_MESSAGE_TO_SERVER
    dispatch(emit_message_to_server(messageObj))
    setDialogVisible(false);
    navigate(-1);
  }

  // 发送量表获取到对应的链接
  async function sendForm(selectForms) {
    const convertedData = combineWfVariablesData(selectForms)
    const params = {
      staffId: chatAuthId,
      wfVariablesList: convertedData
    }
    let res = await createFormTask(params)
    if (res.errorCode) {
      Toast.show('量表信息生成失败，请重试')
    } else {
      onMessageSend(res.result)
    }
  }

  return (
    <div className='form-library-root'>
      <TitleBar title='测评量表' isShowBack={true}></TitleBar>
      {renderFormLibraryContent()}
      <CustomConfirmDialog
        title={renderDialogTitle()}
        visible={dialogVisible}
        content={renderSelectFormsContent(selectForms)}
        onCancel={() => {
          setDialogVisible(false)
        }}
        onConfirm={() => {
          sendForm(selectForms)
        }}
      />
    </div>
  )
}

export default FormLibrary;