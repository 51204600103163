import React from 'react';
import { setEntranceUrl, getEntranceUrl } from '@/utils/localStorage';

function useEntryPath() {

  function saveEntryPath() {
    const { href, pathname, search } = window.location;
    if (href.toLowerCase().indexOf('login') > -1) {
      const entryPath = pathname + search;
      setEntranceUrl(entryPath);
    }
  }

  function getEntryPath() {
    const entry = getEntranceUrl();
    return entry;
  }

  return { saveEntryPath, getEntryPath }
}

export default useEntryPath;