import React, { useState, useEffect } from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { doctorGetStaffList, batchAdd } from '@/services/api';
import { Picker, Toast, Button } from 'antd-mobile'
import '@/components/Calendar/index.less';
import CustomDatePicker from '@/components/CustomDatePicker';

/**
 * 添加来访者预约
 * @param {*} props 
 * @returns 
 */
function AddCalendar(props) {
  const [dateVisible, setDateVisible] = useState(false); // 选择时间的picker
  const [patientVisible, setPatientVisible] = useState(false); // 选择患者的picker
  const [dataIndex, setDataIndex] = useState(-1);
  const [timeslots, setTimeslots] = useState([]);
  const [patientList, setPatientList] = useState([]);
  const [submitErrList, setSubmitErrList] = useState([]);
  const [hasConflict, setHasConflict] = useState(false);

  const addPointErrorText = ' 与已确定的值班/可预约时间/预约时间重合'

  useEffect(() => {
    setTimeslots([{appointTime: MomentDiffZone().add(1, 'hour')}])
    _doctorGetStaffList();
  }, [props.status]);

  // 监听submitErrList，同步更新timeSlots
  useEffect(() => {
    if (submitErrList.length > 0) {
      const _timeSlots = _.cloneDeep(timeslots);
      submitErrList.map((item) => {
        _timeSlots[item] && (_timeSlots[item].hasConflict = true)
      })
      setTimeslots(_timeSlots);
    }
  }, [submitErrList])

  // timeSlot变化，更新
  useEffect(() => {
    const hsc = checkConflictData(timeslots);
    setHasConflict(hsc)
  }, [timeslots])

  // 根据后端返回的时间戳，寻找在timeSlot中的index
  function getIndex(time, arr) {
    const length = arr.length;
    for (let i=0; i < length; i++) {
      const item = arr[i];
      const timeslot = MomentDiffZone(MomentDiffZone(item.appointTime).format('YYYY-MM-DD HH:mm')).valueOf();
      if (timeslot === time) {
        return i
      }
    }
    return null;
  }

  // 后端返回的数据转换
  function converToErrorList (data) {
    let res = data.reduce((pre,cur) => {
      const index = getIndex(cur, timeslots)
      if (index!== null) {
        pre.push(index)
      }
      return pre;
    }, [])
    return res;
  }

  async function batchAddAppoint (data) {
    const params = data.reduce((pre,cur) => {
      const param = {
        appointTime: MomentDiffZone(MomentDiffZone(cur.appointTime).format('YYYY-MM-DD HH:mm')).valueOf(),
        staffId: cur.staffId
      }
      pre.push(param)
      return pre
    }, [])
    let res = await batchAdd({
      list: params
    });
    if (res.errorCode === 'BIZ_CHECK_FAIL') {
      const errList = res.error.split(',')
      const data = converToErrorList(errList);
      setSubmitErrList(data)
    } else {
      Toast.show({
        icon: 'success',
        content: '添加成功'
      })
      setSubmitErrList([]);
      props.backToCalendar();
    }
    return null
  }

  function checkConflictData (timeslots) {
    const res = timeslots.filter(item => item.hasConflict)
    return res.length > 0;
  }

  // 校验数据是否包含提交时的冲突时段
  function checkoutDataAndSubmit() {
    const isIncludeConflict = submitErrList.length > 0 && checkConflictData(timeslots);
    if (!isIncludeConflict) {
      batchAddAppoint(timeslots)
    } else {
      Toast.show({
        content: '请先修改有冲突的预约',
      })
    }
  }

  function createEmptyData (arr) {
    arr.push({appointTime: MomentDiffZone().add(1, 'hour')});
    return arr;
  }

  // 修改提交数据的属性并新增一条空数据
  function editSubmitDataAndcreateEmptyData (index) {
    const _timeslots = _.cloneDeep(timeslots);
    const editData = {..._timeslots[index], isSubmit: 'submit'};
    _timeslots.splice(index, 1, editData);
    setTimeslots(createEmptyData(_timeslots));
  }

  // 创建一条新的空数据
  async function addAppointAndEmptyData () {
    const length = timeslots.length;
    if (length === 0) {
      setTimeslots(createEmptyData([]));
    } else {
      const slot = _.cloneDeep(timeslots[length - 1]);
      // 检查前一条数据是否输入完整，并添加一条新的数据
      if (slot && slot.appointTime && slot.staffId) {
        // editSubmitDataAndcreateEmptyData(index);
        setTimeslots(createEmptyData( _.cloneDeep(timeslots)))
      } else if (slot && !slot.appointTime) {
        Toast.show({
          icon: 'fail',
          content: '请先选择预约时间'
        })
      } else if (slot && !slot.staffId) {
        Toast.show({
          icon: 'fail',
          content: '请选择来访者'
        })
      } else {
        editSubmitDataAndcreateEmptyData(length - 1)
      }
    }
  }


  // 删除预约或者空数据
  async function deletAppointmentOrEmptyData (i) {
    const _timeslots = _.cloneDeep(timeslots);
    _timeslots.splice(i, 1);
    setTimeslots(_timeslots);
  }


  // 转换数据
  function convertPickerData (arr) {
    const pickerData = arr.reduce((pre, cur) => {
      const data = {
        label: cur.name,
        value: cur.staffId
      }
      pre.push(data)
      return pre;
    }, [])
    return [pickerData];
  }
  async function _doctorGetStaffList() {
    let res = await doctorGetStaffList();
    const result = _.get(res, 'result', []);
    // 组装picker中需要的数据格式
    setPatientList(convertPickerData(result));
  }
  
  // 渲染选择患者
  function renderSelectPatient (t, i) {
    return (
      <>
        <div className='calendar-date-picker' onClick={() => {
          setDataIndex(i);
          setPatientVisible(true);
          setDataIndex(i);
        }}>
          <div className='calendar-date-value' style={{ width: '5rem' }}>{t.name || '请选择'}</div>
        </div>
      </>
    )
  }

  // 渲染时间选择
  function renderSelectTimeSlot () {
    return (
      <>
        {timeslots.map((t, i) => <div className={`calendar-datetime-picker ${t.hasConflict && 'error-tx-color'}`} key={i}>
          <div
            className='calendar-delete-picker'
            onClick={() => {
              deletAppointmentOrEmptyData(i)
            }}
          >
            <img alt="" src={require('@/assets/icon/delete.svg').default} />
          </div>
          {
            renderSelectPatient(t, i)
          }
          <div className='calendar-date-picker' onClick={() => {
            setDateVisible(true);
            setDataIndex(i);
          }}>
            <div className='calendar-date-value'>
              {/* <div className='calendar-date-icon'><CalendarOutline color={t.hasConflict ? 'red':'blue'} /></div> */}
              {MomentDiffZone(t.appointTime).format('YYYY-MM-DD')}
            </div>
          </div>
          <div className='calendar-date-picker' onClick={() => {
            setDateVisible(true);
            setDataIndex(i);
          }}>
            <div className='calendar-date-value'>
              {MomentDiffZone(t.appointTime).format('HH:mm')}
            </div>
            <div className='calendar-date-end'>{'- ' + MomentDiffZone(t.appointTime).add(1, 'hour').format('HH:mm')}</div>
          </div>
        </div>)}
      </>
    )
  }

  return (
    <>
      <div className="calendar-add-match-page">
        <div className="calendar-add-match-content">
          {renderSelectTimeSlot()}
          {
            hasConflict && (
              <div className='submit-error-hint'>
                <img 
                  alt=""
                  src={require('@/assets/icon/warning.svg').default} 
                />
                {addPointErrorText}
              </div>
              )
          }
          <div 
            className='add-calendar-value' 
            onClick={() => {
            addAppointAndEmptyData()
          }}
          >
            <img 
              alt=""
              src={require('@/assets/icon/dialogue/add.svg').default} 
              className="add-icon"
            />
          </div>
        </div>
        <div className="bottom-btn">
          <Button
            style={{ 
              padding: '.5rem 2rem' ,
              width: '90%',
            }}
            color='warning'
            onClick={() => {
              checkoutDataAndSubmit()
            }}>
            确认添加
          </Button>
        </div>
        <CustomDatePicker
          visible={dateVisible}
          onClose={() => {
            setDateVisible(false)
          }}
          precision='minute'
          onConfirm={val => {
            const _timeslots = _.cloneDeep(timeslots);
            const data = {..._timeslots[dataIndex], appointTime: new Date(val), staffId: _timeslots[dataIndex].staffId, hasConflict: false}
            _timeslots.splice(dataIndex, 1, data);
            setTimeslots(_timeslots);

          }}
        />
        <Picker
          visible={patientVisible}
          columns={patientList}
          onConfirm={(value, ex) => {
            const _timeslots = _.cloneDeep(timeslots);
            _timeslots[dataIndex].staffId = value[0]
            const patient =  ex.items.filter(item => item.value === value[0])
            _timeslots[dataIndex].name = patient[0].label;
            setTimeslots(_timeslots);
            setHasConflict(false);
          }}
          onClose={() => {
            setPatientVisible(false);
          }}
        />
      </div>
    </>
  )
}

export default AddCalendar;