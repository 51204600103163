import React, { useState, useEffect, useRef } from 'react';
import './index.less';
import { Input, Radio, Button, Toast } from 'antd-mobile';
import TitleBar from '@/components/TitleBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { setPatientBaseInfo, sendPatientVerifyCode, checkVerifyCode, updateStaffInfo } from '@/services/api';
import { setLoginToken, setIsFirstTimeLogin, getLocalNeedFirstEvaluationStatus } from '@/utils/localStorage'
import moment from 'moment'
import _ from 'lodash';
import CustomDatePicker from '@/components/CustomDatePicker';
import { save_patient_info } from '@/actions'
import { useDispatch, useSelector } from 'react-redux';
import MomentDiffZone from '@/utils/useMomentDiffZone';

function FirstSetting(props) {
  const ROLE = 'patient';
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { consultantId, organizationId, needCellphone, roleInfo = {}, readOnly } = _.get(location, ['state'], {});
  const patientInfo = useSelector((state) => state.baseInfo[ROLE]);
  const [name, setName] = useState(_.get(roleInfo, 'name', ''));
  const [gender, setGender] = useState(_.get(roleInfo, 'gender', ''));
  const [birthdayTimestamp, setBirthdayTimestamp] = useState(_.get(roleInfo, 'birthday', ''));
  const [cellphone, setCellPhone] = useState();
  const [visible, setVisible] = useState(false)
  // 短信验证码
  const [verificationCode, setVerificationCode] = useState('');
  // 短信倒计时
  const [vcodeInterval, setVcodeInterval] = useState(0);
  const timerID = useRef(null);

  // 获取本地存取的是否需要初始评估的状态
  const needFirstEvaluation = getLocalNeedFirstEvaluationStatus();


  // 发送短信倒计时
  useEffect(() => {
    if (vcodeInterval > 0) {
      timerID.current = setTimeout(() => {
        setVcodeInterval(vcodeInterval - 1)
      }, 1000)
      timerID.current = null;
    }
    return () => {
      timerID.current = null;
    }
  }, [vcodeInterval])

  // 发送短信验证码  
  async function handleVerifyCode() {
    if (!cellphone || cellphone.length !== 11) {
      Toast.show('请检查您的手机号码')
      return;
    }
    handleSendVerifyCode(cellphone);
    setVcodeInterval(30);
  }

  // 发送短信验证码
  async function handleSendVerifyCode(cellphone) {
    const res = await sendPatientVerifyCode(cellphone);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '短信发送成功',
        key: 'success'
      });
    }
  }

  // 校验短信验证码
  async function _checkVerifyCode() {
    const params = {
      verifyCode: verificationCode
    }
    const res = await checkVerifyCode(cellphone, params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      console.error('checkVerifyCode.error::', res.error)
      return false;
    }
    return res.result;
  }

  // 提交手机号，更新操作
  async function updateTelephone() {
    if (cellphone && verificationCode) {
      const cheeckResult = await _checkVerifyCode();
      if (!cheeckResult) {
        Toast.show({
          content: '您输入的短信验证码不正确',
          key: 'code'
        });
        return;
      }
    }
    const params = {
      telephone: cellphone
    }
    const res = await updateStaffInfo(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      const info = { ...patientInfo, roleInfo: res.result };
      dispatch(save_patient_info(info))
      if (needFirstEvaluation) {
        navigate('/client/initial-evaluation');
      } else {
        navigate('/client/home-v2/main')
      }
    }
  }

  // 提交信息
  async function submitBaseInfo() {
    if (!name) {
      Toast.show({
        content: '请填写姓名',
        key: 'name'
      });
      return;
    }
    if (!gender) {
      Toast.show({
        content: '请填写性别',
        key: 'gender'
      });
      return;
    }
    if (!birthdayTimestamp) {
      Toast.show({
        content: '请选择生日',
        key: 'birthdayTimestamp'
      });
      return;
    }
    if (cellphone && !verificationCode) {
      Toast.show({
        content: '请输入短信验证码',
        key: 'code'
      });
      return;
    }
    // 先校验短信和手机是否匹配
    if (cellphone && verificationCode) {
      const cheeckResult = await _checkVerifyCode();
      if (!cheeckResult) {
        Toast.show({
          content: '您输入的短信验证码不正确',
          key: 'code'
        });
        return;
      }
    }
    // 手机号码不强制输入
    // if (needCellphone) {
    //   if (!cellphone || cellphone.length !== 11) {
    //     Toast.show({
    //       content: '请检查您的手机号是否正确',
    //       key: 'cellphone'
    //     });
    //     return;
    //   }
    // }

    const params = {
      name,
      gender,
      birthday: new Date(birthdayTimestamp).getTime(),
      authId: _.get(location, ['state', 'authId'], ''),
    }
    if (needCellphone) {
      params['cellphone'] = cellphone;
    }
    consultantId && (params['consultantId'] = consultantId);
    organizationId && (params['organizationId'] = organizationId);
    let res = await setPatientBaseInfo(params);
    if (res.errorCode) {
      setIsFirstTimeLogin(ROLE, true)
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      // 更新token
      const token = _.get(res, ['result', 'token'], '');
      const info = { ...patientInfo, roleInfo: res.result };
      setLoginToken(ROLE, token);
      setIsFirstTimeLogin(ROLE, false)
      dispatch(save_patient_info(info))
      if (needFirstEvaluation) {
        navigate('/client/initial-evaluation');
      } else {
        navigate('/client/home-v2/main')
      }
    }
  }

  return (
    <>
      <TitleBar title="账号设置" />
      <div className="info">
        <div className="info-content">
          <div className="info-title">姓名</div>
          <Input size="large" placeholder="请输入您的姓名" value={name} onChange={val => setName(val)} readOnly={readOnly} />
          <div className="info-title">性别</div>
          <Radio.Group
            onChange={val => setGender(val)}
            size="large"
            value={gender}
            className="info-radio"
            disabled={roleInfo.gender}
          >
            <Radio value="MALE" style={{ marginRight: '2rem' }}>男</Radio>
            <Radio value="FEMALE">女</Radio>
          </Radio.Group>
          <div className="info-title">生日</div>
          <div onClick={() => {
            !readOnly && setVisible(true);
          }}>
            <Input size="large" placeholder="请输入您的生日" disabled={visible} value={birthdayTimestamp && MomentDiffZone(birthdayTimestamp).format('YYYY-MM-DD')} readOnly={readOnly} />
          </div>
          <CustomDatePicker
            title='出生日期'
            visible={visible}
            onClose={() => {
              setVisible(false)
            }}
            defaultValue={MomentDiffZone('1990').toDate()}
            onConfirm={val => {
              setBirthdayTimestamp(val)
            }}
          />
          {
            needCellphone ? <>
              <div className="info-title">手机号码</div>
              <Input size="large" placeholder="请输入您的手机号码" onChange={val => setCellPhone(val)} />
              <div className="phone-code-content">
                <Input className="phone-input phone-code" placeholder="请输入验证码" onChange={value => setVerificationCode(value)} />
                {
                  vcodeInterval === 0 ? <Button className={`phone-send-code-btn bg-theme-client}`} color='primary' onClick={(handleVerifyCode)}>获取验证码</Button> :
                    <Button className="phone-send-code-btn" style={{ '--background-color': '#aaa', color: '#fff' }}>{vcodeInterval}s重新发送</Button>
                }
              </div>
            </> : null
          }
        </div>
        <Button size="large" className="info-btn-submit" onClick={() => {
          if (readOnly) {
            // 设置手机号
            updateTelephone()
          } else {
            // 设置基础信息
            submitBaseInfo()
          }
        }}>填写完成</Button>
      </div>
    </>
  )
}

export default FirstSetting;