import _request from '@/utils/request';
import { getUrls } from '@/utils/getUrls';

/**
 * 获取sse连接链接
 * @param {*} params clientId
 */
const { BASE_URL, TRAINING_URL } = getUrls();
// URL 参数拼接，直接遍历对象，若为value为空，则不会拼接
export function _getQuerystr(params) {
  if (!params) return '';
  if (typeof (params) === 'string') return params
  const queryStr = Object.keys(params)
    .reduce((ary, key) => {
      if (key && params[key]) {
        ary.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
      return ary;
    }, [])
    .join('&');
  return '?' + queryStr;
}

// // 医生登录
// export async function loginByDoctor(data) {
//   const url = `/doctorAuthService/loginOrRegisterByTelephoneAndVerifyCode`;
//   console.log(data);
//   return await _request({
//     method: 'POST',
//     url,
//     data
//   });
// }

// // 获取量表列表
// export async function getAllQuestionnaire() {
//   const url = `/questionnaire/getAllQuestionnaire`;
//   return await _request({
//     method: 'GET',
//     url
//   });
// }

// // 新增EAP计划
// export async function updatePlan(data) {
//   const url = `/plan/update`;
//   return await _request({
//     method: 'PUT',
//     url,
//     data
//   });
// }

// // 医生删除模板
// export async function deletePlanTemplate(planTemplateId) {
//   const url = `/planTemplate/delete/${planTemplateId}`;
//   return await _request({
//     method: 'DELETE',
//     url
//   });
// }


// 文件上传
export async function uploadFile(data) {
  const url = `/img/upload`;
  return await _request({
    method: 'POST',
    url,
    data,
    type: 'upload'
  });
}

// 获取绑定的咨询师列表
export async function bindList() {
  const url = `/staff/consultant/bindList`;
  return await _request({
    method: 'GET',
    url
  });
}
// 根据当前员工，返回推荐咨询师列表
export async function getSuggestConsultants() {
  const url = `/staff/suggest`;
  return await _request({
    method: 'GET',
    url,
    type: 'hideError'
  });
}

// 查询咨询师排班日历
export async function patientGetConsultantScheduling(consultantId, params) {
  return await _request({
    method: 'GET',
    url: `/staff/consultant/${consultantId}/scheduling${_getQuerystr(params)}`
  });
}

// 添加与咨询师的预约
export async function patientAddConsultantAppoint(consultantId, data) {
  return await _request({
    method: 'POST',
    url: `/staff/consultant/${consultantId}/appoint`,
    data
  });
}

// 咨询师查询工作日历（排班+预约）
export async function doctorGetConsultantScheduling(params) {
  return await _request({
    method: 'GET',
    url: `/consultant/scheduling${_getQuerystr(params)}`
  });
}

// 查询来访者列表
export async function doctorGetStaffList() {
  return await _request({
    method: 'GET',
    url: `/consultant/staff/list`
  });
}

// 添加可预约时段
export async function doctorAddAppointTimeslot(data) {
  return await _request({
    method: 'POST',
    url: `/consultant/appointTimeslot`,
    data
  });
}

// 咨询师添加预约
export async function doctorAddAppoint(data) {
  return await _request({
    method: 'POST',
    url: `/consultant/appoint`,
    data
  });
}

// 咨询师删除可预约时段
export async function doctorDeleteAppointTimeslot(timeslotId) {
  return await _request({
    method: 'DELETE',
    url: `/consultant/appointTimeslot/${timeslotId}`
  });
}

// 咨询师取消预约
export async function doctorDeleteAppoint(appointId) {
  return await _request({
    method: 'DELETE',
    url: `/consultant/appoint/${appointId}`
  });
}

// 咨询师查询聊天记录
export async function doctorGetChatMsg(params) {
  return await _request({
    method: 'GET',
    url: `/consultant/chat/msgPage${_getQuerystr(params)}`
  });
}

// 员工查询聊天记录
export async function patientGetChatMsg(params) {
  return await _request({
    method: 'GET',
    url: `/staff/chat/msgPage${_getQuerystr(params)}`
  });
}

// 咨询师查询（已绑定）STAFF列表
export async function doctorGetChatStaffList(params) {
  return await _request({
    method: 'GET',
    url: `/consultant/staff/chatStaffList${_getQuerystr(params)}`
  });
}

// 咨询师查询STAFF列表 分页接口
export async function doctorGetStaffListPage(params) {
  return await _request({
    method: 'GET',
    url: `/consultant/staff/pageChatStaff${_getQuerystr(params)}`
  });
}

// 咨询师聊天文件上传
export async function doctorUploadChatFile(resourceType, data) {
  return await _request({
    method: 'POST',
    url: `/consultant/upload/${resourceType}`,
    data,
    type: 'upload'
  });
}

// 咨询师申请修改资料
export async function consultantInfoApply(params) {
  const url = '/consultant/infoApply/add';
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// 咨询师撤销修改资料申请
export async function consultantRevokeInfoApply(applyId) {
  const url = `/consultant/infoApply/${applyId}/revoke`;
  return await _request({
    method: 'POST',
    url,
    data: applyId,
  })
}

// 咨询师分页查询资料申请修改记录
export async function consultantSearchInfoApply(params) {
  const url = `/consultant/infoApply/page${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [咨询师] 咨询完成确认
export async function consultantConsultCompleted(params) {
  const url = `/consultant/consult/consultRecodeCompleted`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [咨询师] 关闭音视频房间
export async function consultantLeaveRoom(params) {
  const url = `/consultant/appoint/leaveRoom`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [咨询师]匿名拨号
export async function consultantAnonymousCall(params) {
  let url = ''
  const { staffId } = params;
  if (!staffId) {
    url = `/consultant/appoint/bindSecretNo/noStaff`
  } else {
    url = `/consultant/appoint/bindSecretNo/${params.staffId}`;
  }
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [咨询师]撤回消息
export async function consultantChatWithdraw(params) {
  const url = `/consultant/chat/withdraw`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// 患者聊天文件上传
export async function patientUploadChatFile(resourceType, data) {
  return await _request({
    method: 'POST',
    url: `/staff/upload/${resourceType}`,
    data,
    type: 'upload'
  });
}


// 员工获取 RTC token
// 参数 channelType    预约APPOINT   默认DEFAULT
export async function getPatientRTCToken(params) {
  const url = `/staff/RTC/createToken${_getQuerystr(params)}`
  return await _request({
    method: 'GET',
    url
  });
}

// 咨询师获取RTC token
export async function getDoctorRTCToken(params) {
  const url = `/consultant/RTC/createToken${_getQuerystr(params)}`
  return await _request({
    method: 'GET',
    url
  });
}

// 医生登录
export async function loginByDoctor(data) {
  const url = `/consultant/autoRegisterLogin`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 员工登录
export async function loginByPatient(data) {
  const url = `/staff/autoRegisterLogin`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 设置员工基本信息，仅可设置一次
export async function setPatientBaseInfo(data) {
  const url = `/staff/info`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}
// 员工发送短信验证码 telephone
export async function sendPatientVerifyCode(telephone) {
  const url = `/staff/sendVerifyCode/${telephone}`;
  return await _request({
    method: 'POST',
    url
  });
}
// 咨询师发送短信验证码
export async function sendDoctorVerifyCode(telephone) {
  const url = `/consultant/sendVerifyCode/${telephone}`;
  return await _request({
    method: 'POST',
    url
  });
}

// 员工查询匹配量表
export async function initMatchQuestionnaireUrl() {
  const url = `/staff/initMatchQuestionnaireUrl`
  return await _request({
    method: 'GET',
    url
  });
}

/**
 * 通知员工发起音视频聊天
 * @param {*} roomUrl  
 * @param {*} toUserId  
 * @returns 
 */
export async function createAppointRoomSuccess(data) {
  const url = `/consultant/appoint/room`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 员工绑定咨询师
export async function bindConsultant(consultantId) {
  const url = `/staff/consultant/${consultantId}/bind`;
  return await _request({
    method: 'POST',
    url
  });
}

// 舒小辅获取所有咨询师信息
export async function getAllConsultantList() {
  const url = `/consultant/list`;
  return await _request({
    method: 'GET',
    url
  });
}

/**
 *  舒小辅手动绑定咨询师
 * @param {*} data consultantId staffId
 * @returns 
 */
export async function reBindBySiuvo(data) {
  const url = `/consultant/shuXiaoFu/reBind`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 批量预约
export async function batchAdd(data) {
  const url = `/consultant/appoint/batchAdd`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 咨询师查看 员工基本信息
export async function getPatientBaseInfo(id) {
  const url = `/staff/info?authId=${id}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 员工取消预约
export async function patientDeleteAppoint(appointId) {
  return await _request({
    method: 'DELETE',
    url: `/staff/appoint/${appointId}`
  });
}

// 咨询师通过authId查询STAFF详情
export async function doctorGetPatientInfo(params) {
  const url = `/consultant/staff/info${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url
  });
}

// [咨询师]查询来访者的form list
export async function doctorGetPatientFormList(params) {
  const url = `/consultant/task/formList${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 咨询师通过authId查询基本信息
export async function doctorGetSelfInfo(id) {
  const url = `/consultant/info?authId=${id}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 员工通过authId查询咨询师基本信息
export async function patientGetDoctorInfo(id) {
  const url = `/staff/consultant/info?authId=${id}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 员工通过authid查询基本信息
export async function patientGetSelfInfo(id) {
  const url = `/staff/info?authId=${id}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 咨询师查询量表list
export async function getQuestionnaireList() {
  const url = `/consultant/questionnaire/list`;
  return await _request({
    method: 'GET',
    url
  });
}

// 咨询师更新个人信息
export async function updateConsultantInfo(data) {
  const url = `/consultant/info/update`;
  return await _request({
    method: 'PUT',
    url,
    data
  });
}

// 根据选择的量表生成task
export async function createFormTask(data) {
  const url = `/consultant/task/create`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 查询任务详情
export async function getFormTaskList(taskId) {
  const url = `/consultant/task?taskId=${taskId}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 查询任务详情
export async function getQuestionnaireUrl(params) {
  const url = `/consultant/questionnaireUrl${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 获取量表作答的链接地址
export async function getFormH5Url(taskId, seq) {
  const url = `/staff/task/formUrl?taskId=${taskId}&seq=${seq}`;
  return await _request({
    method: 'GET',
    url
  });
}

export async function getFormCallbackResult(taskId, seq) {
  const url = `/staff/task/formCallbackResult?taskId=${taskId}&seq=${seq}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 获取量表作答的链接地址
export async function startConsultantConsult(consultId) {
  const url = `/consultant/consult?consultId=${consultId}`;
  return await _request({
    method: 'GET',
    url
  });
}

// 来访者端获取所有量表信息
export async function getStaffFormList(params) {
  const url = `/staff/task/formList${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// 根据选择的量表生成task
export async function clearUnreadMsg(data) {
  const url = `/consultant/chat/clear`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 来访者查询量表list
export async function getStaffQuestionnaireList() {
  const url = `/staff/questionnaire/list`;
  return await _request({
    method: 'GET',
    url
  });
}

// [用户]创建自选量表task,返回url
export async function createStaffTask(data) {
  const url = '/staff/task/create';
  return await _request({
    method: 'POST',
    url,
    data
  })
}

// [员工] 分页查询咨询评价
export async function getStaffEvaluationList(params) {
  const url = `/staff/consult/pageEvaluation${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [员工] 查询咨询评价
export async function getStaffEvaluation(evaluationId) {
  const url = `/staff/consult/evaluation/${evaluationId}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [员工] 新增咨询评价
export async function createEvaluation(params) {
  const url = `/staff/consult/createEvaluation`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [员工] 分页查询咨询记录（recode）
export async function getStaffConsultRecode(params) {
  const url = `/staff/consult/pageRecode${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [员工] 咨询完成确认
export async function staffConsultCompleted(params) {
  const url = `/staff/consult/consultRecodeCompleted`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [员工]查询咨询详情
export async function staffGetConsultantInfo(consultRecodeId) {
  const url = `/staff/consult/${consultRecodeId}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [员工] 关闭音视频房间
export async function staffLeaveRoom(params) {
  const url = `/staff/appoint/leaveRoom`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [员工] 撤回消息
export async function staffChatWithdraw(params) {
  const url = `/staff/chat/withdraw`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [员工] 查询聊天记录
export async function staffGetChatMsg(params) {
  const url = `/staff/chat/msgPage${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [来访者] 查询设置通知详情
export async function getStaffSettingInfo() {
  const url = `/staff/getSettingInfo`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [来访者]打点服务的接口
export async function addEventDot(params) {
  const url = `/eventDot/create`;
  return _request({
    method: 'POST',
    url,
    data: params,
  });
}

// [来访者] 修改设置通知详情
// params: evaluationPushCycle
export async function updateStaffSetting(params) {
  const url = `/staff/updateSetting`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [来访者] 完成测评提交
export async function completeStaffEvaluation(params) {
  const url = `/staff/completeEvaluation`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [来访者] 查询tag列表
export async function getStaffTagList() {
  const url = `/staff/getTagList`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [来访者] 查询用户首页文章推荐列表
export async function getStaffRecommendList(params) {
  const url = `/staff/getRecommendList${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [来访者] 查询用户首页文章推荐列表
export async function getStaffAllRecommendList(params) {
  const url = `/staff/getAllRecommendList${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [来访者] 查询用户首页文章推荐列表
export async function getNeedFirstEvaluation() {
  const url = `/staff/needFirstEvaluation`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [来访者] 查询第三方用户参加的class列表
export async function getListByTenant(params) {
  const url = `${TRAINING_URL}api/class/listByTenant${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
    ex: true
  })
}

// [来访者] 获取DataEnc接口
export async function getStaffDataEnc(params) {
  const url = `/staff/getDataEnc`;
  return await _request({
    method: 'GET',
    url,
  })
}

// 短的id获取原始id
export async function getOriginalIdByShortId(shortChatAuthId) {
  const url = `/shortId/authId/${shortChatAuthId}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员]查询管理员列表
export async function getAdminList(params) {
  const url = `/admin/list${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}
// [管理员]管理员查询来访者聊天记录的咨询师列表
export async function adminGetConsultantByStaff(params) {
  const url = `/admin/getConsultantListByStaff${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// admin service api
// 管理员登录
export async function loginByAdmin(data) {
  const url = `/admin/login`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// [管理员]查询待审核列表
export async function getInfoApply(params) {
  const url = `/admin/infoApply/page${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员]拒绝咨询师资料申请
export async function refuseInfoApply(params) {
  const url = '/admin/infoApply/refuse';
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [管理员]同意咨询师资料申请
export async function agreeInfoApply(params) {
  const url = '/admin/infoApply/pass';
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [管理员]通过authId查询咨询师基本信息
export async function getConsultantInfo(authId) {
  const url = `/admin/consultant/info${_getQuerystr(authId)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员]查询咨询师列表
export async function getConsultantList(params) {
  const url = `/admin/consultant/list${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员] 查询咨询师工作日历（排班+预约）
export async function getConsultantScheduling(params) {
  const url = `/admin/consultantScheduling${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员] 查询咨询记录
export async function getConsultant(params) {
  const url = `/admin/consult${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员] 分页查询咨询记录
export async function getConsultantByTime(params) {
  const url = `/admin/consult/page${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员] 按照来访者分组查询咨询记录
export async function getConsultantByGroup(params) {
  const url = `/admin/consult/groupList${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  });
}

// [管理员] 分页查询咨询评价
export async function adminGetEvaluationList(params) {
  const url = `/admin/consult/pageEvaluation${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员] 查询咨询师的咨询评价总分
export async function adminGetEvaluationConsultantTotalScore(consultantId) {
  const url = `/admin/evaluation/consultantTotalScore${_getQuerystr(consultantId)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员]搜索咨询师（姓名or昵称or手机号）
export async function adminSearchConsultant(searchKeyword) {
  const url = `/admin/searchConsultant${_getQuerystr(searchKeyword)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员] 清除未读消息
export async function adminClearChat(params) {
  const url = `/admin/chat/clear`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [管理员] 查询聊天记录
export async function adminGetChatMsg(params) {
  const url = `/admin/chat/msgPage${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员] 聊天文件上传
export async function adminUpload(resourceType, params) {
  const url = `/admin/upload/${resourceType}`;
  return await _request({
    method: 'POST',
    url,
    data: params,
    type: 'upload'
  })
}

// [管理员]查询咨询师的咨询评价总得分
export async function adminGetRecord(params) {
  const url = `/admin/consult/pageRecode${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员] 咨询完成确认
export async function adminConsultRecodeCompleted(params) {
  const url = `/admin/consult/consultRecodeCompleted`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [管理员] 查看最近10次排班计划 
export async function adminGetLastTenTriggers(params) {
  const url = `/callcenter/getLastTenTriggers${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员] 上传排班表
export async function adminUploadScheduling(params) {
  const url = `/callcenter/schedule/upload`;
  return await _request({
    method: 'POST',
    url,
    data: params,
    type: 'upload'
  })
}

// [管理员] 查询排班咨询师列表
export async function adminGetAllExterns() {
  const url = `/callcenter/getAllExterns`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [管理员] 修改排班咨询师信息
export async function adminUpdateExtern(params) {
  const url = `/callcenter/updateMobile${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// [国药-员工]查询量表list
export async function getSFDAQuestionnaireList() {
  const url = `/staff/SFDA/questionnaire/list`;
  return await _request({
    method: 'GET',
    url
  });
}

// [国药-员工]上传关爱信息
export async function addStaffCareFor(params) {
  const url = `/staff/SFDA/addStaffCareFor`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// 匿名登录--使用dataEnc
/**
 * 
 * @param {*} data organizationId dataEnc
 * @returns 
 */
export async function loginByDataEnc(data) {
  const url = `/staff/loginByDataEnc`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

/* 
 * 学号登录--使用学号+密码
 * @param {*} data: account dataEnc
 * @returns 
 */
export async function loginByAccount(data) {
  const url = `/staff/loginByAccount`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

/**
 *  打开咨询师的聊天窗口 时调用 动作触发
 * @param {*} data: triggerType  chatAuthId time
 * @returns 
 */
export async function staffTrigger(data) {
  const url = `/staff/trigger`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

/**
 *  咨询师打开和来访者的聊天窗口 时调用 动作触发
 * @param {*} data: triggerType  chatAuthId time
 * @returns 
 */
export async function consultantTrigger(data) {
  const url = `/consultant/trigger`;
  return await _request({
    method: 'POST',
    url,
    data
  });
}

// 多模态心理测评增加参数
export async function addAIRecord(params) {
  const url = `/staff/addAIRecord`;
  return await _request({
    method: 'POST',
    url,
    data: params
  });
}

// 管理员查询来访者信息
export async function adminSearchAuth(params) {
  const url = `/admin/searchAuth${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

// 验证手机号码
export async function checkVerifyCode(telephone, params) {
  const url = `/staff/checkVerifyCode/${telephone}${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}
// 更新基础信息，目前仅手机号码
export async function updateStaffInfo(params) {
  const url = `/staff/update`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// [来访者] 清除未读消息
export async function clientClearChat(params) {
  const url = `/staff/chat/clear`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// 七陌查询用户列表
/**
 * 
 * @param {*} params 
  ringingDateFrom 查询被叫振铃开始时间from
  ringingDateTo 查询被叫振铃开始时间to
  callNo 主叫号码
  calledNo 被叫号码
 * @returns 
 */
export async function getCallcenterList(params) {
  const url = `/callcenter/page${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

/**
 *  咨询师分页查询咨询记录列表
 * @param {*} params 
 * @returns 
 */
export async function getConsultationRecord(params) {
  const url = `/consultant/consultationRecord/page${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}

/**
 *  管理员分页查询咨询记录列表
 * @param {*} params 
 * @returns 
 */
export async function getAdminConsultationRecord(params) {
  const url = `/admin/consultationRecord/page${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}
/**
 *  咨询师查询记录详情
 * @param {*} params  recordId
 * @returns 
 */
export async function getConsultationRecordDetail(params) {
  const url = `/consultant/consultationRecord/${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}
/**
 *  管理员查询记录详情
 * @param {*} params  recordId
 * @returns 
 */
export async function getAdminConsultationRecordDetail(params) {
  const url = `/admin/consultationRecord/${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url,
  })
}
/**
 *  管理员下载记录列表
 * @param {*} params
 * @returns 
 */
export async function downloadConsultationRecord(params) {
  const url = `${BASE_URL}admin/downloadConsultationRecord${_getQuerystr(params)}`;
  // return await _request({
  //   method: 'GET',
  //   url,
  // })
  return url;
}

// 咨询师新增记录列表
export async function createConsultationRecord(params) {
  const url = `/consultant/consultationRecord/create`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

// 咨询师新增记录列表
export async function updateConsultationRecord(params) {
  const url = `/consultant/consultationRecord/update`;
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}

export function getSSEConnectUrl(params) {
  return BASE_URL + 'sse/createConnect' + _getQuerystr(params);
}
/**
 * 获取sse断开链接
 * @param {*} params clientId
 */
export function closeSSEConnectUrl(params) {
  return BASE_URL + 'sse/closeConnect' + _getQuerystr(params);
}

// 清除掉用户和ai对话的聊天记录
export async function clearChatRecord(params) {
  const url = `/ai/clear${_getQuerystr(params)}`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  });
}

// [来访者]验证注册码
export async function checkRegisterCode(params) {
  const url = `/staff/v2/checkRegisterCode${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url
  });
}
// [来访者]校验手机号
export async function checkTelephone(params) {
  const url = `/staff/v2/checkTelephone${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url
  });
}

// [来访者]登录
export async function loginV2(params) {
  const url = `/staff/v2/login${_getQuerystr(params)}`;
  return await _request({
    method: 'GET',
    url
  });
}

// [来访者]关联已有账号
export async function linkWithCode(params) {
  const url = `/staff/v2/linkWithCode`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  });
}

// [来访者]注册新账号
export async function staffRegisterAccount(params) {
  const url = `/staff/v2/registerWithCode`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [来访者]注册子账号
export async function staffRegisterSubAccount(params) {
  const url = `/staff/v2/registerSubAccount`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [来访者]关联子账号
export async function staffBindSubAccount(params) {
  const url = `/staff/v2/linkSubAccount`;
  return await _request({
    method: 'POST',
    url,
    data: params,
  })
}

// [来访者]查询子账号列表
export async function staffGetSubList() {
  const url = `/staff/listSub`;
  return await _request({
    method: 'GET',
    url
  })
}

// [来访者] 删除子账号
export async function staffDeleteSubAccount(params) {
  const url = '/staff/v2/deleteSubAccount';
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}


// [新增咨询师，管理员] 关闭工单
// staffAuthId 来访者authId
// orderType 工单类型：1-文字咨询 2-AI咨询 3-向导
export async function closeWorkOrder(params) {
  const url = '/workOrder/close';
  return await _request({
    method: 'POST',
    url,
    data: params
  })
}




