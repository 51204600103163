// 路由访问不到的页面，展示404
import React, { useEffect } from 'react';
import { ErrorBlock } from 'antd-mobile';

const NotFoundPage = () => {
  useEffect(() => {
    document.title = '404';
  }, []);

  return (
    <div>
      <ErrorBlock
        title="404"
        description="页面不存在"
        fullPage
      />
    </div>
  );
}

export default NotFoundPage;