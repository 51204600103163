// 数据打点 纯异步行为 非阻塞事件
/**
 *  id: "xxxx",# 事件唯一标识,使用uuid保证全局唯一
    source: "browser"，#事件源，如：browser|EAP|TRAINING|<service name> 等
    name: "jsonfom_finished",#事件名称，根据业务需求自行定义
    userId: "xxxxx", #用户唯一标识，如果场景中获取不到userId,则可以填anonymous表示匿名用户，EAP传authId,心理训练营传studentId
    props: {}
    occurOn: 1623375719,   #事件发生的时间点
    project: "", #项目 EAP|TRAINING 等
    env: "uat", #保留字段，以后Event数据集中保存后，用来区分不同部署环境发生的事件    
    deviceId: "",#保留字段，以后用来生成设备id
    sessionId:"",#保留字段，可以在用户登录完成后，生成sessionId,用来把后续产生的所有event串起来分析
    tId:"",#持续性的行为
 */
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addEventDot } from '@/services/api';
import CONFIG from 'GlobalConfigFile';
import { _getPhoneModel } from '@/utils/utils';
import { decodeToken} from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';
const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;

const WithTracking = (WrappedComponent, params) => {
  const TrackableComponent = (props) => {
    const { name } = WrappedComponent;
    console.log('WithTracking name', name);
    const location = useLocation();
    const { pathname } = location;
    const ua = _getPhoneModel();

    // 根据pathname判断角色
    const recognizeRole = () => {
      if (pathname.includes('client')) {
        return 'patient';
      }
      if (pathname.includes('therapist')) {
        return 'doctor';
      }
      if (pathname.includes('admin')) {
        return 'admin';
      }
      return 'anonymous';
    }
    const staffId = useSelector(state => state.baseInfo[recognizeRole(pathname)]?.roleInfo?.staffId);
    const { tenantId } = decodeToken(getLoginToken(recognizeRole(pathname)))

    useEffect(() => {
      const uuid = uuidv4();
      const trackingParams = {
        id: uuid,
        source: 'browser',
        // 传给后端13位的时间戳
        occurOn: moment().unix() * 1000,
        project: 'EAP',
        env: DEFAULT_SERVER,
        userId: staffId || 'anonymous',
        deviceId: ua,
        ...params,
        name: params?.name || name,
        props: {
          ...params?.props,
          tenantId: tenantId || 'anonymous',
        },
      };
      addEventDot(trackingParams);
      return () => {
        console.log(`track ${name} unmounted`);
      };
    }, [name, staffId, tenantId]);

    return (
      <WrappedComponent
      // {...props}
      />
    );
  }

  return TrackableComponent;
}

export default WithTracking;