// 多模态心理测评iframe 
import React, { useEffect } from 'react';
import _ from 'lodash';

import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import IframeComponent from '@/components/Iframe';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function MultiModalTestIframe(props) {
  const { handleAddEventDotClick } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const title = _.get(location, ['state', 'title']);
  const url = _.get(location, ['state', 'url']);

  // 打点
  useEffect(() => {
    handleAddEventDotClick({
      name: 'visit:ceping/multi-modal-test',
      props: {
        eventName: '测评：访问AI心理辅导师'
      }
    });
  }, []);

  return (
    <>
      <IframeComponent
        title={title}
        src={url}
        permission="camera; microphone"
        barCancel={() => {
          // 返回上级页面（登录页面）再返回上级页面（AI测评）---update直接返回主页
          // navigate(-2);
          navigate('/client/home-v2/main');
        }} />
    </>
  )
}
export default WithTrackingForMedia(MultiModalTestIframe);