import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Dialog } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import { getUrls } from '@/utils/getUrls';
import { getListByTenant, getStaffDataEnc } from '@/services/api';
import { funcList } from '@/Constant';
import makingCall from '@/utils/makingCall';
import { decodeToken } from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';
import familyValuesFamily from '@/assets/images/FamilyValuesFamily.png';
import familyValuesCouple from '@/assets/images/FamilyValuesCouple.png';

const ROLE = 'patient';
const { TRAINING_URL } = getUrls();
const { tenantId } = decodeToken(getLoginToken(ROLE))
const { trainingCall } = funcList[tenantId] || funcList.default;

// 训练营查看界面
function TrainingList(props) {
  const { handleAddEventDotClick } = props;
  // 训练营目前就两个营，暂时写死
  const trainingListAll = [
    {
      courseName: '心理成长营',
      courseId: '1',
      pic: familyValuesFamily
    },
    {
      courseName: '亲子关系训练营',
      courseId: '2',
      pic: familyValuesCouple
    }
  ]

  // 用户已加入的训练营
  const [joinedList, setJoinedList] = useState([]);
  // 用户信息的dataEnc
  const [dataEnc, setDataEnc] = useState('');

  async function _getStaffDataEnc() {
    const res = await getStaffDataEnc();
    const result = _.get(res, ['result', 'dataEnc'], '');
    setDataEnc(result);
  }

  async function _getListByTenant(dataEnc) {
    const params = {
      tenantId: 'EAP',
      dataEnc
    }
    const res = await getListByTenant(params);
    const result = _.get(res, ['result'], []);
    setJoinedList(result)
  }

  // 进到该页面埋点
  useEffect(() => {
    handleAddEventDotClick({
      name: 'visit:selfGrowth/training',
      props: {
        eventName: '自助成长：访问训练营'
      }
    })
  }, [])

  // 获取用户dataEnc
  useEffect(() => {
    _getStaffDataEnc();
  }, [])

  // 初始查询用户加入的营
  useEffect(() => {
    if (dataEnc) {
      _getListByTenant(dataEnc)
    }
  }, [dataEnc])

  function renderTrainingItem(item, index, handleClick) {
    return (
      <div
        className='recommend-training'
        key={index}
        onClick={handleClick}
      >
        <img alt='' src={item.pic || item.courseId === '1' ? familyValuesFamily : familyValuesCouple} />
        <div className='recommend-training-desc'>
          <div className='recommend-training-text'>
            <div>{item.courseName}</div>
          </div>
        </div>
      </div>
    )
  }


  function renderJoinedList() {
    if (joinedList === null) {
      return null
    }
    if (joinedList.length === 0) {
      return (
        <div className='training-list-empty'>
          <div>您现在还没有加入的训练营！</div>
          <div>请联系客服加入</div>
        </div>
      )
    }
    return (
      <div>
        <div className='block-title'>已加入的营</div>
        {
          joinedList.map((item, index) => {
            return renderTrainingItem(item, index, () => {
              const url = TRAINING_URL + `login?providerId=EAP&dataEnc=${dataEnc}`
              window.location.href = url;
              // 跳转到训练营 打点
              // handleAddEventDotClick({
              //   name: 'visit:selfGrowth/training',
              //   props: {
              //     eventName: '自助成长：访问训练营',
              //     courseName: item.courseName
              //   }
              // })
            })
          })
        }
      </div>
    )
  }
  // 渲染全部
  function renderAll() {
    return (
      <div>
        <div className='block-title'>全部成长营</div>
        {
          trainingListAll.map((item, index) => renderTrainingItem(item, index, () => {
            Dialog.confirm({
              title: '提示',
              content: '即将拨打客服电话，咨询训练营入营事宜。',
              onConfirm: () => { makingCall(trainingCall) }
            })
          }))
        }
      </div>
    )
  }


  return (
    <>
      <TitleBar title='训练营' isShowBack={true} />
      <div className='training-list-page'>
        {renderJoinedList()}
        {renderAll()}
      </div>
    </>
  )
}

export default WithTrackingForMedia(TrainingList);