// 封装moment组件, 展示不同时区的时间
// import React, { useState, useEffect } from 'react';
import Moment from 'moment-timezone';

const MomentDiffZone = (time) => {
  // 从本地取下选中的时区
  const localZone = localStorage.getItem('timeZone') || Moment.tz.guess();
  // const [zoneTime, setZoneTime] = useState(Moment.tz(time, localZone));
  const zoneTime = Moment.tz(time, localZone);

  // useEffect(() => {
  //   // 时区变化时, 重新计算时间
  //   const localZone = localStorage.getItem('zone') || Moment.tz.guess();
  //   setZoneTime(Moment.tz(time, localZone));
  // }, [time, localZone]);

  return zoneTime
}

export default MomentDiffZone;