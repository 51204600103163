import React, { useState, useEffect, useCallback } from 'react';
import './index.less';
import { checkFuncAuth } from '@/utils/utils';
import { useNavigate } from 'react-router-dom';
import familyValuesFamily from '@/assets/images/FamilyValuesFamily.png';
import { getStaffQuestionnaireList, createStaffTask } from "@/services/api";
import formStress from '@/assets/images/form_stress.png';
import formAI from '@/assets/images/form_AI.png';
import { addAIRecord } from '@/services/api';
import { getUrls } from '@/utils/getUrls';
import { ModalList } from '@/Constant/moltiModalList';

// 首页测评模块
function CepingComponent(props) {
  const { funcAuthList, staffId, tenantId } = props;
  const navigate = useNavigate();
  const menus = [{ id: 'form-test' }];
  const checkedMenus = checkFuncAuth(funcAuthList, menus);
  if (checkedMenus.length === 0) {
    return null
  }
  const { disabledAITest } = checkedMenus[0]
  const { AI_BASE_URL } = getUrls();
  // 查询默认的form
  const title = '测评';
  const more = '更多测评';
  const recommendFormPic = [formAI, formStress];
  const [defaultForms, setDefaultForms] = useState([]);

  const AIForm = {
    title: 'AI多模态测评',
    pic: formAI,
    handleClick: _addAIRecord
  }

  const recommendForms = [];
  if (!disabledAITest) {
    recommendForms.push(AIForm);
    recommendForms.push({
      title: defaultForms[0]?.name_zh,
      pic: recommendFormPic[1],
      url: '',
      handleClick: () => {
        getFormUrl(defaultForms[0]);
      }
    })
  } else {
    defaultForms.map((defaultForm, index) => {
      recommendForms.push({
        title: defaultForm?.name_zh,
        pic: recommendFormPic[index],
        url: '',
        handleClick: () => {
          getFormUrl(defaultForm);
        }
      });
    })
  }

  // 暂时没有推荐，查默认的，选第一个放在首页上
  useEffect(() => {
    _getStaffQuestionnaireList();
  }, [])
  // 先用咨询师那边的量表接口模拟
  const _getStaffQuestionnaireList = useCallback(async () => {
    let res = await getStaffQuestionnaireList();
    const defaultItem1 = res.result[0];
    const defaultItem2 = res.result[4] || res.result[1];
    const form1 = defaultItem1?.picks[0];
    const form2 = defaultItem2?.picks[0];
    setDefaultForms([form1, form2])
  }, [])

  // AI测评免登
  async function _addAIRecord() {
    // 拉取测评列表
    const name = 'AI多模态测评';
    if (tenantId === 'fafuli') {
      navigate('/client/multi-modal-test', { state: { moltiModalList: ModalList[tenantId] } })
    } else {
      const params = {
        type: 'pinganFiveInOne',
        name
      }
      const res = await addAIRecord(params);
      const { dataEnc, callbackUrl, organizationId } = _.get(res, ['result'], {});
      const url = AI_BASE_URL + '/login?type=pinganFiveInOne' + `&dataEnc=${dataEnc}&callbackUrl=${callbackUrl}&organizationId=${organizationId}&providerId=${organizationId}`
      navigate('/client/multi-modal-test-iframe', {
        state: {
          title: name,
          url: url,
        }
      })
    }
  }
  // 组装后台接口数据
  function combineWfVariablesData(selectList) {
    let wfVariablesResult = [];
    for (let i = 0; i < selectList.length; i++) {
      let pickedItems = [];
      let wholeItems = [];
      pickedItems.push(selectList[i].id);
      wholeItems.push(selectList[i]);
      // let wfVariablesData = JSON.stringify()
      let wfVariablesData = {
        Forms: {
          pickedItems,
          wholeItems
        }
      }
      wfVariablesResult.push(wfVariablesData)
    }
    const result = wfVariablesResult.reduce((pre, cur) => {
      pre.push(JSON.stringify(cur))
      return pre;
    }, [])
    return result;
  }

  // 获取量表的url进行跳转
  async function getFormUrl(form) {
    let selectForms = [];
    selectForms.push(form);
    const convertedData = combineWfVariablesData(selectForms);
    let params = {
      staffId,
      taskType: 'STAFF_OPTIONAL',
      wfVariablesList: convertedData,
    };
    let res = await createStaffTask(params);
    if (res.errorCode) {
      Toast.show('量表信息生成失败，请重试')
    } else {
      navigate('/client/psychological-test-user-form', { state: { url: res?.result?.firstFormUrl, title: form?.name_zh } })
    }
  }
  // 测评item
  function renderFormItem(list) {
    return (
      <div className='form-ziyuan-content'>
        {
          list.map((item, index) => {
            return (
              <div key={index} className='form-ziyuan-item' onClick={() => item.handleClick()}>
                <img alt='' src={item.pic} />
                <div>{item.title}</div>
              </div>
            )
          })
        }
      </div>
    )
  }
  return (
    <div style={{ marginTop: 30 }}>
      <div className='appoint-sonsult-recommend-text'>
        <div className='recommend-desc-large'>{title}</div>
        <div className='recommend-more' onClick={() => {
          navigate('/client/home-v2/psychological-test')
        }}>{more}</div>
      </div>
      {renderFormItem(recommendForms)}
    </div>
  )
}

export default CepingComponent;