import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBar from "@/components/TitleBar";
import StaffInfoComponent from "@/components/UserInfo/StaffInfo";
import { doctorGetPatientInfo } from "@/services/api";
import { useDispatch } from "react-redux";
import { getLoginToken } from '@/utils/localStorage.js';
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';
import { getRoleFromLocation } from '@/utils/commonUtils';
import changeName from '@/utils/changeName';
import { getPageQuery } from '@/utils/utils';

// 之前页面的参数role可以不用传
function PatientInfo(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const staffInfo = _.get(location, ["state", "staffInfo"], {});
  // const role = _.get(location, ["state", "role"], {});
  const role = changeName(getRoleFromLocation());
  const [patientInfo, setPatientInfo] = useState({});
  const lob = getLobFromToken(getLoginToken(role));
  const {authId} = getPageQuery();


  useEffect(() => {
    _doctorGetPatientInfo(authId);
  }, []);

  async function _doctorGetPatientInfo(authId) {
    let params = {
      authId,
      groupReportBy: lob === lobType.SFDA ? 'groupByInstance' : 'groupByForm',
    }
    let res = await doctorGetPatientInfo(params);
    setPatientInfo(_.get(res, "result", {}));
  }

  return (
    <>
      <TitleBar title="来访者" isShowBack={true}></TitleBar>
      <StaffInfoComponent
        patientInfo={patientInfo}
        role={role}
      ></StaffInfoComponent>
    </>
  );
}

export default PatientInfo;
