import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'antd-mobile';
import { Toast } from 'antd-mobile';
import _ from 'lodash';

import './index.less';
import { checkFuncAuth } from '@/utils/utils';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import ConsultItem from '@/components/ConsultItem';
import phoneCall from '@/assets/icon/clientHome/v2/phone_call.svg';
import makingCall from '@/utils/makingCall';
import textConsult from '@/assets/icon/clientHome/v2/text.svg';
import { bindList, bindConsultant } from '@/services/api';

// 首页咨询模块
function ZixunComponent(props) {
  const { funcAuthList, title = '咨询', hotLine } = props;
  const { handleAddEventDotClick } = props;
  const navigate = useNavigate();
  const menus = [
    {
      id: '24-hotline',
      nickName: '24小时心理咨询热线',
      avatarUrl: phoneCall,
      consultantInformation: {
        introduce: '随时随地为您提供专业的心理咨询'
      },
      unreadMessageCount: '',
      handleClick: () => {
        
        makingCall(hotLine);
        // 打点
        handleAddEventDotClick({
          name: 'visit:consult/24-hotline',
          props: {
            eventName: '咨询：访问热线'
          }
        });
      }
    },
    {
      id: '24-text-consult',
      nickName: '24小时图文咨询',
      avatarUrl: textConsult,
      unreadMessageCount: '',
      consultantInformation: {
        introduce: '随时随地为您提供专业的心理咨询'
      },
      handleClick: async () => {
        if (consultantList[0]?.authId) {
          await bindConsultant(consultantList[0]?.authId)
          navigate(`/client/dialogue/${consultantList[0]?.authId}`, { state: { eventDotKey: 'visit:consult/24-text-consult' } });
        } else {
          Toast.show('暂未查询到绑定的咨询师，请联系客服')
        }
      }
    }
  ]
  const checkedMenus = checkFuncAuth(funcAuthList, menus);
  if (checkedMenus.length === 0) {
    return null
  }
  console.log('*******checkedMenus******', checkedMenus)
  // 绑定的咨询师
  const [consultantList, setConsultantList] = useState([]);

  const getBindList = useCallback(async () => {
    let res = await bindList();
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      let doctorList = _.get(res, ['result'], []);
      setConsultantList(doctorList);
    }
  }, [])

  useEffect(() => {
    getBindList();
  }, [getBindList])

  return (
    <div>
      {title && <div className='func-title'>{title}</div>}
      {
        checkedMenus.map((item, index) => {
          // title, subtitle是配置在配置文件中的定制化title
          const { avatarUrl, nickName, handleClick, unreadMessageCount, title, subtitle } = item;
          const desc = _.get(item, ['consultantInformation', 'introduce'], '');
          return (
            <div key={index}>
              <ConsultItem
                avatarUrl={avatarUrl}
                title={title || nickName}
                desc={subtitle || desc}
                renderRight={() => <Badge content={unreadMessageCount}></Badge>}
                handleClick={handleClick}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default WithTrackingForMedia(ZixunComponent);