// 返回stream
import React, { useCallback, useState, useRef } from 'react';
import { Avatar, Toast } from 'antd-mobile';

import './index.less';
import recommendChatItems from '@/Constant/recommendChatItems';
import siuvoRobot from '@/assets/images/avatar_robot.png';
import useStream from '@/utils/hooks/useStream';

const ChatGptStream = (props) => {
  const {
    chatgptParamsObj,
    chatList,
    setChatList,
    setItemIdInfo,
    setChatgptStreamOver,
    scrollMessageListToEnd,
    fromUserId
  } = props;
  const [chatgptAnswer, setChatgptAnswer] = useState({
    title_zh: '',
  });
  const answerDataRef = useRef('');
  const videoUrlRef = useRef('');

  const { requestUrl, chatgptParams } = chatgptParamsObj;

  // 匹配并提取字符串中的中括号内容
  const extractBracketsContent = (str) => {
    // 匹配中括号的正则表达式
    const regex = /\[(.*?)\]/g;
    // 使用正则表达式匹配中括号的内容
    const matches = str.match(regex);
    // 提取中括号中的内容
    const bracketsContent = matches ? matches : [];
    // 去除字符串中的中括号及其中的内容
    const cleanStr = str.replace(regex, '');
    return {
      bracketsContent,
      cleanStr
    };
  }

  // 处理{}这样数据的data
  function handleBraceContent(item) {
    let output = '';
    const braceContentInner = JSON.parse(item);
    const itemId = braceContentInner.itemId;
    const itemIdContent = recommendChatItems.find(item => item.itemId === itemId);
    const { description, url, media } = itemIdContent;
    setItemIdInfo({ ...itemIdContent, itemId });
    if (itemId.includes('exercise')) {
      output = `${description}<div class="recommend-video-content"></div>`
      videoUrlRef.current = media;
    }
    if (itemId.includes('positive')) {
      output = `<a href="${url}">点击进行正念练习</a>\r\n`
    }
    return output;
  }

  // 处理commend的推荐流
  function handleCommend(item) {
    // 如果传来[OVER]，则表示推流结束，开始推荐
    if (item.includes('[OVER]')) {
      item = item.replace(/\[OVER\]/g, '');
      setChatgptAnswer({});
      setChatgptStreamOver(true);
    }
    if (item.includes('[DONE]')) {
      item = item.replace(/\[DONE\]/g, '');
      videoUrlRef.current
        ? setChatList([...chatList, { title_zh: answerDataRef.current, fromUserId, messageType: "AI_CHAT" }, { videoUrl: videoUrlRef.current, fromUserId, messageType: "AI_CHAT" }])
        : setChatList([...chatList, { title_zh: answerDataRef.current, fromUserId, messageType: "AI_CHAT" }]);
      answerDataRef.current = '';
      videoUrlRef.current = '';
      setChatgptAnswer({});
    }
    // 如果推流有报错
    if (item.includes('[FAILED]')) {
      item = item.replace(/\[FAILED\]/g, '');
      videoUrlRef.current
        ? setChatList([...chatList, { title_zh: answerDataRef.current, fromUserId, messageType: "AI_CHAT" }, { videoUrl: videoUrlRef.current, fromUserId, messageType: "AI_CHAT" }])
        : setChatList([...chatList, { title_zh: answerDataRef.current, fromUserId, messageType: "AI_CHAT" }]);
      answerDataRef.current = '';
      videoUrlRef.current = '';
      setChatgptAnswer({});
      localStorage.removeItem('recommendChat');
      Toast.show({
        icon: 'fail',
        content: 'AI机器人连接错误，请稍后再试',
      });
    }
  }

  const getChatGptStream = async res => {
    let data = await res.body.getReader().read();
    data.output = new TextDecoder('utf-8').decode(data.value);
    console.log('data', data.output);
    const { cleanStr, bracketsContent } = extractBracketsContent(data.output);
    // 如果类似是xxxx[DONE]这种类型的token传进来，首先需要将token和[DONE]分开，再一次处理逻辑
    if (cleanStr && bracketsContent.length > 0) {
      console.log('cleanStr, bracketsContent', cleanStr, bracketsContent);
      if (cleanStr) {
        data.output = cleanStr;
        if (data.output.includes('{') || data.output.includes('}')) {
          data.output = handleBraceContent(data.output);
        }
        if (data.output.includes('<br/>')) {
          data.output = data.output.replace(/<br\/>/g, '\r\n');
        }
        answerDataRef.current += data.output;
        setChatgptAnswer({ title_zh: answerDataRef.current, });
        scrollMessageListToEnd();
      }
      if (bracketsContent[0]) {
        handleCommend(bracketsContent[0]);
        return;
      }
    }
    if (data.output.includes('[DONE]') || data.output.includes('[FAILED]') || data.output.includes('[OVER]')) {
      handleCommend(data.output);
      return;
    }
    // 如果推流过来的token有大括号，需要提取括号里面的itemId和reason
    if (data.output.includes('{') || data.output.includes('}')) {
      data.output = handleBraceContent(data.output);
    }
    if (data.output.includes('<br/>')) {
      data.output = data.output.replace(/<br\/>/g, '\r\n');
    }
    answerDataRef.current += data.output;
    setChatgptAnswer({ title_zh: answerDataRef.current, });
    scrollMessageListToEnd();
  };

  const onFirst = useCallback(async res => {
    const chatKey = res.get('chat-key');
  }, []);

  useStream(requestUrl, { onFirst, onNext: getChatGptStream, fetchParams: chatgptParams });

  return (
    <>
      {
        chatgptAnswer?.title_zh && (
          <div className="chatting-records-content"
            style={{
              padding: '0 0.5rem',
              marginTop: '-1rem',
            }}
          >
            <div className="dialogue-block flex-start">
              <div className="head">
                <Avatar src={siuvoRobot} style={{ '--size': '32px' }} />
              </div>
              <div className="dialogue left-message-text" style={{ background: 'lavender' }}>
                <div dangerouslySetInnerHTML={{ __html: chatgptAnswer?.title_zh }}>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default ChatGptStream;