import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from 'antd-mobile'
import { MessageOutline } from "antd-mobile-icons";

import './index.less';
import TitleBar from '@/components/TitleBar';
import AdminConsultantInfo from "./AdminConsultantInfo";

function ConsultantDetailInfo(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const consultantInfo = _.get(location, ["state", "staffInfo"], {});
  const role = _.get(location, ["state", "role"], {});

  return (
    <>
      <TitleBar
        title='咨询师信息详情'
        isShowBack={true}
        rightContent={
          <>
            {/* <Badge content={Badge.dot}> */}
            <MessageOutline fontSize={30} />
            {/* </Badge> */}
          </>
        }
        rightContentOnclick={() => navigate('/admin/dialogue', { state: { staffInfo: consultantInfo, role: role } })}
      />
      <AdminConsultantInfo
        consultantInfo={consultantInfo}
        role={role}
      />
    </>
  )
}

export default ConsultantDetailInfo;