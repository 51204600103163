import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addEventDot } from '@/services/api';
import { _getPhoneModel } from '@/utils/utils';
import CONFIG from 'GlobalConfigFile';
import { decodeToken} from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';
const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;

const WithTrackingForMedia = (WrappedComponent) => {
  const WithInnerPlayer = ({ ...props }) => {
    const { name } = WrappedComponent;
    const location = useLocation();
    const { pathname } = location;
    const ua = _getPhoneModel();

    // 根据pathname判断角色
    const recognizeRole = () => {
      if (pathname.includes('client')) {
        return 'patient';
      }
      if (pathname.includes('therapist')) {
        return 'doctor';
      }
      if (pathname.includes('admin')) {
        return 'admin';
      }
      return 'anonymous';
    }

    const staffId = useSelector(state => state.baseInfo[recognizeRole(pathname)]?.roleInfo?.staffId);
    const { tenantId } = decodeToken(getLoginToken(recognizeRole(pathname)))

    const _addEventDot = (params) => {
      const uuid = uuidv4();
      const trackingParams = {
        id: uuid,
        source: 'browser',
        occurOn: moment().unix() * 1000,
        project: 'EAP',
        env: DEFAULT_SERVER,
        userId: staffId || 'anonymous',
        deviceId: ua,
        ...params,
        name: params?.name || name,
        props: {
          ...params?.props,
          tenantId: tenantId || 'anonymous',
        },
      };
      addEventDot(trackingParams)
    };

    // useEffect(() => {
    //   console.log('*****WithPlayer****mount**', name)

    //   return () => console.log('*****WithPlayer****unmount**', name)
    // }, [name])

    return <WrappedComponent handleAddEventDotClick={_addEventDot} {...props} />;
  };

  return WithInnerPlayer;
};

export default WithTrackingForMedia;
