import React from 'react';
import MainLayout from '@/components/MainLayout';
import {funcList} from '@/Constant';
import { decodeToken} from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';


function Home (props) {
  const ROLE = 'patient';
  const tabs = [
    { 
      key: '/client/home-v2/main',
      title: active => <div className={`tab-txt-margin-top ${active ? 'tx-color-patient' : ''}`}>首页</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/clientHome/v2/home/activate.svg' : '@/assets/icon/clientHome/v2/home/inactivate.svg').default} />,
    },
    // {
    //   key: '/client/dialogue',
    //   title:  active => <div className={active ? 'tx-color-patient' : ''}>咨询</div>,
    //   icon: active => <img alt="" src={require(active ? '@/assets/icon/clientHome/v2/consult/activate.svg' : '@/assets/icon/clientHome/v2/consult/inactivate.svg').default} />,
    // },
    // {
    //   id: 'training',
    //   key: '/client/home-v2/training-list',
    //   title: active => <div className={active ? 'tx-color-patient' : ''}>成长营</div>,
    //   icon: active => <img alt="" src={require(active ? '@/assets/icon/clientHome/v2/grow/activate.svg' : '@/assets/icon/clientHome/v2/grow/inactivate.svg').default} />
    // },
    {
      id: 'resource',
      key: '/client/home-v2/resource-list',
      title: active => <div className={active ? 'tx-color-patient' : ''}>资源</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/clientHome/v2/resource/activate.svg' : '@/assets/icon/clientHome/v2/resource/inactivate.svg').default} />,
    },
    {
      id: 'form-test',
      key: '/client/home-v2/psychological-test',
      title: active => <div className={active ? 'tx-color-patient' : ''}>测评</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/clientHome/v2/form/activate.svg' : '@/assets/icon/clientHome/v2/form/inactivate.svg').default} />,
    }
  ]
  const { tenantId } = decodeToken(getLoginToken(ROLE))
  const {funcs} = funcList[tenantId] || funcList.default;
  return (
    <MainLayout
      funcs={funcs}
      tabs={tabs}
    />
  )
}

export default Home;