import ShufuFormChat from '@/utils/shufuFormChat';
import { useMemo } from 'react';

// 创建shufuFormChat的自定义hook
const useFormChat = (props) => {
  // 接受参数-form的内容
  const { formContent } = props;

  // 创建shufuFormChat，使用useMem，防止重复创建（仅form为新的时，重新创建）
  const shufuFormChat = useMemo(() => {
    // 创建formChat时传入的方法
    const updateMessageWithAnswer = (messageToBeUpdated, answer, answerVal) => {
      messageToBeUpdated.answer = answer;
      return messageToBeUpdated;
    };
    const createQuestionMessage = (questionId, messageObject, language, model) => {
      const { title_zh, type, viewType, audioUrl } = messageObject[questionId];
      const sectionId = questionId.split('/')[0];
      return {
        _id: questionId,
        questionId,
        title_zh,
        type,
        viewType,
        sectionId,
        audioUrl,
      };
    };
    const createAnswerMessage = (
      currentQuestionId,
      answerKey,
      answerVal,
      question
    ) => {
      return {
        _id: `${currentQuestionId}_answer`,
        text: answerVal,
        type: 'answer', //! !!! THE LIB REQUIRES THIS KEY AND VALUE TO DETERMINE THE MESSAGE TYPE
        questionId: currentQuestionId,
        answer: answerKey,
        questionModel: question.model, //! !!! THE LIB REQUIRES THIS KEY AND VALUE TO DETERMINE THE QUESTION MODEL
        viewType: question.viewType,
      };
    };
    return new ShufuFormChat({
      newMessage: formContent,
      language: 'zh',
      createQuestionMessage,
      updateMessageWithAnswer,
      createAnswerMessage,
      isReplyProcessedSuccess: () => {
        return false;
      },
    });
  }, [formContent])

  // 计算questionList
  const questionList = useMemo(() => {
    if (shufuFormChat) {
      const { wholeOrder, messageObject } = shufuFormChat;
      // 组装questionList
      let formQuestions = wholeOrder.map((questionId, index) => {
        const question = messageObject[questionId];
        return {
          viewType: question.viewType,
          audioUrl: question.audioUrl,
          questionId,
          questionNum: index + 1,
          sectionId: questionId.split('/')[0],
        };
      });
      const start = {
        questionNum: 0,
        questionId: 'start',
        viewType: 'instructions',
      };
      const result = {
        questionNum: formQuestions.length + 1,
        questionId: 'results',
        viewType: 'instructions',
      };
      return [start, ...formQuestions, result];
    }
    return [];
  }, [shufuFormChat])

  const sections = useMemo(() => {
    // 组装section，课程的段落
    /**
     *
     * @param {status}
     * wait 未开始
     * process 正在进行
     * finish 已完成
     */
    const getSections = (form) => {
      const sectionKeys = form.schema.order;
      // 获取当前状态下的 current question
      const currentQuestionId = shufuFormChat.currentQuestionId;
      const currentQuestionSectionKey = currentQuestionId.split('/')[0];
      return sectionKeys.map((sectionKey, index) => {
        //根据sectionKey判断status
        let status = 'wait';
        if (sectionKey === currentQuestionSectionKey) {
          status = 'process';
        }
        let currentQuestionSectionKeyIndex = sectionKeys.findIndex(
          (key) => key === currentQuestionSectionKey
        );
        if (index < currentQuestionSectionKeyIndex) {
          status = 'finish';
        }
        if (index > currentQuestionSectionKeyIndex) {
          status = 'wait';
        }
        const section = form.schema.properties[sectionKey];
        const { title_zh, order } = section;
        const firstQuestionKey = order[0];
        const startQuestionId = `${sectionKey}/${firstQuestionKey}`;
        const startQuestionNum = questionList.find(
          (question) => question.questionId === startQuestionId
        ).questionNum;
        return {
          id: sectionKey,
          startQuestionNum,
          title: title_zh,
          status: status,
        };
      });
    };
    return getSections(formContent, shufuFormChat.currentQuestionId, questionList)
  }, [formContent, shufuFormChat.currentQuestionId, questionList])

  return { shufuFormChat, questionList, sections }

}

export default useFormChat;