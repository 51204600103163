import React from 'react';
import './icon.css'
/**
 * 
 * @param {*} props iconfont->icon名称  width height
 * @returns 
 */
function Icon (props) {
  const {iconfont = 'default', width = '', height=''} = props;
  const iconSrc = require(`@/assets/icon/${iconfont}.svg`);
  return (
    // <svg className={selected ? 'icon icon-selected' : 'icon'} aria-hidden="true">
    //     <use xlinkHref={"#icon-" + iconfont}></use>
    // </svg>
    <img style={{width: `${width}rem`, height:`${height}rem`}} src={iconSrc} alt=""/>
  )
}

export default Icon;