import React, { useEffect } from 'react';
import IframeComponent from '@/components/Iframe';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFormCallbackResult } from '@/services/api';
import { Toast } from 'antd-mobile';

function PatientFormIframe(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const {url, taskId, seq} = _.get(location, ['state'], {});

  const isNeedQueryCallBackResult = url.indexOf('juliet/html') < 0;

  useEffect(() => {
    let timer;
    if (isNeedQueryCallBackResult) {
      timer = setInterval(() => {
        _getFormCallbackResult(timer);
      }, 3000);
    }
    return () => {
      timer && clearInterval(timer);
    }
  }, []);

  async function _getFormCallbackResult(timer) {
    let res = await getFormCallbackResult(taskId, seq);
    const callbackResult = res.result;
    if (callbackResult) {
      Toast.show({
        icon: 'success',
        content: '填写完成',
      })
      clearInterval(timer);
      navigate(-1);
    }
  }

  return (
    <IframeComponent title="量表测评" src={url}></IframeComponent>
  )
}
export default PatientFormIframe;