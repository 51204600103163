import React, { useState, useEffect } from 'react';
import { Dialog, Image, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTracking from '@/components/WithTracking';
import banner from '@/assets/images/home_banner.jpg';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { emitterEvent } from '@/Constant';

// 心理健康报告
function HeallthReport() {
  const navigate = useNavigate();
  const baseInfo = useSelector(state => state.baseInfo['patient']);
  console.log('*******', baseInfo)

  const [report, setReport] = useState([])

  useEffect(() => {
    _getHealthReport();
    // EventEmitter.on(emitterEvent.EVALUATION_REPORT_TO_STAFF, handleReport);
    // return () => {
    //   EventEmitter.off()
    // }
  }, [])

  function handleReport () {
  }

  async function _getHealthReport () {
    // const res = await getHealthReport();
    // if (res.errorCode) {
    //   Toast.show({
    //     content: res.error,
    //   });
    //   return;
    // }
    // const result = _.get(res, ['result'], {})
    const result = ['1111111111111111111']
    setReport(result);
  }

  function renderBanner () {
    let name = baseInfo?.roleInfo.name + '心理健康报告'
    return (
      <div className='report-banner'>
        <Image className='banner-img' src={banner} alt=''/>
        <div className='report-user-name'>{name}</div>
      </div>
    )
  }

  function renderReportContent () {
    return (
      <div className='report-desc'>
        {report.map((item, index) => {
          return <p key={index}>{item}</p>
        })}
      </div>
    )
  }

  return (
    <>
      <TitleBar title="心理健康报告" isShowBack={true} />
      <div className='health-report-content'>
        {renderBanner()}
        {renderReportContent()}
      </div>
    </>
  )
}

export default WithTracking(
  HeallthReport,
  {
    name: 'visit:health-report',
    props: {
      eventName: '访问心理健康报告'
    }
  }
);