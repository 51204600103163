import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TitleBar from '@/components/TitleBar';
import { patientUploadChatFile, patientGetChatMsg, patientGetDoctorInfo, staffConsultCompleted, staffTrigger } from '@/services/api';
import { Input, ImageUploader, Button, ActionSheet, PullToRefresh } from 'antd-mobile'
import { RenderMessageContent, uploadDialogueImage } from '@/components/DialogueMessage';
import '@/components/DialogueMessage/index.less';
import { useDispatch, useSelector } from 'react-redux';
import { emit_message_to_server, message_get, message_update, message_add, save_current_chat_auth_id } from '@/actions';
import { scrollMessageListToEnd } from '@/utils/commonUtils';
import { FILE_UPLOAD } from '@/actions/actionType';
import { getLoginToken } from '@/utils/localStorage'
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';
import { emitterEvent } from '@/Constant';
import EventEmitter from '@/utils/eventEmitter';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import getUUID from '@/utils/getUUID';

function Dialogue(props) {
  const lob = getLobFromToken(getLoginToken('patient'));
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const chatMsg = useSelector(state => state.chatMsg);
  // const chatAuthId = _.get(location, ['state', 'chatAuthId'], '');
  // const consultantId = _.get(location, ['state', 'consultantId'], '');
  const { chatAuthId, consultantId } = useParams();
  // 分页的页数
  const pageNo = useRef();
  // 来访者通过authId获取咨询师的信息，对于来访者而言，咨询师的authId就是他对话人Id,即chatAuthId
  const [staffInfo, setStaffInfo] = useState();
  // 每一页展示的消息数量
  const [pageSize, setPageSize] = useState(20);
  // 是否需要将scroll加载到最底部
  const [scrollEnd, setScrollEnd] = useState(true);
  const [functionsVisible, setFunctionsVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [actionSheetVisible, setActionSheetVisible] = useState(false)
  // 确认是否需要结束咨询的弹窗
  const [consultModalVisible, setConsultModalVisible] = useState(false);
  // 咨询记录id
  const [consultRecodeId, setConsultRecodeId] = useState('');
  // 记录当前发送失败的消息的次数
  const [sendFailCount, setSendFailCount] = useState(0);
  const baseInfo = useSelector(state => state.baseInfo['patient']);
  const functionBlocks = [
    {
      icon: <ImageUploader beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/photo.svg').default} />
      </ImageUploader>, title: '照片'
    },
    {
      icon: <ImageUploader capture='camera' beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/camera.svg').default} />
      </ImageUploader>, title: '拍摄'
    }
  ];

  const _staffTrigger = useCallback(async () => {
    const params = {
      chatAuthId,
      triggerType: 'openChat',
      time: MomentDiffZone().valueOf()
    }
    await staffTrigger(params);
  }, [])

  useEffect(() => {
    async function initChat() {
      await _patientGetChatMsg();
      await _staffTrigger();
    }
    initChat();
    dispatch(save_current_chat_auth_id(chatAuthId));
  }, []);

  useEffect(() => {
    setFunctionsVisible(false);
    // 下拉加载到最底部
    scrollEnd && scrollMessageListToEnd();
    setScrollEnd(true);
    let lastMsg = _.last(chatMsg);
    if (lastMsg?.onSendSuccess == false) {
      setSendFailCount(sendFailCount + 1);
    };
  }, [chatMsg])

  // 监听事件是否通话挂断后需要进行弹窗的通知
  useEffect(() => {
    const consultantEndConfirm = (val) => {
      setConsultRecodeId(val?.consultRecodeId);
      if (val?.type === "CONSULT_END_CONFIRM") {
        setConsultModalVisible(true)
      }
      else {
        setConsultModalVisible(false)
      }
    }
    EventEmitter.on(emitterEvent.CONSULT_END_CONFIRM, consultantEndConfirm);
    return () => {
      EventEmitter.removeListener(emitterEvent.CONSULT_END_CONFIRM, consultantEndConfirm);
    }
  }, [])

  async function _patientGetChatMsg() {
    pageNo.current = 1;
    const res = await patientGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId:  baseInfo.authId  });
    dispatch(message_get(_.get(res, ['result', 'data'], []).reverse()));
  }

  // 下拉更新数据
  async function _patientAddChatMsg() {
    const res = await patientGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId:  baseInfo.authId  });
    dispatch(message_add(_.get(res, ['result', 'data'], []).reverse()));
  }

  const _patientGetDoctorInfo = useCallback(async () => {
    const res = await patientGetDoctorInfo(chatAuthId);
    setStaffInfo(_.get(res, "result", {}))
  }, []);

  useEffect(() => {
    _patientGetDoctorInfo();
  }, [_patientGetDoctorInfo]);

  // 把setConsultModalVisible包一下传给子组件
  function setModalVisible(val) {
    setConsultModalVisible(val);
  }

  // 文件上传类型的消息
  function onSendFileToMessageList(url) {
    const messageObj = {
      chatType: FILE_UPLOAD,
      message: '',
      toUserId: chatAuthId,
      resourceType: 'PICTURE',
      url: url
    }
    dispatch(message_update(messageObj));
  }

  async function uploadDialogueFile(file) {
    uploadDialogueImage(file, chatAuthId, async params => {
      const res = await patientUploadChatFile('PICTURE', params);
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        })
      } else {
        const url = res.result.url || '';
        onSendFileToMessageList(url);
      }
    });
  }

  function onMessageSend(msg) {
    if (msg) {
      const messageObj = {
        chatType: 'chatToOne',
        message: msg,
        toUserId: chatAuthId,
        chatMsgId: getUUID()
      }
      // EMIT_MESSAGE_TO_SERVER
      dispatch(emit_message_to_server(messageObj))
      // dispatch(message_update(messageObj))
      setInputValue('');
    }
  }

  // 定义下拉加载时的自定义提示
  const statusRecord = {
    pulling: '下拉获取更多消息',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
  }

  return (
    <>
      <TitleBar
        title={staffInfo?.nickName}
        isShowBack={true}
      />
      <div className='dialogue-style' >
        <div className='dialogue-blocks' id='messageList-dialogue-blocks'>
          <PullToRefresh
            onRefresh={() => {
              pageNo.current += 1;
              _patientAddChatMsg();
              setScrollEnd(false);
            }}
            refreshingText={(text) => {
              return (
                // 此处的return需要带上圆括号，否则会报warning：functions are not valid as a React child
                <div>{statusRecord[text]}</div>
              )
            }}
          >
            {/* {renderMessageContent(chatMsg, 'patient', staffInfo)} */}
            <RenderMessageContent
              sendFailCount={sendFailCount}
              messageList={chatMsg}
              role='patient'
              roleInfo={staffInfo}
              consultantId={consultantId}
              // 点击人工客服后，手动发送消息给咨询师
              handleCustomServiceCallback={() => {
                const mgs = '呼叫人工客服'
                onMessageSend(mgs)
              }}
            />
          </PullToRefresh>
        </div>
        <div className='bottom-block'>
          <div className='dialogue-input'>
            <div className='input-block'>
              <Input
                placeholder='请输入内容'
                style={{ '--font-size': '1.6rem', backgroundColor: 'white', height: '100%' }}
                value={inputValue}
                onChange={val => setInputValue(val)}
                onEnterPress={() => {
                  onMessageSend(inputValue)
                }}
                onFocus={() => {
                  setFunctionsVisible(false);
                }}
              />
            </div>
            {!inputValue ? <div className='input-function' onClick={() => {
              setFunctionsVisible(!functionsVisible)
              scrollMessageListToEnd()
            }}>
              <img alt="" src={require('@/assets/icon/dialogue/add.png')} />
            </div> :
              <Button className='send-msg-btn' disabled={inputValue.trim().length <= 0} onClick={() => onMessageSend(inputValue)}>发送</Button>}
          </div>
          {functionsVisible ? <div className='function-blocks'>
            {functionBlocks.map((functionBlock, index) => <div className='function-block' key={index} onClick={() => { if (functionBlock.func) functionBlock.func() }}>
              <div className='function-block-icon'>{functionBlock.icon}</div>
              <div className='function-block-title'>{functionBlock.title}</div>
            </div>)}
          </div> : null}
        </div>
      </div>
    </>
  )
}

export default Dialogue;