// 用与展示文章列表
import React from 'react';
import { List, Image } from 'antd-mobile';
import TitleBar from '@/components/TitleBar';
import './index.less';
import {articleList} from '@/Constant/articleList';

function PatientArticleList(props) {
  console.log('articleList:', articleList)

  return (
    <>
    <TitleBar title='科普文章' isShowBack={true} />
    <List>
      {
        articleList.map((element, index) => {
          return (
            <List.Item
              className="list-item"
              key={index}
              description={element.time}
              extra={
                <Image
                  src={element.img}
                  style={{ borderRadius: 10}}
                  fit='cover'
                  width={60}
                  height={60}
                />
              }
              arrow={false}
              onClick={() => {
                window.location.href = element.url;
              }}
              children={element.title}
            />
          )
        })
      }
    </List>
    </>
  )
}

export default PatientArticleList;