import React, { useEffect, useState, useCallback } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import _ from 'lodash';
import { List, Image, Toast, Badge, Swiper } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';
import traningImg from '@/assets/images/traning.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bindList, getStaffFormList, bindConsultant } from '@/services/api';
import { commonConstant, companyOrgIds } from '@/Constant';
import sakura from '@/assets/images/patient-home/Palz Sitting Chair.png';
import nega from '@/assets/images/patient-home/Palz Standing.png';
import { getLoginToken } from '@/utils/localStorage';
import { getLobFromToken, decodeToken } from '@/utils/utils';
import { lobType } from '@/Constant';
import { articleList } from '@/Constant/articleList';
import { sfdaMoltiModalList, fenglinMoltiModalList, ModalList } from '@/Constant/moltiModalList';
import EventEmitter from '@/utils/eventEmitter';
import axios from 'axios';
import moment from 'moment/moment';
import CONFIG from 'GlobalConfigFile';
import siuvoRobot from '@/assets/images/patient-home/robot.png';


function Home() {
  const token = getLoginToken('patient');
  const lob = getLobFromToken(token);
  // 解析token
  const { authorityIdList = [], tenantId } = decodeToken(token);
  const navigate = useNavigate();
  const location = useLocation();
  const staffId = useSelector(state => state.baseInfo['patient']?.roleInfo?.staffId);
  const [isMatchConsultant, setIsMatchConsultant] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  // 未完成的量表任务
  const [formIncompleteList, setFormIncompleteList] = useState();
  // 已完成的任务列表
  const [successFormList, setSuccessFormList] = useState([]);
  // ui config
  const [uiConfig, setUiConfig] = useState({});

  // toC端title区分
  const title = lob === lobType.EAP ? '舒辅EAP平台' : '舒辅平台';
  const articles = articleList.slice(0, 3);
  const HOME_MENUS_LIST = {
    'psychological-tools': [
      {
        id: 'form-test',
        name: '心理测评',
        avatar: require('@/assets/icon/clientHome/Test.svg').default,
        clickEvent: () => {
          navigate('/client/psychological-test')
        }
      },
      {
        id: 'multimodality-form-test',
        name: 'AI多模态',
        avatar: require('@/assets/icon/clientHome/AI.svg').default,
        clickEvent: () => {
          navigate('/client/multi-modal-test', { state: { moltiModalList: ModalList[tenantId] } })
        }
      },
      {
        id: 'xlqyb',
        name: '心理晴雨表',
        avatar: require('@/assets/icon/clientHome/Mental.svg').default,
        clickEvent: () => {
          navigate('/client/psychology-status', { state: { title: '心理晴雨表', url: 'https://h5.siuvo.cn/?sceneId=2a97f5447cc0456&toPage=scaleIntroductionWithReport' } })
        },
      },
      {
        id: 'znlx',
        name: '正念练习',
        avatar: require('@/assets/icon/clientHome/Meditation.svg').default,
        clickEvent: () => {
          navigate('/client/positive-thinking');
        },
      },
      {
        id: 'jysp',
        name: '减压视频',
        avatar: require('@/assets/icon/clientHome/Pressure.svg').default,
        clickEvent: () => {
          navigate('/client/jysp', { state: { isShowBack: true } })
        }
      },
    ],
    'psychological-service': [
      // 咨询师
      {
        id: 'xlgagw',
        name: '心理疏导',
        avatar: require('@/assets/icon/clientHome/Consult.svg').default,
        clickEvent: () => {
          if (consultantList.length > 0) {
            navigate(`/client/dialogue/${consultantList[0].authId}`);
          } else {
            Toast.show('暂无心理关爱顾问')
          }
        }
      },
      // 舒小辅
      {
        id: 'bgjd',
        name: '预约服务',
        avatar: require('@/assets/icon/clientHome/Interpretation.svg').default,
        clickEvent: () => {
          navigate(`/client/dialogue-admin/${CONFIG.SiuvoManagerId}/${consultantList[0].authId}`);
        }
      }
    ]
  }

  // 获取UI配置文件
  useEffect(() => {
    async function getLocalConfig() {
      try {
        const uiConfig = await axios.get(`/config/uiConfig/${tenantId}/${tenantId}.json`);
        setUiConfig(uiConfig.data);
      } catch (error) {
        console.log('未找到本地配置文件，使用默认配置')
        const defaultUiConfig = await axios.get(`/config/uiConfig/default.json`);
        setUiConfig(defaultUiConfig.data);
      }
    }
    getLocalConfig();
  }, [])

  useEffect(() => {
    async function _getStaffFormList() {
      let consultantUserParams = {
        taskType: 'CHAT_FORM',
        staffId
      }
      let res = await getStaffFormList(consultantUserParams);
      const formIncompleteList = _.get(res, ['result', 'doingFormList'], []);
      const successFormList = _.get(res, ['result', 'successFormList'], []);
      setFormIncompleteList(formIncompleteList);
      setSuccessFormList(successFormList);
    }
    _getStaffFormList();
    EventEmitter.on('UPDATE_UNREAD_MESSAGE', getBindList)
    return () => {
      EventEmitter.removeListener('UPDATE_UNREAD_MESSAGE', getBindList)
    }
  }, []);

  const getBindList = useCallback(async () => {
    let res = await bindList();
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: '获取绑定咨询师列表失败',
      })
    } else {
      let doctorList = _.get(res, ['result'], []);
      setIsMatchConsultant(doctorList.length > 0)
      setConsultantList(doctorList);
    }
  }, [])

  useEffect(() => {
    getBindList();
  }, [getBindList])

  function handleMatchConsultant() {
    navigate('/client/consult-introduction');
  }


  function RenderPsychologicalTraining() {
    return (
      <>
        <div className="sectionTitleBar">
          <div className="sectionTitle">心理训练营</div>
        </div>
        <div className="traning-content">
          <img className="temp-image" src={traningImg} alt="" />
        </div>
      </>
    )
  }

  function renderTitleRight() {
    return (
      <div onClick={() => {
        navigate('/client/setting');
      }}>
        <img style={{ width: '2.4rem' }} alt="" src={require('@/assets/icon/patient_setting.svg').default} />
      </div>
    )
  }

  // item的title
  function renderItemTitle(title) {
    return <div className='func-item-title'>
      {title}
    </div>
  }
  // item的描述
  function renderItemDesc(desc) {
    return <div className='func-item-desc'>
      {desc}
    </div>
  }

  function renderListRightContent(unreadMessageCount) {
    return <>
      <Badge content={unreadMessageCount}></Badge>
      <RightOutline />
    </>
  }


  // 我的咨询师-咨询师列表
  function renderConsultList(handleClickConsultant, unreadMessageCount) {
    return (
      <div className='myConsultant-section'>
        {
          consultantList.map((item, index) => {
            return (
              <div className='myConsultant-section-content' key={index}>
                <List className='myConsultant-list'>
                  <List.Item
                    style={{ borderRadius: '8px' }}
                    prefix={
                      <Image
                        src={item.avatarUrl}
                        style={{ borderRadius: 32 }}
                        fit='cover'
                        width={44}
                        height={44}
                      />
                    }
                    arrow={renderListRightContent(item.unreadMessageCount)}
                    description={renderItemDesc(_.get(item, ['consultantInformation', 'introduce'], ''))}
                    onClick={() => {
                      handleClickConsultant(item)
                    }}
                  >
                    {renderItemTitle(item.nickName)}
                  </List.Item>
                </List>
              </div>
            )
          })
        }
        <div className='myConsultant-section-content' >
          <List className='myConsultant-list'>
            <List.Item
              style={{ borderRadius: '8px' }}
              prefix={
                <Image
                  src={siuvoRobot}
                  style={{ borderRadius: 32 }}
                  fit='cover'
                  width={44}
                  height={50}
                />
              }
              description={renderItemDesc('智能咨询，贴心陪伴')}
              onClick={() => {
                navigate('/client/recommend-chat')
              }}
            >
              {renderItemTitle('AI咨询师')}
            </List.Item>
          </List>
        </div>
      </div>
    )
  }

  // 个人和eap平台，在未绑定咨询师的情况下，可以匹配绑定
  function renderMatchFunc() {
    return <div className='myConsultant-button' onClick={handleMatchConsultant}>
      <span className='myConsultant-button-text'>
        匹配我的咨询师
      </span>
    </div>
  }
  // 未查询到值班咨询师的时候，提示值班咨询师工作时间为9:00-21:00
  function renderEmptyMatch() {
    return <div className='myConsultant-button'>
      <span className='out-of-sche-time-hint'>暂无咨询师，值班时间为：9:00-21:00</span>
    </div>
  }

  // 我的咨询师
  function RenderMyConsultant(config = {}) {
    const handleClickConsultant = (item) => navigate(`/client/dialogue/${item.authId}`);
    return (
      <div className='myConsultant'>
        {/* <img src={sakura} className='myConsultant-img' /> */}
        <div className='myConsultant-title'>
          {config.title || '我的咨询师'}
        </div>
        <div className='myConsultant-description'>
          {config.hintText || '匹配最适合我的心理咨询师'}
        </div>
        {
          isMatchConsultant
            ? renderConsultList(handleClickConsultant)
            : <>
              {renderMatchFunc()}
            </>
        }
      </div>
    )
  }
  // 值班-我的咨询师
  function RenderScheMyConsultant(config = {}) {
    const handleClickConsultant = async (item) => {
      await bindConsultant(item.consultantId)
      navigate(`/client/dialogue${item.authId}`)
    };
    return (
      <div className='myConsultant'>
        {/* <img src={sakura} className='myConsultant-img' /> */}
        <div className='myConsultant-title'>
          {config.title || '我的咨询师'}
        </div>
        <div className='myConsultant-description'>
          {config.hintText || '匹配最适合我的心理咨询师'}
        </div>
        {
          isMatchConsultant
            ? renderConsultList(handleClickConsultant)
            : <>
              {renderEmptyMatch()}
            </>
        }
      </div>
    )
  }

  // 国药-我的心理关爱顾问
  function RenderMyPsychologicalCare(isShowAppoint = true, config = {}) {
    const handleClickConsultant = (item) => navigate(`/client/dialogue/${item.authId}`)
    return (
      <div className='myConsultant'>
        {/* <img src={sakura} className='myConsultant-img' /> */}
        <div className='myConsultant-title' style={{ width: '200px' }}>
          {config.title || '我的心理关爱顾问'}
        </div>
        {
          isMatchConsultant
            ? renderConsultList(handleClickConsultant)
            : <div className='myConsultant-button'>
              <span className='out-of-sche-time-hint'>暂未获取到咨询师信息</span>
            </div>
        }
        {
          isShowAppoint && <div
            style={{ marginTop: isMatchConsultant ? '0px' : '40px' }}
            className='myConsultant-button'
            onClick={() => {
              window.location.href = 'https://www.wjx.top/vm/PQSgzzo.aspx#';
            }}
          >
            <span className='myConsultant-button-text'>
              点击预约驻场关爱咨询
            </span>
          </div>
        }
      </div>
    )
  }

  // 舒小辅
  function RenderShuXiaoFu(config = {}) {
    let menus = {
      name: '舒小辅',
      nickName: '舒小辅',
      authId: '45666668888',
      consultantId: '45666668888',
      avatarUrl: require('@/assets/icon/siuvo_avatar.svg').default,
      consultantInformation: {
        introduce: '人工客服'
      }
    }
    return (
      <div
        className='shuXiaoFu'
        onClick={() => {
          navigate(`/client/dialogue/${menus.authId}`);
        }}
      >
        <div style={{ height: '45px', display: 'inline-block' }}>
          <img src={require('@/assets/icon/siuvo_avatar.svg').default} className='shuXiaoFu-avatar' />
          <div style={{ fontWeight: 'bold' }}>舒小辅</div>
        </div>
        <div className='shuXiaoFu-dialog'>
          <div className='shuXiaoFu-text'>{config.hintText || '您好，我是客服舒小辅'}</div>
        </div>
      </div>
    )
  }

  // 我的任务
  function RenderMyTask(config = {}) {

    return (
      // 我的任务小标题，该样式可以独立出来
      <>
        <div className='sectionTitleBar'>
          <div className='sectionTitle'>{config.title || '我的任务'}</div>
          <div
            className='section-all'
            onClick={() => {
              navigate('/client/form-task-list');
            }}
          >
            查看全部
          </div>
        </div>
        {
          formIncompleteList?.length > 0
            ? renderMyTaskList()
            : renderScheMyConsultant()
        }
      </>
    )
  }

  /* 
    我的任务，默认显示三条，超过三条点击查看全部
    分为两个部分，没有任务和有任务
  */
  function renderScheMyConsultant() {

    return (
      <div className='myTask'
        // 国药的我的任务底色不一样
        style={{ background: lob === lobType.SFDA ? '#F1F1F2' : '#FFCC00' }}
      >
        <div className='myTask-content'>
          <div className='myTask-avatar-background'>
            <img src={require('@/assets/icon/new_task.svg').default} className='myTask-avatar' />
          </div>
          <div className='myTask-content-title'>
            <div className='content-title'>目前您还没有任务呢</div>
            <div className='content-description'>目前您还没有任务呢</div>
          </div>
        </div>
      </div>
    )
  }

  function renderMyTaskList() {
    let showData;
    formIncompleteList && formIncompleteList.length > 3
      ? showData = formIncompleteList.splice(0, 3)
      : showData = formIncompleteList

    return (
      <>
        {
          showData.map((element, index) => {
            return (
              <div
                className='myTask'
                // 背景颜色较没有任务的做一个区分
                style={{ background: '#F1F1F2' }}
                key={element.nameZh + `${index}`}
                onClick={() => {
                  navigate('/client/staff-task-iframe', { state: { title: element.nameZh, url: element.formUrl } });
                }}
              >
                <div className='myTask-content'>
                  <div className='myTask-avatar-background'>
                    <img src={require('@/assets/icon/new_task.svg').default} className='myTask-avatar' />
                  </div>
                  <div className='myTask-content-title'>
                    <div className='content-title'>{element.nameZh}</div>
                    <div className='content-description'>请尽快完成哦~</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  // 心理健康小工具
  function RenderPsychologyTool() {
    let menus = [
      {
        name: '正念练习',
        avatar: require('@/assets/icon/positive_thinking.svg').default,
        description: '心理健康小工具',
        clickEvent: () => {
          navigate('/client/positive-thinking');
        },
      },
      {
        name: '心理晴雨表',
        avatar: require('@/assets/icon/psychology_status.svg').default,
        description: '为保证评测质量，请填写真实信息',
        clickEvent: () => {
          navigate('/client/psychology-status', { state: { title: '心理晴雨表', url: 'https://h5.siuvo.cn/?sceneId=2a97f5447cc0456&toPage=scaleIntroductionWithReport' } })
        },
      },
      {
        name: '心理测评',
        avatar: require('@/assets/icon/test.svg').default,
        description: '心理评测小工具',
        clickEvent: () => {
          navigate('/client/psychological-test')
        }
      },
    ];

    return (
      <>
        <div className='sectionTitle'>心理健康小工具</div>
        {
          menus.map((element, index) => {
            return (
              <div
                key={element.name + `${index}`}
                className='psycholoTool'
                onClick={element.clickEvent}
              >
                <div className='psycholoTool-content'>
                  <div className='psycholoTool-avatar-background'>
                    <img src={element.avatar} className='psycholoTool-avatar' />
                  </div>
                  <div className='psycholoTool-content-title'>
                    <div className='psycholoTool-title'>{element.name}</div>
                    <div className='psycholoTool-description'>{element.description}</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  // 国药-心理健康小工具
  function RenderPsychologyToolSfda() {
    let menus = [
      {
        id: 'form-test',
        name: '心理测评',
        avatar: require('@/assets/icon/test.svg').default,
        description: '心理评测小工具',
        background: '#FFCC00',
        clickEvent: () => {
          navigate('/client/sfda-psychological-test')
        }
      },
      {
        id: 'multimodality-form-test',
        name: '多模态心理测评',
        avatar: require('@/assets/icon/test.svg').default,
        description: '趣味心理测试',
        background: '#F1F1F2',
        clickEvent: () => {
          navigate('/client/multi-modal-test', { state: { moltiModalList: sfdaMoltiModalList } })
        }
      }
    ];

    return (
      <>
        <div className='sectionTitle'>心理健康小工具</div>
        {
          menus.map((element, index) => {
            return (
              <div
                key={element.name + `${index}`}
                className='psycholoTool'
                onClick={element.clickEvent}
                style={{ background: element.background }}
              >
                <div className='psycholoTool-content'>
                  <div className='psycholoTool-avatar-background'>
                    <img src={element.avatar} className='psycholoTool-avatar' />
                  </div>
                  <div className='psycholoTool-content-title'>
                    <div className='psycholoTool-title'>{element.name}</div>
                    <div className='psycholoTool-description'>{element.description}</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  // fenglin-心理健康小工具
  function RenderPsychologyToolFenglin() {
    let menus = [
      {
        name: '心理测评',
        avatar: require('@/assets/icon/test.svg').default,
        description: '心理评测小工具',
        background: '#FFCC00',
        clickEvent: () => {
          navigate('/client/fenglin-psychological-test')
        }
      },
      {
        name: '多模态心理测评',
        avatar: require('@/assets/icon/test.svg').default,
        description: '趣味心理测试',
        background: '#F1F1F2',
        clickEvent: () => {
          navigate('/client/multi-modal-test', { state: { moltiModalList: fenglinMoltiModalList } })
        }
      }
    ];

    return (
      <>
        <div className='sectionTitle'>心理健康小工具</div>
        {
          menus.map((element, index) => {
            return (
              <div
                key={element.name + `${index}`}
                className='psycholoTool'
                onClick={element.clickEvent}
                style={{ background: element.background }}
              >
                <div className='psycholoTool-content'>
                  <div className='psycholoTool-avatar-background'>
                    <img src={element.avatar} className='psycholoTool-avatar' />
                  </div>
                  <div className='psycholoTool-content-title'>
                    <div className='psycholoTool-title'>{element.name}</div>
                    <div className='psycholoTool-description'>{element.description}</div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  // 心理健康知识
  function RenderPsychologKonwledge(config = {}, hideBanner = false) {

    return (
      <>
        <div className='sectionTitleBar'>
          <div className='sectionTitle'>{config.title || '心理健康知识'}</div>
          <div
            className='section-all'
            onClick={() => {
              navigate('/client/article');
            }}
          >
            查看全部
          </div>
        </div>
        {
          !hideBanner &&
          <div className='psychologyKnowledge-banner'>
            <div className='psychologyKnowledge-banner-content'>
              <div className='content-title'>心理健康很重要</div>
              <div className='content-description'>关注您的心理健康~</div>
            </div>
            <img src={nega} className='psychologyKnowledge-banner-img' />
          </div>
        }
        <div className='articleWhole'>
          {
            articles.map((element, index) => {
              return (
                <div key={index} className='article-item'>
                  <img className='articleImg' src={element.img} alt='' />
                  <div
                    className='articleSection'
                    onClick={() => {
                      window.location.href = element.url;
                    }}
                  >
                    <div className='article-title'>
                      {element.title}
                    </div>
                    <div className='article-info'>
                      <div className='article-description'>
                        {element.description}
                      </div>
                      <div className='article-time'>
                        {element.time}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  /**
   * 把时间转换为描述，今天，之前的显示日期
   */
  function revertTimeToDesc(time) {
    if (MomentDiffZone(time).isBefore(MomentDiffZone(), 'day')) {
      return '于' + MomentDiffZone(time).format('YYYY-MM-DD')
    }
    return '今天';
  }

  // 新版首页---我的任务
  function RenderMyNewTask(config = {}) {
    return (
      <>
        <div className='sectionTitleBar'>
          <div className='sectionTitle'>{config.title}</div>
        </div>
        <div className='my-new-task'>
          <img src={require('@/assets/icon/clientHome/Report.svg').default} className='task-icon' />
          <div className='task-content'>
            {
              successFormList.length > 0 ? <> <div>您{revertTimeToDesc(successFormList[0].reportTime)}完成了</div>
                <div>{successFormList[0].nameZh}</div></> : <div>您暂时没有任务</div>
            }
          </div>
          <div className='task-more' onClick={() => navigate('/client/form-task-list')}>查看更多 &gt;</div>
        </div>
      </>
    )
  }

  // 心理小工具模块, 心理教育与训练模块
  /**
   * 
   * @param {*} menus 首页模块列表集合
   * @param {*} subMenus 子菜单列表
   * @param {*} config ui配置
   * @returns 
   */
  function RenderPsychologyTools(menus, subMenus, config = {}) {
    const matchMenus = subMenus.reduce((pre, cur) => {
      pre.push(menus.filter(item => cur === item.id)[0])
      return pre;
    }, [])
    return renderPsychologyToolsContent(matchMenus, config)
  }

  // 新增，归结所有的心理健康小工具，然后根据服务返回的菜单列表，确认具体的小工具
  function renderPsychologyToolsContent(menus, config = {}) {
    if (menus.length === 0) return <></>
    return (
      <>
        <div className='sectionTitle'>{config.title}</div>
        <div className='psycholo-tools'>
          {
            menus.map((element, index) => {
              const key = element.name + index
              const subConfig = _.get(config, 'subMenus', []).filter(item => item.id === element.id)[0];
              return renderToolsItem(element, key, subConfig);
            })
          }
        </div>
      </>
    )
  }

  /**
   * 
   * @param {*} element item内容： title， icon， click event
   * @param {*} key 
   * @param {*} subConfig 配置项，包括自定义title，样式: 宽度
   * @returns 
   */
  function renderToolsItem(element, key, subConfig = {}) {
    const { width = 33.3 } = subConfig;
    return (
      <div
        style={{ width: `${width}%` }}
        key={key}
        className='psycholo-item'
        onClick={element.clickEvent}
      >
        <img alt='' src={element.avatar} />
        <div>{subConfig.title}</div>
      </div>
    )
  }
  /**
   * 轮播图
   * @param {} config 
   */
  function RenderBanner(config = {}) {
    const { imgs = [] } = config;
    const items = imgs.map((img, index) => {
      return (
        <Swiper.Item key={index}>
          <img className='banner-img' alt='' src={img} />
        </Swiper.Item>
      )
    })
    return (
      <Swiper
        autoplay={true}
        loop={true}
      >
        {items}
      </Swiper>
    )
  }

  /**
   * 
   * @returns 个人和eap平台的功能渲染
   */
  function renderEAPContent() {
    return <>
      {RenderMyConsultant()}
      {RenderShuXiaoFu()}
      {RenderPsychologicalTraining()}
      {RenderMyTask()}
      {RenderPsychologyTool()}
      {RenderPsychologKonwledge()}
    </>
  }

  // 国药平台的功能渲染
  function renderSFDAContent() {

    return <>
      {RenderPsychologyToolSfda()}
      {RenderMyPsychologicalCare()}
      {RenderShuXiaoFu()}
      {RenderMyTask()}
      {RenderPsychologKonwledge()}
    </>
  }

  /**
   * 
   * @returns 绑定值班咨询的个人功能
   */

  function renderCompanyContent() {
    return <>
      {RenderScheMyConsultant()}
      {RenderShuXiaoFu()}
      {RenderMyTask()}
      {RenderPsychologKonwledge()}
    </>
  }

  // 枫林平台的功能渲染
  function renderFenglinContent() {

    return <>
      {RenderPsychologyToolFenglin()}
      {RenderMyTask()}
      {RenderPsychologKonwledge()}
    </>
  }

  /**
   * 
   * @param {*} funcInfo 权限列表
   * @returns 
   */
  function getRenderFuncById(funcInfo) {
    const id = funcInfo.id;
    const funcConfig = _.get(uiConfig, 'menus', []).filter(item => item.id === id)[0];
    switch (id) {
      // 轮播图
      case 'banner':
        return RenderBanner(funcConfig);
      case 'psychological-tools':
      case 'psychological-service':
        return RenderPsychologyTools(HOME_MENUS_LIST[id], funcInfo.subMenus, funcConfig)
      case 'my-psychological-care':
        return RenderMyPsychologicalCare(funcConfig)
      case 'my-psychological-care-no-appoint':
        return RenderMyPsychologicalCare(false, funcConfig)
      case 'my-customer-service':
        // 客服舒小辅
        return RenderShuXiaoFu(funcConfig)
      case 'my-missions':
        // 我的任务--x新版首页
        return RenderMyNewTask(funcConfig)
      case 'psychological-articles':
        // 心理健康知识
        return RenderPsychologKonwledge(funcConfig, true)
      case 'my-consultant':
        // 我的心理关爱顾问
        return RenderMyConsultant(funcConfig)
      case 'my-sche-consultant':
        // 我的咨询师
        return RenderScheMyConsultant(funcConfig)
      default:
        return <></>
    }
  }

  // 根据服务端的authorityIdList,渲染对应的功能项
  function renderFunctionByRemote() {
    return <>
      {authorityIdList.length > 0 && authorityIdList.map((funcInfo, index) => {
        return <div key={index}>{getRenderFuncById(funcInfo)}</div>
      })}
    </>
  }


  function renderMainFuncContent() {
    switch (lob) {
      case lobType.COMPANY:
        // 1.医务工会 2.民航使用用老版的eap首页, 配置在Constants中
        if (companyOrgIds.indexOf(tenantId) > -1) {
          return renderCompanyContent();
        }
        // 新增 根据后端token中的authorityIdList 渲染功能
        return renderFunctionByRemote();
      case lobType.CONSULTANT:
        return renderEAPContent();
      case lobType.EAP:
        return renderEAPContent();
      case lobType.SFDA:
        return renderSFDAContent();
      case lobType.FENGLIN:
        return renderFenglinContent();
      default:
        return renderEAPContent();
    }
  }

  return (
    <>
      <TitleBar title={uiConfig.title || title} isShowBack={false} rightContent={renderTitleRight()} />
      <div className='patient-home-content-block'>
        {
          renderMainFuncContent()
        }
      </div>
    </>
  )
}

export default Home;