// 评估页面
import React, { useState, useEffect } from "react";
import { Checkbox, Button, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";

import './index.less';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import useFormInfo from '@/utils/hooks/useFormInfo';
import QuestionAndAnswerBoard from './ExerciseBoard';
import { completeStaffEvaluation } from '@/services/api';
import { setLocalNeedFirstEvaluationStatus } from '@/utils/localStorage'
import getIntakeForm from '@/utils/getIntakeForm'
import { decodeToken } from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';

const ROLE = 'patient';
const InitialEvaluationPage = (props) => {
  const { handleAddEventDotClick } = props;
  const [goalsValue, setGoalsValue] = useState([]);
  const navigate = useNavigate();
  const lessonInstanceId = 'mentalHealthTraining_firstAssessment';
  const { tenantId } = decodeToken(getLoginToken(ROLE))
  // 获取初始评估量表
  const formContent = getIntakeForm(tenantId);

  const {
    currentLesson: {
      isProcessStart,
      isProcessEnd,
      onProcessStart,
      model
    },
    currentQuestion: {
      onSubmitAnswer,
      currentQuestionId,
      questionContent,
      questionOptions,
      questionType,
      viewType
    },
  } = useFormInfo({ lessonInstanceId, formContent: formContent });

  useEffect(() => {
    if (isProcessStart) {
      onProcessStart();
      // 开始初始评估打点
      handleAddEventDotClick({
        name: 'test:initial-evaluation',
        props: {
          eventName: '开始做初始评估'
        }
      });
    }
  }, []);

  useEffect(() => {
    if (currentQuestionId === 'results') {
      // localStorage.removeItem(`shufuFormChat_${lessonInstanceId}`);
      // // 此处提交数据到服务端
      // _completeStaffEvaluation(model)
    } else if (viewType === 'instructions') {
      onSubmitAnswer({ key: currentQuestionId, value: true });
    }
  }, [currentQuestionId]);

  // 提交测评
  async function _completeStaffEvaluation(evaluationData) {
    const params = {
      evaluationData
    }
    const res = await completeStaffEvaluation(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    setLocalNeedFirstEvaluationStatus(false)
    navigate('/client/transition');
  }

  // 结束测评
  const onEndExercise = () => {
    localStorage.removeItem(`shufuFormChat_${lessonInstanceId}`);
    // 此处提交数据到服务端
    _completeStaffEvaluation(model)
    // 结束初始评估打点
    handleAddEventDotClick({
      name: 'leave:initial-evaluation',
      props: {
        eventName: '结束做初始评估'
      }
    });
  };

  const handleAnswerClick = (answer) => {
    let params = {
      key: currentQuestionId,
      value: answer,
    };
    if (answer === 'confirm') {
      onEndExercise();
      return;
    }
    else {
      onSubmitAnswer(params);
    }
  };

  return (
    <>
      <div className="initial-evaluation-page-content">
        <div className="title-section">
          <div className="title-text">
            {isProcessEnd
              ? '测评结束,确认结束测评吗？'
              : questionContent}
          </div>
        </div>
        <div className="growth-goals-content">
          <QuestionAndAnswerBoard
            disable={false}
            type={questionType}
            content={questionOptions}
            handleClick={handleAnswerClick}
          />
        </div>
      </div>
    </>
  )
}

export default WithTrackingForMedia(InitialEvaluationPage);