import React, { useState } from 'react';
import './index.less';
import { Input, Radio, Button, Toast } from 'antd-mobile';
import TitleBar from '@/components/TitleBar';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import _ from 'lodash';
import CustomDatePicker from '@/components/CustomDatePicker';

function FirstSetting(props) {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [birthdayTimestamp, setBirthdayTimestamp] = useState();
  const [visible, setVisible] = useState(false)
  const {handleSetting} = props;

  async function submitBaseInfo() {
    if (!name) {
      Toast.show({
        content: '请填写姓名',
        key: 'name'
      });
      return;
    }
    if (!gender) {
      Toast.show({
        content: '请填写性别',
        key: 'gender'
      });
      return;
    }
    if (!birthdayTimestamp) {
      Toast.show({
        content: '请选择生日',
        key: 'birthdayTimestamp'
      });
      return;
    }
    const params = {
      name,
      gender,
      birthday: new Date(birthdayTimestamp).getTime()
    }
    handleSetting(params)
  }

  return (
    <>
        <TitleBar title="账号设置" isShowBack />
        <div className="info">
          <div className="info-content">
            <div className="info-title">姓名</div>
            <Input size="large" placeholder="请输入您的姓名" onChange={val => setName(val)} />
            <div className="info-title">性别</div>
            <Radio.Group
              onChange={val => setGender(val)}
              size="large"
              value={gender}
              className="info-radio"
            >
              <Radio value="MALE" style={{marginRight: '2rem'}}>男</Radio>
              <Radio value="FEMALE">女</Radio>
            </Radio.Group>
            <div className="info-title">生日</div>
            <div onClick={() => {
              setVisible(true);
            }}>
              <Input size="large" placeholder="请输入您的生日" disabled={true} value={birthdayTimestamp && MomentDiffZone(birthdayTimestamp).format('YYYY-MM-DD')}/>
            </div>
            <CustomDatePicker
              title='出生日期'
              visible={visible}
              onClose={() => {
                setVisible(false)
              }}
              defaultValue={MomentDiffZone('1990').toDate()}
              onConfirm={val => {
                setBirthdayTimestamp(val)
              }}
            />
          </div>
          <Button size="large" className="info-btn-submit" onClick={() => submitBaseInfo()}>填写完成</Button>
        </div>
    </>
  )
}

export default FirstSetting;