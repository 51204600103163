// 我的评价页面
import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import TitleBar from '@/components/TitleBar';
import { List, Rate } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { getStaffEvaluationList, getStaffConsultRecode, staffGetConsultantInfo } from "@/services/api";
import { UpOutline } from 'antd-mobile-icons';

function MyEvaluation() {
  const navigate = useNavigate();
  const [completedEvaluationList, setCompletedEvaluationList] = useState([]);
  const [viewCompletedListAll, setViewCompletedListAll] = useState(false);
  // 是否需要显示查看全部
  const [showCompletedAllFlag, setShowCompletedAllFlag] = useState(true);
  const [uncompletedEvaluationList, setUncompletedEvaluationList] = useState([]);
  const [viewUncompletedListAll, setViewUncompletedListAll] = useState(false);
  const [showUncompletedAllFlag, setShowUncompletedAllFlag] = useState(true);

  const _getStaffCompletedEvaluationList = useCallback(async () => {
    const res = await getStaffEvaluationList();
    setCompletedEvaluationList(res?.result.data || []);
  }, []);

  const _getStaffConsultRecode = useCallback(async () => {
    // 查询没有评价的咨询记录
    let params = {
      recodeStatus: 'CONSULT_SUCCESS',
    };
    const res = await getStaffConsultRecode(params);
    setUncompletedEvaluationList(res?.result?.data || []);
  }, []);

  useEffect(() => {
    _getStaffCompletedEvaluationList()
  }, [_getStaffCompletedEvaluationList]);

  useEffect(() => {
    _getStaffConsultRecode()
  }, []);

  // 查询咨询记录的详情
  async function _staffGetConsultantInfo(consultRecodeId) {
    const res = await staffGetConsultantInfo(consultRecodeId);
    return res?.result
  }

  function renderCompletedEvaluationList() {
    let completedEvaluationListData;
    viewCompletedListAll === true
      ? completedEvaluationListData = completedEvaluationList
      : completedEvaluationListData = completedEvaluationList.slice(0, 3)
    return (
      <List>
        {
          completedEvaluationListData && completedEvaluationListData.map((item, index) => {
            return (
              <List.Item
                className='list-item'
                key={index}
                arrow={true}
                onClick={async () => {
                  // 请求接口拿到详情后传到详情页
                  let consultRecodeId = item.consultRecodeId;
                  const consultantInfo = await _staffGetConsultantInfo(consultRecodeId);
                  navigate('/client/consulted-evaluation', { state: { consultedInfo: consultantInfo } });
                }}
              >
                {MomentDiffZone(item.createdTime).format('YYYY-MM-DD')}
                <Rate defaultValue={item.evaluationScore} count={10} allowHalf={true} readOnly={true} className='rate-content-body' />
              </List.Item>
            )
          })
        }
        {
          showCompletedAllFlag
            ? completedEvaluationList.length > 3
              ? (
                <div
                  className='view-all'
                  onClick={() => {
                    setViewCompletedListAll(!viewCompletedListAll);
                    setShowCompletedAllFlag(false);
                  }}>
                  查看全部
                </div>
              )
              : (
                <></>
              )
            : (
              <div className='up-outline'>
                <UpOutline
                  fontSize={24}
                  color='var(--adm-color-primary)'
                  onClick={() => {
                    setViewCompletedListAll(!viewCompletedListAll);
                    setShowCompletedAllFlag(true);
                  }}
                />
              </div>
            )
        }
      </List>
    )
  }

  function renderUncompletedEvaluationList() {
    let uncompletedEvaluationListData;
    viewUncompletedListAll === true
      ? uncompletedEvaluationListData = uncompletedEvaluationList
      : uncompletedEvaluationListData = uncompletedEvaluationList.slice(0, 3)
    return (
      <>
        {
          uncompletedEvaluationListData && uncompletedEvaluationListData.map((item, index) => {
            return (
              <List.Item
                className='list-item'
                key={index}
                arrow={true}
                onClick={() => {
                  navigate('/client/consulting-evaluation', { state: { consultingInfo: item } })
                }}
              >
                {MomentDiffZone(item.createdTime).format('YYYY-MM-DD')}
              </List.Item>
            )
          })
        }
        {
          showUncompletedAllFlag
            ? uncompletedEvaluationList.length > 3
              ? (
                <div
                  className='view-all'
                  onClick={() => {
                    setViewUncompletedListAll(!viewUncompletedListAll);
                    setShowUncompletedAllFlag(false)
                  }}>
                  查看全部
                </div>
              )
              : (
                <></>
              )
            : (
              <div className='up-outline'>
                <UpOutline
                  fontSize={24}
                  color='var(--adm-color-primary)'
                  onClick={() => {
                    setViewUncompletedListAll(!viewUncompletedListAll);
                    setShowUncompletedAllFlag(true);
                  }}
                />
              </div>
            )
        }
      </>
    )
  }

  // 暂无评价
  function renderNoConsultant() {

    return (
      <>
        暂无评价
      </>
    )
  }

  return (
    <>
      <TitleBar title='我的评价' isShowBack={true} />
      <div className='my-evaluation-content'>
        <div className='list-title'>
          已评价
        </div>
        {
          completedEvaluationList.length > 0
            ? renderCompletedEvaluationList()
            : renderNoConsultant()
        }
        <div className='list-title'>
          待评价
        </div>
        {
          uncompletedEvaluationList.length > 0
            ? renderUncompletedEvaluationList()
            : renderNoConsultant()
        }
      </div>
    </>
  )
}

export default MyEvaluation