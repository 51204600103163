// 资源列表页面--->正念练习的列表
import React, { useState, useEffect } from 'react';
import { Image } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTracking from '@/components/WithTracking';
import { positiveThinkingList } from '@/Constant/positiveThinking';

function PositiveThinkingComponent() {
  const navigate = useNavigate();

  return (
      <div className="positive-thinking-component">
        <div className="thinking-card-body">
          {positiveThinkingList.map((element, index) => {
            return (
              <div
                className="thinking-card"
                key={index}
                onClick={() => {
                  navigate(`/client/positive-thinking-iframe/${element.id}`);
                }}
              >
                <div className="card-body">
                  <Image
                    src={element.background}
                    className="card-body-image"
                    fit="cover"
                  />
                  <div className="card-body-description">{element.subhead}</div>
                </div>
                <div className="card-title">{element.title}</div>
              </div>
            );
          })}
        </div>
      </div>
  )
}

// export default WithTracking(
//   PositiveThinkingComponent,
//   {
//     name: 'visit:positive-thinking',
//     props: {
//       eventName: '访问正念练习'
//     }
//   }
// );
export default PositiveThinkingComponent;