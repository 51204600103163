import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import moment from 'moment';
/**
 * 获取可选择时间的数组
 * @param {*} startHour 开始小时数
 * @param {*} endHour 结束小时数
 * @param {*} startMinute 开始分钟数
 * @param {*} endMinute 结束分钟数
 * @param {*} interval 时间间隔
 * @returns 
 */
export function getTimeArray(startHour = 8, startMinute = 30, endHour = 23, endMinute = 0, interval = 15) {
  let hourArr = [];
  let minuteArr = []
  const iNum = endHour - startHour + 1;
  const mNum = 60 / interval;

  _.times(mNum, (i) => {
    const m = i * interval;
    const minute = {
      label: m < 10 ? '0' + m : m,
      value: m,
    }
    minuteArr.push(minute)
  })

  // 生成小时的结构
  _.times(iNum, (i) => {
    const h = startHour + i;
    const hour = {
      label: h,
      value: h
    }
    if (i === 0) {
      const specialMinuteArr = minuteArr.filter(item => {
        return item.value >= startMinute;
      })
      hour.children = specialMinuteArr;
    } else if (i === iNum - 1) {
      const specialMinuteArr = minuteArr.filter(item => {
        return item.value <= endMinute;
      })
      hour.children = specialMinuteArr;
    } else {
      hour.children = minuteArr;
    }
    hourArr.push(hour)
  })
  return hourArr
}

// 根据id滚动到底部
export function scrollMessageListToEnd(id = 'messageList-dialogue-blocks') {
  setTimeout(() => {
    let messageListwrapper = document.getElementById(id);
    if (messageListwrapper) {
      messageListwrapper.scrollTop = messageListwrapper.scrollHeight;
    }
  }, 0)
}

// 根据URL地址获取角色
export function getRoleFromLocation() {
  const createHistory = require('history').createBrowserHistory;
  let history = createHistory();
  const pathname = history.location.pathname;
  const role = pathname.split('/')[1];
  return role;
}

// 判断时间距离现在是否超过24小时
export function isMoreThan24Hours(time) {
  return MomentDiffZone(time).valueOf() - MomentDiffZone().valueOf() > 1000 * 60 * 60 * 24;
}
// 判断时间距离现在是否超过指定时间
export function isMoreThanSpecificHours(time, hours) {
  return MomentDiffZone(time).valueOf() - MomentDiffZone().valueOf() > 1000 * 60 * 60 * hours;
}

// 判断时间距离现在是否超过3分钟
export function isMoreThan3Mins(time) {
  const currentTime = MomentDiffZone().valueOf();
  const timeValue = MomentDiffZone(time).valueOf();
  return currentTime - timeValue > 1000 * 60 * 3;
}

// 删除数组中的指定元素
export function removeChild(arr, val) {
  var index = arr.indexOf(val)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr;
}

// 判断时间是不是在工作时间内
export function isWorkTime(startWorkTime, endWorkTime) {
  // 无参数默认24小时工作
  if(!startWorkTime) {
    return true
  }
  // 转换开始结束时间
  const startTime = moment(startWorkTime, 'HH:mm');
  const endTime = moment(endWorkTime, 'HH:mm');
  const currentTime = moment();
  return currentTime.isBetween(startTime, endTime);
}
