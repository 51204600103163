// 该页面用于展示量表数据的页面
import React, { useEffect, useState } from "react";
import TitleBar from "@/components/TitleBar";
import { List } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from "lodash";
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { doctorGetPatientFormList } from "@/services/api";
import { RightOutline } from "antd-mobile-icons";
import { getLoginToken } from '@/utils/localStorage.js';
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';

export default function FormList(props) {
  const location = useLocation();
  const navigate = useNavigate();
  // const state = _.get(location, ['state']);
  const staffId =  _.get(location, ['state', 'staffId']);
  const [formList, setFormList] = useState([]);
  const lob = getLobFromToken(getLoginToken('doctor'));

  async function _doctorGetPatientFormList() {
    let params = {
      groupReportBy: lob === lobType.SFDA ? 'groupByInstance' : 'groupByForm',
      staffId,
    }
    let res = await doctorGetPatientFormList(params);
    setFormList(_.get(res, ['result', 'successFormList'], []));
  }


  useEffect(() => {
    _doctorGetPatientFormList();
  }, []);

  function renderState() {
    if (formList.length > 0) {
      return (
        <List>
          {
            formList.map((element, index) => {
              return (
                <List.Item
                  key={index}
                  clickable
                  description={MomentDiffZone(element?.reportTime).format("YYYY年MM月DD日")}
                  arrow={
                    <div style={{
                      color: '#FFAA00',
                      fontWeight: 'bold',
                    }}>
                      <RightOutline />
                    </div>
                  }
                  onClick={() => {
                    navigate('/therapist/form-iframe', {
                      state: {
                        url: element?.reportUrl,
                        consultId: element?.consultId,
                        type: 'readonly',
                        title: '量表数据',
                      }
                    })
                  }}
                >
                  {element.nameZh}
                </List.Item>
              )
            })
          }
        </List>
      )
    }
    else {
      return (
        <List>
          <List.Item
            style={{
              textAlign: 'center'
            }}
          >
            暂无数据
          </List.Item>
        </List>
      )
    }
  }

  return (
    <>
      <TitleBar title="量表数据" isShowBack={true} />
      {renderState()}
    </>
  )
}