// 查看和监控网络状态
import React, { useEffect, useState } from "react";

export default function NetworkConnectedStatus() {
  // 默认是有网的，否则无法加载页面
  const [isOnline, setIsOnline] = useState(true);

  const handleOnline = (e) => {
    setIsOnline(true);
    if (isOnline === false && e.type === 'online') {
      window.location.reload();
    }
  }

  const handleOffline = (e) => {
    setIsOnline(false);
    // if (isOnline === true && e.type === 'offline') {
    //   window.location.reload();
    // }
  }

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);

  useEffect(() => {   
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    }
  }, [])

  return (
    <>
    </>
  )
}