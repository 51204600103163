import React, {useEffect} from "react";
import './index.less';
import { useNavigate } from "react-router-dom";
import TitleBar from '@/components/TitleBar';
import TransitionGif from '@/assets/images/transition.gif';
import { decodeToken } from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';
import { funcList, commonConstant } from '@/Constant';

const transitionText = '根据您刚刚的评估内容，正在为您生成定制化计划~请稍等';
const ROLE = 'patient';
function TransitionPage () {
  const { tenantId } = decodeToken(getLoginToken(ROLE))
  const { pageTitle = commonConstant.pageTitle,  } = funcList[tenantId] || funcList.default;

  // 过渡界面
  function renderTransitionContent () {
    const navigate = useNavigate();

    useEffect(() => {
      setTimeout(() => {
        navigate('/client/home-v2/main', {replace: true})
      }, 3000)
    }, [])

    return (
      <div className="transition-content">
        <div className="transition-text">
          {transitionText}
        </div>
        <img alt='' src={TransitionGif}/>
      </div>
    )
  }

  return (
   <>
     <TitleBar
      title={pageTitle}
    />
    {
      renderTransitionContent()
    }
   </>
  )
}

export default TransitionPage;