// 该组件在页面的上层（titlebar）下面，用于进行提示
import React, { useCallback, useEffect } from 'react';
import './index.less';
import { Modal } from "antd-mobile";
import { useNavigate } from 'react-router-dom';
import { consultantRevokeInfoApply } from "@/services/api";

function NavigationHint(props) {
  const status = props?.status;
  const applyId = props?.applyId
  const applyTime = props?.applyTime;
  const navigate = useNavigate();
  const _revokeInfoApply = useCallback(async() => {
    let res = await consultantRevokeInfoApply(applyId);
  }, []);

  /**
   * 
   * @param {*} status 
   */
  function renderNavigationHint(status) {

    switch (status) {
      case 'NOT_APPROVED':
        return (
          <div className='hole-hint-not-pass'>
            审核未通过，请重新编辑提交
          </div>
        )
      case 'APPROVING':
        return (
          <div className='hole-revocation'>
            <div className='revocation-text'>审核中</div>
            <div 
              className='revocation-button'
              onClick={() => {
                Modal.show({
                  content: (
                    <div>
                      {`您确定要撤销${applyTime}提交的个人介绍审核申请嘛？`}
                    </div>
                  ),
                  closeOnMaskClick: true,
                  closeOnAction: true,
                  actions: [
                    {
                      key: 'confirm',
                      text: '确认提交信息',
                      onClick: () => {
                        _revokeInfoApply()
                        navigate(-1);
                      },
                      style: {
                        color: '#FFAA00',
                      }
                    }
                  ],
                })
              }}
            >
              撤销
            </div>
          </div>
        )
      default:
        return (
          <></>
        )
    }
  }
  
  return (
    <div className='hole-hint'>
      {
        renderNavigationHint(status)
      }
    </div>
  )
}
export default NavigationHint;