import React from 'react';
import './index.less';

function SplitBorder() {
  
  return (
    <>
      <div className="split-border"></div>
    </>
  )
}

export default SplitBorder;