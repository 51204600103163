import React, { useState, useEffect, useRef } from 'react';
import './login.less';
import { Modal, Checkbox, Input, Toast, Button } from 'antd-mobile';
import { sendPatientVerifyCode, sendDoctorVerifyCode } from '@/services/api';

function Login(props) {
  const { loginTitle, theme, onLogin, onSendVerifyCode, type } = props;
  const [agreeType, setAgreeType] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [stdId, setStdId] = useState(''); // 学生学号
  const [pwd, setPwd] = useState('12345*'); // 学号对应的密码
  const [verificationCode, setVerificationCode] = useState('');
  const [vcodeInterval, setVcodeInterval] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const timerID = useRef(null);

  // 发送短信倒计时
  useEffect(() => {
    if (vcodeInterval > 0) {
      timerID.current = setTimeout(() => {
        setVcodeInterval(vcodeInterval - 1)
      }, 1000)
      timerID.current = null;
    }
    return () => {
      timerID.current = null;
    }
  }, [vcodeInterval])

  // 发送短信验证码  
  async function handleVerifyCode() {
    onSendVerifyCode(cellphone);
    setVcodeInterval(30);
  }

  function checkAgree() {
    !agreeType && Toast.show({
      content: '请阅读并同意《舒辅EAP服务协议》',
      key: 'login'
    })
    return agreeType
  }

  function checkPhoneAndVerifycode() {
    if (!cellphone || cellphone.length !== 11) {
      Toast.show({
        content: '请检查您的手机号是否正确',
        key: 'cellphone'
      });
      return false
    }
    if (verificationCode.length === 4 || verificationCode.length === 6) {
      return true
    }
    Toast.show({
      content: '请正确填写验证码',
      key: 'verificationCode'
    });
    return false;
  }

  // 登录
  function onCellphoneLogin() {
    // const agreement = checkAgree();
    // 先注释掉同意eap协议的选项
    const agreement = true;
    const inputCorrect = checkPhoneAndVerifycode();
    if (agreement && inputCorrect) {
      const params = {
        telephone: cellphone,
        verifyCode: verificationCode,
      }
      onLogin(params);
    }
  }
  // 学号登录
  function onStdIdLogin() {
    // const agreement = checkAgree();
    // 先注释掉同意eap协议的选项
    const agreement = true;
    if (!stdId) {
      Toast.show({
        content: '请检查您的学号是否正确',
        key: 'stdId'
      });
      return
    }
    if (!pwd) {
      Toast.show({
        content: '请检查您的密码',
        key: 'pwd'
      });
      return
    }
    if (agreement) {
      const params = {
        account: stdId,
        password: pwd,
      }
      onLogin(params);
    }
  }

  function renderCellphoneLogin() {
    return (
      <>
        <div className="login-info">
          <div className={`info-title tx-color-${theme}`}>手机号</div>
          <Input className="phone-input" size="large" placeholder="请输入手机号" onChange={value => setCellphone(value)} />
          <div className={`info-title tx-color-${theme}`}>验证码</div>
          <div className="phone-code-content">
            <Input className="phone-input phone-code" placeholder="请输入验证码" onChange={value => setVerificationCode(value)} />
            {
              vcodeInterval === 0 ? <Button className={`phone-send-code-btn bg-theme-${theme}`} color='primary' onClick={(handleVerifyCode)}>获取验证码</Button> :
                <Button className="phone-send-code-btn" style={{ '--background-color': '#aaa', color: '#fff' }}>{vcodeInterval}s重新发送</Button>
            }
          </div>
        </div>
        <div className='login-btn-content'>
          <Button className={`btn-login bg-theme-${theme}`} onClick={onCellphoneLogin}>一键登录</Button>
          {/* <div className={`agree-type-${theme}`}>
          <Checkbox className='agree-hint' checked={agreeType} onChange={val => setAgreeType(val)}>我已阅读并同意</Checkbox>
          <div onClick={() => { setAgreementModal(true) }} className={`tx-color-${props.theme}`}>《{loginTitle}服务协议》</div>
        </div> */}
        </div>
      </>
    )
  }

  function renderStdIdLogin() {
    return (
      <>
        <div className="login-info">
          <div className={`info-title tx-color-${theme}`}>您的学号</div>
          <Input className="phone-input" size="large" placeholder="请输入您的学号" onChange={value => setStdId(value)} />
          {/* <div className={`info-title tx-color-${theme}`}>密码</div>
          <Input
            placeholder='请输入您的密码'
            type='password'
            onChange={value => setPwd(value)}
          /> */}
        </div>
        <div className='login-btn-content'>
          <Button className={`btn-login bg-theme-${theme}`} onClick={onStdIdLogin}>一键登录</Button>
          {/* <div className={`agree-type-${theme}`}>
          <Checkbox className='agree-hint' checked={agreeType} onChange={val => setAgreeType(val)}>我已阅读并同意</Checkbox>
          <div onClick={() => { setAgreementModal(true) }} className={`tx-color-${props.theme}`}>《{loginTitle}服务协议》</div>
        </div> */}
        </div>
      </>
    )
  }

  /**
   * 根据type类型，选择登录方式
   * default cellphone：手机号码
   * stdId： 学生学号
   */
  function renderLoginContent() {
    switch (type) {
      case 'cellphone':
        return renderCellphoneLogin();
      case 'stdId':
        return renderStdIdLogin()
      default:
        return renderCellphoneLogin();
    }
  }

  return (
    <>
      <div className="login">
        <div className="login-main-content">
          {
            theme === 'patient' ?
              <img className="logo" alt="" src={require('@/assets/icon/logo.bmp')} />
              :
              <img className="logo" alt="" src={require('@/assets/icon/logo.svg').default} />
          }
          <div className="title">欢迎使用</div>
          <div className="title">{loginTitle}助手</div>
          {
            renderLoginContent()
          }
        </div>
        {/* <div className='login-btn-content'>
          <Button className={`btn-login bg-theme-${theme}`} onClick={login}>一键登录</Button>
          <div className={`agree-type-${theme}`}>
            <Checkbox className='agree-hint' checked={agreeType} onChange={val => setAgreeType(val)}>我已阅读并同意</Checkbox>
            <div onClick={() => { setAgreementModal(true) }} className={`tx-color-${props.theme}`}>《{loginTitle}服务协议》</div>
          </div>
        </div> */}
      </div>
      <div className={showModal ? "shade-modal" : "shade-modal show-none"}>
        <div className="shade"></div>
        <div className="modal">
          <div className="title">{loginTitle}助手</div>
          <div className="content">为了确保您的就医安全，需要您输入您的微信手机号</div>
          <div className="btn" onClick={() => {
            setShowModal(false);
          }}>去授权</div>
        </div>
      </div>
      <Modal
        visible={agreementModal}
        transparent
        closeOnMaskClick={true}
        showCloseButton={true}
        title={`${loginTitle}协议`}
        onClose={() => {
          setAgreementModal(false)
        }}
        footer={[{ text: '确认', onPress: () => { setAgreementModal(false) } }]}
      >
        <p style={{ padding: '1rem' }}>请详细阅读以下协议内容</p>
      </Modal>
    </>
  )
}

export default Login;