import React, { useState, useEffect, useRef, useCallback } from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import TitleBar from '@/components/TitleBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { List, Tabs, Swiper } from 'antd-mobile';
import { getStaffFormList } from "@/services/api";
import { getLoginToken } from '@/utils/localStorage.js';
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';

function FormTaskList(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const taskId = _.get(location, ['state', 'taskId'], '');
  const [formIncompleteList, setFormIncompleteList] = useState([]); // [咨询师]未完成的量表
  const [formCompleteList, setFormCompleteList] = useState([]); // [咨询师]已完成的量表
  const [userFormCompleteList, setUserFormCompleteList] = useState([]); // 自测量表
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const staffId = useSelector(state => state.baseInfo['patient']?.roleInfo?.staffId);
  // 国药EAP 需要聚合量表
  const lob = getLobFromToken(getLoginToken('patient'));

  const tabsList = [
    '自测量表', '咨询师发送量表'
  ]

  // 获取用户自测量表
  const _getUserFormList = useCallback(async () => {
    let userParams = {
      taskType: 'STAFF_OPTIONAL',
      staffId,
      groupReportBy: lob === lobType.SFDA ? 'groupByInstance' : 'groupByForm',
    }
    let res = await getStaffFormList(userParams);
    let userFormCompleteList = _.get(res, ['result', 'successFormList'], []);
    setUserFormCompleteList(userFormCompleteList);
  }, [])

  // 获取咨询师发送自测量表
  const _getConsultantFormList = useCallback(async () => {
    let consultantUserParams = {
      taskType: 'CHAT_FORM',
      staffId,
      groupReportBy: lob === lobType.SFDA ? 'groupByInstance' : 'groupByForm',
    }
    let res = await getStaffFormList(consultantUserParams);
    let formIncompleteList = _.get(res, ['result', 'doingFormList'], []);
    setFormIncompleteList(formIncompleteList);
    let formCompleteList = _.get(res, ['result', 'successFormList'], []);
    setFormCompleteList(formCompleteList);
  }, [])

  useEffect(() => {
    _getConsultantFormList();
  }, [_getConsultantFormList])

  useEffect(() => {
    _getUserFormList();
  }, [_getUserFormList])

  // 咨询师发送量表
  function renderFormsList() {
    return (
      <div className='form-list-content'>
        <div className='list-title'>
          待完成的量表
        </div>
        {
          formIncompleteList.length > 0
            ? (
              <List>
                {
                  formIncompleteList.map((item, index) => {
                    return (
                      <List.Item
                        className='menu-list-item'
                        key={index}
                        arrow={true}
                        onClick={() => {
                          navigate('/client/staff-task-iframe', { state: { title: item.nameZh, url: item.formUrl } });
                        }}>
                        {item.nameZh}
                      </List.Item>
                    )
                  })
                }
              </List>
            )
            : renderNone()
        }
        <div className='list-title'>
          已完成的量表
        </div>
        {
          formCompleteList.length > 0
            ? (
              <List>
                {
                  formCompleteList.map((item, index) => {
                    return (
                      <List.Item
                        className='menu-list-item'
                        key={index}
                        arrow={true}
                        onClick={() => {
                          navigate('/client/staff-task-iframe', { state: { title: item.nameZh, url: item.reportUrl } });
                        }}>
                        {item.nameZh}
                      </List.Item>
                    )
                  })
                }
              </List>
            )
            : renderNone()
        }
      </div>
    )
  }

  // 自测量表
  function renderMineFormList() {

    return (
      userFormCompleteList.length > 0
        ? (
          <div className='form-list-content'>
            <List>
              {
                userFormCompleteList.map((item, index) => {
                  return (
                    <List.Item
                      className='menu-list-item'
                      key={index}
                      arrow={true}
                      onClick={() => {
                        navigate('/client/staff-task-iframe', { state: { title: item.nameZh, url: item.reportUrl } });
                      }}
                      extra={MomentDiffZone(item.reportTime).format('YYYY-MM-DD HH:mm:00')}
                    >
                      {item.nameZh}
                    </List.Item>
                  )
                })
              }
            </List>
          </div>
        )
        : renderNone()
    )
  }

  // 暂无数据
  function renderNone() {

    return (
      <>
        <List>
          <List.Item
            style={{
              textAlign: 'center'
            }}
          >
            暂无数据
          </List.Item>
        </List>
      </>
    )
  }

  // swiper返回不同的量表列表
  function renderSwiperContent(index) {
    switch (index) {
      case 0:
        return renderMineFormList()
      case 1:
        return renderFormsList()
      default:
        return renderMineFormList()
    }
  }

  return (
    <div className='form-task-list-root'>
      <TitleBar title='所有量表' isShowBack={true}></TitleBar>
      <Tabs
        activeKey={tabsList[activeIndex]}
        onChange={(keyVal) => {
          let index = tabsList.findIndex(item => item === keyVal);
          setActiveIndex(index);
          swiperRef.current?.swipeTo(index);
        }}
      >
        {
          tabsList.map((item, index) => {
            return (
              <Tabs.Tab
                title={item}
                key={item}
              >
              </Tabs.Tab>
            )
          })
        }
      </Tabs>
      <Swiper
        direction='horizontal'
        loop={true}
        indicator={() => null}
        ref={swiperRef}
        defaultIndex={activeIndex}
        onIndexChange={index => {
          setActiveIndex(index)
        }}
      >
        {
          tabsList.map((item, index) => {
            return (
              <Swiper.Item key={index}>
                {renderSwiperContent(index)}
              </Swiper.Item>
            )
          })
        }
      </Swiper>
    </div>
  )
}

export default FormTaskList;