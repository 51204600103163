import article_1 from "@/assets/images/patient-article/article_1.png";
import article_2 from "@/assets/images/patient-article/article_2.png";
import article_3 from "@/assets/images/patient-article/article_3.png";
import article_4 from "@/assets/images/patient-article/article_4.png";
import article_5 from "@/assets/images/patient-article/article_5.png";
import article_6 from "@/assets/images/patient-article/article_6.png";
import article_7 from "@/assets/images/patient-article/article_7.png";
import article_8 from "@/assets/images/patient-article/article_8.png";
import article_9 from "@/assets/images/patient-article/article_9.png";
import article_10 from "@/assets/images/patient-article/article_10.png";
import article_11 from "@/assets/images/patient-article/article_11.png";
import article_12 from "@/assets/images/patient-article/article_12.png";
import article_13 from "@/assets/images/patient-article/article_13.png";
import article_14 from "@/assets/images/patient-article/article_14.png";
import article_15 from "@/assets/images/patient-article/article_15.png";
import article_16 from "@/assets/images/patient-article/article_16.png";
import article_17 from "@/assets/images/patient-article/article_17.png";
import article_18 from "@/assets/images/patient-article/article_18.png";
import article_19 from "@/assets/images/patient-article/article_19.png";
import article_20 from "@/assets/images/patient-article/article_20.png";
import article_21 from "@/assets/images/patient-article/article_21.png";
import article_22 from "@/assets/images/patient-article/article_22.png";
import article_23 from "@/assets/images/patient-article/article_23.png";
import article_24 from "@/assets/images/patient-article/article_24.png";
import article_25 from "@/assets/images/patient-article/article_25.png";
import article_26 from "@/assets/images/patient-article/article_26.png";
import article_27 from "@/assets/images/patient-article/article_27.png";
import article_28 from "@/assets/images/patient-article/article_28.png";
import article_29 from "@/assets/images/patient-article/article_29.png";
import article_30 from "@/assets/images/patient-article/article_30.png";
import article_31 from "@/assets/images/patient-article/article_31.png";
import article_32 from "@/assets/images/patient-article/article_32.png";
import article_33 from "@/assets/images/patient-article/article_33.png";

export const articleList = [
  {
    img: article_33,
    title: "如何抵抗抑郁之食物疗法",
    time: "2023-07-21",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489377&idx=1&sn=fd8f6da82785bea2d2c90a7cb18b6607&chksm=fbbb0802cccc8114b60fefbae09546317abea5a79845515f087cef67450ec99e144d796ec263&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_32,
    title: "理解和应对孩子青春叛逆期：家长的角色和策略",
    time: "2023-07-21",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489355&idx=1&sn=eea35294433dbc337c1079b4620f8702&chksm=fbbb0828cccc813eed61fdc78e42f9a1b00c96808eb3dee694eee91a77ab5ca28e083b7e17f1&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_31,
    title: "重建破裂的婚姻关系：婚姻危机的应对方法",
    time: "2023-07-21",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489328&idx=1&sn=4291424c4db4e774c5ad7bf6a4c8f277&chksm=fbbb0853cccc81451560f672517dd8494412d3e90625b08a497522ead8f724150225eb072b96&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_30,
    title: "情绪共处：理解和管理情绪的重要性",
    time: "2023-07-20",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489278&idx=1&sn=b6af0e7863e2a1482220c978e7df94af&chksm=fbbb099dcccc808bec13aeca96ef60e020feb69411c807445639c1f3f1d0ed667787bc10c539&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_29,
    title: "构建积极亲子关系：亲子教育科普",
    time: "2023-07-20",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489301&idx=1&sn=fef81324150cdba2151dee32214576ef&chksm=fbbb0876cccc816002b5274aef0506c2f750bced6eff166ad2161a6310741c009cec8014a5c0&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_28,
    title: "理解焦虑和抑郁情绪",
    time: "2023-07-20",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489280&idx=1&sn=003f256a2f801c5cac73d63b8ff0674c&chksm=fbbb0863cccc81752bd912b1751e6d1fc36de34d0d759d1ba0b5d13537617cbc6c3286c4b454&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_27,
    title: "解梦|做了那么多梦，还是不知道梦是什么",
    time: "2023-07-17",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489092&idx=1&sn=0df3a5bbf7cf77f33ea172e2e88c44a0&chksm=fbbb0927cccc8031fcebd62e19dd8f9da1ca5b543a14fde37d660873958e4c5f3f9edc9e46f3&token=1277488138&lang=zh_CN#rd%23rd"
  },
  {
    img: article_26,
    title: "你的孩子是天使还是恶魔？",
    time: "2023-07-17",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247489090&idx=1&sn=aac8359b0c9b85dbc820394da5b2e378&chksm=fbbb0921cccc803760529fcd27f18a5d0eee93a2c3e999bea62acdf9b80d490631f2db5ff36e&token=1277488138&lang=zh_CN#rd%23rd",
  },
  {
    img: article_25,
    title: "面对青春期叛逆的孩子，怎么办？",
    description: "青少年心理健康",
    time: "2022-08-15",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247486340&idx=1&sn=f8807ba0d2d7cc6e7414894c54ea4ab7&chksm=fbbb14e7cccc9df1bf3a41597f2562c34bac21cdc41d2c0c3bb70d8d453346caf97f7fb347b9&token=1605713369&lang=zh_CN#rd",
  },
  {
    img: article_24,
    title: "面对孩子的渴望，家长能给予什么？",
    description: "青少年心理健康",
    time: "2022-06-21",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485804&idx=1&sn=79a0712c80a21ae483d59430da0bcda3&chksm=fbbb160fcccc9f19d869bec455f10f0cf56042cb9fa8d730548b774a5c0b8fce2bfc5db53904&token=395165467&lang=zh_CN#rd",
  },
  {
    img: article_23,
    title: "心理学巨匠罗杰斯几十年心理咨询总结：愉快地活着，是一门专业",
    time: "2022-05-09",
    url: 'https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247487939&idx=1&sn=22c13efddac88b59f1896f9a2fe8996a&chksm=fbbb0ea0cccc87b67cde2bf41393b25a5dd39102cfe26cd1fba8b59c1d5ab107a01c27490890&token=1673591770&lang=zh_CN#rd'
  },
  {
    img: article_22,
    title:
      "委屈的身体：70％以上的人，会以攻击自己身体器官的方式来消化自己的情绪",
    time: "2022-05-09",
    url: 'https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247487953&idx=1&sn=6f462f5f9bf6310774d93b6851df50ec&chksm=fbbb0eb2cccc87a4911bce6ce7069d38e9379548a17bbb000dd3b64e3adda031899487963556&token=1673591770&lang=zh_CN#rd'
  },
  {
    img: article_21,
    title: "10个理由告诉你该不该选择心理咨询",
    time: " 2022-05-09",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247487969&idx=1&sn=bf8fd0a3f288e7196beeef61ccd3852c&chksm=fbbb0e82cccc87943f84f3d668bb54f8c2a7f9dc7ef78828d3a23ee6803f5dbd73158ae1bf5a&token=1673591770&lang=zh_CN#rd",
  },
  {
    img: article_20,
    title: "你知道吗，90%的痛苦和情绪都是“想出来的”",
    time: " 2022-05-09",
    url: 'https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247487967&idx=1&sn=a9ab7f1091cd168289ad3ca21cc63036&chksm=fbbb0ebccccc87aaac3f84ce64aa561206873ae24428176bf094529925438ae75da89bab2c7c&token=1673591770&lang=zh_CN#rd'
  },
  {
    img: article_2,
    title: "应对疫情的9条心理疏导短片",
    description: "应对疫情的9条心理疏导短片",
    time: "2022-04-11",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485592&idx=1&sn=179c6e9224b53dcddfe0232860c7cb50&chksm=fbbb17fbcccc9eed3ebc6beca693b872f5d6135f04cb5c9161bed12f1ff5d91570c921069790&token=109023130&lang=zh_CN",
  },
  {
    img: article_1,
    title: "向上的管理——如何管理领导的预期",
    time: "2021-04-23",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485314&idx=1&sn=d78e050743b904ba53fbf3079b45fd73&chksm=fbbb18e1cccc91f79188ee327758c64a21102f17511a72246da2e380f8f3b8c7992ecf317016&token=109023130&lang=zh_CN",
  },
  {
    img: article_3,
    title: "什么是坚毅？",
    time: "2021-04-08",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485277&idx=1&sn=59dc455df788183a4d485d4fd8f39120&chksm=fbbb183ecccc9128d6b3acdc04b2252489cbf56b66b60c46104d2f9f857a111acc7d71bfede2&token=109023130&lang=zh_CN",
  },
  {
    img: article_4,
    title: "工作容易受情绪所影响，这该怎么办？",
    time: "2021-03-22",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485265&idx=1&sn=f33427ef4b32761d9bd53195d69ba49d&chksm=fbbb1832cccc9124213b67ef534fd3c80e9e23c2105e1055964740116f631a8930ec2c3004d7&token=109023130&lang=zh_CN",
  },
  {
    img: article_5,
    title: "3招设定你的小目标",
    time: "2021-03-08",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485204&idx=1&sn=51b4bae78fd59901db6768cdb47654da&chksm=fbbb1877cccc9161f74c0c570c48e5407944e30be0908aa52db7851bd9014572481bed9a6939&token=109023130&lang=zh_CN",
  },
  {
    img: article_6,
    title: "报复性熬夜：你上一次早睡是什么时候？",
    time: "2021-03-01",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485191&idx=1&sn=9736d7eb38df50e54265a7339d524d31&chksm=fbbb1864cccc917259f147322c42e749477a4afbf0a74fdd35662cca013c774cb8922854e0a3&token=109023130&lang=zh_CN",
  },
  {
    img: article_7,
    title: "懂得道歉的家长，都赢得了孩子的心",
    time: "2021-02-22",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485179&idx=1&sn=f2b2b8bab171f5b5595e0029ef4f72c2&chksm=fbbb1998cccc908e9927a7da18ac32c3b71b61cb3b7fe549181d71216291df962172c30d2f22&token=109023130&lang=zh_CN",
  },
  {
    img: article_8,
    title: "忘忧杂货铺：给悲伤多一些宽容",
    time: "2021-01-25",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485144&idx=1&sn=cc89beeef3eb9dc7e35bbf5a2cb0d4d0&chksm=fbbb19bbcccc90adc413b3d283b6797362f41988c533bcd9fc82bf07f87c088af61eab37112f&token=109023130&lang=zh_CN",
  },
  {
    img: article_9,
    title: "都市人，小心你的“情绪肠胃病”",
    time: "2020-12-28",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485055&idx=2&sn=255e2d2f4fcda33d31d967b07ac4abfa&chksm=fbbb191ccccc900ae5ad33593ae9deed8f9ee95f1baa1ac8b334de4f6400cc9abd5bc537323e&token=109023130&lang=zh_CN",
  },
  {
    img: article_10,
    title: "怎么提高提升自我约束力？",
    time: "2020-12-14",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247485034&idx=1&sn=7e08368c24da6c1934f9a41c5e04135a&chksm=fbbb1909cccc901f3895e3625d3cebb59c060ba7a374a8187c62c8a46da321034191c9dc00df&token=109023130&lang=zh_CN",
  },
  {
    img: article_11,
    title: "四招教你培养高情商的孩子",
    time: "2020-11-02",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484851&idx=2&sn=f9a2724f650495b20360bff5086093b4&chksm=fbbb1ad0cccc93c636022d6b9cae9e5562feee3b0c684e329f81e4d30915a0ba5dac61df610d&token=109023130&lang=zh_CN",
  },
  {
    img: article_12,
    title: "关于抑郁，你需要知道十件事",
    time: "2020-10-19",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484798&idx=1&sn=9466296374519022e53af1f02ed05669&chksm=fbbb1a1dcccc930bfe3c4cf700c71b1a23712822cfe1b089d5799ae8d258e4385df0729f8954&token=109023130&lang=zh_CN",
  },
  {
    img: article_13,
    title: "孩子内向，真的是坏事吗？",
    time: "2020-09-07",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484676&idx=1&sn=c4ae2d57fd469d8c0244667061df342e&chksm=fbbb1a67cccc93711f1572e2b516f7e4decb058f5a40853892c156907b946011c8dffb5737fa&token=109023130&lang=zh_CN",
  },
  {
    img: article_14,
    title: "你还在身材焦虑吗？",
    time: "2020-08-17",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484583&idx=2&sn=66b407f587028f05eb5bc1a3d29c8def&chksm=fbbb1bc4cccc92d2029ec08fe9e66f166fd1ed92b367eedd6be2eabdc9a89d1ec3039e99eed4&token=109023130&lang=zh_CN",
  },
  {
    img: article_15,
    title: "夜深人静的时候，你会干什么？",
    time: "2020-08-12",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484568&idx=2&sn=a0d7403717eee198a1dfbd6111531c72&chksm=fbbb1bfbcccc92edcecd84914bc9f70808cc447e3dcbe8065a8dab58f4b32cf04580398a525e&token=109023130&lang=zh_CN",
  },
  {
    img: article_16,
    title: "压力是敌人还是朋友？",
    time: "2020-07-06",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484433&idx=1&sn=50d005c0f33e8bbbc81b007ab1c412c8&chksm=fbbb1b72cccc92649b4116482dac3703b61a7a51b5a33a6d678d34c796c889e8897245da50d2&token=109023130&lang=zh_CN",
  },
  {
    img: article_17,
    title: "如何有效地远离拖延症？",
    time: "2020-07-01",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484384&idx=1&sn=ba164c5d22f97878aa59373e5ab528ad&chksm=fbbb1c83cccc95957971f3a2fcb5e867bd97a8e270cf18a875394287b95f93c977171588acd2&token=109023130&lang=zh_CN",
  },
  {
    img: article_18,
    title: "怎么养成积极乐观的心态？",
    time: "2020-06-15",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484346&idx=1&sn=372f4a36d5410fc3374745c2ed6e420f&chksm=fbbb1cd9cccc95cf079c4da330236c977cb1b9c17619f16affdbba0e23b90538698dbf16a4e4&token=109023130&lang=zh_CN",
  },
  {
    img: article_19,
    title: "父母与孩子不仅要建立生理的边界，也要建立心理的边界",
    time: "2020-04-20",
    url: "https://mp.weixin.qq.com/s?__biz=MzU0ODc1MTg4NA==&mid=2247484221&idx=1&sn=9db0ba746cf931a06f716b621a03fe09&chksm=fbbb1c5ecccc9548e66ed9ce5a9623c217698c9a4e73dfdfd4a4e024c5bc2dc0c9229bf469bb&token=109023130&lang=zh_CN",
  }
];