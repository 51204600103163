// 来访者和咨询师的聊天记录查看
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Radio, Dialog, FloatingBubble, PullToRefresh, Result } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutline } from 'antd-mobile-icons';

import './index.less';
import TitleBar from "@/components/TitleBar";
import { adminGetChatMsg, adminGetConsultantByStaff } from '@/services/api';
import { message_get, message_add } from '@/actions';
import { RenderMessageContent } from '@/components/DialogueMessage';

export default function ChatRecord(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { staffInfo } = location.state;
  const clientAuthId = staffInfo.authId;
  const chatMsg = useSelector(state => state.chatMsg);
  const [pageSize, setPageSize] = useState(20);
  const pageNo = useRef();
  const [floatBubbleVisible, setFloatBubbleVisible] = useState(false);
  const [dialogueVisible, setDialogueVisible] = useState(false);
  const [consultantList, setConsultantList] = useState([])
  // 当前筛选的咨询师
  const currentConsultant = useRef();

  // 查询来访者聊天的咨询师列表
  async function _getConsultantListByStaff() {
    const params = {
      staffId: clientAuthId
    }
    const res = await adminGetConsultantByStaff(params);
    if (!res.errorCode) {
      setConsultantList(_.get(res, ['result'], []))
    }
  }

  // 查询所有的聊天记录
  async function _patientGetChatMsg() {
    pageNo.current = 1;
    let params;
    if (currentConsultant.current) {
      params = {
        pageNo: pageNo.current,
        pageSize: pageSize,
        fromAuthId: clientAuthId,
        chatAuthId: currentConsultant.current
      }
    } else {
      params = {
        pageNo: pageNo.current,
        pageSize: pageSize,
        byAuthId: clientAuthId
      }
    }
    const res = await adminGetChatMsg(params);
    if (!res.errorCode) {
      dispatch(message_get(_.get(res, ['result', 'data'], []).reverse()));
    }
  }

  // 下拉更新数据
  async function _patientAddChatMsg() {
    let params;
    if (currentConsultant.current) {
      params = {
        pageNo: pageNo.current,
        pageSize: pageSize,
        fromAuthId: clientAuthId,
        chatAuthId: currentConsultant.current
      }
    } else {
      params = {
        pageNo: pageNo.current,
        pageSize: pageSize,
        byAuthId: clientAuthId
      }
    }
    const res = await adminGetChatMsg(params);
    dispatch(message_add(_.get(res, ['result', 'data'], []).reverse()));
  }

  useEffect(() => {
    async function initChat() {
      await _patientGetChatMsg();
    }
    initChat();
    _getConsultantListByStaff();
  }, []);

  // 定义下拉加载时的自定义提示
  const statusRecord = {
    pulling: '下拉获取更多消息',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
  }

  const handleFloatingBubbleClick = () => {
    setDialogueVisible(true);
  }

  return (
    <div className='chat-record-page'>
      <TitleBar title={`${staffInfo?.nickName}聊天记录`} isShowBack={true}></TitleBar>
      <div className='dialogue-style'>
        <div className='dialogue-blocks' id='messageList-dialogue-blocks'>
          <PullToRefresh
            onRefresh={() => {
              pageNo.current += 1;
              // setScrollEnd(false);
              _patientAddChatMsg();
            }}
            refreshingText={(text) => {
              return (
                <div>{statusRecord[text]}</div>
              )
            }}
          >
            {
              chatMsg && chatMsg.length > 0
                ? (
                  <RenderMessageContent
                    messageList={chatMsg}
                    role='admin'
                    roleInfo={staffInfo}
                  />
                )
                : (
                  <Result
                    status='warning'
                    title='暂无聊天记录'
                    description='该咨询师暂无聊天记录'
                  />
                )
            }
          </PullToRefresh>
        </div>
      </div>
      <FloatingBubble
        style={{
          '--initial-position-bottom': '24px',
          '--initial-position-right': '24px',
          '--edge-distance': '24px',
        }}
        visible={true}
        onClick={handleFloatingBubbleClick}
      >
        <SearchOutline fontSize={32} />
      </FloatingBubble>
      <Dialog
        className='dialogue-style'
        visible={dialogueVisible}
        onClose={() => setDialogueVisible(false)}
        content={
          <>
            <Radio.Group onChange={(e) => {
              currentConsultant.current = e;
            }}>
              {
                consultantList.map((item, index) => {
                  return (
                    <div className='consultant-item' key={index}>
                      <Radio
                        value={item.authId}
                      >
                        {item.name}
                      </Radio>
                    </div>
                  )
                })
              }
            </Radio.Group>
          </>
        }
        actions={
          [
            {
              key: 'cancel',
              text: '取消',
              onClick: () => {
                currentConsultant.current = null;
                setDialogueVisible(false);
              }
            },
            {
              key: 'ok',
              text: '确定',
              onClick: () => {
                setDialogueVisible(false);
                _patientGetChatMsg();
              }
            }
          ]
        }
      />
    </div>
  )
}