import BrowserLogger from 'alife-logger';
const Logger = () => {
  const __bl = BrowserLogger.singleton({
    pid:"cz1py5a76q@0adcdcf007b57dd",
    appType: 'web',
    imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
    sendResource: true,
    enableLinkTrace: true,
    behavior: true,
    enableSPA: false,
    apiHelper: [],
    disableHook: true,
    disabled: !!(
      window.location.href.match('localhost') ||
      window.location.href.match('127.0.0.1')
    )
  })
  window.__bl = __bl;
}
export default Logger;