import {
  CHAT_TO_ONE,
  MESSAGE_UPDATE,
  MESSAGE_ADD,
  REGISTER_SOCKET_EVENT,
  GET_MESSAGE,
  STAFF_LIST,
  SAVE_DOCTOR_INFO,
  HANDLE_NOTIFICATION,
  SAVE_PATIENT_INFO,
  SAVE_ADMIN_INFO,
  SAVE_CURRENT_CHAT_AUTH_ID,
  CALL_TO_ONE,
  LOGOUT_CHAT_CLOSE,
  SAVE_ADMIN_QUERY_STAFF_LIST,
  MESSAGE_CLEAR,
  MESSAGE_WITHDRAW,
  SAVE_RESOURCE_LIST,
  SAVE_PSYCHOLOGICAL_TEST_TAB_LIST,
  SHOW_GLOBAL_DIALOG, 
  HIDE_GLOBAL_DIALOG
} from "./actionType";

export function disconnect_socket() {
  return {
    type: LOGOUT_CHAT_CLOSE
  }
}

export function emit_message_to_server(msg) {
  return {
    type: CHAT_TO_ONE,
    value: msg
  }
}

export function emit_call_flag_to_server(msg) {
  return {
    type: CALL_TO_ONE,
    value: msg
  }
}

export function register_socket_event(socket) {
  return {
    type: REGISTER_SOCKET_EVENT,
    socket
  }
}
// 更新消息列表
export function message_update(msg) {
  return {
    type: MESSAGE_UPDATE,
    value: msg
  }
}

// 清空消息缓存记录
export function message_clear() {
  return {
    type: MESSAGE_CLEAR
  }
}

// 撤回消息
export function message_withdraw(msg) {
  return {
    type: MESSAGE_WITHDRAW,
    value: msg
  }
}

export function message_add(msg) {
  return {
    type: MESSAGE_ADD,
    value: msg
  }
}

export function message_get(msg) {
  return {
    type: GET_MESSAGE,
    value: msg
  }
}

// 对话框的用户列表
export function staff_list(list) {
  return {
    type: STAFF_LIST,
    list,
  }
}

// 保存咨询师信息
export function save_doctor_info(info) {
  return {
    type: SAVE_DOCTOR_INFO,
    info
  }
}

// 保存员工信息
export function save_patient_info(info) {
  return {
    type: SAVE_PATIENT_INFO,
    info
  }
}

// 保存舒小辅管理员的信息
export function save_admin_info(info) {
  return {
    type: SAVE_ADMIN_INFO,
    info
  }
}

// 处理notification
export function handle_notification(content) {
  return {
    type: HANDLE_NOTIFICATION,
    content
  }
}

// 保存当前的聊天对象的id
export function save_current_chat_auth_id(chatAuthId) {
  return {
    type: SAVE_CURRENT_CHAT_AUTH_ID,
    chatAuthId
  }
}

//保存管理员查询来访者列表
export function sav_admin_query_staff_list(list) {
  return {
    type: SAVE_ADMIN_QUERY_STAFF_LIST,
    list,
  }
}

// 保存资源列表
export function save_resource_list(list) {
  return {
    type: SAVE_RESOURCE_LIST,
    list,
  }
}

// 保存心理测试tab列表
export function save_psychological_test_tab_list(list) {
  return {
    type: SAVE_PSYCHOLOGICAL_TEST_TAB_LIST,
    list,
  }
}
// 展示全局弹框
export function show_global_dialog(content) {
  return {
    type: SHOW_GLOBAL_DIALOG,
    content
  }
}
// 隐藏全局弹框
export function hide_global_dialog() {
  return {
    type: HIDE_GLOBAL_DIALOG
  }
}