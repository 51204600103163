// 资源列表的reducer
import { SAVE_RESOURCE_LIST } from "@/actions/actionType";

const resourceListReducer = (state = [], action) => {
  switch (action.type) {
    case SAVE_RESOURCE_LIST:
      return action.list;
    default:
      return state;
  }
}
export default resourceListReducer