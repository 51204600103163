import React, { useState, useEffect, useCallback } from 'react';
import './index.less';
import AliyunRTC from '@/components/AliyunRTC';
import { getPatientRTCToken, staffLeaveRoom, patientGetDoctorInfo } from '@/services/api';
import { Toast } from 'antd-mobile';
import _ from 'lodash';
import { getPageQuery } from '@/utils/utils';
import { useNavigate } from 'react-router-dom';

function PatientVideoConsult(props) {
  const [token, setToken] = useState({});
  const [chatAuthAvatarUrl, setChatAuthAvatarUrl] = useState('');
  const { channelId, consultType, chatAuthId } = getPageQuery();
  const navigate = useNavigate();

  const getUserRTCToken = useCallback(
    async (channelId, type) => {
      const params = {
        channelType: 'CHAT',
        channelId: channelId
      }
      let res = await getPatientRTCToken(params);
      console.log('getRTCToken::', res)
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        });
      } else {
        setToken(res.result);
        console.log('authInfo', res.result);
      }
    },
    [],
  )

  // 获取医生信息,获取头像
  const _patientGetDoctorInfo = useCallback(async () => {
    const res = await patientGetDoctorInfo(chatAuthId);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      });
    } else {
      setChatAuthAvatarUrl(res.result.avatarUrl);
    }
  }, []);

  useEffect(() => {
    getUserRTCToken(channelId);
    _patientGetDoctorInfo();
  }, [channelId, getUserRTCToken])

  // 来访者退出房间
  function _staffLeaveRoom() {
    let params = {
      consultantId: chatAuthId,
      authRole: 'patient',
      // authId为token里面的userid
      authId: token.userid,
    };
    const res = staffLeaveRoom(params);
    navigate(`/client/dialogue/${chatAuthId}`);
  }

  return (
    <>
      <AliyunRTC
        role='patient'
        chatAuthId={chatAuthId}
        authInfo={token}
        channelId={channelId}
        isNeedConfirm={true}
        consultType={consultType}
        onLeaveRoom={_staffLeaveRoom}
        chatAuthAvatarUrl={chatAuthAvatarUrl}
      />
    </>
  )
}

export default PatientVideoConsult;