import React, { useEffect } from 'react';
import TitleBar from '@/components/TitleBar';
import IframeComponent from '@/components/Iframe';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { getSuggestConsultants } from '@/services/api';

function ConsultIframe(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const url = _.get(location, ['state', 'url']);

  useEffect(() => {
    const timer = setInterval(() => {
      _getSuggestConsultants(timer);
    }, 3000);
  }, []);

  async function _getSuggestConsultants(timer) {
    let res = await getSuggestConsultants();
    const suggestConsultants = _.get(res, ['result'], []);
    console.log(suggestConsultants);
    if (suggestConsultants.length > 0) {
      clearInterval(timer);
      navigate('/client/matching', { state: { suggestConsultants } });
    }
  }

  return (
    <IframeComponent title="信息完善" src={url}></IframeComponent>
  )
}
export default ConsultIframe;