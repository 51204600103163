// 用户咨询评价页面
import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import axios from 'axios';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import SplitBorder from '@/components/SplitBorder';
import TitleBar from '@/components/TitleBar';
import { Image, Rate, TextArea, Button, Toast } from 'antd-mobile'
import traningImg from '@/assets/images/traning.png';
import { useLocation, useNavigate } from 'react-router';
import { createEvaluation, patientGetDoctorInfo } from "@/services/api";

function ConsultingEvaluation(params) {
  const navigate = useNavigate();
  const location = useLocation();
  const { consultingInfo } = _.get(location, ['state'], {});
  // 咨询的评价
  const [commentText, setCommentText] = useState('');
  // 咨询评分,默认是九分
  const [rate, setRate] = useState({
    satisfaction: 10,
    reuseRate: 10,
    understandingLevel: 10,
    solveProblem: 10,
  });
  // 咨询评价的种类
  const [rateList, setRateList] = useState([]);
  const [consultInfo, setConsultInfo] = useState({});

  useEffect(async () => {
    const res = (await axios.get('/mockApi/rateQuestion.json')).data;
    setRateList(res);
  }, [])

  // 根据咨询师id获取咨询师信息
  async function _patientGetDoctorInfo(consultantId) {
    const res = await patientGetDoctorInfo(consultantId);
    const doctorInfo = _.get(res, "result", {});
    setConsultInfo(doctorInfo);
  }

  useEffect(() => {
    _patientGetDoctorInfo(consultingInfo.consultantId);
  }, [])

  const _createEvaluation = async () => {
    let params = {
      ...rate,
      consultRecodeId: consultingInfo?.consultRecodeId,
      text: commentText,
    };
    const res = createEvaluation(params);
    if (!res.error) {
      Toast.show({
        icon: 'success',
        content: '提交成功',
      })
      // 提交成功后返回
      navigate(-1);
    }
    else {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    }
  };

  return (
    <>
      <TitleBar
        title='咨询评价'
        isShowBack={true}
        rightContent='我的评价'
        rightContentOnclick={() => { navigate('/client/my-evaluation') }}
      />
      <div className='consulting-evaluation-content'>
        <div className='content-title'>
          <div >
            感谢您使用该平台进行心理咨询
          </div>
          <div>
            请对本次咨询进行评价
          </div>
        </div>
        <div className='head-portrait-content'>
          <div className='head-portrait'>
            <Image
              src={consultInfo.avatarUrl}
              width={64}
              height={64}
              fit='cover'
              style={{ borderRadius: 32 }} />
          </div>
          <div className='consultant-data'>
            <div className='consultant-name'>
              {consultInfo.name}
            </div>
            <div className='consultanting-time'>
              咨询时间：{MomentDiffZone(consultingInfo.createdTime).format('YYYY-MM-DD')}
            </div>
          </div>
        </div>
        <SplitBorder />
      </div>
      {
        rateList && rateList.map((item, index) => {
          return (
            <div className='rate-content' key={index}>
              <div className='rate-content-title'>{item.title}</div>
              <Rate
                defaultValue={10}
                allowClear={true}
                count={10}
                allowHalf={true}
                className='rate-content-body'
                onChange={(val) => {
                  setRate({ ...rate, [item.key]: val })
                }}
              />
              <div className='rate-content-will'>
                <span>{item.unwillingness}</span>
                <span>{item.willingness}</span>
              </div>
            </div>
          )
        })
      }
      <div className='consultant-textarea-content'>
        <TextArea
          maxLength={500}
          rows={4}
          className='consultant-textarea'
          placeholder='写出您的评价'
          onChange={(val) => {
            setCommentText(val);
          }}
        />
        <Button
          className='submit-button'
          onClick={() => {
            _createEvaluation();
          }}
        >
          确认提交
        </Button>
      </div>
    </>
  )
}

export default ConsultingEvaluation;