import React, { useState, useEffect } from 'react';
import './index.less';
import { checkFuncAuth } from '@/utils/utils';
import { useNavigate } from 'react-router-dom';
import { Swiper } from 'antd-mobile';
import _ from 'lodash';
import home_banner from '@/assets/images/home_banner_1.jpg';

// 首页轮播图
function BannerComponent(props) {
  const { funcAuthList, onHealthReportClick = () => {} } = props;
  const navigate = useNavigate();
  const menus = [{ id: 'banner' }];
  const checkedMenus = checkFuncAuth(funcAuthList, menus);

  if (checkedMenus.length === 0) {
    return <div className='page-title'>我的心理成长计划</div>;
  }
  const { imgs = [], hasHealthReport } = checkedMenus[0];
  const banners = [...imgs];
  if (hasHealthReport) {
    banners.unshift(home_banner)
  }
  const items = banners.map((img, index) => {
    return (
      <Swiper.Item key={index} onClick={() => {
        if (index === 0) {
          onHealthReportClick()
        }
      }}>
        <img className='banner-img' alt='' src={img} />
      </Swiper.Item>
    )
  })
  return (
    <Swiper
      autoplay={true}
      loop={true}
    >
      {items}
    </Swiper>
  )
}

export default BannerComponent;
