import React, { useEffect, useState } from "react";
import TitleBar from "@/components/TitleBar";
import StaffInfoComponent from "@/components/UserInfo/StaffInfo";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "antd-mobile";
import { useSelector } from 'react-redux';
import { patientGetSelfInfo } from "@/services/api";

function ConsultantInfo() {
  const location = useLocation();
  const baseInfo = useSelector(state => state.baseInfo['patient']);
  const role = _.get(location, ["state", "role"], "");
  const [patientInfo, setPatientInfo] = useState({});

  useEffect(() => {
    _patientGetSelfInfo(_.get(baseInfo, "authId", ""));
  }, []);

  async function _patientGetSelfInfo(consultantId) {
    let res = await patientGetSelfInfo(consultantId);
    setPatientInfo(_.get(res, "result", {}));
  }

  return (
    <>
      <TitleBar title="基本资料" isShowBack={true} />
      <StaffInfoComponent
        patientInfo={patientInfo}
        role={role}
      ></StaffInfoComponent>
    </>
  );
}

export default ConsultantInfo;
