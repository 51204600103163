import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Tabs, Swiper, Toast, Image } from 'antd-mobile';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import _, { forEach, lowerCase } from 'lodash';

import './index.less';
import TitleBar from "@/components/TitleBar";
import WithTracking from '@/components/WithTracking';
import { save_psychological_test_tab_list } from '@/actions';
import { getStaffQuestionnaireList, createStaffTask } from "@/services/api";

function PsychologicalTest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const staffId = useSelector(state => state.baseInfo['patient']?.roleInfo?.staffId);
  const tabItems = useSelector(state => state.psychologicalTestTabList);

  useEffect(() => {
    const init = async () => {
      const tabItemsTemp = await _getStaffQuestionnaireList();
      dispatch(save_psychological_test_tab_list(tabItemsTemp));
    };
    // // 默认先从redux中取数据，如果没有再去请求
    // if (!tabItems.length) {
    //   init();
    // }
    init();
  }, [])

  // 先用咨询师那边的量表接口模拟
  const _getStaffQuestionnaireList = useCallback(async () => {
    const res = await getStaffQuestionnaireList();
    const tabItemsTemp = _.get(res, ['result'], []);
    return tabItemsTemp;
  }, [])

  // 组装后台接口数据
  function combineWfVariablesData(selectList) {
    let wfVariablesResult = [];
    for (let i = 0; i < selectList.length; i++) {
      let pickedItems = [];
      let wholeItems = [];
      pickedItems.push(selectList[i].id);
      wholeItems.push(selectList[i]);
      // let wfVariablesData = JSON.stringify()
      let wfVariablesData = {
        Forms: {
          pickedItems,
          wholeItems
        }
      }
      wfVariablesResult.push(wfVariablesData)
    }
    const result = wfVariablesResult.reduce((pre, cur) => {
      pre.push(JSON.stringify(cur))
      return pre;
    }, [])
    return result;
  }

  // 获取量表的url进行跳转
  async function getFormUrl(form) {
    let selectForms = [];
    selectForms.push(form);
    const convertedData = combineWfVariablesData(selectForms);
    let params = {
      staffId,
      taskType: 'STAFF_OPTIONAL',
      wfVariablesList: convertedData,
    };
    let res = await createStaffTask(params);
    if (res.errorCode) {
      Toast.show('量表信息生成失败，请重试')
    } else {
      navigate('/client/psychological-test-user-form', { state: { url: res?.result?.firstFormUrl, title: form?.name_zh } })
    }
  }

  function renderStaffFormContent() {

    return (
      tabItems.length > 0
        ? (
          <div className='tabs-content'>
            {
              tabItems.length > 1 && <Tabs
                activeKey={tabItems[activeIndex]['name_zh']}
                onChange={key => {
                  const index = tabItems.findIndex(item => item['name_zh'] === key)
                  setActiveIndex(index)
                  swiperRef.current?.swipeTo(index)
                }}
              >
                {
                  tabItems.map((item, index) => {
                    return (
                      <Tabs.Tab
                        title={item['name_zh']}
                        key={item['name_zh']}
                      >
                      </Tabs.Tab>
                    )
                  })
                }
              </Tabs>
            }
            <Swiper
              className='swiper-content'
              // 水平方向循环
              direction='horizontal'
              // 可以循环
              loop={true}
              // 无指示器
              indicator={() => null}
              // 挂在当前的面板的index
              ref={swiperRef}
              defaultIndex={activeIndex}
              onIndexChange={index => {
                setActiveIndex(index)
              }}
            >
              {
                tabItems.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      {
                        item.picks.map((form, index) => {
                          return (
                            <div
                              key={index}
                              className='swiper-item'
                              onClick={() => {
                                getFormUrl(form);
                              }}
                            >
                              <Image src={form?.picture_url} fit='contain' className='swiper-img-background' />
                              <div className='swiper-text'>{form['name_zh']}</div>
                            </div>
                          )
                        })
                      }
                    </Swiper.Item>
                  )
                })
              }
            </Swiper>
          </div>
        )
        : null
    )
  }

  return (
    <>
      <TitleBar
        title='心理测评'
        isShowBack={true}
        rightContent={'量表报告'}
        rightContentOnclick={() => {
          navigate('/client/form-task-list')
        }}
      />
      {
        renderStaffFormContent()
      }
    </>
  )
}

export default WithTracking(
  PsychologicalTest,
  {
    name: 'visit:selfGrowth/form-test',
    props: {
      eventName: '自助成长：访问测评（更多）'
    }
  }
);