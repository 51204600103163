import React, { useEffect, useState, useContext } from "react";
import TitleBar from "@/components/TitleBar";
import BussinessCard from '@/components/BusinessCard';
import ConsultantInfoComponent from "@/components/UserInfo/ConsultantInfo";
import NavigationHint from '@/components/NavigationHint';
import Comment from '@/components/UserInfo/ConsultantInfo/comment';
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "antd-mobile";
import { useSelector } from 'react-redux';
import { doctorGetSelfInfo } from "@/services/api";
import { getLoginToken } from '@/utils/localStorage';
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';

function ConsultantInfo() {
  const location = useLocation();
  const navigate = useNavigate();
  const [editType, setEditType] = useState('edit');
  const baseInfo = useSelector(state => state.baseInfo['doctor']);
  const role = _.get(location, ["state", "role"], "");
  const type = _.get(location, ["state", "type"], "");
  const applyInfo = _.get(location, ["state", "applyInfo"], {});
  const consultantId = _.get(baseInfo, "authId", "");
  const [doctorInfo, setDoctorInfo] = useState({});
  const lob = getLobFromToken(getLoginToken(role));
  const isNeedShowQrcode = lob=== lobType.CONSULTANT;
  const [modalVisible, setModalViseble] = useState(false);

  useEffect(() => {
    JSON.stringify(applyInfo) !== '{}'
      ? setDoctorInfo(applyInfo) 
      : _doctorGetSelfInfo()
  }, []);

  async function _doctorGetSelfInfo() {
    let res = await doctorGetSelfInfo(consultantId);
    setDoctorInfo(_.get(res, "result", {}));
  }

  function callBackEditType(editType) {
    setEditType(editType)
  }

  return (
    <>
      <TitleBar 
        title='咨询师'
        isShowBack={true} 
        rightContent="下载名片"
        rightContentOnclick={() => {
          setModalViseble(true);
        }}
      />
      <ConsultantInfoComponent
        doctorInfo={doctorInfo}
        role={role}
        type={type}
        isNeedShowQrcode={isNeedShowQrcode}
        editCB={() => _doctorGetSelfInfo(consultantId)}
        callBackEditType={callBackEditType}
        editType={editType}
      />
      <BussinessCard
        modalVisible={modalVisible}
        setModalViseble={setModalViseble}
        doctorInfo={doctorInfo}
        closeAction={() => {
          setModalViseble(false)
        }}
      />
    </>
  );
}

export default ConsultantInfo;
