// 被连接的练习的组件
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Popup } from 'antd-mobile';
import { useSelector } from 'react-redux';

import './index.less';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import useFormInfo from '@/utils/hooks/useFormInfo';
import QuestionAndAnswerBoard from '@/components/ExerciseBoard';
import Chat from "@/components/Chat";
import { getUrls } from '@/utils/getUrls';
import ChatGptStream from './ChatgptStream';

const ExerciseCombined = (props) => {
  const { exerciseInstanceId, formContent, chatList, sessionId, count, onloadMore, authId, AIID } = props;
  const { handleAddEventDotClick } = props;
  const { BASE_URL } = getUrls();
  const {
    currentLesson: {
      isProcessStart,
      isProcessEnd,
      onProcessStart,
      messagesShown,
    },
    currentQuestion: {
      onSubmitAnswer,
      currentQuestionId,
      questionContent,
      questionOptions,
      questionType,
      getNLPResponse,
      prompt,
    },
  } = useFormInfo({ lessonInstanceId: exerciseInstanceId, formContent: formContent });

  const [popUpVisible, setPopUpVisible] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isDisplayLoading, setIsDisplayLoading] = useState(true);
  const [handledMessagesShownList, setHandledMessagesShownList] = useState([]);
  const baseInfo = useSelector(state => state.baseInfo['patient']);
  const [chatgptParamsObj, setChatgptParamsObj] = useState({
    chatgptParams: null,
    requestUrl: `${BASE_URL}ai/chat`,
  });
  const nlpSavedAnswerRef = useRef();


  // 作业打点
  useEffect(() => {
    handleAddEventDotClick({
      name: 'test:recommend-exercise',
      props: {
        eventName: '开始推荐的作业',
        exerciseInstanceId: exerciseInstanceId,
      }
    })
  }, []);

  useEffect(() => {
    const handledMessagesShown = messagesShown && handleMessageShown(messagesShown);
    const messageShownListTemp = chatList && chatList.concat(handledMessagesShown);
    setHandledMessagesShownList(messageShownListTemp);
    localStorage.setItem('recommendChat', JSON.stringify({
      chatList,
      sessionId,
      count,
      commendOrExercise: 'exercise',
      exerciseInstanceId,
      formContent,
    }));
  }, [messagesShown, sessionId, count, chatList, exerciseInstanceId, formContent]);

  // 对需要显示的messageShown
  /**
   * 1. 一般viewType: instruction,说明是是共情的内容，不是问题
   * 2. 一般type: string、array,说明是问题， type: answer 说明是回答
   * 3. if viewType: instruction && type: answer, 无意义的回答不需要显示出来
   * 4. if type: boolean && viewType: instructions, 共情的内容，自动跳过
   */
  function handleMessageShown(messageList) {
    const handledMessageList = messageList.filter(message => !(message.viewType === 'instructions' && message.type === 'answer'));
    return handledMessageList;
  }

  // const onEndExercise = async () => {
  //   let exerciseCompletedRes = await exerciseCompleted({ exerciseInstanceId: exerciseInstanceId });
  //   setPopUpVisible(false);
  //   if (exerciseCompletedRes.errorCode) {
  //     Toast.show({
  //       content: exerciseCompletedRes.error,
  //       icon: 'fail',
  //       duration: 2000,
  //     });
  //   }
  //   else {
  //     Toast.show({
  //       content: '提交成功',
  //       icon: 'success',
  //       duration: 2000,
  //       afterClose: () => {
  //         localStorage.removeItem('recommendChat');
  //       }
  //     });
  //   }
  // };

  const handleSetChatList = (data) => {
    setHandledMessagesShownList(data);
    setIsDisplayLoading(true);
    onSubmitAnswer({ key: currentQuestionId, value: nlpSavedAnswerRef.current, nlp_response: data[data.length - 1].title_zh });
  };

  const handleAnswerClick = (answer) => {
    setPopUpVisible(false);
    let params = {
      key: currentQuestionId,
      value: answer,
    };
    if (answer === 'confirm') {
      setIsShowLoading(false);
      // onEndExercise();
      // 完成作业的打点
      handleAddEventDotClick({
        name: 'leave:recommend-exercise',
        props: {
          eventName: '完成推荐的作业',
          exerciseInstanceId: exerciseInstanceId,
        }
      })
      localStorage.removeItem('recommendChat');
      localStorage.removeItem(`shufuFormChat_${exerciseInstanceId}`);
      return;
    }
    else {
      setIsShowLoading(true);
      // 如果是NLP的问题，需要先获取NLP的回答
      if (getNLPResponse) {
        // 插入答案
        handledMessagesShownList && handledMessagesShownList.push({
          questionId: currentQuestionId,
          sectionId: 'Q',
          _id: `${currentQuestionId}_answer`,
          type: 'answer',
          text: answer,
          fromUserId: authId
        });
        nlpSavedAnswerRef.current = answer;
        // 调用NLP接口， 获取NLP的回答
        let nlpParams = {
          clientId: baseInfo.authId,
          messages: [
            `心理咨询师：${questionContent}`,
            `来访者：${answer}`,
          ],
          systemMessage: prompt,
        };
        setChatgptParamsObj({
          ...chatgptParamsObj,
          chatgptParams: {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(nlpParams),
          }
        })
        setIsDisplayLoading(false);
      }
      else {
        onSubmitAnswer(params);
      }
    }
  };

  useEffect(() => {
    if (isProcessStart) {
      onProcessStart();
      setPopUpVisible(true);
    }
    if (isProcessEnd && localStorage.getItem('recommendChat')) {
      setIsShowLoading(false);
      setPopUpVisible(true);
    }
    else {
      // scrollMessageListToEnd();
    }

  }, [isProcessStart, isProcessEnd, onProcessStart]);

  // 共情内容自动往下走
  // 验证一下当前questionId和messagesShown的最后一条消息的questionId是否一致
  useEffect(() => {
    if (handledMessagesShownList && handledMessagesShownList.length > 0) {
      const lastMessage = handledMessagesShownList[handledMessagesShownList.length - 1];
      if (lastMessage.viewType === 'instructions' && lastMessage.type === 'boolean' && lastMessage.questionId === currentQuestionId) {
        // 在下一题到来之前增加延迟和loading
        setIsShowLoading(true);
        setTimeout(() => {
          onSubmitAnswer({ key: currentQuestionId, value: true });
        }, 3000);
      }
      if (lastMessage.type === 'string' || lastMessage.type === 'array') {
        setIsShowLoading(false);
        setPopUpVisible(true);
      }
    }
  }, [handledMessagesShownList, currentQuestionId]);

  // 如果消息超出了屏幕，自动滚动到最底部
  const scrollMessageListToEnd = useCallback(() => {
    const messagesShowContent = document.querySelector('.messages-show-content');
    // 给显示答案的部分动态增加高度
    setTimeout(() => {
      const exerciseBoard = document.querySelector('.exercise-board');
      const popUpBodyContent = document.querySelector('.adm-popup-body');
      if (exerciseBoard && popUpBodyContent && popUpVisible === true) {
        exerciseBoard.style.height = popUpBodyContent.clientHeight + 'px';
      }
      if (exerciseBoard && popUpBodyContent && popUpVisible === false) {
        exerciseBoard.style.height = 0 + 'px';
      }
      if (messagesShowContent) {
        messagesShowContent.scrollTop = messagesShowContent.scrollHeight - messagesShowContent.clientHeight;
      }
    }, 100);
  }, [popUpVisible])

  useEffect(() => {
    scrollMessageListToEnd();
  }, [popUpVisible, isShowLoading, messagesShown, scrollMessageListToEnd]);

  return (
    <>
      <Chat
        messageList={handledMessagesShownList}
        isShowLoading={isShowLoading}
        isShowResponseLoading={false}
        isDisplayLoading={isDisplayLoading}
        onloadMore={onloadMore}
        authId={baseInfo.authId}
      />
      {
        chatgptParamsObj.chatgptParams &&
        <ChatGptStream
          chatgptParamsObj={chatgptParamsObj}
          chatList={handledMessagesShownList}
          setChatList={handleSetChatList}
          scrollMessageListToEnd={scrollMessageListToEnd}
          fromUserId={AIID}
        />
      }
      <div
        className="exercise-board"
        style={{
          height: popUpVisible ? '40vh' : '0vh',
        }}
      >
        <Popup
          visible={popUpVisible}
          onMaskClick={() => {
            setPopUpVisible(false);
          }}
          bodyStyle={{
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            maxHeight: '60vh',
            overflow: 'scroll',
          }}
          onClose={() => {
            setPopUpVisible(false);
          }}
          mask={false}
        >
          <QuestionAndAnswerBoard
            disable={false}
            type={questionType}
            content={questionOptions}
            handleClick={handleAnswerClick}
            // 处理一下键盘弹起遮挡页面的问题
            handleFocus={() => {
              scrollMessageListToEnd();
            }}
          />
        </Popup>
      </div>
    </>
  );
}

export default WithTrackingForMedia(ExerciseCombined);