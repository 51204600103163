import React, { useState } from 'react';
import './index.less';
import ConsultantInfoComponent from "@/components/UserInfo/ConsultantInfo";
import { TextArea, Modal, Toast } from 'antd-mobile';
import TitleBar from '@/components/TitleBar';
import { refuseInfoApply, agreeInfoApply } from '@/services/api';
import {useLocation, useNavigate} from 'react-router-dom';
import _ from 'lodash';

function AuditPage (props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [commentVal, setCommentVal] = useState();
  const applyInfo = _.get(location, ['state', 'applyInfo'], {});
  console.log('applyInfo', applyInfo);
  const { adminId, applyId, applyStatus } = applyInfo

  async function _refuseInfoApply() {
    let params = {
      adminId,
      applyId,
      refuseReason: commentVal,
    }
    let res = await refuseInfoApply(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    Toast.show({
      content: '审核已拒绝！'
    })
    navigate(-1);
  };

  async function _agreeInfoApply() {
    let params = {
      adminId,
      applyId
    };
    let res = await agreeInfoApply(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    Toast.show({
      content: '审核已通过！'
    })
    navigate(-1);
  }

  return (
    <div className='audit-content'>
    <TitleBar title="介绍审核" isShowBack={true} />
      {
        applyStatus === 'NOT_APPROVED'
          ? (
            <div className='not-approved-hint'>
              已拒绝
            </div>
          )
          : null
      }
      {
        applyStatus === 'PASSED'
        ? (
          <div className='passed-hint'>
            已通过
          </div>
        )
        : null
      }
      <ConsultantInfoComponent 
        doctorInfo={applyInfo?.applyInfo}
        role='doctor'
      />
      {
        applyStatus === "APPROVING" 
          ? (
              <>
              <div className='comment-content'>
                <TextArea 
                  className='textarea' 
                  placeholder='添加评价'
                  onChange={(val) => {
                    setCommentVal(val);
                  }}
                />
              </div>
              <div className='button-content'>
                <div 
                  className='button-refuse'
                  onClick={() => {
                    Modal.show({
                      content: '您确定要拒绝该咨询师的个人介绍修改申请吗？',
                      closeOnAction: true,
                      actions: [
                        {
                          key: 'confirm',
                          text: '确认',
                          onClick: () => {
                            _refuseInfoApply();
                          },  
                        },
                        {
                          key: 'cancel',
                          text: '取消',
                        }
                      ]
                    })
                  }}
                >
                  拒绝
                </div>
                <div 
                  className='button-pass'
                  onClick={() => {
                    Modal.show({
                      content: '您确定审核通过该咨询师的个人介绍修改申请吗？',
                      closeOnAction: true,
                      actions: [
                        {
                          key: 'confirm',
                          text: '确认',
                          onClick: () => {
                            _agreeInfoApply();
                          },
                        },
                        {
                          key: 'cancel',
                          text: '取消',
                        }
                      ]
                    })
                  }}
                >
                  通过
                </div>
              </div>
              </>
          )
          : null
      }
    </div>
  )
}

export default AuditPage;