// 管理员从他的角度去看咨询师的预约信息
import React, { useCallback, useState, useEffect } from 'react';
import './index.less';
import _ from 'lodash';
import TitleBar from '@/components/TitleBar';
import PureCalendarComponent from '@/components/PureCalendar';
import { getConsultantScheduling } from '@/services/api';
import { useLocation } from 'react-router-dom';

function ArrangeCalendar(props) {
  const location = useLocation();
  const consultantInfo = _.get(location, ['state', 'consultantInfo'], {});
  const [consultantSchedule, setConsultantSchedule] = useState([]);

  const _getConsultantScheduling = useCallback(async () => {
    let params = {
      consultantId: consultantInfo?.consultantId,
      fromTime: consultantInfo?.fromTime,
      toTime: consultantInfo?.toTime,
    }
    let res = await getConsultantScheduling(params);
    return res?.result
  }, [])

  useEffect(async () => {
    let schedule = await _getConsultantScheduling();
    setConsultantSchedule(schedule);
  }, [_getConsultantScheduling])

  return (
    <>
      <TitleBar title='咨询师排班日历' isShowBack={true} />
      {
        consultantSchedule.length > 0
          ? (
            <PureCalendarComponent
              role='doctor'
              consultantSchedule={consultantSchedule}
              consultantId={consultantInfo?.consultantId}
            />
          )
          : null
      }

    </>
  )
}

export default ArrangeCalendar;