
import { Toast } from 'antd-mobile';
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/**
 * 初始化订阅状态
 * @param {String} userId 
 */
export function initialization(aliWebrtc, userId) {
    if (aliWebrtc) {
        aliWebrtc.configRemoteAudio(userId, false);
        aliWebrtc.configRemoteCameraTrack(userId, false, false);
        aliWebrtc.configRemoteScreenTrack(userId, false);
    }
}

/**
 * 分析远端用户信息
 * @param {String} userId 
 */
export function getSubscribeInfo(userId) {
    var userInfo = aliWebrtc.getUserInfo(userId);
    var subscribeInfo = [], subscribeInfoArr = [], isSubAudio = false, isSubLarge = false, isSubSmall = false, isSubCamera = false, isSubScreen = false, isSubVideo = false;
    if (userInfo) {
        userInfo.streamConfigs.forEach(v => {
        if (v.subscribed) {
            subscribeInfo.push(v.label);
            subscribeInfoArr.push(v);
            v.type == "audio" ? isSubAudio = true : "";
            v.type == "video" ? isSubVideo = true : "";
            v.label == "sophon_video_camera_large" ? isSubLarge = true : "";
            v.label == "sophon_video_camera_small" ? isSubSmall = true : "";
            v.label == "sophon_video_screen_share" ? isSubScreen = true : "";
            if (isSubLarge || isSubSmall) {
                isSubCamera = true;
            }
        }
        });
    }
    return { subscribeInfo: subscribeInfo, subscribeInfoArr: subscribeInfoArr, isSubLarge: isSubLarge, isSubSmall: isSubSmall, isSubCamera: isSubCamera, isSubAudio: isSubAudio, isSubScreen: isSubScreen, isSubVideo: isSubVideo };
}

/**
 * 取消订阅设置
 * @param {String} userId 
 * @param {String} type 
 * @param {String} label 
 */
export  function setConfigRemote(userId, type, label){
    return new Promise((resolve, reject) => {
        if (type == "audio") {
            aliWebrtc.configRemoteAudio(userId, false);
        } else {
            if (label === "sophon_video_camera_large") {
                aliWebrtc.configRemoteCameraTrack(userId, false, false);
                console.warn("取消相机流");
            } else if (label === "sophon_video_screen_share") {
                console.warn("取消共享流");
                aliWebrtc.configRemoteScreenTrack(userId, false);
            }
        }
    aliWebrtc.subscribe(userId).then(re => {
        resolve();
        }).catch(err => { console.error("重新订阅失败", err); alert(err.message); })
    });
}

/**
 * 显示提示信息，并打印
 * @param {String} text 要显示的信息
 * @param {String} type 信息类型(默认成功) success, warning error info warn: alias of warning
 * @param {Number} duration 持续时间
 */
export function showAlert(text,type,duration) {
    // if(!text) return;
    console.log('type:', type + ':' + text);
    if (text.indexOf('not allowed') > -1) {
        text = '录音权限获取失败，请手动重新授权！'
        return Toast.show({
            content: text, 
            duration
        })
    }
    return null;
}

/**
 * 根据属性值获取在数组中的index
 */
Array.prototype.getIndexByProprety = function (val, proprety) {
    var arr = this;
    var index = -1;
    arr.forEach((v, i, a) => {
        if (v[proprety] == val) {
            index = i;
        }
    });
    return index;
}

/**
 * 根据属性值获取数组中的某项
 */
Array.prototype.getObjByProprety = function (val, proprety) {
    var arr = this;
    var obj = {};
    arr.forEach((v, i, a) => {
        if (v[proprety] === val) {
            obj = v;
        }
    });
    return obj;
}