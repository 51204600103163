
const REGISTER_SOCKET_EVENT = 'REGISTER_SOCKET_EVENT'; // 空事件，用于middleware中注册socket event
const CHAT_TO_ONE = 'chatToOne'; // 向服务端发送消息
const FILE_UPLOAD = 'fileUpload'; // 文件上传
const NOTIFICATION = 'notification'; // 文件上传
const HANDLE_NOTIFICATION = 'notofication';
const GET_MESSAGE = 'GET_MESSAGE'; // 消息列表获取
const STAFF_LIST = 'STAFF_LIST'; // 首页对话列表的用户
const MESSAGE_UPDATE = 'MESSAGE_UPDATE'; // 消息列表更新
const MESSAGE_ADD = 'MESSAGE_ADD' //获取消息列表的历史消息
const SAVE_DOCTOR_INFO = 'SAVE_DOCTOR_INFO'; // 保存咨询师信息
const SAVE_PATIENT_INFO = 'SAVE_PATIENT_INFO'; // 保存来访者信息
const SAVE_ADMIN_INFO = 'SAVE_ADMIN_INFO'; // 保存舒小辅管理员的信息
const SAVE_CURRENT_CHAT_AUTH_ID = 'SAVE_CURRENT_CHAT_AUTH_ID';
const MESSAGE_UPDATE_CALL_FLAG = 'MESSAGE_UPDATE_CALL_FLAG'; //消息中更新音视频通话记录的消息
const CALL_TO_ONE = 'callToOne'; // 向服务端发送语音通话的action
const LOGOUT_CHAT_CLOSE = 'LOGOUT_CHAT_CLOSE'; // 向服务端发送语音通话的action
const ROBOT_CHAT = 'ROBOT_CHAT'; // 机器人发送的消息类型
const SAVE_ADMIN_QUERY_STAFF_LIST = 'SAVE_ADMIN_QUERY_STAFF_LIST'; // 机器人发送的消息类型
const MESSAGE_CLEAR = 'MESSAGE_CLEAR'; // 清空消息列表
const MESSAGE_WITHDRAW = 'MESSAGE_WITHDRAW'; // 撤回消息
const SAVE_RESOURCE_LIST= 'SAVE_RESOURCE_LIST'; // 存资源列表
const SAVE_PSYCHOLOGICAL_TEST_TAB_LIST = 'SAVE_PSYCHOLOGICAL_TEST_TAB_LIST'; // 存储心理测试tab列表
const SHOW_GLOBAL_DIALOG = 'SHOW_GLOBAL_DIALOG'; // 心理健康报告弹框（全局弹框可复用）
const HIDE_GLOBAL_DIALOG = 'HIDE_GLOBAL_DIALOG'; // 全局弹框隐藏

export {
  REGISTER_SOCKET_EVENT,
  CHAT_TO_ONE,
  FILE_UPLOAD,
  NOTIFICATION,
  MESSAGE_UPDATE,
  MESSAGE_ADD,
  GET_MESSAGE,
  STAFF_LIST,
  SAVE_DOCTOR_INFO,
  SAVE_ADMIN_INFO,
  HANDLE_NOTIFICATION,
  SAVE_PATIENT_INFO,
  SAVE_CURRENT_CHAT_AUTH_ID,
  CALL_TO_ONE,
  MESSAGE_UPDATE_CALL_FLAG,
  LOGOUT_CHAT_CLOSE,
  ROBOT_CHAT,
  SAVE_ADMIN_QUERY_STAFF_LIST,
  MESSAGE_CLEAR,
  MESSAGE_WITHDRAW,
  SAVE_RESOURCE_LIST,
  SAVE_PSYCHOLOGICAL_TEST_TAB_LIST,
  SHOW_GLOBAL_DIALOG,
  HIDE_GLOBAL_DIALOG
}
