import React, { useState, useEffect, useRef, useCallback } from 'react';
import _, { last } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, ImageUploader, Button, Toast, PullToRefresh, TextArea } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';

import './index.less';
import '@/components/DialogueMessage/index.less';
import TitleBar from '@/components/TitleBar';
import {
  doctorUploadChatFile,
  doctorGetChatMsg,
  createAppointRoomSuccess,
  getAllConsultantList,
  reBindBySiuvo,
  clearUnreadMsg,
  consultantConsultCompleted,
  consultantAnonymousCall,
  consultantTrigger,
  consultantChatWithdraw,
  doctorGetPatientInfo,
  closeWorkOrder,
} from '@/services/api';
import ChatGptStream from './chatgptStream';
import {
  AudioOutline,
  AddOutline,
  CameraOutline,
  PictureOutline,
  PhonebookOutline,
  VideoOutline,
  UnorderedListOutline,
  CloseCircleFill
} from 'antd-mobile-icons'
import { RenderMessageContent, uploadDialogueImage } from '@/components/DialogueMessage';
import {
  emit_message_to_server,
  message_get, message_update,
  message_add,
  message_withdraw,
  save_current_chat_auth_id,
  emit_call_flag_to_server
} from '@/actions';
import { CHAT_TO_ONE, FILE_UPLOAD, CALL_TO_ONE } from '@/actions/actionType';
import CONFIG from 'GlobalConfigFile';
import CustomConfirmDialog from '@/components/CustomConfirmDialog';
import Icon from '@/components/Icon';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { emitterEvent, lobType } from '@/Constant';
import EventEmitter from '@/utils/eventEmitter';
import makingCall from '@/utils/makingCall';
import commonAnswerList from './commonAnswer';
import isJsonAndReturnObj from '@/utils/isJsonAndReturnObj';
import { isMoreThan3Mins, getRoleFromLocation, scrollMessageListToEnd } from '@/utils/commonUtils';
import getUUID from '@/utils/getUUID';
import { getLoginToken } from '@/utils/localStorage.js';
import { getLobFromToken, getPageQuery } from '@/utils/utils';
import changeName from '@/utils/changeName';
import { useChatAuthId } from './useChatAuthId';

function Dialogue() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const chatMsg = useSelector(state => state.chatMsg);
  const baseInfo = useSelector(state => state.baseInfo.doctor);
  const admin = useSelector(state => state.baseInfo?.admin);
  const { isAdmin } = getPageQuery();
  const { chatAuthId } = useChatAuthId();
  const pageNo = useRef();
  const [pageSize, setPageSize] = useState(20);
  const [scrollEnd, setScrollEnd] = useState(true);
  const [functionsVisible, setFunctionsVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [chatgptAnswerVisible, setChatgptAnswerVisible] = useState(false);
  const [showCommonAnswer, setShowCommonAnswer] = useState(false);
  // 确认是否需要结束咨询的弹窗
  const [consultModalVisible, setConsultModalVisible] = useState(false);
  const [chatgptParams, setChatgptParams] = useState({});
  // 咨询记录id
  const [consultRecodeId, setConsultRecodeId] = useState('');
  const initData = [
    {
      icon: <ImageUploader beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/photo.svg').default} />
      </ImageUploader>, title: '照片'
    },
    {
      icon: <ImageUploader capture='camera' beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/camera.svg').default} />
      </ImageUploader>, title: '拍摄'
    },
    { icon: <img alt="" src={require('@/assets/icon/dialogue/phone_call.svg').default} />, title: '语音咨询', func: () => { videoConsult('audio'); onCallActionSend('AUDIO', 'CALL') } },
    { icon: <img alt="" src={require('@/assets/icon/dialogue/video_call.svg').default} />, title: '视频咨询', func: () => { videoConsult('video'); onCallActionSend('VIDEO', 'CALL') } },
    { icon: <img alt="" src={require('@/assets/icon/dialogue/anonymous_call.svg').default} />, title: '虚拟拨号', func: () => { anonymousCall() } },
    { icon: <img alt="" src={require('@/assets/icon/dialogue/evaluate.svg').default} />, title: '测评', func: () => { navigate('/therapist/form-library', { state: { chatAuthId } }) } },
    { icon: <img alt="" src={require('@/assets/icon/dialogue/close_work_order.svg').default} />, title: '关闭工单', func: () => { _closeWorkOrder() } }
  ];
  const [functionBlocks, setFunctionBlocks] = useState(initData);
  const [consultants, setConsultants] = useState([]); // 所有咨询师列表
  const [inputId, setInputId] = useState('');
  const [matchedConsultant, setMatchedConsultant] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);
  const [anonymousBindDialog, setAnonymousBindDialog] = useState(false); // 虚拟号码绑定弹框
  const telephone = baseInfo?.roleInfo.telephone;
  const [phoneNoA, setPhoneNoA] = useState(telephone); // 虚拟号码绑定的手机号
  // 记录当前发送失败的消息的次数
  const [sendFailCount, setSendFailCount] = useState(0);
  // 是否显示连续消息时候的loading
  const [isShowLoading, setIsShowLoading] = useState(false);
  // 被引用的文字
  const [quoteText, setQuoteText] = useState('');
  // 被撤回的文字
  const [withdrawText, setWithdrawText] = useState('');
  const role = changeName(getRoleFromLocation());
  const lob = getLobFromToken(getLoginToken(role));
  // 来访者基本信息
  const [staffInfo, setStaffInfo] = useState({});

  // 咨询师打开聊天窗口，向服务端发送消息通知
  const _consultantTrigger = useCallback(async () => {
    const params = {
      chatAuthId,
      triggerType: 'openChat',
      time: MomentDiffZone().valueOf()
    }
    await consultantTrigger(params);
  }, [])

  useEffect(() => {
    // 如没有chatAuthId，程序缺少依赖不执行
    if(!chatAuthId) return;
    if (isAdmin) {
      setStaffInfo(admin[0])
    } else {
      // 根据chatAUthId获取患者详情
      _doctorGetPatientInfo(chatAuthId);
    }
    EventEmitter.on('SHOW_DOT_LOADING', (val) => {
      setIsShowLoading(val);
    })
  }, [chatAuthId])

  useEffect(async () => {
    if(!chatAuthId) return;
    await _doctorGetChatMsg();
    await _consultantTrigger();
    dispatch(save_current_chat_auth_id(chatAuthId));
    // hard code for siuvo manager
    if (baseInfo.authId === CONFIG.SiuvoManagerId) {
      setFunctionBlocks([...functionBlocks, { icon: <UnorderedListOutline />, title: '匹配咨询师', func: matchConsultant }])
    }
    return () => {
      dispatch(message_get([]));
      clearMsg();
    }
  }, [chatAuthId]);

  // 关闭工单
  async function _closeWorkOrder() {
    const params = {
      staffAuthId: chatAuthId,
      orderType: baseInfo.authId === CONFIG.SiuvoManagerId ? '3' : '1'
    }
    const res = await closeWorkOrder(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    Toast.show({
      content: '工单关闭成功！'
    })
  }

  async function _doctorGetPatientInfo(authId) {
    let params = {
      authId,
      groupReportBy: lob === lobType.SFDA ? 'groupByInstance' : 'groupByForm',
    }
    let res = await doctorGetPatientInfo(params);
    // setPatientInfo(_.get(res, "result", {}));
    if (res.errorCode) {
      Toast.show({
        content: '来访者信息获取失败'
      })
      // 没有查询到来访者信息，无法操作返回列表
      navigate(-1);
      return;
    }
    setStaffInfo(_.get(res, "result", {}))
  }

  // 构建传给chatgpt的参数
  function structureChatgptParams() {
    let chatgptParams = {
      staffId: chatAuthId,
      consultId: baseInfo.authId,
      messages: []
    };
    // 不同消息段落的索引
    let messageParagraphIndexList = [];
    // 最近的来自来访者的消息
    let lastClientMessageList = [];
    // 最近的来自咨询师的消息
    let lastConsultantMessageList = [];
    // array.reverse() 会改变原数组，所以这里需要拷贝一份
    let chatMsgReverse = [...chatMsg].reverse();
    // console.log('chatMsgReverse', chatMsgReverse);
    for (let index = 0; index < chatMsgReverse.length; index++) {
      let item = chatMsgReverse[index];
      // 当前消息和下一条消息不对等
      if (item.toUserId !== chatMsgReverse[index + 1]?.toUserId) {
        // 咨询师
        if (item.toUserId === chatAuthId) {
          messageParagraphIndexList.push({
            role: 'consultant',
            index
          })
        }
        // 来访者说的话
        else {
          messageParagraphIndexList.push({
            role: 'client',
            index
          })
        }
      }
    }
    // console.log('messageParagraphIndexList', messageParagraphIndexList);
    let messageParagraphClientIndex = messageParagraphIndexList.findIndex((item) => item.role === 'client');
    // console.log('messageParagraphClientIndex:', messageParagraphClientIndex);
    let messageParagraphConsultantIndex = messageParagraphIndexList.findIndex((item) => item.role === 'consultant');
    // console.log('messageParagraphConsultantIndex:', messageParagraphConsultantIndex);
    if (messageParagraphClientIndex >= 0 && messageParagraphConsultantIndex >= 0) {
      // 最后一段消息是来访者发送的
      if (messageParagraphClientIndex < messageParagraphConsultantIndex) {
        lastClientMessageList = chatMsgReverse.slice(0, messageParagraphIndexList[messageParagraphClientIndex].index + 1);
        lastConsultantMessageList = chatMsgReverse.slice(messageParagraphIndexList[messageParagraphClientIndex].index + 1, messageParagraphIndexList[messageParagraphConsultantIndex]?.index + 1 || chatMsgReverse.length);
      }
      // 最后一段消息是咨询师发的
      else {
        lastConsultantMessageList = chatMsgReverse.slice(messageParagraphIndexList[messageParagraphClientIndex].index + 1, messageParagraphIndexList[messageParagraphClientIndex + 1]?.index + 1 || chatMsgReverse.length);
        lastClientMessageList = chatMsgReverse.slice(messageParagraphIndexList[messageParagraphConsultantIndex].index + 1, messageParagraphIndexList[messageParagraphClientIndex]?.index + 1 || chatMsgReverse.length);
      }
    }
    // 只有来访者说话
    else if (messageParagraphClientIndex >= 0 && messageParagraphConsultantIndex < 0) {
      lastClientMessageList = chatMsgReverse.slice(0, messageParagraphIndexList[messageParagraphClientIndex].index + 1);
    }
    // 只有咨询师说话
    else if (messageParagraphClientIndex < 0 && messageParagraphConsultantIndex >= 0) {
      lastConsultantMessageList = chatMsgReverse.slice(0, messageParagraphIndexList[messageParagraphConsultantIndex].index + 1);
    }
    let chatgptParamsMessage = lastConsultantMessageList.concat(lastClientMessageList).map((item) => {
      let messageObj = isJsonAndReturnObj(item.message);
      return `${item.toUserId === chatAuthId ? '咨询师' : '来访者'}: ${messageObj.answer}`
    });
    chatgptParams.messages = chatgptParamsMessage;
    return chatgptParams;
  }


  // 内容变动，自动滚动到底部
  useEffect(() => {
    // let params = {
    //   staffId: chatAuthId,
    //   messages: [
    //     "来访者: what is the weather like today in shanghai"
    //   ]
    // };
    // // 拿到最后一名来访者的消息
    // for (let index = chatMsg.length - 1; index > 0; index--) {
    //   const element = chatMsg[index];
    //   if (element.toUserId !== chatAuthId && typeof element.message === 'string') {
    //     // params.prompt = element.message;
    //     params.messages[0] = `来访者: ${element.message}`;
    //     break;
    //   }
    // };
    scrollEnd && scrollMessageListToEnd();
    setScrollEnd(true);
    let lastMsg = _.last(chatMsg);
    if (lastMsg?.onSendSuccess == false) {
      setSendFailCount(sendFailCount + 1);
    };
  }, [chatMsg])

  // 监听事件是否通话挂断后需要进行弹窗的通知
  useEffect(() => {
    const consultantEndConfirm = (val) => {
      setConsultRecodeId(val?.consultRecodeId);
      if (val?.type === "CONSULT_END_CONFIRM") {
        setConsultModalVisible(true)
      }
      else {
        setConsultModalVisible(false)
      }
    }
    EventEmitter.on(emitterEvent.CONSULT_END_CONFIRM, consultantEndConfirm);
    return () => {
      EventEmitter.removeListener(emitterEvent.CONSULT_END_CONFIRM, consultantEndConfirm);
    }
  }, [])

  useEffect(() => {
    if (inputId) {
      const consultant = searchConsultantInfo(inputId);
      setMatchedConsultant(consultant);
    }
  }, [inputId])

  // 根据ID查询匹配的咨询师信息
  function searchConsultantInfo(id) {
    const result = consultants.filter(item => item.authId == id)
    return result[0] || {}
  }

  // 清空未读消息
  async function clearMsg() {
    const res = await clearUnreadMsg({ chatAuthId });
    if (res.errorCode) {
      console.log('clearMsg:', res.error)
    }
  }

  async function _doctorGetChatMsg() {
    pageNo.current = 1;
    const res = await doctorGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId: chatAuthId });
    dispatch(message_get(_.get(res, ['result', 'data'], []).reverse()));
  }

  // 下拉更新数据
  async function _doctorAddChatMsg() {
    const res = await doctorGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId: chatAuthId });
    dispatch(message_add(_.get(res, ['result', 'data'], []).reverse()));
  }

  // 把setConsultModalVisible包一下传给子组件
  function setModalVisible(val) {
    setConsultModalVisible(val);
  }

  // 获取所有的咨询师列表，用于舒小辅
  async function getAllConsulttant() {
    const res = await getAllConsultantList();
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      setConsultants(res.result)
    }
  }

  // 匹配弹框内容区
  function renderMatchDialogContent() {
    return (
      <>
        <div>匹配咨询师</div>
        <div>
          <span>UID</span>
          <Input placeholder='请输入咨询师ID' clearable value={inputId} onChange={val => {
            setInputId(val)
          }} />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <span>姓名</span>
          <Input placeholder='' disabled value={matchedConsultant.name || ''} />
        </div>
      </>
    )
  }
  // 虚拟号码绑定弹框，咨询师可修改手机号码--非登录
  function renderAnonymousBindContent() {
    return (
      <>
        <div className='anonymous-hint'>请确认您绑定的手机号码（可修改）</div>
        <div>
          <Input placeholder='请输入您想绑定的手机号码' clearable value={phoneNoA} onChange={val => {
            setPhoneNoA(val)
          }} />
        </div>
      </>
    )
  }

  // 舒小辅匹配咨询师
  function matchConsultant(consultants) {
    consultants.length === 0 && getAllConsulttant();
    setDialogVisible(true)
  }

  // 创建房间， 发起音视频通话
  async function videoConsult(type) {
    const params = {
      roomUrl: `/client/patient-video-consult?channelId=${baseInfo.authId}&consultType=${type}&chatAuthId=${baseInfo.authId}`,
      toAuthId: chatAuthId
    }
    const res = await createAppointRoomSuccess(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      // const messageObj = {
      //   chatType: CHAT_TO_ONE,
      //   message: '发起了音视频通话',
      //   toUserId: chatAuthId
      // }
      // dispatch(message_update(messageObj))
      // 测试使用452493911793860608，
      if (baseInfo.authId === '452493911793860608') {
        navigate('/therapist/doctor-video-consult-chatgpt', { state: { channelId: baseInfo.authId, consultType: type, chatAuthId, staffInfo } })
      } else {
        navigate('/therapist/doctor-video-consult', { state: { channelId: baseInfo.authId, consultType: type, chatAuthId, staffInfo } })
      }
    }
  }

  async function uploadDialogueFile(file) {
    uploadDialogueImage(file, chatAuthId, async params => {
      params.append('chatMsgId', getUUID());
      const res = await doctorUploadChatFile('PICTURE', params);
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        })
      } else {
        const url = res.result.url || '';
        onSendFileToMessageList(url);
      }
    });
  }
  // 音视频通话操作发送至服务端
  function onCallActionSend(callType, callStatus) {
    const messageObj = {
      chatType: CALL_TO_ONE,
      toUserId: chatAuthId,
      sendTime: MomentDiffZone().valueOf(),
      message: JSON.stringify({
        callType: callType,
        callStatus: callStatus,
      }),
      fromUserId: baseInfo.authId,
      chatMsgId: getUUID(),
    }
    dispatch(emit_call_flag_to_server(messageObj))
    dispatch(message_update(messageObj))
  }

  // 文字消息发送
  function onMessageSend(msg) {
    // 拼接message
    let message = msg;
    if (quoteText) {
      message = JSON.stringify({ answer: msg, quote: quoteText });
    } else {
      message = JSON.stringify({ answer: msg });
    };
    const messageObj = {
      chatType: CHAT_TO_ONE,
      message: message,
      toUserId: chatAuthId,
      quoteMessage: quoteText,
      fromUserId: baseInfo.authId,
      chatMsgId: getUUID(),
    }
    // EMIT_MESSAGE_TO_SERVER
    dispatch(emit_message_to_server(messageObj))
    setInputValue('');
    setQuoteText('');
  }

  // 文件上传类型的消息
  function onSendFileToMessageList(url) {
    const messageObj = {
      chatType: FILE_UPLOAD,
      message: '',
      toUserId: chatAuthId,
      resourceType: 'PICTURE',
      url: url,
      fromUserId: baseInfo.authId,
      chatMsgId: getUUID(),
    }
    dispatch(message_update(messageObj));
  }

  // 舒小辅绑定咨询师
  async function onReBind() {
    if (!matchedConsultant.authId) {
      Toast.show({
        content: '请先输入正确的咨询师ID'
      })
      return;
    }
    const params = {
      consultantId: matchedConsultant.authId,
      staffId: chatAuthId
    }
    const res = await reBindBySiuvo(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error
      })
    } else {
      Toast.show({
        icon: 'success',
        content: '绑定成功'
      })
      setDialogVisible(false);
    }
  }

  function renderTitleRight() {
    return (
      <div className='doc-dialogue-right-content' onClick={() => {
        navigate(`/therapist/client-info?authId=${chatAuthId}`)
      }}>
        <Icon iconfont='date' width="2" height="2" />
        <div className="title-right-txt">详细信息</div>
      </div>
    )
  }

  // 定义下拉加载时的自定义提示
  const statusRecord = {
    pulling: '下拉获取更多消息',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
  }

  function onFormClick() {
    navigate(`/therapist/client-info?authId=${chatAuthId}`)
  }

  // 虚拟拨号,只在预约时间段显示
  function anonymousCall() {
    setAnonymousBindDialog(true)
  }

  async function onAnonymousBind() {
    let params = {
      staffId: chatAuthId,
      phoneNoA
    };
    let res = await consultantAnonymousCall(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      setAnonymousBindDialog(false);
      makingCall(res.result);
    }
  }

  // 咨询师咨询完成确认
  const _consultCompleted = async (submitEnd) => {
    let params = {
      submitEnd,
      recodeId: consultRecodeId,
    };
    const res = await consultantConsultCompleted(params);
  }

  function renderCommonAnswer() {

    return (
      <div className='chatgpt-amswer-block'>
        {
          commonAnswerList.map((commonAnswer, index) => {
            return (
              <div className='chatgpt-answer-item' key={index}>
                <div className='item-adopt'
                  onClick={() => {
                    // onMessageSend(commonAnswer);
                    setInputValue(commonAnswer)
                  }}
                >
                  采用
                </div>
                <div
                  className='item-message'>
                  {commonAnswer}
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  // 引用消息
  const quoteMessage = (message) => {
    setQuoteText(message);
  }

  // 撤回消息
  const handleWithdrawMessage = async (message) => {
    console.log('message', message);
    // 只能撤回自己的消息
    if (message.fromUserId !== baseInfo.authId) {
      Toast.show({
        icon: 'fail',
        content: '不能撤回他人消息',
      })
      return;
    }
    // 机器人的消息不能撤回
    if (message.fromRobotId) {
      Toast.show({
        icon: 'fail',
        content: '不能撤回他人消息',
      })
      return;
    }
    // 超过三分钟的话不能撤回
    if (isMoreThan3Mins(message.sendTime)) {
      Toast.show({
        icon: 'fail',
        content: '超过三分钟的消息不能撤回',
      })
      return;
    }
    let params = {
      chatMsgId: message.chatMsgId,
    };
    const res = await consultantChatWithdraw(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      setWithdrawText(message)
      dispatch(message_withdraw({ ...message, withdrawFlag: true }))
    }
  }

  const handleReEdit = (withdrawText) => {
    const { message } = withdrawText;
    const { answer } = JSON.parse(message);
    setInputValue(answer || message);
    setWithdrawText(null);
  }

  let otCombineName = '';
  const title = staffInfo.name;
  // 当聊天对象是管理员时
  if (isAdmin) {
    otCombineName = '管理员';
  } else {
    const { ownerName, tenantName, workerId } = staffInfo;
    // ownerName和tenantName相同时只显示一个
    const sub = ownerName === tenantName ? tenantName : (ownerName + '-' + tenantName);
    otCombineName = sub + '-' + workerId ? workerId : '';
  }
  return (
    <>
      <TitleBar
        title={title}
        subTitle={otCombineName}
        isShowBack={true}
        barCancel={() => { navigate('/therapist/home/user-manage') }}
        rightContent={renderTitleRight()}
      />
      <div className='dialogue-style'>
        <div className='dialogue-blocks' id='messageList-dialogue-blocks'>
          <PullToRefresh
            onRefresh={() => {
              pageNo.current += 1;
              _doctorAddChatMsg();
              setScrollEnd(false);
            }}
            refreshingText={(text) => {
              return (
                // 此处的return需要带上圆括号，否则会报warning：functions are not valid as a React child
                <div>{statusRecord[text]}</div>
              )
            }}
          >
            <RenderMessageContent
              sendFailCount={sendFailCount}
              messageList={chatMsg}
              role='doctor'
              roleInfo={staffInfo}
              onFormClick={onFormClick}
              consultModalVisible={consultModalVisible}
              setModalVisible={setModalVisible}
              _consultCompleted={_consultCompleted}
              isShowLoading={isShowLoading}
              handleQuoteMessage={quoteMessage}
              handleWithdrawMessage={handleWithdrawMessage}
            />
            {
              withdrawText && (
                <div className='message-withdraw-content'>
                  <div>你撤回了一条消息
                    <span
                      className='withdraw-text'
                      onClick={() => {
                        handleReEdit(withdrawText);
                      }}
                    >
                      重新编辑
                    </span>
                  </div>
                </div>
              )
            }
          </PullToRefresh>
        </div>
        <div className='bottom-block'>
          <div className='dialogue-input'>
            <div className='input-button-block'>
              <div className='input-block'>
                <TextArea
                  autoSize={true}
                  allowClear={true}
                  rows={1}
                  placeholder='请输入内容'
                  style={{ '--font-size': '1.6rem', backgroundColor: 'white', height: '100%', padding: '5px 2px 5px 2px', borderRadius: '5px' }}
                  value={inputValue}
                  onChange={val => setInputValue(val)}
                  onEnterPress={() => {
                    onMessageSend(inputValue)
                  }}
                  onFocus={() => {
                    setFunctionsVisible(false);
                  }}
                />
              </div>
              {!inputValue
                ? <div className='input-function' onClick={() => {
                  setFunctionsVisible(!functionsVisible);
                  scrollMessageListToEnd()
                }}>
                  <img alt="" src={require('@/assets/icon/dialogue/add.svg').default} />
                </div>
                : <Button
                  className='send-msg-btn'
                  disabled={inputValue.trim().length <= 0}
                  onClick={() => onMessageSend(inputValue)}
                >
                  发送
                </Button>
              }
            </div>
            {
              quoteText && (
                <div className='quote-content'>
                  <div className='quote-text'>
                    {quoteText}
                  </div>
                  <CloseCircleFill
                    fontSize={24}
                    onClick={() => {
                      setQuoteText('');
                    }}
                  />
                </div>
              )
            }
            <div className='chatGpt-block' >
              <div
                className='chatGpt-block-title'
                onClick={() => {
                  setChatgptAnswerVisible(!chatgptAnswerVisible);
                  setShowCommonAnswer(false);
                  let chatgptParams = structureChatgptParams();
                  setChatgptParams(chatgptParams);
                  scrollMessageListToEnd();
                }}>
                智能问答
              </div>
              <div
                className='chatGpt-block-title'
                onClick={() => {
                  setChatgptAnswerVisible(false);
                  setShowCommonAnswer(!showCommonAnswer);
                  scrollMessageListToEnd();
                }}>
                常用回答
              </div>
            </div>
          </div>
          {
            showCommonAnswer && renderCommonAnswer()
          }
          {
            chatgptAnswerVisible
              ? <ChatGptStream
                params={chatgptParams}
                onMessageSend={(e) => {
                  setInputValue(e)
                }}
              />
              : null
          }
          {functionsVisible ? <div className='function-blocks'>
            {functionBlocks.map((functionBlock, index) => <div className='function-block' key={index} onClick={() => {
              functionBlock.func && functionBlock.func(consultants)
            }}>
              <div className='function-block-icon'>{functionBlock.icon}</div>
              <div className='function-block-title'>{functionBlock.title}</div>
            </div>)}
          </div> : null}
        </div>
      </div>
      <CustomConfirmDialog
        visible={dialogVisible}
        content={renderMatchDialogContent()}
        onCancel={() => {
          setDialogVisible(false)
        }}
        onConfirm={() => {
          // bind consultant
          onReBind()
        }}
      />
      <CustomConfirmDialog
        visible={anonymousBindDialog}
        content={renderAnonymousBindContent()}
        onCancel={() => {
          setAnonymousBindDialog(false);
          setPhoneNoA(telephone);
        }}
        onConfirm={() => {
          // bind consultant
          onAnonymousBind()
        }}
      />
    </>
  )
}

export default Dialogue;