import React, { useRef, useState, useEffect } from 'react';
import _, { set } from 'lodash';
import Compressor from 'compressorjs';
import { Toast, ImageViewer, PullToRefresh, Modal, Button, Image, DotLoading, Popover } from 'antd-mobile'
import { useLocation, useNavigate } from "react-router-dom";
import { UserOutline, ExclamationCircleFill } from 'antd-mobile-icons'
import { useSelector } from 'react-redux';
import { CHAT_TO_ONE, FILE_UPLOAD, CALL_TO_ONE, ROBOT_CHAT } from '@/actions/actionType';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { getUrls } from '@/utils/getUrls';
import { getFormH5Url, staffGetConsultantInfo } from "@/services/api";
import Xlyy from '@/components/MessageComponents/Xlyy';
import SocketDisconnect from '@/utils/socketDisconnect';
import isJsonAndReturnObj from '@/utils/isJsonAndReturnObj';
import contractVideoOrPositive from './contractVideoOrPositive';

/**
 * 
 * @param {*} props
 *  messageList,
    role,
    roleInfo = {},
    onFormClick = () => { },
    consultModalVisible,
    setModalVisible = () => {},
    _consultCompleted = () => {},
    consultantId
 * @returns 
 */
export function RenderMessageContent(props) {
  const {
    messageList,
    sendFailCount,
    role,
    roleInfo = {},
    onFormClick = () => { },
    consultModalVisible,
    setModalVisible = () => { },
    _consultCompleted = () => { },
    consultantId,
    isShowLoading,
    handleQuoteMessage,
    handleWithdrawMessage,
    handleCustomServiceCallback
  } = props;
  const { BASE_URL } = getUrls();
  const navigate = useNavigate();
  const location = useLocation();
  const baseInfo = useSelector(state => state.baseInfo[role]);
  const defaultAvatarUrl = baseInfo?.roleInfo.avatarUrl;
  const [imageViewerUrl, setImageViewerUrl] = useState('');  // 选择的原始图片url
  const [imgVisible, setImgVisible] = useState(false); // 是否显示原始图片查看
  // // 是否显示popover menu
  // const [popoverMenuVisible, setPopoverMenuVisible] = useState(false);
  // // popover渲染的父节点元素
  // const [popoverParentNode, setPopoverParentNode] = useState(null);
  // 当前的popover的index
  const popoverIndex = useRef(null);
  // popover的ref
  const ref = useRef([]);

  // 角色的颜色区分
  const roleStyleColor = role == 'doctor' ? '#FFB92E' : '#2979FF';
  // 长按时间
  let pressTimer = null;

  const handleRightClick = (index, event) => {
    event.stopPropagation();
    event.preventDefault(); // 阻止默认的右键菜单
    ref.current[popoverIndex.current]?.hide();
    ref.current[index]?.show()
    popoverIndex.current = index;
  }

  const handleTouchStart = (index, event) => {
    // event.stopPropagation();
    // event.preventDefault(); // 阻止默认的右键菜单
    ref.current[popoverIndex.current]?.hide();
    pressTimer = setTimeout(() => {
      // setPopoverMenuVisible(true);
      ref.current[index]?.show()
      popoverIndex.current = index;
    }, 1000);
  }

  const handleTouchEnd = () => {
    clearTimeout(pressTimer);
  }

  const hidePop = () => {
    ref.current[popoverIndex.current]?.hide()
  }

  useEffect(() => {
    window.addEventListener('scroll', hidePop, true)
    // window.addEventListener('click', hidePop, true)
    // window.addEventListener('contextmenu', hidePop)
    // window.addEventListener('touchstart', hidePop)

    return () => {
      window.removeEventListener('scroll', hidePop)
      // window.addEventListener('contextmenu', hidePop)
      // window.addEventListener('touchstart', hidePop)
    }
  }, [])

  function viewPicture(url) {
    setImageViewerUrl(url);
    setImgVisible(true);
  }

  async function getFormTaskUrl(taskId, seq) {
    const res = await getFormH5Url(taskId, seq);
    if (res.errorCode) {
      Toast.show('测评链接获取异常，请重试')
      return null;
    }
    return res.result;
  }

  function renderPicture(url) {
    return (
      <div onClick={() => { viewPicture(url) }} >
        {/* 加上pointerEvents为none是为了静止触发长按的时候触发微信里面自带的菜单 */}
        <img style={{ pointerEvents: 'none' }} className="chat-message-img" alt="" src={url} />
      </div>
    )
  }

  // 查询咨询记录的详情
  async function _staffGetConsultantInfo(consultRecodeId) {
    const res = await staffGetConsultantInfo(consultRecodeId);
    return res?.result
  }

  // 根据资源类型渲染聊天message
  function parseDataToNode(item) {
    const resourceType = item.resourceType;
    let sourceNode = null;
    switch (resourceType) {
      case 'PICTURE':
        sourceNode = renderPicture(item.url);
        break;
      case 'VIDEO':
        break;
      case 'AUDIO':
        break;
      default:
        break;
    }
    return sourceNode;
  }

  // 发送测评的量表
  function renderTaskMsg(item) {
    const messages = JSON.parse(item.message);
    return (
      <>
        {messages.map((item, index) => {
          return (
            <div
              key={index}
              className='text-form-task'
              onClick={async () => {
                if (role === 'patient') {
                  let url = await getFormTaskUrl(item.taskId, item.seq);
                  url && navigate('/client/form-iframe', { state: { url: url, taskId: item.taskId, seq: item.seq } })
                }
                if (role === 'doctor') {
                  onFormClick();
                }
              }}
            >
              <img src={require('@/assets/icon/date.svg').default} className='task-icon' />
              <span>{item.name}</span>
            </div>
          )
        })}
        {
          role === 'patient' ? <div>请点击上方量表依次作答</div> : null
        }
      </>
      // <div 
      //   className='text-form-task'
      //   onClick={() => {
      //   if(role === 'patient') {
      //     // 患者点击作答量表
      //     Toast.show('url::' + url);
      //   }
      // }}>{text}</div>
    )
  }

  // 
  function renderEvaluation(item) {
    const message = JSON.parse(item.message);
    return (
      <div className='evaluation'
        onClick={async () => {
          const consultantInfo = await _staffGetConsultantInfo(message.consultRecodeId);
          // 咨询成功，但是没有评价
          if (consultantInfo.recodeStatus === 'CONSULT_SUCCESS' && role === 'patient') {
            navigate('/client/consulting-evaluation', { state: { consultingInfo: consultantInfo } })
          }
          // 评价成功，已经评价
          else if (consultantInfo.recodeStatus === 'EVALUATE_SUCCESS' && role === 'patient') {
            navigate('/client/consulted-evaluation', { state: { consultedInfo: consultantInfo } })
          }
        }}
      >
        {message.messageContent}
      </div>
    )
  }

  /**
   * 
   * @param {*} id  ["xlsd", "yyfw", "rgfw"]
   */


  function renderFuncById(id) {
    switch (id) {
      // 心理预约服务--consultantId: 绑定的咨询师ID
      case 'xlyy_process':
        return <Xlyy consultantId={consultantId} />;
      default:
        return null;
    }
  }

  // 渲染单条聊天消息
  function renderTextMessage(item) {
    const { answer, quote } = isJsonAndReturnObj(item.message);
    const messageContent = contractVideoOrPositive(answer);

    return (
      <div className='text-message'>
        <div className='answer-message' dangerouslySetInnerHTML={{ __html: messageContent }}></div>
        {
          quote && (
            <Popover
              trigger='click'
              placement='top'
              content={
                <>
                  <div className='popover-content'>
                    {quote}
                  </div>
                </>
              }
            >
              <div className='quote-message'>{quote}</div>
            </Popover>
          )
        }
      </div >
    )
  }

  // // 渲染AI咨询师的消息
  // function renderAIChatMessage(item) {

  // }

  // 渲染消息发送失败
  function renderMessageFail(item) {
    if (item.onSendSuccess === false) {
      return (
        <ExclamationCircleFill
          fontSize={24}
          color='var(--adm-color-danger)'
        />
      )
    }
    if (sendFailCount % 4 === 0 && sendFailCount > 0) {
      Toast.show({
        content: '该账号已在其他设备登录',
        duration: 2000,
        icon: 'fail',
      })
    }
    return null;
  }

  function renderChatToeOneMsg(item) {
    // 消息类型：TEXT 文本； TAKS 量表；AI_CHAT AI咨询师；CONSULT_END 咨询结束
    const messageType = item.messageType;
    let message = null
    switch (messageType) {
      case 'TEXT':
        message = renderTextMessage(item);
        break
      case 'TASK':
        message = renderTaskMsg(item);
        break;
      case 'CONSULT_END':
        message = renderEvaluation(item);
        break;
      case 'FUNCTION':
        message = renderFuncById(item.message);
        break;
      case 'AI_CHAT':
        // message = renderAIChatMessage(item);
        message = renderTextMessage(item);
        break;
      default:
        message = renderTextMessage(item);
        break;
    }
    return message;
  }

  // 渲染撤回的消息
  function renderWithdrawMessage(item) {
    if (item?.toUserId === baseInfo?.authId) {
      return (
        <div>
          对方撤回了一条消息
        </div>
      )
    } else {
      return (
        <div>
          你撤回了一条消息
        </div>
      )
    }
  }

  function renderMessageByType(item) {
    let messageNode = null;
    const chatType = item.chatType;
    const withdrawFlag = item.withdrawFlag;
    if (!withdrawFlag) {
      switch (chatType) {
        // 文本消息
        case CHAT_TO_ONE:
          messageNode = renderChatToeOneMsg(item);
          break;
        case FILE_UPLOAD:
          messageNode = parseDataToNode(item)
          break;
        default:
          break;
      }
      return messageNode
    }
    messageNode = renderWithdrawMessage(item);
    return messageNode;
  }

  // 渲染收到的消息
  function renderLeftMessage(item, index) {
    const callTypeMessage = item.chatType === CALL_TO_ONE;
    const isRobotMessage = item.messageType === ROBOT_CHAT; // 是否为机器人的消息，如果是，更换显示消息的头像
    const { quote, answer } = isJsonAndReturnObj(item.message);
    return (
      <div key={index}>
        <div className='call-flag-time'>{MomentDiffZone(item.sendTime).format('YYYY-MM-DD HH:mm:ss')}</div>
        <>
          <div className='dialogue-block flex-start'
            key={item.chatMsgId}
            id={`${item.chatMsgId}`}
          >
            <>
              <div
                className='head-u'
                onClick={() => {
                  if (role === 'patient' && !isRobotMessage) {
                    navigate('/client/consultant-info', { state: { consultantInfo: roleInfo, role: 'patient', type: 'readonly' } })
                  }
                }}>
                {
                  isRobotMessage && role === 'patient' ? <Image
                    src={require('@/assets/icon/siuvo_avatar.svg').default}
                    style={{ borderRadius: 32 }}
                    fit='cover'
                    width={30}
                    height={30}
                  /> : <UserOutline />
                }
              </div>
              {
                callTypeMessage
                  ? <div className='dialogue-u'>{renderCallFlagMessage(item, index)}</div>
                  :
                  <div>
                    <div className='nick-name'>{role === 'patient' ? '咨询师' : item.fromUserNickname}</div>
                    <div className={`dialogue-u ${item.chatType === 'fileUpload' ? 'dialogue-no-bg' : ''} ${item.messageType === 'AI_CHAT' ? 'dialogue-ai' : ''}`}
                      onContextMenu={(event) => {
                        handleRightClick(index, event);
                      }}
                      onTouchStart={(event) => {
                        handleTouchStart(index, event);
                      }}
                      onTouchEnd={handleTouchEnd}
                    >
                      <Popover
                        ref={(popoverRef) => {
                          if (popoverRef) ref.current[index] = popoverRef
                        }}
                        content={
                          <div style={{ width: 50, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                            <div
                              onClick={() => {
                                handleQuoteMessage(answer);
                                ref.current[popoverIndex.current]?.hide();
                              }}
                              onTouchStart={(event) => { event.stopPropagation() }}
                            >
                              引用
                            </div>
                            <hr></hr>
                            <div
                              onClick={() => {
                                handleWithdrawMessage(item);
                                ref.current[popoverIndex.current]?.hide();
                              }}
                              onTouchStart={(event) => { event.stopPropagation() }}
                            >
                              撤回
                            </div>
                          </div>
                        }
                        placement='top'
                      >
                        <div>
                          {renderMessageByType(item)}
                        </div>
                      </Popover>
                    </div>
                  </div> 
              }
            </>
          </div>
          {
            quote && (
              <div style={{ height: '32px' }}></div>
            )
          }
        </>
      </div>
    )
  }

  function renderRightMessage(item, index) {
    const callTypeMessage = item.chatType === CALL_TO_ONE;
    const messageType = item.messageType;
    const isRobotMessage = item.messageType === ROBOT_CHAT; // 是否为机器人的消息，如果是，更换显示消息的头像
    // 多个咨询师记录区分
    let avatarSrc = '';
    if (isRobotMessage && role === 'doctor') {
      avatarSrc = require('@/assets/icon/siuvo_avatar.svg').default;
    } else {
      avatarSrc = item.fromUserAvatar || defaultAvatarUrl;
    };
    const { quote, answer } = isJsonAndReturnObj(item.message);
    return (
      <div key={index} style={{ position: 'relative' }}>
        <div className='call-flag-time'>{MomentDiffZone(item.sendTime).format('YYYY-MM-DD HH:mm:ss')}</div>
        {
          // 咨询师隐藏评价的消息
          messageType === 'CONSULT_END'
            ? null
            : (
              <>
                <div className='dialogue-block flex-end' key={item.chatMsgId}>
                  {
                    renderMessageFail(item)
                  }
                  {
                    callTypeMessage
                      ? <div className={`dialogue-i dialogue-${role}`} >{renderCallFlagMessage(item, index)}</div>
                      :
                      <div className='dialogue-i-message'>
                        <div className='nick-name'>{item.fromUserNickname}</div>
                        <div className={`dialogue-i dialogue-${role} ${item.chatType === 'fileUpload' ? 'dialogue-no-bg' : ''}`}
                          onContextMenu={(event) => {
                            handleRightClick(index, event);
                          }}
                          onTouchStart={(event) => {
                            handleTouchStart(index, event);
                          }}
                          onTouchEnd={handleTouchEnd}
                        >
                          <Popover
                            ref={(popoverRef) => {
                              if (popoverRef) ref.current[index] = popoverRef
                            }}
                            content={
                              <div style={{ width: 50, alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <div
                                  onClick={() => {
                                    handleQuoteMessage(answer);
                                    ref.current[popoverIndex.current]?.hide();
                                  }}
                                  onTouchStart={(event) => { event.stopPropagation() }}
                                >
                                  引用
                                </div>
                                <hr></hr>
                                <div
                                  onClick={() => {
                                    handleWithdrawMessage(item);
                                    ref.current[popoverIndex.current]?.hide();
                                  }}
                                  onTouchStart={(event) => { event.stopPropagation() }}
                                >
                                  撤回
                                </div>
                              </div>
                            }
                            placement='top'
                          >
                            <div>
                              {renderMessageByType(item)}
                            </div>
                          </Popover>
                        </div>
                      </div>
                  }
                  <div className='head-i'>
                    <Image
                      src={avatarSrc}
                      style={{ borderRadius: 32 }}
                      fit='cover'
                      width={30}
                      height={30}
                    />
                  </div>
                </div>
                {
                  quote && (
                    <div style={{ height: '32px' }}></div>
                  )
                }
              </>
            )
        }
      </div>
    )
  }

  // 拨打语音视频通话消息记录
  function renderCallFlagMessage(item, index) {
    const callInfo = JSON.parse(item.message) || {};
    const callType = callInfo.callType;
    const callStatus = callInfo.callStatus;
    let callFlagMessage;
    switch (callType) {
      case 'VIDEO':
        callFlagMessage = '视频通话';
        break;
      case 'AUDIO':
        callFlagMessage = '语音通话';
        break;
      default:
        break
    }
    switch (callStatus) {
      case 'CALL':
        callFlagMessage = '发起了' + callFlagMessage;
        break;
      case 'HANG_UP':
        callFlagMessage = callFlagMessage + '已结束';
        break;
      default:
        break;
    }
    return (
      <>
        {/* <div className='call-flag-time'>
          {MomentDiffZone(item.sendTime).format('YYYY-MM-DD HH:mm:ss')}
        </div> */}
        <div
          key={index}
          className='call-flag-message'
        >
          <img
            className='message-icon'
            src={callType == 'VIDEO' ? require('@/assets/icon/dialogue/video.svg').default : require('@/assets/icon/dialogue/telephone.svg').default}
          />
          <div className='call-flag-content '>
            {callFlagMessage}
          </div>
        </div>
      </>
    )
  }


  return (
    <>
      <SocketDisconnect />
      {
        messageList.map((item, index) => {
          if (role === 'doctor') {
            // 咨询师端---现在聊天记录可以查询到多个咨询师的
            // 对应的是同一个来访者，因此渲染消息根据fromUserId判断
            // 咨询师端---现在聊天记录可以查询到多个咨询师的
            // 对应的是同一个来访者，因此渲染消息根据fromUserId判断
            if (item?.fromUserId === roleInfo.authId) {
              return renderLeftMessage(item, index)
            } else {
              return renderRightMessage(item, index);
            }
          }
          else if (role === 'admin') {
            // 管理员端---查询来访者所有的聊天记录
            if (item?.fromUserId === roleInfo.authId) {
              return renderLeftMessage(item, index)
            } else {
              return renderRightMessage(item, index);
            }
          }
          else {
            // 来访者端---现在聊天记录可以查询到多个咨询师的
            // 发送消息都是来访者本人发送的，因此使用toUserId看是不是发给本人的
            if (item?.toUserId === baseInfo?.authId) {
              return renderLeftMessage(item, index)
            } else {
              return renderRightMessage(item, index);
            }
          }
        })
      }
      <ImageViewer
        style={{
          PointerEvent: 'none'
        }}
        image={imageViewerUrl}
        visible={imgVisible}
        onClose={() => {
          setImgVisible(false)
        }}
      />
      <Modal
        visible={consultModalVisible}
        title='本次咨询是否结束'
        content={
          <div className='modal-content'>
            <div
              className='modal-content-confirm'
              style={{ background: roleStyleColor }}
              onClick={() => {
                setModalVisible(false);
                _consultCompleted(true);
              }}
            >
              确认
            </div>
            <div
              className='modal-content-cancel'
              style={{ background: '#C9C9C9' }}
              onClick={() => {
                setModalVisible(false);
                // _consultCompleted(false);
              }}
            >
              取消
            </div>
          </div >
        }
      >
      </Modal >
      <div
        className='dialogue-block flex-start fade-in'
        style={{
          display: isShowLoading ? 'flex' : 'none',
        }}
      >
        <div className='head-u'>
          <UserOutline />
        </div>
        <div className='dialogue-u' style={{ fontSize: '28px' }} >
          <DotLoading />
        </div>
      </div>
    </>
  )
}

export function uploadDialogueImage(file, authId, cb) {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('文件为空')
    }
    new Compressor(file, {
      quality: 0.2,
      success: (result) => {
        if (result.size > 1024 * 1024) {
          Toast.show('请选择小一点的图片')
          reject('上传文件压缩后依然太大')
          return
        }
        resolve(_uploadIMG(result, authId, cb));
      }
    })
  });
}

async function _uploadIMG(file, authId, cb) {
  const params = new FormData();
  params.append('file', file);
  params.append('toAuthId', authId);
  params.append('bizType', 'CHAT');
  params.append('fileName', file.name);
  cb(params);
}