import React from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { patientGetConsultantScheduling } from '@/services/api';
import CalendarComponent from '@/components/Calendar';

function CalendarOnlyRead() {

  return (
    <>
      <TitleBar isShowBack={true} title="预约" />
      <CalendarComponent
        role='patient'
        getConsultantScheduling={patientGetConsultantScheduling}
      />
    </>
  )
}

export default CalendarOnlyRead;