// 初始评估的页面
import React from "react";
import { Button } from 'antd-mobile';
import { useNavigate } from "react-router-dom";

import './index.less';
import TitleBar from '@/components/TitleBar';
import assess from '@/assets/icon/client/initial-evaluation/assess.svg';
import evaluation from '@/assets/icon/client/initial-evaluation/evaluation.svg';

export default function InitialEvaluation() {
  const navigate = useNavigate();

  return (
    <div className="page-content">
      {/* <TitleBar title="初始评估" /> */}
      <div className="title-section">
        <div className="title-text">个性化心理成长计划</div>
        <div className="title-desc">您只需要花一点时间来回答几个问题</div>
      </div>
      <div className="target-section">
        <div className="assess-content">
          <div className="assess-icon">
            <img src={assess} alt="" height={24} />
          </div>
          <div className="assess-text">
            <div className="assess-text-title">选择您的成长目标</div>
            <div className="assess-text-desc">以及您需要哪些帮助</div>
          </div>
        </div>
        <div className="assess-content">
          <div className="assess-icon">
            <img src={evaluation} alt="" height={24} />
          </div>
          <div className="assess-text">
            <div className="assess-text-title">心理状态评估</div>
            <div className="assess-text-desc">便于我们制定最适合您的方案</div>
          </div>
        </div>
      </div>
      <div className="button-section">
        <Button
          className="button"
          block
          color='primary'
          size='large'
          fill="solid"
          onClick={() => {
            navigate('/client/initial-evaluation-page')
          }}
        >
          开始
        </Button>
        <Button
          className="button"
          block
          color='primary'
          size='large'
          fill="outline"
          onClick={() => { navigate('/client/home-v2/main') }}
        >
          直接咨询
        </Button>
      </div>
      <div className="footer">
        <div>可以直接进入24小时图文/电话咨询，</div>
        <div>但您本次登录将无法获得个性化心理健康方案</div>
      </div>
    </div>
  )
}