import CONFIG from 'GlobalConfigFile';

const DEFAULT_SERVER = CONFIG.Server;
const URLEnv = CONFIG.URLEnv;

export function getUrls(server) {
  const config = URLEnv[DEFAULT_SERVER];
  if (!config) {
    throw new Error(`Unknown server ${DEFAULT_SERVER}`);
  }

  return config;
}
