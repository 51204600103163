import React, { useState, useEffect } from 'react';
import LoginWithRegisterComponent from '@/components/LoginWithRegisterComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getLoginToken,
  setLoginToken,
  getIsFirstTimeLogin,
  setLocalNeedFirstEvaluationStatus,
  getLocalNeedFirstEvaluationStatus
} from '@/utils/localStorage'
import { checkTokenIsValid, decodeToken, getPageQuery } from '@/utils/utils';
import { Toast, Tabs } from 'antd-mobile';
import CONFIG from 'GlobalConfigFile';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { loginV2, checkTelephone, sendPatientVerifyCode, getNeedFirstEvaluation, checkRegisterCode } from '@/services/api';
import { register_socket_event, save_patient_info } from '@/actions';
import WithTracking from '@/components/WithTracking';
import moment from 'moment';

function LoginWithRegister() {
  const ROLE = 'patient'; //此声明只是UI上的区分
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { intake } = getPageQuery();

  const navUrl = location?.state?.from || '/client/home-v2/main';


  // 注册码是否有效
  const [registerCodeAvailable, setRegisterCodeAvailable] = useState(false);

  useEffect(() => {
    // 初始化
    async function init() {
      // 检查token是否存在及有效
      const token = getLoginToken(ROLE);
      const isTokenValid = checkTokenIsValid(token);
      const isFirstTime = getIsFirstTimeLogin(ROLE);
      // token无效或者第一次登录不做操作
      if (!isTokenValid || isFirstTime === 'true') {
        return;
      }
      // 链接socket
      const { authId } = decodeToken(token)
      registerSocketAndConnect(authId);
      if (intake === 'true') {
        navigate('/client/initial-evaluation');
        return
      }
      // 获取本地缓存的是否需要初始评估的状态
      let status = getLocalNeedFirstEvaluationStatus();
      if (status === null) {
        status = await _getNeedFirstEvaluation();
      }
      if (status) {
        navigate('/client/initial-evaluation');
      }
    }
    init();
  }, [])

  async function _getNeedFirstEvaluation() {
    try {
      const res = await getNeedFirstEvaluation();
      const needFirstEvaluation = _.get(res, 'result', true);
      setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
      return needFirstEvaluation
    } catch (e) {
      console.error('getNeedFirstEvaluation error', e)
      // 接口异常仍回主页
      return false
    }
  }

  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect(id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    dispatch(register_socket_event(socket));
  }

  async function handleSendVerifyCode(cellphone) {
    const res = await sendPatientVerifyCode(cellphone);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '短信发送成功',
        key: 'success'
      });
    }
  }
  // 登录
  async function onPatientLogin(params) {
    let res = await loginV2(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    const { token, needFirstEvaluation } = res.result;
    setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
    const authId = _.get(res, ['result', 'authId'], '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    dispatch(save_patient_info(_.get(res, ['result'], {})))
    // if (location?.state?.from) {
    //   navigate(navUrl);
    //   return;
    // }
    if (intake === 'true') {
      navigate('/client/initial-evaluation');
      return
    }
    if (needFirstEvaluation) {
      navigate('/client/initial-evaluation');
      return
    }
    navigate(navUrl)
  }
  // 校验手机号
  async function onTelephoneCheck(params) {
    let res = await checkTelephone(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    const authList = _.get(res, ['result', 'authList'], [])
    navigate('/client/register', { state: { type: 'register', ...params, authList: authList } })
  }

  // 注册码状态转换
  function revertRegisterCodeStatus (status) {
    switch (status) {
      case 'WAIT_OPEN':
        return '注册码未开放注册';
      case 'OPEN':
        return '注册码验证成功';
      case 'USED':
        return '注册码已注册';
      case 'EXPIRED':
        return '注册码过期或失效';
      case 'UNDEFINED':
        return '注册码未找到';
      default:
        return '';
    }
  }

  // 校验注册码
  async function handleCheckRegisterCode(registerCode) {
    const params = {
      registerCode
    }
    const res = await checkRegisterCode(params);
    if (res.errorCode) {
      Toast.show(res.error)
      return;
    }
    // 获取注册码的状态
    const status = _.get(res, ['result', 'status'], '');
    Toast.show(revertRegisterCodeStatus(status))
    if (status === 'OPEN') {
      setRegisterCodeAvailable(true);
    }
  }

  return (
    <LoginWithRegisterComponent
      theme={ROLE}
      loginTitle='舒辅EAP'
      onLogin={onPatientLogin}
      onSendVerifyCode={handleSendVerifyCode}
      registerCodeAvailable={registerCodeAvailable}
      handleCheckRegisterCode={handleCheckRegisterCode}
      handleTelephoneCheck={onTelephoneCheck}
    />
  )
}

export default WithTracking(
  LoginWithRegister,
  {
    name: 'visit:login',
    props: {
      eventName: '登录'
    },
    userId: 'anonymous'
  }
);