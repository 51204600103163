// 该页面用于服务子账号的注册
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import _, { replace } from 'lodash';

import './index.less';
import { staffBindSubAccount, staffRegisterSubAccount } from '@/services/api';
import TitleBar from '@/components/TitleBar';
import RegisterComponents from '@/components/RegisterComponents';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';

const SubAccountRegister = (props) => {
  const { handleAddEventDotClick } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    authList,
    verifyCode,
    telephone,
    relationship
  } = _.get(location, 'state', {
    authList: [],
    verifyCode: '',
    telephone: '',
    relationship: ''
  });

  // 关联已有账号
  async function handleLinkAccount(currentAuthId) {
    handleAddEventDotClick({
      name: 'action:account/link',
      props: {
        eventName: '子账号关联已有账号'
      }
    });
    const params = {
      authId: currentAuthId,
      telephone,
      verifyCode,
      relationship
    };
    const res = await staffBindSubAccount(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        icon: 'fail',
      });
      return;
    };
    Toast.show({
      content: '关联成功',
      icon: 'success',
      afterClose: () => {
        navigate(-1);
      }
    });
  };

  // 注册
  async function handleRegister(userInfo) {
    handleAddEventDotClick({
      name: 'action:account/register',
      props: {
        eventName: '子账号注册新账号'
      }
    });
    const params = {
      ...userInfo,
      telephone,
      verifyCode,
    }
    const res = await staffRegisterSubAccount(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    };
    Toast.show({
      content: '注册成功',
      icon: 'success',
      afterClose: () => {
        navigate(-1, { replace: true });
      }
    });
  };

  return (
    <>
      <TitleBar title="注册信息完善" isShowBack={true} />
      <RegisterComponents
        authList={authList}
        // 关联已有账号
        onLinkAccount={handleLinkAccount}
        // 注册新的账号
        onRegisterNewUser={handleRegister}
      />
    </>
  )
}
export default WithTrackingForMedia(SubAccountRegister);