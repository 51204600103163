import React from 'react';
import _ from "lodash";
import { useLocation, useNavigate } from 'react-router-dom';
import { RightOutline } from 'antd-mobile-icons';

import './index.less';
import TitleBar from "@/components/TitleBar";
import StaffBaseInfoComponent from "@/components/UserInfo/StaffBaseInfo";

function StaffDetail(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { staffInfo } = _.get(location, ['state'], ''); //获取url参数

  return (
    <>
      <TitleBar title="来访者信息" isShowBack={true}></TitleBar>
      <StaffBaseInfoComponent patientInfo={staffInfo} role='doctor' />
      <div className='chat-record'>
        <div className='chat-record-title'>
          聊天记录
        </div>
        <div
          className='chat-record-item'
          onClick={() => {
            navigate('/admin/chat-record', { state: { staffInfo } })
          }}
        >
          <div>点击查看来访者全部聊天记录</div>
          <div><RightOutline /></div>
        </div>
      </div>
    </>
  )
}

export default StaffDetail;