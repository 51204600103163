import React, { useEffect } from 'react';
import IframeComponent from '@/components/Iframe';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { startConsultantConsult } from '@/services/api';
import { Toast } from 'antd-mobile';

function DoctorFormIframe(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const url = _.get(location, ['state', 'url']);
  const type = _.get(location, ['state', 'type']);
  const consultId = _.get(location, ['state', 'consultId']);
  const title = _.get(location, ['state', 'title']);

  useEffect(() => {
    if (type !== 'readonly') {
      const timer = setInterval(() => {
        _startConsultantConsult(timer);
      }, 3000);
      return () => { clearInterval(timer); }
    }
  }, []);

  async function _startConsultantConsult(timer) {
    let res = await startConsultantConsult(consultId);
    const suggestConsultants = _.get(res, ['result'], null);
    if (suggestConsultants) {
      Toast.show({
        icon: 'success',
        content: '填写完成',
      })
      clearInterval(timer);
      navigate(-1);
    }
  }

  return (
    <IframeComponent title="咨询记录" src={url}></IframeComponent>
  )
}
export default DoctorFormIframe;