
import React, { useState, useEffect, useRef } from 'react';
import TitleBar from '@/components/TitleBar';
import '@/utils/aliyunRtcUtil/aliyun-webrtc-sdk.js';
import { showAlert, initialization } from '@/utils/aliyunRtcUtil/utils.js';
import { networkEventListener } from '@/utils/networkInformation.js';
import './index.less';
import silentIcon from '@/assets/icon/dialogue/mute_on.svg';
import voiceIcon from '@/assets/icon/dialogue/mute_off.svg';
import ringOff from '@/assets/icon/dialogue/ringOff.svg';
import calling from '@/assets/icon/dialogue/call.svg';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import { message_update, emit_call_flag_to_server } from '@/actions';
import { CALL_TO_ONE } from '@/actions/actionType';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { useDispatch } from 'react-redux';
import EventEmitter from '@/utils/eventEmitter';
import { emitterEvent } from '@/Constant';

function AliyunRTC(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [publisherList, setPublisherList] = useState([]);
  // 本地视频
  const localVideoRef = useRef(null);
  // 远程咨询方视频
  const remoteVideoRef = useRef(null);
  const aliWebrtc = useRef(null);
  const [muteLocalAudio, setMuteLocalAudio] = useState(false); // 本地音频推流
  const { authInfo, consultType, isNeedConfirm, role, chatAuthId, onLeaveRoom, chatAuthAvatarUrl, onMediaStreamUpdate = () =>{} } = props;
  const [isCalling, setIsCalling] = useState(false); // 音视频是否接通
  const joinHint = isNeedConfirm ? '您预约的咨询师正在呼叫您...' : '等待对方加入中，请稍候';
  const [joinStatusHint, setJoinStatusHint] = useState(joinHint);
  const isNeedVideo = consultType === 'video';

  // 初始化aliWebrtc,根据音视频类型，设置模式
  useEffect(() => {
    if (JSON.stringify(authInfo) !== '{}') {
      // 挂断消息推送
      const hangUpCallBack = () => {
        leaveRoom();
      }
      EventEmitter.on(emitterEvent.HANG_UP, hangUpCallBack);
      aliWebrtc.current = new AliRtcEngine();
      // // 获取音频播放设备
      // aliWebrtc.current.getDevices().then((res) => {
      //   console.log('getDevices', res);
      //   aliWebrtc.current.setAudioOutput('default').then((res) => {
      //     // 设置成功
      //     console.log('setAudioOutput');
      //   }).catch((error) => {
      //     // 设置失败
      //     console.log(error);
      //   })
      // }).catch((error) => {
      //   console.log(error.message)
      // });
      if (!isNeedVideo) {
        // 音频通话，设置仅使用音频
        aliWebrtc.current.setAudioOnlyMode(true)
      } else {
        // 指定前置摄像头设备
        aliWebrtc.current.currentCamera = {
          facingMode: "user"
        };
      }
      regitstListener();
      checkSupport().then(res => {
        // 防止权限允许过程中，来访者加入，导致事件注册后↑
        // regitstListener();
        !isNeedConfirm && joinroom(true);
      }).catch(e => {
        console.log('checkSupport error:', e)
      });
      return () => {
        EventEmitter.removeListener(emitterEvent.HANG_UP, hangUpCallBack);
      }
    }
  }, [authInfo])

  // useEffect(() => {
  //   if (JSON.stringify(authInfo) !== '{}') {
  //     !isNeedConfirm && joinroom(true);
  //   }
  // }, [authInfo])

  // useEffect(() => {
  //   let timer = setInterval(() => {
  //     networkToast()
  //   }, 1000)
  //   return () => clearInterval(timer);
  // }, [])

  /**
   * 检查音视频是否支持
   */
  function checkSupport() {
    return new Promise((resolve, reject) => {
      aliWebrtc.current.isSupport({isDebug: true, customPlay: true}).then(res => {
        // 检查设备
        const deviceSuppport = checkDeviceAvaliable(res);
        if (deviceSuppport) {
          resolve('device avaliable');
        } else {
          reject('device is not avaliable')
        }
      }).catch(error => {
        checkDeviceAvaliable(error);
        reject(error);
      })
    })
  }

  /**
   * 
   * @param {*} info isSupport response
   * @returns is audio camera avaliable
   */
  function checkDeviceAvaliable(info) {
    if (!info.audioDevice) {
      Toast.show({
        content: '音频设备不可用。'
      })
      __bl.error(new Error('音频设备不可用'))
      return false;
    }
    if (isNeedVideo && !info.videoDevice) {
      Toast.show({
        content: '摄像头不可用。'
      })
      __bl.error(new Error('摄像头不可用'))
      // 虽然视频不可用，但是只要音频可用就能通话
      return true;
    }
    console.log('device is all avaliable')
    return true;
  }

  function regitstListener() {
    /**
     * remote用户加入房间 onJoin
     * 更新在线用户列表
     */
    aliWebrtc.current.on("onJoin", (publisher) => {
      console.log('onJoin:::', publisher)
    })

    /**
     * 订阅onPublisher回调。当远程用户推流时，
     * 在SDK里会触发onPublisher回调
     */
    aliWebrtc.current.on('onPublisher', (publisher) => {
      //远程发布名字
      console.log('onPublisher:', publisher);
      subscribeRemoteStream(publisher.userId, remoteVideoRef.current, 1);
      setIsCalling(true);
      isNeedVideo ? setJoinStatusHint('') : setJoinStatusHint('语音通话中...')
    });

    /**
     * 检测到用户离开频道 
     * 更新用户列表 
     * 移除用户视图
     */
    aliWebrtc.current.on("onLeave", (publisher) => {
      console.log(':::onLeave:::', publisher)
      initialization(aliWebrtc.current, publisher.userId);
      // setIsCalling(false);
      // showAlert(publisher.displayName + "离开房间", "success");
      // setJoinStatusHint('通话已结束')
      // Toast.show({
      //   content: '通话已挂断..'
      // })
      // onDestroy()
    })

    /**
       * 被服务器踢出或者频道关闭时回调 onBye
       */
    aliWebrtc.current.on("onBye", (message) => {
      //1:被服务器踢出
      //2:频道关闭
      //3:同一个ID在其他端登录,被服务器踢出
      let msg;
      switch (message.code) {
        case 1: msg = "被服务器踢出";
          break;
        case 2: msg = "频道关闭";
          break;
        case 3: msg = "同一个ID在其他端登录,被服务器踢出";
          break;
        default: msg = "onBye";
      }
      showAlert(msg, "warning");
    });

    // 新增--监听订阅音频流回调--此时需要自定义播放。setDisplayRemoteVideo失效
    aliWebrtc.current.on('onMediaStreamUpdate', (data) => {
      console.log('*********onMediaStreamUpdate******', data)
      const {userId, mediaStream} = data;
      if(isNeedVideo) {
        remoteVideoRef.current.srcObject = mediaStream
      } else {
        const audio = new Audio();
        audio.srcObject = mediaStream;
        audio.play();
      }
      onMediaStreamUpdate(mediaStream)
    })
    
    aliWebrtc.current.on('onMedia', (data) => {
      console.log('*********onMedia******', data)
    })

    aliWebrtc.current.on('onError', (error) => {
      console.log('onError:', error)
      __bl.error(error, {
        filename: 'AliyunRTC.js',
        lineno: 168
      })
    })

  }

  /**
   * 
   * @param {*} userId 用户ID
   * @param {*} video stream对象的video元素,此处为remoteVideo
   * @param {*} type number:1表示摄像头流（大流和小流），2表示屏幕分享流
   */
  function subscribeRemoteStream(userId, video, type) {
    aliWebrtc.current.subscribe(userId).then((userId) => {
      aliWebrtc.current.configRemoteAudio(userId, true);
      aliWebrtc.current.configRemoteCameraTrack(userId, true, true);
      console.log('******subscribeRemoteStream********', userId)
      // isNeedVideo && aliWebrtc.current.setDisplayRemoteVideo(
      aliWebrtc.current.setDisplayRemoteVideo(
        userId,
        video,
        type
      )
    }, (error) => {
      console.log(error.message);
    });
  }

  /**
   * 设置静音
   */
  function muteLocalMic(mute) {
    aliWebrtc.current && aliWebrtc.current.muteLocalMic(mute, true)
    setMuteLocalAudio(mute);
  }

  /**
   * 退出视频房间
   */
  async function leaveRoom() {
    try {
      if (aliWebrtc.current) {
        Toast.show({
          content: '通话已挂断..'
        })
        aliWebrtc.current.configLocalAudioPublish = false;
        aliWebrtc.current.configLocalCameraPublish = false;
        aliWebrtc.current.configLocalScreenPublish = false;
        await aliWebrtc.current.publish();
        await aliWebrtc.current.stopPreview();
      }
    } catch (e) {
      console.log('leaveRoom error', e);
      __bl.error(e)
    } finally {
      onDestroy();
      // 向后台发送退出房间
      onLeaveRoom();
    }
  }

  /**
   * 加入房间
   * 触发：输入房间号、单击加入房间按钮
   * 更新页面信息
   * 默认开启预览
   * 获取鉴权信息
   * 加入房间
   * 本地默认自动推视频流（视频流 + 音频流）
   * 发布本地流
   * auto 是否是自动加入房间
   */
  function joinroom(auto) {
    //1.预览
    // 仅视频通信需要开启摄像头预览
    isNeedVideo && aliWebrtc.current.startPreview(localVideoRef.current).then(obj => {
    }).catch((error) => {
      ("[开启预览失败]" + error.message, "danger");
    });
    console.log('joinroom*******authInfo:', authInfo)
    //3. 加入房间 默认推音频视频流
    aliWebrtc.current.joinChannel(authInfo, '').then(() => {
      showAlert("加入房间成功", "success");
      console.log('**********切换通话状态*********************', auto)
      if (!auto) {
        setIsCalling(true);
        isNeedVideo ? setJoinStatusHint('') : setJoinStatusHint('语音通话中...')
      }
      // 4. 发布本地流
      aliWebrtc.current.publish().then((res) => {
        console.log("发布流成功");
      }, (error) => {
        showAlert("[推流失败]" + error.message, "error");
      });
    }).catch((error) => {
      console.log('error:::', error)
      __bl && __bl.error(error);
      showAlert("[加入房间失败]" + error.message, "error");
    })
  }

  function onDestroy() {
    // 只有在通话接通的时候才需要释放aliWebrtc
    isCalling && aliWebrtc.current && aliWebrtc.current.dispose();
    // onHangUp();
  }

  // 通话拨打中的界面
  function renderCallingContent() {
    let contentNode = null;
    if (role === 'patient') {
      contentNode = <img src={calling} alt="" onClick={() => {
        joinroom(false)
      }} />
    }
    return contentNode;
  }

  // 音视频通话操作发送至服务端
  function onCallActionSend(callStatus) {
    const messageObj = {
      chatType: CALL_TO_ONE,
      toUserId: chatAuthId,
      sendTime: MomentDiffZone().valueOf(),
      message: JSON.stringify({
        callType: consultType.toUpperCase(),
        callStatus: callStatus,
      })
    }
    console.log('messageObj:::::::', messageObj)
    dispatch(emit_call_flag_to_server(messageObj))
    dispatch(message_update(messageObj))
  }

  // 挂断函数节流
  function hangUp() {
    console.log('hangUp:::::function')
    onCallActionSend('HANG_UP');
    leaveRoom();
  }

  function renderHoldActionContent() {
    const hangUpThrottled = _.throttle(hangUp, 3000, { trailing: false })
    return (
      <>
        <div className="video-bg"></div>
        <div className='video-head-portrait'>
          <img src={chatAuthAvatarUrl} alt="" />
        </div>
        <div className="video-bg-hint">{joinStatusHint}</div>
        <div className="video-consult-action-content">
          {
            isCalling
              ? <img
                src={muteLocalAudio ? silentIcon : voiceIcon}
                alt=""
                onClick={() => { muteLocalMic(!muteLocalAudio) }}
              />
              : renderCallingContent()
          }
          <img className="video-silent-icon" src={ringOff} alt="" onClick={hangUpThrottled} />
        </div>
      </>
    )
  }

  function renderConsultVideo() {
    return (
      <div className="video-container">
        <video className="local-video" ref={localVideoRef} autoPlay playsInline controls={false}></video>
        <video className="remote-video" ref={remoteVideoRef} autoPlay playsInline controls={false}></video>
        {renderHoldActionContent()}
      </div>
    )
  }

  /**
   * 信号不好时给出的提示
   */
  function networkToast() {
    let isShowToast = networkEventListener();
    console.log('isShowToast', isShowToast);
    if (isShowToast == false) {
      Toast.show({
        content: '当前网络状态不佳',
      })
    }
  }

  return (
    <>
      <TitleBar
        title={isNeedVideo ? '视频咨询' : '音频咨询'}
        isShowBack
        barCancel={() => {
          leaveRoom();
        }} />
      {renderConsultVideo()}
    </>
  )
}

export default AliyunRTC;