// 控制是白天或者黑夜模式的开关
import React, { useState } from 'react';
import Darkmode from 'darkmode-js';

export default function useDarkmode() {
  const [isDarkmode, setIsDarkmode] = useState(false);
  const options = {
    bottom: '64px', // default: '32px'
    right: 'unset', // default: '32px'
    left: '32px', // default: 'unset'
    time: '0.5s', // default: '0.3s'
    mixColor: '#fff', // default: '#fff'
    backgroundColor: '#fff',  // default: '#fff'
    buttonColorDark: '#100f2c',  // default: '#100f2c'
    buttonColorLight: '#fff', // default: '#fff'
    saveInCookies: false, // default: true,
    label: '🌓', // default: ''
    autoMatchOsTheme: true // default: true
  }
  const darkmode = new Darkmode(options);

  return (
    <div style={{
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      backgroundColor: isDarkmode === true ? '#fff' : '#100f2c',
      color: isDarkmode === true ? '#100f2c' : '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    }}
      onClick={() => {
        darkmode.toggle();
        document.documentElement.setAttribute(
          'data-prefers-color-scheme',
          darkmode.isActivated() ? 'dark' : 'light'
        )
        setIsDarkmode(darkmode.isActivated());
        console.log('darkmode.isActivated()', darkmode.isActivated());
      }}
    >
      {isDarkmode ? '🌓' : '🌕'}
    </div>
  )
}
