export const commonConstant = {
  INCOMPLETE_FUNC_HINT: '功能即将开通...',
  pageTitle: '舒辅智慧心理'
}

export const lobType = {
  EAP: 'EAP',
  CONSULTANT: 'CONSULTANT',
  COMPANY: 'COMPANY',
  SFDA: 'SFDA',
  FENGLIN: 'FENGLIN',
}

export const emitterEvent = {
  APPOINT_UPDATE: 'APPOINT_UPDATE',
  HANG_UP: 'HANG_UP',
  CONSULT_END_CONFIRM: 'CONSULT_END_CONFIRM',
  CONSULT_WAIT_EVALUATE: 'CONSULT_WAIT_EVALUATE',
  STAFF_FORM_TASK_SUCCESS: 'STAFF_FORM_TASK_SUCCESS',
  EVALUATION_REPORT_TO_STAFF: 'EVALUATION_REPORT_TO_STAFF',
}

export const companyOrgIds = ['1', 'minhang', 'szzyjsxy', 'shyyjsdx', 'shhydx'];

// 首页功能列表
export const funcList = {
  // 'szzyjsxy': {
  //   funcs: [ 'personalized-psychologist', 'ai-psychological-counselor',  '24-hotline', '24-text-consult', 'training', 'resource'],
  //   hotLine: '4000081610',
  //   trainingCall: '400-001-6335'
  // },
  'szzyjsxy': {
    funcs: [ {id: 'personalized-psychologist', title: '专属服务向导'}, {id: 'ai-psychological-counselor'},  {id: '24-hotline'}, {id: '24-text-consult'}, {id: 'training'}, {id: 'resource'}],
    hotLine: '4000081610',
    trainingCall: '400-001-6335'
  },
  'minhang': {
    funcs: [ {id: 'personalized-psychologist', nickName: '专属服务向导'}, {id: 'ai-psychological-counselor'},  {id: '24-hotline'}, {id: '24-text-consult'}, {id: 'training'}, {id: 'resource'}],
    hotLine: '4000081610',
    trainingCall: '400-001-6335'
  },
  '1': {
    funcs: [ {id: 'personalized-psychologist', nickName: '专属服务向导'}, {id: 'ai-psychological-counselor'}, 
     {id: '24-hotline', title: '心理热线', subtitle: '在线时间：9:00 - 21:00', startWorTime: '9:00', endWorkTime: '21:00'}, 
     {id: '24-text-consult', title: '图文咨询', subtitle: '在线时间：9:00 - 21:00', startWorTime: '9:00', endWorkTime: '21:00'}, 
     {id: 'training'}, {id: 'resource'}, {id: 'form-test', disabledAITest: true},],
    hotLine: '4008208261',
    trainingCall: '400-001-6335'
  },
  'fafuli': {
    funcs: [   
      {
        "id": "banner",
        'hasHealthReport': false,
        "imgs": ["/config/uiConfig/fafuli_ZGS01/banner1.png", "/config/uiConfig/fafuli_ZGS01/banner2.png", "/config/uiConfig/fafuli_ZGS01/banner3.png"]
      },
      {id: 'personalized-psychologist', nickName: '预约服务'},  
      {id: 'training'}, 
      {id: 'resource', submenus: ['znlx', 'jysp']}, 
      {id: 'form-test'},
      {id: 'znlx'},
      {id: 'jysp'},
      {id: 'appointment'}
    ],
    hotLine: '4000081610',
    appointCall: '400-001-6335',
    trainingCall: '400-001-6335'
  },
  'baoshijie': {
    funcs: [ {id: 'personalized-psychologist', nickName: '专属服务向导'}, {id: 'ai-psychological-counselor'},  {id: '24-hotline'}, {id: 'resource'}, {id: 'form-test', disabledAITest: true},],
    hotLine: '4000081610',
    appointCall: '400-001-6335',
    trainingCall: '400-001-6335'
  },
  '2': {
    funcs: [ 
      {
        "id": "banner",
        "imgs": ["/config/uiConfig/fafuli_ZGS01/banner1.png", "/config/uiConfig/fafuli_ZGS01/banner2.png", "/config/uiConfig/fafuli_ZGS01/banner3.png"]
      },
      {id: 'personalized-psychologist'}, {id: 'ai-psychological-counselor'},  {id: '24-hotline'}, {id: '24-text-consult'}, {id: 'training'}, {id: 'resource'}, {id: 'form-test'}, {id: 'appointment'}],
    hotLine: '4000081610',
    appointCall: '400-001-6335',
    trainingCall: '400-001-6335'
  },
  'sh_msa': {
    funcs: [ {id: 'personalized-psychologist'}, {id: 'ai-psychological-counselor'},  {id: 'training'}, {id: 'resource'}, {id: 'form-test', disabledAITest: true}]
  },
  'huayi': {
    pageTitle: '幸福加油站--心理健康营',
    funcs: [ {id: 'personalized-psychologist'}, {id: 'ai-psychological-counselor'},  {id: '24-hotline'}, {id: '24-text-consult'}, {id: 'training'}, {id: 'resource'}, {id: 'form-test', disabledAITest: true}, {id: 'appointment'}],
    hotLine: '4000081610',
    appointCall: '400-001-6335',
  },
  default: {
    funcs: [ {id: 'personalized-psychologist'}, {id: 'ai-psychological-counselor'},  {id: '24-hotline'}, {id: '24-text-consult'}, {id: 'training'}, {id: 'resource'}, {id: 'form-test'}, {id: 'appointment'}],
    hotLine: '4000081610',
    appointCall: '400-001-6335',
    trainingCall: '400-001-6335'
  }
}

// 使用初始评估（学生版）的机构号
export const studentOrgId = ['minhang', 'szzyjsxy']