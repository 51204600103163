import MomentDiffZone from '@/utils/useMomentDiffZone';
import React from 'react';
import { parse, stringify } from 'qs';
import jwtDecode from 'jwt-decode';
import { pathInterceptorList } from '@/Constant/pathInterceptor';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [MomentDiffZone(now), MomentDiffZone(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [MomentDiffZone(beginTime), MomentDiffZone(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = MomentDiffZone(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      MomentDiffZone(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      MomentDiffZone(
        MomentDiffZone(
          `${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`,
        ).valueOf() - 1000,
      ),
    ];
  }

  const year = now.getFullYear();
  return [MomentDiffZone(`${year}-01-01 00:00:00`), MomentDiffZone(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path,
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some(
      (route) => route !== item && getRelation(route, item) === 1,
    );
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

// 获取url参数
export function getPageQuery(url) {
  if (url) {
    return parse(url.split('?')[1]);
  }
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

export function getQueryValue(queryName) {
  var reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  } else {
    return null;
  }
}

export function generateNumOptionArray(number, isNeedZero = false) {
  if (Number.isInteger(number) && number > 0) {
    const ageOptions = Array.apply(null, Array(number)).map((item, i) => {
      const result = {
        label: isNeedZero ? i : i + 1,
        value: isNeedZero ? i : i + 1
      }
      return result;
    })
    return ageOptions;
  }
  return [];
}

export function generateNumOptionBlockArray(startNumber, endNumber) {
  if (Number.isInteger(startNumber) && Number.isInteger(endNumber) && endNumber > startNumber) {
    const options = [];
    for (let i = startNumber; i <= endNumber; i++) {
      options.push({
        label: i,
        value: i
      });
    }
    return options;
  }
  return [];
}

export function desenseText(text) {
  if (text) {
    const reg = /^(\d{3})\d{4}(\d{4})$/;
    text = text.replace(reg, "$1****$2");
  }
  return text;
}

/**
 * 解析token
 * @param {*} token 
 * @returns 
 */
export function decodeToken(token) {
  if (!token) return {};
  return JSON.parse(jwtDecode(token).aud);
}

/**
 * 解析token获取当前业务线
 * @param {*} token 
 */
export function getLobFromToken(token) {
  if (!token) return ''
  const tokenInfo = decodeToken(token);
  return tokenInfo.tenantType;
}
/**
 * 解析token获取当前业务线
 * @param {*} token 
 */
export function getTenantIdFromToken(token) {
  if (!token) return ''
  const tokenInfo = decodeToken(token);
  return tokenInfo.tenantId;
}

/**
 * 登录token是否有效 firstTime为false
 * @param {} token 
 * @returns 
 */
export function checkTokenIsValid(token) {
  if (!token) return false;
  const tokenInfo = decodeToken(token);
  // const { firstTime } = tokenInfo;
  // // 如果firstTime !== false，说明用户还没有填写过个人信息，此时token是无效的
  // if (firstTime !== false) return false;
  if (tokenInfo) {
    const { expireTime } = tokenInfo;
    return MomentDiffZone().valueOf() < MomentDiffZone(expireTime).valueOf();
  }
}

/**
 * 获取角色的主题
 * @param {*} params 
 * @returns 
 */
export function getTheme(params) {
  if (params === 'therapist' || params === 'doctor' || params === 'admin') {
    return params = 'doctor';
  }
  else if (params === 'client' || params === 'patient') {
    return params = 'patient';
  }
}

/**
 * 
 * @param {*} path 路由
 * @returns 是否需要登录拦截的status
 */
export function isNeedLogin(path) {
  return pathInterceptorList.indexOf(path) < 0;
}

/**
 * 
 * @param {*} obj 原始数据
 * @param {*} path 更新路径
 * @param {*} value 更新答案
 * @returns 
 */
export function updateObjectAtPath(obj, path, value) {
  const keys = path.split('-');
  let currentObject = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      currentObject[key] = value; // 最后一个路径对应的属性设置为传入的值
    } else {
      if (!currentObject[key]) {
        currentObject[key] = {}; // 如果路径不存在，创建一个新的空对象
      }
      currentObject = currentObject[key]; // 更新当前对象为已有的对象或新创建的对象
    }
  }
  return obj
}

// 根据路径获取对应的值，如果不存在，则返回空
export function getValueAtPath(obj, path, defaultValue) {
  const keys = path.split('-');
  let value = obj;

  for (const key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key] || ''; // 获取当前路径对应的属性值
    } else {
      return defaultValue || ''; // 路径不存在，返回空
    }
  }
  return value;
}

/**
 * 
 * @param {*} auth 配置的权限列表
 * @param {*} functionList 功能列表
 * @returns 在权限列表中的功能清单
 */
export function checkFuncAuth(auth = [], functionList = []) {
  return functionList.reduce((pre, cur) => {
    const { id } = cur;
    const match = auth.find((aItem) => aItem.id === id);
    if (!match) {
      return pre;
    }
    // // 
    // if (match.title) {
    //   pre.push({ ...cur, nickName: match.title });
    // } else {
    //   pre.push(cur);
    // }
    pre.push({ ...cur, ...match });
    return pre;
  }, []);
}

// 获取当前手机型号
export function _getPhoneModel() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('iphone') !== -1) {
    return 'iphone';
  } else if (ua.indexOf('android') !== -1) {
    return 'android';
  } else {
    return 'other';
  }
}

// 正则校验手机号
export function checkTelephone(telephoneNum){
  var reg=/^1[3456789]\d{9}$/g;
  if(telephoneNum!=='' && reg.test(telephoneNum)){
    return true;
  }else{
    return false;
  }
}