// 管理员端查看咨询师详情的页面
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { RightOutline, AddOutline } from "antd-mobile-icons";

import './index.less';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import TitleBar from '@/components/TitleBar';
import StaffInfoComponent from "@/components/UserInfo/StaffInfo";
import Comment from '@/components/UserInfo/ConsultantInfo/comment';
import { getConsultantByTime, adminGetEvaluationList, adminGetEvaluationConsultantTotalScore } from '@/services/api';

function AdminConsultantInfo(props) {
  const navigate = useNavigate();
  const consultantInfo = _.get(props, "consultantInfo", {});
  const role = _.get(props, "role", 'doctor');
  const [consultantFormList, setConsultantFormList] = useState();
  const [evaluationList, setEvaluationList] = useState();
  const [consultantTotalScore, setConsultantTotalScore] = useState();

  // 按照时间排序获取咨询记录
  const _getConsultantByTime = useCallback(async () => {
    let params = {
      consultantId: consultantInfo.consultantId,
    };
    const res = await getConsultantByTime(params);
    setConsultantFormList(res?.result?.data);
  }, []);

  // 查询咨询评价
  const _adminGetEvaluationList = useCallback(async () => {
    let params = {
      consultantId: consultantInfo.consultantId,
    };
    const res = await adminGetEvaluationList(params);
    setEvaluationList(res?.result?.data);
  }, []);

  // 查询总体的咨询评价
  const _adminGetEvaluationConsultantTotalScore = useCallback(async () => {
    // let consultantId = consultantInfo.consultantId;
    let params = {
      consultantId: consultantInfo.consultantId,
    };
    const res = await adminGetEvaluationConsultantTotalScore(params);
    setConsultantTotalScore(res?.result);
  }, [])

  useEffect(() => {
    _getConsultantByTime();
  }, [_getConsultantByTime]);

  useEffect(() => {
    _adminGetEvaluationList();
  }, [_adminGetEvaluationList]);

  useEffect(() => {
    _adminGetEvaluationConsultantTotalScore();
  }, [_adminGetEvaluationConsultantTotalScore]);

  /* 
  @params 
    title
    data
    keyname
    renderSectionContent
    navigateUrl: 查看全部的点击事件的跳转路由
*/
  function renderFormItem(renderSectionContent = function () { },) {
    return (
      <div className="form-item">
        {renderSectionContent()}
      </div>
    );
  }

  function renderGender(gender) {
    switch (gender) {
      case "MALE":
        return "男";
      case "FEMALE":
        return "女";
      default:
        return "-";
    }
  }

  // 用户基本信息
  function renderBaseInfo() {

    return (
      <div className="consultant-info">
        <div
          className="consultant-head"
          style={{
            borderColor: role == 'doctor' ? '#FFB92E' : '#2979FF'
          }}
        >
          <img src={_.get(consultantInfo, "avatarUrl", "")} alt="" />
        </div>
        <div className="consultant-data">
          <div className="consultant-name">{_.get(consultantInfo, "name", "")}</div>
          <div className="consultant-other-info">
            {renderGender(_.get(consultantInfo, "gender", ""))}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {_.get(consultantInfo, "age", "")}
          </div>
        </div>
      </div>
    )
  }

  // 排班日历
  function renderArrangeCalendar() {

    return (
      <div
        className="form-item-title"
        onClick={() => {
          navigate('/admin/arrange-calendar', { state: { consultantInfo } })
        }}
      >
        <div className="form-item-text">排班日历</div>
        <div className={"item-icon " + role + "-item-icon"}>
          <RightOutline fontSize={24} />
        </div>
      </div>
    )
  }

  // 咨询记录表
  function renderConsultList(data) {
    let showData;
    data && data.length > 3
      ? showData = data.splice(0, 3)
      : showData = data || []

    return (
      <>
        <div className="form-item-title">
          <div className="form-item-text">咨询记录表</div>
          <div
            className={"show-all " + role + "-show-all"}
            onClick={() => {
              navigate('/admin/consultation-record-form', { state: { consultantInfo } });
            }}
          >
            查看全部
          </div>
        </div>
        {
          showData.map((item, index) => {
            let dataDate = MomentDiffZone(_.get(item, "time", "")).format(
              "YYYY年MM月DD日"
            );
            let dataName = _.get(item, 'keyName', "");
            dataDate = MomentDiffZone(_.get(item, "startTime", "")).format("YYYY年MM月DD日");
            dataName = MomentDiffZone(_.get(item, "startTime", "")).format("HH:mm")
            return (
              <div
                className="data-item"
                key={index}
                onClick={() => {
                  // 点击单个的咨询记录表的事件
                  console.log('click');
                }}
              >
                <div className="item-section">
                  <div className="item-info">
                    <span className="span-time">{dataDate}</span>
                    <span className="span-title">{dataName}</span>
                  </div>
                  <div className={"item-icon " + role + "-item-icon"}>
                    <RightOutline fontSize={15} />
                  </div>
                </div>
              </div>
            );
          })
        }
      </>
    )
  }

  // 咨询评价
  function renderComment() {
    return (
      <>
        <div className="form-item-title">
          <div className="form-item-text">咨询评价</div>
          <div
            className={"show-all " + role + "-show-all"}
            onClick={() => {
              navigate('/admin/consultant-comment', { state: { consultantInfo, evaluationList, consultantTotalScore } });
            }}
          >
            查看全部
          </div>
        </div>
        {
          evaluationList?.length > 0
            ? (
              <div className='consulting-comment'>
                <div>总体满意度：{consultantTotalScore?.satisfactionRoundStr || '暂无评分'}</div>
                <div>再次使用率：{consultantTotalScore?.reuseRateRoundStr || '暂无评分'}</div>
                <div>对来访者理解度：{consultantTotalScore?.understandingLevelRoundStr || '暂无评分'}</div>
                <div>能帮助解决问题：{consultantTotalScore?.solveProblemRoundStr || '暂无评分'}</div>
              </div>
            )
            : null
        }
      </>
    )
  }

  // 查看咨询师聊天记录
  function renderConsultantChatRecord() {

    return (
      <div
        className="form-item-title"
        onClick={() => {
          navigate('/admin/chat-record', { state: { staffInfo: consultantInfo, role: role } })
        }}
      >
        <div className="form-item-text">聊天记录</div>
        <div className={"item-icon " + role + "-item-icon"}>
          <RightOutline fontSize={24} />
        </div>
      </div>
    )
  }

  return (
    <div className='detail-info-content'>
      {
        renderFormItem(
          () => renderBaseInfo(),
        )
      }
      {
        renderFormItem(
          () => renderArrangeCalendar()
        )
      }
      {
        renderFormItem(
          () => renderConsultList(consultantFormList),
        )
      }
      {
        renderFormItem(
          () => renderComment(),
        )
      }
      {
        renderFormItem(
          () => renderConsultantChatRecord(),
        )
      }
    </div>
  )
}

export default AdminConsultantInfo;
