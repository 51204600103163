import React from 'react';
import IframeComponent from '@/components/Iframe';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

function StartConsultForm(props) {
  const location = useLocation();
  const url = _.get(location, ['state', 'url']);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     _getSuggestConsultants(timer);
  //   }, 3000);
  // }, []);

  // async function _getSuggestConsultants(timer) {
  //   let res = await getSuggestConsultants();
  //   const suggestConsultants = _.get(res, ['result'], []);
  //   console.log(suggestConsultants);
  //   if (suggestConsultants.length > 0) {
  //     clearInterval(timer);
  //     navigate('/client/matching', { state: { suggestConsultants } });
  //   }
  // }

  return (
    <IframeComponent title="添加表" src={url}></IframeComponent>
  )
}
export default StartConsultForm;