import React from 'react';
import { Badge } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

import './index.less';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import { checkFuncAuth } from '@/utils/utils';
import makingCall from '@/utils/makingCall';
import ConsultItem from '@/components/ConsultItem';
import phoneCall from '@/assets/icon/clientHome/v2/phone_call.svg';
import textConsult from '@/assets/icon/clientHome/v2/text.svg';

// 首页预约模块
function YuyueComponent(props) {
  const { funcAuthList, showConfirmDialog, appointCall } = props;
  const { handleAddEventDotClick } = props;
  const navigate = useNavigate();
  const menus = [{ id: 'appointment' }];
  const consultant = {
    avatarUrl: 'https://siuvo-image.oss-cn-hangzhou.aliyuncs.com/eap/uat/2022-4/e02cf1060781e99c926f5715ce664337.jpg',
    desc: '二级心理咨询师',
    nickName: '徐老师',
    handleClick: () => {
      showConfirmDialog(
        '预约咨询师', '即将拨打客服电话，预约咨询师。',
        () => {
          makingCall(appointCall);
          // 打点
          handleAddEventDotClick({
            name: 'visit:consult/appointment',
            props: {
              eventName: '咨询：访问预约（客服）'
            }
          });
        })
    }
  }
  const checkedMenus = checkFuncAuth(funcAuthList, menus);
  if (checkedMenus.length === 0) {
    return null
  }
  const { avatarUrl, nickName, handleClick, desc, unreadMessageCount } = consultant;
  return (
    <>
      <div className='appoint-consult-title'>预约咨询</div>
      <div className='appoint-sonsult-recommend-text'>
        <div className='recommend-desc'>我们根据您的需求为您推荐：</div>
        <div className='recommend-more'>查看其他推荐</div>
      </div>
      <ConsultItem
        avatarUrl={avatarUrl}
        title={nickName}
        desc={desc}
        renderRight={() => <Badge content={unreadMessageCount}></Badge>}
        handleClick={handleClick}
      />
    </>
  )
}

export default WithTrackingForMedia(YuyueComponent);