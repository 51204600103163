// 用于展示咨询评价页面的所有评价
import React from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

function ConsultingComment(props) {
  const location = useLocation();
  const { consultantInfo, evaluationList, consultantTotalScore } = _.get(location, ['state'], {});

  const commentList = [
    {
      satisfaction: 10,
      reuseRate: 10,
      understandingLevel: 10,
      solveProblem: 10,
    },
    {
      satisfaction: 10,
      reuseRate: 10,
      understandingLevel: 10,
      solveProblem: 10,
    },
    {
      satisfaction: 10,
      reuseRate: 10,
      understandingLevel: 10,
      solveProblem: 10,
    },
  ]

  return (
    <>
      <TitleBar title='咨询评价' isShowBack={true} />
      <div className='consulting-comment-hole-content'>
        <div className='consulting-comment-total'>
          <div>总体满意度：{consultantTotalScore?.satisfactionRoundStr || '暂无评分'}</div>
          <div>再次使用率：{consultantTotalScore?.reuseRateRoundStr || '暂无评分'}</div>
          <div>对来访者理解度：{consultantTotalScore?.understandingLevelRoundStr || '暂无评分'}</div>
          <div>能帮助解决问题：{consultantTotalScore?.solveProblemRoundStr || '暂无评分'}</div>
        </div>
        <div className='consulting-comment-content'>
          {
            evaluationList && evaluationList.map((item, index) => {
              return (
                <div className='consulting-comment-item' key={index}>
                  <div>总体满意度：{item.evaluationInfo.count}</div>
                  <div>再次使用率：{item.evaluationInfo.reuseRate}</div>
                  <div>对来访者理解度：{item.evaluationInfo.understandingLevel}</div>
                  <div>能帮助解决问题：{item.evaluationInfo.solveProblem}</div>
                  <div>评价：{item.evaluationInfo.text || '暂无评价'}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default ConsultingComment