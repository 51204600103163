import React, { useEffect } from "react";
import './index.less';
import {  Modal, Space, Tag, } from 'antd-mobile'
import html2canvas from "html2canvas";

function BussinessCard(props) {
  const { modalVisible, doctorInfo, setModalViseble } = props;

  function savePicture() {
    let htmlDom = document.querySelector('#bussiness-card');
    html2canvas(htmlDom, {
      useCORS: true
    }).then((canvas) => {
      let type = 'png';
      // 打印后的imgData','后的是base64数据
      let imgData = canvas.toDataURL(type);
        
      function base64ToBlob(img) {
        
        let imgBase64 = imgData.split(';base64,')[1];
        let contentType = imgData.split(';base64,')[0].split(':')[1];
        // 解码base64
        let raw = window.atob(imgBase64);
        let rawLength = raw.length;
        // 转为8 位无符号整型数组
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
          // f返回i位置的字符Unicode编码
          uInt8Array[i] = raw.charCodeAt(i)
        }
        return new Blob([uInt8Array], { type: contentType });
      }

      // 下载后的文件名
      let filename = '个人名片' + (new Date()).getTime() + '.' + type;
      const blob = base64ToBlob(imgData);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);
      let aLink = document.createElement("a");
      aLink.download = filename;
      aLink.href = URL.createObjectURL(blob);
      aLink.target = '_blank';
      aLink.click();
    })
  }

  function renderCardContent() {
    return (
      <div className="card-content" id="bussiness-card">
        <div className="personal-card">个人名片</div>
        <div className="card-name-content">
          <div>
            <img src={`${doctorInfo?.avatarUrl}?prevent_cache`}  crossOrigin="anonymous" style={{height: '100px'}} /> 
          </div>
          <div className="card-name">{doctorInfo?.nickName}</div>
        </div>
        <div className="personal-introduction">个人介绍</div>
        <div className="personal-introduction-content">
          {doctorInfo?.consultantInformation?.introduce}
        </div>
        <div className="skilled-field">擅长领域</div>
        <div>
          <Space wrap>
          {
            doctorInfo.consultantInformation?.tags.map((element, index) => {
              return (
                <Tag key={index}>{element}</Tag>
              )
            })
          }
          </Space>
        </div>
        {/* <div>扫码</div> */}
      </div>
    )
  }

  return (
      <Modal
        className="bussiness-card-modal"
        visible={modalVisible}
        closeAction={() => {
          setModalViseble(false)
        }}
        onClose={() => {
          setModalViseble(false)
        }}
        actions={[
          {
            key: 'confirm',
            text: '确认下载',
          },
        ]}
        onAction={() => {
          savePicture();
        }}
        closeOnMaskClick={true}
        showCloseButton={true}
        content={renderCardContent()}
        bodyStyle={{
          width: '110%',
        }}
      />
  )
}

export default BussinessCard;