import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './assets/commonCss/common.less';
import './App.css';
import './Common.css';
import routers from "./routers";
import Listener from './utils/listener';
import NetworkConnectedStatus from './utils/networkConnect';
import { getRoleFromLocation } from '@/utils/commonUtils';
import GlobalDialog from '@/components/GlobalDialog';
import NotFoundPage from '@/components/404Page';
import RouteGuard from '@/components/RouteGuard';

function App() {
  const role = getRoleFromLocation() || 'client';
  function renderRouter(router, index) {
    if (router.children) {
      return (
        <Route
          path={router.path}
          element={
            router.needInterceptor
              ? (
                <RouteGuard role={role} >
                  <Suspense fallback={<div>Loading...</div>}>
                    {router.component}
                  </Suspense>
                </RouteGuard>
              )
              : (
                <Suspense fallback={<div>Loading...</div>}>
                  {router.component}
                </Suspense>
              )
          }
          key={index}
        >
          {
            router.children.map(item => renderRouter(item))
          }
        </Route>
      )
    }
    return (
      <Route
        key={router.path}
        path={router.path}
        element={
          router.needInterceptor
            ? (
              <RouteGuard role={role} >
                <Suspense fallback={<div>Loading...</div>}>
                  {router.component}
                </Suspense>
              </RouteGuard>
            )
            : (
              <Suspense fallback={<div>Loading...</div>}>
                {router.component}
              </Suspense>
            )
        }
        index={router.index === 'true'}
      />
    )
  }

  return <>
    <Router>
      <Routes>
        {
          routers.map((router, index) => renderRouter(router, index))
        }
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Listener />
      <NetworkConnectedStatus />
      <GlobalDialog />
    </Router>
  </>
}

export default App;
