const _ = require('lodash');

function getValueScoreAndId(val) {
  if (typeof val === 'string' && val.includes('|')) {
    const [valueId, valueScore] = val.split('|');
    return { valueId, valueScore }; //value format is <valueId>|<valueScore>, valueId is used in askedIfExpression, valueScore is used in scoring
  } else return { valueId: val, valueScore: val };
}

function _createScoreModel(schema, path, formModel) {
  // console.log('FORM MODEL', path, formModel);
  const { type, properties, enumInfo = {} } = schema;
  if (type === 'string') {
    // console.log("formModel: ", formModel, "path: ", path);
    const answer = _.get(formModel, path);
    const enumInfoScore = _.get(enumInfo, [answer, 'score']);
    if (enumInfoScore != null) return enumInfoScore;
    // console.log("factors enumInfo: ", enumInfo, "key: ", [answer, "score"]);
    const { valueScore } = getValueScoreAndId(answer || '0');

    return parseFloat(valueScore); //need to figure out what default value is best here. 0 can potentially cause problems
  } else if (type === 'number') {
    const { valueScore } = getValueScoreAndId(_.get(formModel, path, '0'));
    return parseFloat(valueScore) || 0; // FIXME maybe will have some problem
    // return _.get(formModel, path); //return the number if it exists, undefined if it doesn't
  } else if (type === 'array') {
    return _.get(formModel, path, []).map((key) => {
      const { valueScore } = getValueScoreAndId(key);
      return valueScore === 'noneOfTheAbove' ? 0 : parseFloat(valueScore);
    }); //return the array if it exists, undefined if it doesn't
  } else {
    //type === object
    return Object.keys(properties).reduce(
      (model, propertyKey) =>
        Object.assign(model, {
          [propertyKey]: _createScoreModel(
            properties[propertyKey],
            path.concat(propertyKey),
            formModel
          ),
        }),
      {}
    );
  }
}

export function calculateFactors(
  model,
  factorData = {},
  form,
  factorExpressions,
  factorExpressionsOrder
) {
  let calculations = {};
  let calculationsOrder = null;

  if (factorExpressions) {
    calculations = factorExpressions;
    calculationsOrder = factorExpressionsOrder || Object.keys(calculations);
  } else {
    calculations = form && form.factorExpressions;
    calculationsOrder =
      form.factorExpressionsOrder || Object.keys(calculations);
  }

  // console.log("calculationsOrder: ", calculationsOrder, calculations);

  if (calculations) {
    let factors = factorData || {};
    let factorKeys = calculationsOrder;
    let modelCopy;
    try {
      modelCopy = _createScoreModel(form.schema, [], model);
      modelCopy = {
        ...model,
        ...modelCopy,
      };
    } catch (e) {
      console.error('Error When createScoreModel:');
      console.error(e);
      throw new Error(`Error When createScoreModel:\n ${e}`);
    }

    let paraKeys = Object.keys(modelCopy);
    let paras = paraKeys.map((key) => {
      return modelCopy[key];
    });

    factorKeys.forEach((factorKey) => {
      let expressionString = calculations[factorKey];
      // console.log('expressionString ==>', expressionString);
      try {
        let evalFunction = new Function(
          'factors',
          'model',
          ...paraKeys,
          `return (${expressionString})`
        );
        let result = evalFunction(factors, model, ...paras);
        // console.log("Result:", result)
        if (isNaN(result)) {
          console.log(
            `Result of factorExpression '${expressionString}' is NaN: ${result}`
          );
        }
        factors[factorKey] = result;
      } catch (e) {
        console.error(`Error When Eval: \`${expressionString}\``);
        console.error(e);
        throw new Error(`Error When Eval: \`${expressionString}\` \n ${e}`);
      }
    });

    // console.log("RESULT", factors)

    return factors;
  } else {
    return;
  }
}
