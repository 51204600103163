import React from 'react';
import './index.less';
import { TabBar } from 'antd-mobile';
import {useNavigate, useLocation, Outlet} from 'react-router-dom';
import {Dialog} from 'antd-mobile';
import serviceQrcode from '@/assets/images/service-qr-code.jpg';

function MainLayout (props) {
    const {tabs, funcs} = props;
    const navigate = useNavigate();
    const location = useLocation();

    // 保存图片二维码
    function savePicture () {
      const link = document.createElement('a');
      link.href = serviceQrcode; 
      link.download = '客服二维码.png'; // 保存的文件名
      link.click();
    }

    function showDialog () {
      const content = <div className='main-layout-dialog'>
        <div className='title'>您未授权使用该功能，请扫码联系客服。</div>
        <img className='qrcode' alt='' src={serviceQrcode}/>
      </div>
       Dialog.confirm({
        title: '提示',
        content: content,
        confirmText: '保存',
        onConfirm: () => {
          savePicture()
        }
      })
    }

    // 菜单切换，用户端需要校验，管理员和咨询师不需要，判断funcs(需要校验的菜单list)是否存在,
    function onTabChange (key) {
      const activeItem = tabs.filter(item => item.key === key)[0];
      if (funcs) {
      // 如果有参数获取参数，用于导航跳转
      const {id = ''} = activeItem;
      // 功能列表中没有则弹框提示
      const checkedFun = funcs.filter(item => item.id === id)
      if (id && checkedFun.length < 1) {
          showDialog()
          return
        }
      }
      const navParams = activeItem.navParams || {};
      navigate(key, {state: {...navParams}})
    }

    const Bottom = () => {
       
        return (
          <>
            {
              <TabBar className="main-layout-bottom" activeKey={location.pathname} onChange={key => {onTabChange(key)}}>
                {tabs.map(item => (
                  <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
              </TabBar>
            }
          </>
        )
      }

    return (
        <div className="main-layout-page">
          <div className="main-layout-content">
            <Outlet/>
          </div>
          <Bottom />

        </div>
      )
}
export default MainLayout;