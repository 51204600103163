// 改页面用于展示正念练习的详情页面
import React, { useEffect } from 'react';
import _ from 'lodash';
import wx from 'weixin-js-sdk';
import { useParams } from 'react-router-dom';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import AudioPlayerComponent from '@/components/AudioPlayer';
import { positiveThinkingList } from '@/Constant/positiveThinking';

function PositiveThinkingAudio(props) {
  const { handleAddEventDotClick } = props;
  const { id } = useParams();
  const { title, audio, background, cover, subhead, description, sharedDesc } =
    _.find(positiveThinkingList, { id: Number(id) });

  // 页面进入和销毁时的打点
  useEffect(() => {
    handleAddEventDotClick && handleAddEventDotClick({
      name: `enter:positive-thinking/${id}`,
      props: {
        eventName: `进入正念练习${id}音频`,
      }
    });

    return () => {
      handleAddEventDotClick && handleAddEventDotClick({
        name: `leave:positive-thinking/${id}`,
        props: {
          eventName: `离开正念练习${id}音频`,
        }
      });
    }
  }, [handleAddEventDotClick, id]);

  useEffect(() => {
    wx.ready(function () {
      console.log('wx.ready');
      wx.updateAppMessageShareData({
        title: title,
        desc: sharedDesc,
        link: window.location.href.split('#')[0] + `?t=${new Date().getTime()}`,
        imgUrl: cover,
        success: function () {
          console.log('分享成功');
        },
      });
    });
  }, [cover, sharedDesc, title]);

  return (
    <>
      <TitleBar title="正念练习" isShowBack={true} />
      <AudioPlayerComponent title={title} audioUrl={audio} background={background} subhead={subhead} description={description} />
    </>
  )
}

export default WithTrackingForMedia(PositiveThinkingAudio);