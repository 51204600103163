ExVideoPlay

// 正念练习的列表
import React from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import VideoPlayer from '@/components/VideoPlayer';
import { useLocation, useNavigate } from 'react-router-dom';


function ExVideoPlay(props) {
	const location = useLocation();
	const videoInfo = _.get(location, ['state', 'videoInfo'], {});

	function renderVideo() {
		return(
			<VideoPlayer className="video-player" src={videoInfo.videoUrl}/>
		)
	}

  return (
    <>
    <TitleBar title={videoInfo.title} isShowBack={true} />
		<div className='video-content'>
			{
				renderVideo()
			}
		</div>
    </>
  )
}

export default ExVideoPlay;