import React from 'react';
import './index.less';
import { checkFuncAuth } from '@/utils/utils';
import { useNavigate } from 'react-router-dom';
import ConsultItem from '@/components/ConsultItem';
import siuvoRobot from '@/assets/images/patient-home/robot.png';
import { Badge } from 'antd-mobile';

// 首页陪伴模块
function PeibanComponent(props) {
  const { funcAuthList, title = '陪伴' } = props;
  const navigate = useNavigate();
  const menus = [
    {
      id: 'personalized-psychologist',
      nickName: '专属服务向导',
      authId: '45666668888',
      consultantId: '45666668888',
      avatarUrl: require('@/assets/icon/siuvo_avatar.svg').default,
      consultantInformation: {
        introduce: '心理成长计划的任何问题都可以来问我'
      },
      unreadMessageCount: '',
      handleClick: () => {
        navigate('/client/dialogue/45666668888', { state: { eventDotKey: 'visit:accompany/personalized-psychologist' } });
      }
    },
    {
      id: 'ai-psychological-counselor',
      avatarUrl: siuvoRobot,
      unreadMessageCount: '',
      consultantInformation: {
        introduce: '24小时贴心陪伴，心理成长'
      },
      nickName: 'AI心理辅导师',
      handleClick: () => {
        navigate('/client/recommend-chat', { state: { eventDotKey: 'visit:accompany/ai-psychological-counselor' } })
      }
    }
  ];
  const checkedMenus = checkFuncAuth(funcAuthList, menus);
  if (checkedMenus.length === 0) {
    return null
  }
  return (
    <div>
      {title && <div className='func-title'>{title}</div>}
      {
        checkedMenus.map((item, index) => {
          const { avatarUrl, nickName, handleClick, unreadMessageCount } = item;
          const desc = _.get(item, ['consultantInformation', 'introduce'], '');
          return (
            <div key={index}>
              <ConsultItem
                avatarUrl={avatarUrl}
                title={nickName}
                desc={desc}
                renderRight={() => <Badge content={unreadMessageCount}></Badge>}
                handleClick={handleClick}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default PeibanComponent;