import React, { useState, useRef, useEffect } from "react";
import './index.less';
import TitleBar from '@/components/TitleBar';
import { Form, Button, Toast, Input, Radio, Space, Collapse, DatePicker, TextArea } from 'antd-mobile';
import moment from "moment";
import { createConsultationRecord, getConsultationRecordDetail, updateConsultationRecord } from '@/services/api';
import ListView from '@/components/ListView';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import healthRecordQuestionary from '@/Constant/healthRecordQuestionary';
import CustomDatePicker from '@/components/CustomDatePicker';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { updateObjectAtPath, getValueAtPath } from '@/utils/utils';
import { useSelector } from 'react-redux';

// 存储必填的key
const requiredKeys = new Set();
const role = 'doctor';
// 咨询记录详情（可查看或者填写）
function RecordDetail(props) {

  const location = useLocation();
  const navigate = useNavigate();
  // 获取咨询师名字
  const baseInfo = useSelector(state => state.baseInfo[role].roleInfo || {});

  const { recordId, recordStatus, staffId = '' } = _.get(location, ['state'], {});
  // 答案,默认属性咨询记录状态
  // 除基础信息外，其他内容放在consultationInfo中
  const [model, setModel] = useState({})
  // 日期组件样式渲染
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const selectedDateAttrIdRef = useRef();
  const selectedDatePrecisionRef = useRef(); //精度
  const [collapseIndex, setCollapseIndex] = useState(0); //手风琴类别ref

  // 是否自动保存(误触返回，自动提交暂存，网页直接关闭无法拦截)
  const isAutoSave = useRef(true);
  const dataRef = useRef(model); // 使用 useRef 来保存数据的引用


  // 是否只读
  const isReadOnly = recordStatus === 'SUCCESS';

  // 进入页面查询详情，如果有答案回显
  useEffect(() => {
    async function _getConsultationRecordDetail(recordId) {
      const res = await getConsultationRecordDetail(recordId);
      if (res.errorCode) {
        Toast.show({
          content: res.error
        })
        return;
      }
      const result = _.get(res, ['result'], {})
      setModel(result);
    }

    if (recordId) {
      // 新创建的没有id，而且不用查询
      _getConsultationRecordDetail(recordId)
    } else {
      if (baseInfo.name) {
        // 自动填写咨询师姓名
        const tempValue = updateObjectAtPath({ ...model }, 'consultant-name', baseInfo.name);
        setModel(tempValue)
      }
    }
    return () => {
      if (isAutoSave.current && recordStatus !== 'SUCCESS') {
        onsubmit('START', dataRef.current, true)
      }
    }
  }, [])

  // 更新数据
  useEffect(() => {
    dataRef.current = model;
  }, [model])

  // 校验必填项是否都已填写完成
  function checkModelIsInvalid() {
    // set 转为array
    const requiredKeysList = Array.from(requiredKeys);
    // 根据key判断是否有值
    const res = requiredKeysList.some(key => {
      const value = getValueAtPath(model, key);
      return !value;
    })
    return res
  }

  async function onsubmit(status, model, autoBack) {
    if (status === 'SUCCESS') {
      // 提交时检验必填是否都填了
      if (checkModelIsInvalid()) {
        Toast.show('请把必填项填写完整')
        return;
      }
    }
    const params = {
      staffId,
      ...model,
      recordStatus: status
    }
    // 姓名和手机号放在参数最外层
    if (model.staffItem?.name) {
      params.name = model.staffItem.name
    }
    if (model.staffItem?.staffTelephone) {
      params.staffTelephone = model.staffItem.staffTelephone
    }
    let res;
    if (recordId) {
      params['recordId'] = recordId;
      res = await updateConsultationRecord(params);
    } else {
      res = await createConsultationRecord(params);
    }
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    isAutoSave.current = false;
    if (!autoBack) {
      navigate(-1)
    }
  }

  // 渲染section title
  function renderSectionTitle(title) {
    return (
      <div className="section-title">{title}</div>
    )
  }
  // 渲染section 备注
  function renderNotes(notes) {
    if (notes) {
      return (
        <div className="notes">
          {notes.map((item, index) => {
            return (
              <div className="note-item">{item}</div>
            )
          })}
        </div>
      )
    }
    return null;
  }

  // 保存model中的某个属性
  // attrs可能是多级路径
  function saveAttrForModel(attrs, value) {
    const tempValue = updateObjectAtPath({ ...model }, attrs, value);
    setModel(tempValue)
  }

  function renderQUestionTitle(question, required) {
    return (
      <div className="question-title">
        {
          required && <span style={{ color: '#f23030' }}>*</span>
        }
        {question}
      </div>
    )
  }

  // 文本框类型
  function renderInput(id, question, required) {
    return (
      <div key={id}>
        {renderQUestionTitle(question, required)}
        <Input placeholder='' value={getValueAtPath(model, id)} onChange={(val) => { saveAttrForModel(id, val) }} readOnly={isReadOnly} />
      </div>
    )
  }
  // 文本扩展区
  function renderTextArea(id, question, required) {
    return (
      <div key={id}>
        {renderQUestionTitle(question, required)}
        {
          recordStatus === 'START' ?
            <TextArea placeholder='' value={getValueAtPath(model, id)} onChange={(val) => { saveAttrForModel(id, val) }} readOnly={isReadOnly} /> :
            <div className="text-area-div">
              {getValueAtPath(model, id)}
            </div>
        }
      </div>
    )
  }
  // 文本框类型
  function renderText(id, question, required) {
    return (
      <div key={id}>
        {renderQUestionTitle(question, required)}
        <Input placeholder='' value={getValueAtPath(model, id)} readOnly={true} />
      </div>
    )
  }
  // 时长计算
  function renderTimeCalculate(id, question, timeKeys = []) {
    let timeDurationValue = timeKeys.reduce((pre, cur) => {
      const v = getValueAtPath(model, cur);
      if (!v) {
        return 0
      }
      return ((v - pre));
    }, 0);
    timeDurationValue = timeDurationValue / (60 * 1000)
    return (
      <div key={id}>
        <div className="question-title">{question}</div>
        <Input placeholder='' value={timeDurationValue} readOnly />
      </div>
    )
  }

  // 单选题
  function renderRadio(parentKey, { id, question, options, otherQList = [] }, required) {
    // 是否有其他选项（渲染其他输入框）
    const hasOther = otherQList.length > 0;
    return (
      <div key={id} >
        {renderQUestionTitle(question, required)}
        <Radio.Group
          disabled={isReadOnly}
          value={getValueAtPath(model, parentKey)}
          onChange={val => {
            saveAttrForModel(parentKey, val)
          }}
        >
          <Space wrap style={{ padding: '0 20px' }}>
            {
              options.map((item, index) => {
                const { label, value } = item;
                return <Radio value={value} key={index}>{label}</Radio>
              })
            }
          </Space>
        </Radio.Group>
        {
          hasOther && getValueAtPath(model, parentKey) === 'other' && otherQList.map((item, index) => {
            const keys = parentKey.split('-');
            const len = keys.length;
            const combinedKey = keys.slice(0, len - 1).join('-');
            return renderQuestion(item, combinedKey)
          })
        }
      </div>
    )
  }

  // 渲染日期类型
  function renderDate(parentKey, question, precision = 'day', required) {
    const inputValue = getValueAtPath(model, parentKey);
    let inputFormat;
    if (inputValue) {
      if (precision === 'minute') {
        inputFormat = moment(inputValue).format('YYYY-MM-DD HH:mm')
      }
      if (precision === 'day') {
        inputFormat = moment(inputValue).format('YYYY-MM-DD')
      }
    }

    return (
      <div key={parentKey} onClick={() => {
        if (!isReadOnly) {
          selectedDateAttrIdRef.current = parentKey;
          selectedDatePrecisionRef.current = precision;
          setDatePickerVisible(true)

        }
      }}>
        {renderQUestionTitle(question, required)}
        <Input placeholder='' readOnly value={inputFormat} />
      </div>
    )
  }

  // 渲染手风琴内容区
  // parentKey父级id，记录选择的是哪个类型
  function renderCollapseContent(options) {
    return (
      <Space wrap>
        {
          options.map((item, index) => {
            // 组装value--拼接父级类型
            return <Radio value={item} key={index}>{item}</Radio>
          })
        }
      </Space>
    )
  }


  // 渲染手风琴
  function renderCollapse(parentKey, panels = []) {
    // 手风琴存值时，根据类别，作为存储的key
    const combinedKey = parentKey + '-' + panels[collapseIndex]?.question;
    return (
      <Radio.Group disabled={isReadOnly} value={getValueAtPath(model, combinedKey)} onChange={val => {
        // 手风琴是单选，不同类别选择是，替换之前的
        const value = {
          [panels[collapseIndex]?.question]: val
        }
        saveAttrForModel(parentKey, value)
      }}>
        <Collapse accordion key={parentKey} onChange={(activeKey) => {
          setCollapseIndex(activeKey);
        }}>
          {
            panels.map((item, index) => {
              return (
                <Collapse.Panel key={index} title={item.question}>
                  {renderCollapseContent(item.options)}
                </Collapse.Panel>
              )
            })
          }
        </Collapse>
      </Radio.Group>
    )
  }

  // 组合题目，按块区分
  function renderCombineQuestion(parentKey, question, subQuestion) {
    return (
      <div key={parentKey} className="combine-content">
        <div className="combine-title">{question}</div>
        {
          subQuestion.map((item, index) => {
            return renderQuestion(item, parentKey)
          })
        }
      </div>
    )
  }

  // 根据类型器渲染问题
  // 仅有组合问题，parentKey为上级问题的类型-
  function renderQuestion(item, parentKey = '') {
    const { id, question, type, options, panels, subQuestion, precision, timeKeys, required } = item;
    const combinedKey = parentKey ? parentKey + '-' + id : id;
    // 必传存储对应的key
    if (required) {
      requiredKeys.add(combinedKey);
    }
    switch (type) {
      case 'input':
        return renderInput(combinedKey, question, required);
      case 'textArea':
        return renderTextArea(combinedKey, question, required);
      case 'text':
        return renderText(combinedKey, question, required);
      case 'radio':
        return renderRadio(combinedKey, item, required);
      case 'date':
        return renderDate(combinedKey, question, precision, required);
      case 'collapse':
        return renderCollapse(combinedKey, panels);
      case 'parentQuestion':
        return renderCombineQuestion(combinedKey, question, subQuestion);
      case 'timeCalculate':
        return renderTimeCalculate(combinedKey, question, timeKeys);
      default:
        return null;
    }
  }

  // 渲染问题
  function renderQuestionList(qList, modelKey) {
    return (
      <>
        {
          qList.map((item, index) => renderQuestion(item, modelKey))
        }
      </>
    )
  }

  function renderQuestionContent() {
    const { sections } = healthRecordQuestionary;
    return (
      <>
        {
          sections.map((item, index) => {
            // 章节id，标题，该section下的问题列表，备注
            const { sectionId, modelKey, sectionTitle, qList, notes } = item;
            return (
              <div key={sectionId} className="record-section">
                {sectionTitle && renderSectionTitle(sectionTitle)}
                {renderQuestionList(qList, modelKey)}
                {renderNotes(notes)}
              </div>
            )
          })
        }
      </>
    )
  }

  // 渲染提交按钮
  function renderSubmitBtn() {
    if (recordStatus === 'START') {
      return (
        <div className="submit-btn-content">
          <Button color="primary" className="temp-save-btn" onClick={() => onsubmit('START', dataRef.current)}>暂存</Button>
          <Button className="submit-btn" onClick={() => onsubmit('SUCCESS', dataRef.current)}>提交</Button>
        </div>
      )
    }
    return null;
  }

  return (
    <div className="record-detail-page">
      <TitleBar title='舒辅心理健康咨询档案' isShowBack={true}></TitleBar>
      {
        renderQuestionContent()
      }
      {
        renderSubmitBtn()
      }
      <DatePicker
        title='请选择日期'
        visible={datePickerVisible}
        precision={selectedDatePrecisionRef.current}
        onClose={() => {
          setDatePickerVisible(false)
        }}
        defaultValue={MomentDiffZone().toDate()}
        onConfirm={val => saveAttrForModel(selectedDateAttrIdRef.current, moment(val).valueOf())}
      />
    </div>
  )
}

export default RecordDetail;