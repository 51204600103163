import { getUrls } from '@/utils/getUrls';
import exercise1 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework1_shortVer.json';
import exercise2 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework2_shortVer.json';
import exercise3 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework3_shortVer.json';
import exercise4 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework4_shortVer.json';
import exercise5 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework5_shortVer.json';
import exercise6 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework6_shortVer.json';
import exercise7 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework7_shortVer.json';
import exercise8 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework8_shortVer.json';
import mht_exercise1 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework1_parentChildVer.json';
import mht_exercise2 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework2_parentChildVer.json';
import mht_exercise3 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework3_parentChildVer.json';
import mht_exercise4 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework4_parentChildVer.json';
import mht_exercise5 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework5_parentChildVer.json';
import mht_exercise6 from '@/assets/form/lisa.followUp.mentalHealthTrainingHomework6_parentChildVer.json';
const { BASE_HOST } = getUrls();

const recommendChatList = [
  {
    "itemId": "exercise1_1_v2",
    "description": "介绍心理压力的分类：分为急性压力和慢性压力。帮助个体识别自身的压力来源以及压力之下的反应，包括情绪反应、心理防御反应、行为反应等。介绍心理压力会造成的相关问题，帮助个体识别压力之下出现的情绪问题和心身症状。让个体注意到身心给出的压力信号，找到压力的源头，这是解决压力的第一步。",
    "url": `${BASE_HOST}normal/exercise-url/1`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise1.mp4",
    "formContent": exercise1,
  },
  {
    "itemId": "exercise2_1_v2",
    "description": "增进大家对情绪的了解，认识到情绪存在的原因，了解情绪体验伴随的身体反应和行为反应。命名你的情绪，识别情绪传递的信号以及评估情绪的强度。介绍如何管理和表达情绪，避免发泄情绪，学会通过建设性的方法释放和调节情绪。",
    "url": `${BASE_HOST}normal/exercise-url/2`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise2.mp4",
    "formContent": exercise2,
  },
  {
    "itemId": "exercise3_1_v2",
    "description": "介绍如何使用行为激活的方法，可以帮助个体摆脱压力的负性循环，重新掌控快乐。通过探索五类活动：社会交往活动、有控制感的活动、愉快的活动、自我安抚的活动、运动，来帮助我们积蓄能量，从而对抗负性情绪和压力。帮助个体制定“一周计划表”，将五类活动安排到日常生活中。",
    "url": `${BASE_HOST}normal/exercise-url/3`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise3.mp4",
    "formContent": exercise3,
  },
  {
    "itemId": "exercise4_1_v2",
    "description": "让大家学会如何控制强烈情绪。从介绍情绪的易感性与行为冲动的生物因素和社会因素开始，帮助个体了解自身的情绪特性和周围的社会环境适宜性。介绍控制强烈情绪的方法：通过积累积极情绪和学会强烈情绪下“喊停”（STOP）的技术，让自己拥有更加稳定的情绪。",
    "url": `${BASE_HOST}normal/exercise-url/4`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise4.mp4",
    "formContent": exercise4,
  },
  {
    "itemId": "exercise5_1_v2",
    "description": "让大家学会行动过程中的一些技巧，用来摆脱焦虑和抑郁情绪的困扰。通过四个具体的行动技巧：排任务优先级、一次只做一件事、找到顺应内心的节奏、承认自己的成就，帮助个体提升行动效率，沉浸在自己想要做的事情当中。",
    "url": `${BASE_HOST}normal/exercise-url/5`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise5.mp4",
    "formContent": exercise5,
  },
  {
    "itemId": "exercise6_1_v2",
    "description": "介绍人际关系的两面性与情绪表达在人际沟通中的作用。帮助个体在人际互动中洞见深层次的情绪和需求，并用建设性的方式表达自身的深层情绪与需求。通过描述、表达、提出请求、强化、正念、保持自信、协商等步骤完成更好的沟通。",
    "url": `${BASE_HOST}normal/exercise-url/6`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise6.mp4",
    "formContent": exercise6,
  },
  {
    "itemId": "exercise7_1_v2",
    "description": "帮助个体认识到想法、情绪、行为三者之间的关系。让个体意识到激发负面情绪的不是事件本身，而是我们对该事件持有的想法。学会识别自身的不合理信念，减少不必要的精神内耗。介绍三种常见的不合理信念：绝对化要求、以偏概全的想法以及灾难化的想法。",
    "url": `${BASE_HOST}normal/exercise-url/7`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise7.mp4",
    "formContent": exercise7,
  },
  {
    "itemId": "exercise8_1_v2",
    "description": "介绍正念的概念：带着善意与好奇，不带评判地去观察此刻自己内心和外部环境所发生的事情及经过。帮助培养个体的觉察力和专注力，增进自我理解。训练一种正念的态度。让自己更好地专注当下，面对当下的问题。真正面对内心的困境，寻找有效的解决方法，而不是沉湎于各种念头、担忧之中。",
    "url": `${BASE_HOST}normal/exercise-url/8`,
    "media": "https://image.siuvo.com.cn/video/toughness/exercise8.mp4",
    "formContent": exercise8,
  },
  {
    "itemId": "exercise1_mht",
    "description": "识别孩子情绪，教孩子表达命名情绪，条件情绪的方法",
    "media": "https://image.siuvo.com.cn/mentalHealthLesson_parentChild/Lesson1_3.mp4",
    "formContent": mht_exercise1,
  },
  {
    "itemId": "exercise2_mht",
    "description": "亲子关系中家长如何控制情绪",
    "media": "https://image.siuvo.com.cn/mentalHealthLesson_parentChild/Lesson2_3.mp4",
    "formContent": mht_exercise2,
  },
  {
    "itemId": "exercise3_mht",
    "description": "如何高效陪伴孩子",
    "media": "https://image.siuvo.com.cn/mentalHealthLesson_parentChild/Lesson3_2.mp4",
    "formContent": mht_exercise3,
  },
  {
    "itemId": "exercise4_mht",
    "description": "亲子互动中洞见孩子的深处情绪和需求练习，练习亲子沟通技巧",
    "media": "https://image.siuvo.com.cn/mentalHealthLesson_parentChild/Lesson4_2.mp4",
    "formContent": mht_exercise4,
  },
  {
    "itemId": "exercise5_mht",
    "description": "帮助孩子摆脱焦虑抑郁，提高成绩",
    "media": "https://image.siuvo.com.cn/mentalHealthLesson_parentChild/Lesson5_1.mp4",
    "formContent": mht_exercise5,
  },
  {
    "itemId": "exercise6_mht",
    "description": "练习识别亲子关系中的不合理信念",
    "media": "https://image.siuvo.com.cn/mentalHealthLesson_parentChild/Lesson6_2.mp4",
    "formContent": mht_exercise6,
  },
  {
    "itemId": "positive_0",
    "description": "放松身心，轻松进入睡眠",
    "url": `${BASE_HOST}client/positive-thinking-iframe/0`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_1.mp3"
  },
  {
    "itemId": "positive_1",
    "description": "关注呼吸，最简单，也最有效",
    "url": `${BASE_HOST}client/positive-thinking-iframe/1`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_2.mp3"
  },
  {
    "itemId": "positive_2",
    "description": "静坐，连接能量的世界",
    "url": `${BASE_HOST}client/positive-thinking-iframe/2`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_3.mp3"
  },
  {
    "itemId": "positive_3",
    "description": "打开我们的五感，体会新奇的世界",
    "url": `${BASE_HOST}client/positive-thinking-iframe/3`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_4.mp3"
  },
  {
    "itemId": "positive_4",
    "description": "安放身心，觉察此刻的体验",
    "url": `${BASE_HOST}client/positive-thinking-iframe/4`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_5.mp3"
  },
  {
    "itemId": "positive_5",
    "description": "接纳情绪，如实的关照当下",
    "url": `${BASE_HOST}client/positive-thinking-iframe/5`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_6.mp3"
  },
  {
    "itemId": "positive_6",
    "description": "如大山般沉稳宁静，提升稳定、力量、勇气",
    "url": `${BASE_HOST}client/positive-thinking-iframe/6`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_7.mp3"
  },
  {
    "itemId": "positive_7",
    "description": "如水一般善纳万物，迎接一切变化",
    "url": `${BASE_HOST}client/positive-thinking-iframe/7`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_8.mp3"
  },
  {
    "itemId": "positive_8",
    "description": "一个随时随地，仅需6分钟的放松体验",
    "url": `${BASE_HOST}client/positive-thinking-iframe/8`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_9.mp3"
  },
  {
    "itemId": "positive_9",
    "description": "入门小练习，快速缓解当下的紧张与焦虑",
    "url": `${BASE_HOST}client/positive-thinking-iframe/9`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_10.mp3"
  },
  {
    "itemId": "positive_10",
    "description": "新的未来，从独一无二的呼吸开始",
    "url": `${BASE_HOST}client/positive-thinking-iframe/10`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_11.mp3"
  },
  {
    "itemId": "positive_11",
    "description": "深层放松，改善焦虑，拥有清新愉快的感受",
    "url": `${BASE_HOST}client/positive-thinking-iframe/11`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_12.mp3"
  },
  {
    "itemId": "positive_12",
    "description": "通过8组肌肉放松，缓解紧张",
    "url": `${BASE_HOST}client/positive-thinking-iframe/12`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_13.mp3"
  },
  {
    "itemId": "positive_13",
    "description": "9分钟让你获得放松的肌肉与身心",
    "url": `${BASE_HOST}client/positive-thinking-iframe/13`,
    "media": "https://image.siuvo.com.cn/mindfulness/voice_14.mp3"
  }
];

export default recommendChatList;