// 七陌查询用户列表
import React, { useState, useEffect } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { useNavigate } from 'react-router-dom';
import { getCallcenterList, getSSEConnectUrl, colseSSEConnectUrl } from '@/services/api';
import { Toast, Collapse } from 'antd-mobile';
import moment from 'moment';
import useSSE from '@/hooks/useSSE';
import { useSelector } from 'react-redux';

// 默认一页查询的数据是10条
const PAGE_SIZE = 10;
function QimoUserInfo() {
  const navigate = useNavigate();
  const baseInfo = useSelector(state => state.baseInfo.doctor);
  const [userInfoList, setUserInfoList] = useState([])
  const sseConnectUrl = getSSEConnectUrl({ clientId: baseInfo.authId });
  useSSE(sseConnectUrl, (messageInfo) => {
    if (messageInfo) {
      const { clientId, data } = messageInfo;
      // console.log('get sse data:', data)
      // 广播消息，判断clientId是否相同, 相同则处理
      if (baseInfo.authId === clientId && data) {
        const messgae = JSON.parse(data);
        const newList = userInfoList.concat(messgae)
        setUserInfoList(newList)
      }
    }
  });

  // 查询数据
  useEffect(() => {
    async function _getCallcenterList() {
      const params = {
        pageNo: '1',
        pageSize: PAGE_SIZE
      }
      const res = await getCallcenterList(params);
      if (res.errorCode) {
        Toast.show('七陌用户获取异常，请重试')
        return;
      }
      res.result && setUserInfoList(res.result.data);
    }
    _getCallcenterList();
  }, [])

  // 转换接听状态码为文字描述
  function revertCallState(state) {
    // 呼叫振铃Ring(话务进入呼叫中心触发的事件)
    // 被呼振铃Ringing
    // 接通Link
    // 通话结束Hangup (Unlink也当成Hangup处理)
    const callStateObj = {
      Ring: '呼叫振铃',
      Ringing: '被呼振铃',
      Link: '接通',
      Unlink: '通话结束',
      Hangup: '通话结束',
    }
    return callStateObj[state];
  }

  // 手风琴title
  function renderTitle(item, isEAPUser) {
    return <div className="user-item">
      <div>主叫号码：{item.callNo}</div>
      <div>被叫号码：{item.calledNo}</div>
      <div>接听状态：{revertCallState(item.callState)}</div>
      <div>是否为EAP用户: {isEAPUser ? '是' : '否'}</div>
    </div>
  }

  // 渲染详情
  function renderDetailInfo(item, staffInfo, isEAPUser) {
    const { callcenterData } = item;
    let infoNode;
    if (isEAPUser) {
      infoNode = <>
        <div>EAP个人信息</div>
        <div>姓名：{staffInfo.name} </div>
        <div>性别：{staffInfo.gender === 'FEMALE' ? '女' : '男'} </div>
      </>
    }
    return (
      <div className='call-ceter-data-detail'>
        {infoNode}
        <div>坐席姓名：{item.agentName}</div>
        <div>被叫振铃开始时间: {item.ringingDate ? moment(item.ringingDate).format('YYYY-MM-DD HH:mm') : '--'}</div>
        <div>通话开始时间: {callcenterData.begin ? moment(callcenterData.begin).format('YYYY-MM-DD HH:mm') : '--'}</div>
        <div>通话结束时间: {callcenterData.end ? moment(item.end).format('YYYY-MM-DD HH:mm') : '--'}</div>
        <div>呼入地址: {callcenterData.province} {callcenterData.district}</div>
      </div>
    )
  }
  // 渲染目标item
  function renderListItem(item, index) {
    const { staffInfo, consultantInfo } = item;
    const isEAPUser = !!staffInfo;
    return (
      <Collapse.Panel key={index} title={renderTitle(item, isEAPUser)}>
        {
          renderDetailInfo(item, staffInfo, isEAPUser)
        }
      </Collapse.Panel>
    );
  }

  // 渲染列表
  function renderUserInfoList() {
    return (
      <div className='qimo-user-info-page'>
        {
          userInfoList.length > 0 ?
            <Collapse accordion>
              {
                userInfoList.slice().reverse().map((item, index) => {
                  return renderListItem(item, index)
                })
              }
            </Collapse>
            :
            <div className='no-data'>暂无数据</div>
        }
      </div>
    )
  }

  return (
    <>
      <TitleBar title="七陌用户列表" isShowBack={true} />
      {
        renderUserInfoList()
      }
    </>
  )
}

export default QimoUserInfo;