import React  from "react";
import _ from "lodash";
import moment from "moment";
import { RightOutline, AddOutline } from "antd-mobile-icons";
import "./index.less";

function StaffBaseInfoComponent(props) {
  const patientInfo = _.get(props, "patientInfo", {});
  const {role} = props;

  function renderGender(gender) {
    switch (gender) {
      case "MALE":
        return "男";
      case "FEMALE":
        return "女";
      default:
        return "-";
    }
  }

  function renderBseInfo() {
      return (
        <>
          <div className="basic-info-content">
            账号：{patientInfo.account || '--'}
          </div>
          <div className="basic-info-content">
            {
              patientInfo.telephone? <div>手机号：<a href={`tel:${patientInfo.telephone}`}>{patientInfo.telephone}</a></div>:
              <div>手机号：{patientInfo.telephone || '--'}</div>
            }
          </div>
        </>
      )
  }

  return (
    <div className="patient-infos">
      <div className="patient-info">
        <div
          className="patient-head"
          style={{
            borderColor: role == 'doctor' ? '#FFB92E' : '#2979FF'
          }}
        >
          <img src={_.get(patientInfo, "avatarUrl", "")} alt="" />
        </div>
        <div className="patient-data">
          <div className="patient-name">{_.get(patientInfo, "name", "")}</div>
          <div className="patient-other-info">
            {renderGender(_.get(patientInfo, "gender", ""))}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {_.get(patientInfo, "age", "") || moment().diff(patientInfo.birthday, 'years')}岁
          </div>
          <div className="patient-other-info">
            渠道：{patientInfo.tenantName}
            &nbsp;&nbsp;&nbsp;&nbsp;
            公司：{patientInfo.ownerName}
          </div>
        </div>
      </div>
      <div className="basic-info-title">基本资料</div>
      <div className="basic-info">
        {renderBseInfo()}
      </div>
    </div>
  );
}

export default StaffBaseInfoComponent;