import React, {useState} from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { useNavigate } from 'react-router-dom';
import TitleBar from '@/components/TitleBar';
import { patientGetConsultantScheduling, patientAddConsultantAppoint, patientDeleteAppoint } from '@/services/api';
import { Checkbox, Modal, Toast } from 'antd-mobile'

import CalendarComponent from '@/components/Calendar';
import {isMoreThan24Hours, isMoreThanSpecificHours} from '@/utils/commonUtils'
import { decodeToken } from '../../../utils/utils';
import { getLobFromToken } from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';


function Calendar() {
  const navigate = useNavigate();
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  // 获取机构号
  const { tenantId } = decodeToken(getLoginToken('patient'))

  function openCancelAppointModal(selectId, consultName, selectStartAppointTime, selectEndAppointTime) {
    console.log(selectId, consultName, selectStartAppointTime, selectEndAppointTime);
    const modal = Modal.show({
      content: (
        <>
          <div className="modal-title">取消确定</div>
          <div>您要取消{MomentDiffZone(selectStartAppointTime).format('YYYY年MM月DD日')}{MomentDiffZone(selectStartAppointTime).format('HH:mm')}-{MomentDiffZone(selectEndAppointTime).format('HH:mm')}的预约</div>
          <div className='modal-buttons'>
            <div className='modal-button modal-cancel' onClick={() => modal.close()}>取&nbsp;&nbsp;消</div>
            <div className='modal-button modal-confirm' onClick={() => {
              modal.close();
              _patientDeleteAppoint(selectId);
            }}>确&nbsp;&nbsp;认</div>
          </div>
        </>
      ),
    })
  }

  async function _patientDeleteAppoint(appointId) {
    const res = await patientDeleteAppoint(appointId);
    Toast.show({ icon: 'success', content: '取消成功' });
    setRefreshCalendar(!refreshCalendar);
  }

  function openAppointmentModal(consultantId, consultName, timeslotId, selectStartAppointTime, selectEndAppointTime) {
    let agreeRule = false;
    console.log(consultantId, consultName, selectStartAppointTime, selectEndAppointTime);
    let confirmText = !isMoreThan24Hours(selectStartAppointTime) ? <span>因距离预约时间已不足24小时，预约后将不能取消。</span> : '';
    const modal = Modal.show({
      content: (
        <>
          <div className="modal-title">预约确认</div>
          <div>您确认预约{consultName}：<br/>{MomentDiffZone(selectStartAppointTime).format('YYYY年MM月DD日')}{MomentDiffZone(selectStartAppointTime).format('HH:mm')}-{MomentDiffZone(selectEndAppointTime).format('HH:mm')}<br/>这个咨询时段，{confirmText}</div>
          <Checkbox
            className='agree-rule'
            value={agreeRule}
            style={{ '--icon-size': '18px', '--font-size': '14px', '--gap': '6px', }}
            onChange={() => agreeRule = !agreeRule}
          >我同意以上条款</Checkbox>
          <div className='modal-buttons'>
            <div className='modal-button modal-cancel' onClick={() => modal.close()}>取&nbsp;&nbsp;消</div>
            <div className='modal-button modal-confirm' onClick={() => {
              if (agreeRule) {
                modal.close();
                _patientAddConsultantAppoint(consultantId, timeslotId);
              } else {
                Toast.show({ content: '请同意以上条款' });
              }
            }}>确&nbsp;&nbsp;认</div>
          </div>
        </>
      ),
    })
  }

  async function _patientAddConsultantAppoint(consultantId, timeslotId) {
    const res = await patientAddConsultantAppoint(consultantId, { timeslotId: timeslotId });
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '预约成功！',
        key: 'error'
      });
      setRefreshCalendar(!refreshCalendar);
    }
  }

  // 不可重复预约，或者重新绑定咨询师，因此返回首页
  function backToHome () {
    navigate('/client/home-v2/main');
  }
  // fafuli 仅可预约24小时以上的
  if (tenantId === 'fafuli') {
    return (
      <>
      <TitleBar isShowBack={true} title="预约" barCancel={backToHome}></TitleBar>
      <CalendarComponent
        role='patient'
        getConsultantScheduling={patientGetConsultantScheduling}
        openAppointmentModal={openAppointmentModal}
        refreshCalendar={refreshCalendar}
        openCancelAppointModal={openCancelAppointModal}
        appointInterval={0.5}
        isMoreThanSpecificHours={isMoreThanSpecificHours}
        limitHour={24}
      />
    </>
    )
  }

  return (
    <>
      <TitleBar isShowBack={true} title="预约" barCancel={backToHome}></TitleBar>
      <CalendarComponent
        role='patient'
        getConsultantScheduling={patientGetConsultantScheduling}
        openAppointmentModal={openAppointmentModal}
        refreshCalendar={refreshCalendar}
        openCancelAppointModal={openCancelAppointModal}
      />
    </>
  )
}

export default Calendar;