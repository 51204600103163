import React, { useState, useEffect } from 'react';
import './index.less';
import { useNavigate, useLocation } from 'react-router-dom';
import LoginComponents from '@/components/Login';
import {
  getLoginToken,
  setLoginToken,
  setIsFirstTimeLogin,
  getLocalNeedFirstEvaluationStatus,
  setLocalNeedFirstEvaluationStatus
} from '@/utils/localStorage'
import { checkTokenIsValid, decodeToken } from '@/utils/utils';
import { Toast } from 'antd-mobile';
import CONFIG from 'GlobalConfigFile';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { loginByAccount, getNeedFirstEvaluation } from '@/services/api';
import { register_socket_event, save_patient_info } from '@/actions'
import { getPageQuery } from '@/utils/utils';
import mappingTitle from '@/Constant/mappingTitle';
import WithTracking from '@/components/WithTracking';
import MigratedOrgId from './MigratedOrgId';

function LoginByStdId() {
  const ROLE = 'patient'; //此声明只是UI上的区分
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { organizationId, intake } = getPageQuery();
  const navUrl = location?.state?.from || '/client/home-v2/main';

  // 定制化title
  const loginTitle = mappingTitle[organizationId] || mappingTitle['default'];

  useEffect(() => {
    async function init() {
      const token = getLoginToken(ROLE);
      const isTokenValid = checkTokenIsValid(token);
      if (isTokenValid) {
        const { authId } = decodeToken(token)
        registerSocketAndConnect(authId)
        // 已迁移的项目第一批使用初始评估
        if (intake === 'true') {
          navigate('/client/initial-evaluation');
          return
        }
        let status = getLocalNeedFirstEvaluationStatus();
        if (status === null) {
          status = await _getNeedFirstEvaluation();
        }
        if (status) {
          navigate('/client/initial-evaluation');
          return
        }
        navigate(navUrl);
      }
    }
    init();
  }, [])

  async function _getNeedFirstEvaluation() {
    try {
      const res = await getNeedFirstEvaluation();
      const needFirstEvaluation = _.get(res, 'result', true);
      setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
      return needFirstEvaluation
    } catch (e) {
      console.error('getNeedFirstEvaluation error', e)
      // 接口异常仍回主页
      return false
    }
  }

  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect(id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    dispatch(register_socket_event(socket));
  }

  async function onPatientLogin(params) {
    params['tenantId'] = organizationId;
    let res = await loginByAccount(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    const token = res.result.token;
    const firstTime = res.result.firstTime;
    const needFirstEvaluation = res.result.needFirstEvaluation;
    const authId = _.get(res, ['result', 'authId'], '');
    const roleInfo = _.get(res, ['result', 'roleInfo'], {});
    const telephone = _.get(roleInfo, 'telephone', '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    // 存初始评估状态
    setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
    setIsFirstTimeLogin(ROLE, firstTime)
    dispatch(save_patient_info(_.get(res, ['result'], {})))
    if (MigratedOrgId.indexOf(organizationId) > -1) {
      // 已迁移的项目
      if (firstTime) {
        const pageParams = {
          authId,
          needCellphone: true
        }
        organizationId && (pageParams['organizationId'] = organizationId);
        navigate('/client/first-setting-v2', { state: pageParams });
        return
      }
      if (intake === 'true' || needFirstEvaluation) {
        navigate('/client/initial-evaluation');
        return
      }
      navigate(navUrl);
    } else {
      if (firstTime) {
        const pageParams = {
          authId,
          needCellphone: true,
        }
        navigate('/client/first-setting', { state: pageParams });
      } else if (!firstTime && !telephone) {
        const pageParams = {
          authId,
          needCellphone: true,
          roleInfo,
          readOnly: true
        }
        navigate('/client/first-setting', { state: pageParams });
      } else {
        navigate(navUrl);
      }
    }
  }

  return (
    <LoginComponents
      theme={ROLE}
      type='stdId'
      loginTitle={loginTitle}
      onLogin={onPatientLogin}
    />
  )
}

export default WithTracking(
  LoginByStdId,
  {
    name: 'visit:login',
    props: {
      eventName: '登录'
    },
    userId: 'anonymous'
  }
);