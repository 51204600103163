

export function getCurrentUserId() {
  // return 'o0gFU0m-KwkAXiIi2AYnqbRbaVaI';
  return sessionStorage.getItem('userId');
}

export function setCurrentUserId(userId) {
  sessionStorage.setItem('userId', userId);
}

export function getOrganizationId() {
  // return 'o0gFU0m-KwkAXiIi2AYnqbRbaVaI';
  return sessionStorage.getItem('organizationId');
}

export function setOrganizationId(organizationId) {
  // return 'o0gFU0m-KwkAXiIi2AYnqbRbaVaI';
  sessionStorage.setItem('organizationId', organizationId);
}

export function setToken(token) {
  // _token = token;
  sessionStorage.setItem('token', token);
  return token;
}

export function getToken() {
  return (
    sessionStorage.getItem('token') ||
    'Shufu@dMBe+AlM72rnSnBLx3E3lpgjkZjU8sOm8Z/6xKOf9tOnZWBRlWOB1n4nmAlQ9ugc9Ay/1b6VE2zQkCBe+AlMpWOjf71I1W04fsflx3sj7UEirlR672KMrl/V+2B7mWO2mZKnkCBRmANLE2yslu/4f71I9tlQ9C4nf3lA82N/72rnSWye934Q1WNMf2Osflo692lqE3Oj+3ZnSbaQ1tBL93ZnS/QnZ3Os82lVECBEkCBVm2e/1bcn9qgto/Z497ekEAjg23/B8pBg22yzm/BnmlfR77BF'
  );
  // return 'Shufu@dMBe+AlM72rnSnBLx3E3lpgjkZjU8sOm8Z/6xKOf9tOnZWBRlWOB1n4nmAlQ9ugc9Ay/1b6VE2zQkCBe+AlMpWOjf71I1W04fsflx3sj7UEirlR672KMrl/V+2B7mWO2mZKnkCBRmANLE2yslu/4f71I9tlQ9C4nf3lA82N/72rnSWye934Q1WNMf2Osflo692lqE3Oj+3ZnSbaQ1tBL93ZnS/QnZ3Os82lVECBEkCBVm2e/1bcn9qgto/Z497ekEAjg23/B8pBg22yzm/BnmlfR77BF';
}

export function getServiceInstanceId() {
  // return 'o0gFU0m-KwkAXiIi2AYnqbRbaVaI';
  return sessionStorage.getItem('serviceInstanceId');
}

export function setServiceInstanceId(serviceInstanceId) {
  // return 'o0gFU0m-KwkAXiIi2AYnqbRbaVaI';
  sessionStorage.setItem('serviceInstanceId', serviceInstanceId);
}

export function setCurrentUserInfo(userInfo) {
  sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
}
export function getCurrentUserInfo() {
  return JSON.parse(sessionStorage.getItem('userInfo') || '{}');
}

export function saveFormStatus(messageId, formStatus) {
  localStorage.setItem(`form_cache_${messageId}`, JSON.stringify(formStatus));
}

export function deleteFormStatus(messageId) {
  localStorage.removeItem(`form_cache_${messageId}`);
}

export function getFormStatus(messageId) {
  let jsonString = localStorage.getItem(`form_cache_${messageId}`);
  if (jsonString) {
    return JSON.parse(jsonString);
  }
  return null;
}

export function setShowTextToSpeech(showTextToSpeech) {
  return sessionStorage.setItem('showTextToSpeech', showTextToSpeech);
}

export function getShowTextToSpeech() {
  return sessionStorage.getItem('showTextToSpeech');
}

export function setHtmlZoom(htmlZoom) {
  return sessionStorage.setItem('htmlZoom', htmlZoom);
}

export function getHtmlZoom() {
  return sessionStorage.getItem('htmlZoom');
}

export function setServiceItemId(serviceItemId) {
  return sessionStorage.setItem('serviceItemId', serviceItemId);
}

export function getServiceItemId() {
  return sessionStorage.getItem('serviceItemId');
}

/**
 * 是否完善基础信息-医生
 * @params isFirstTimeLogin
 */
export function setIsDoctorFirstTimeLogin(isFirstTimeLogin) {
  localStorage.setItem('isDoctorFirstTimeLogin', isFirstTimeLogin);
}

/**
 * @return isFirstTimeLogin-医生
 */
export function getIsDoctorFirstTimeLogin() {
  return localStorage.getItem('isDoctorFirstTimeLogin');
}
/**
 * 是否完善基础信息
 * @params isFirstTimeLogin-患者
 */
export function setIsFirstTimeLogin(role, isFirstTimeLogin) {
  localStorage.setItem(`is${role}FirstTimeLogin`, isFirstTimeLogin);
}

/**
 * @return isFirstTimeLogin-患者
 */
export function getIsFirstTimeLogin(role) {
  return localStorage.getItem(`is${role}FirstTimeLogin`);
}

/**
 * 
 * @param {*} params 扫码时存放的url
 */
export function getLinkUrl() {
  return localStorage.getItem('linkUrl');
}
export function setLinkUrl(linkUrl) {
  return localStorage.setItem('linkUrl', linkUrl);
}
/**
 * 
 * @param {*} params 扫码时存放的绑定参数
 */
export function getBindParams() {
  return localStorage.getItem('bindParams');
}
export function setBindParams(bindParams) {
  return localStorage.setItem('bindParams', bindParams);
}
/**
 * 
 * @param {*} bindParams 
 * @returns 
 */
export function setAuthId(role, authId) {
  return localStorage.setItem(role+'AuthId', authId);
}
export function getAuthId(role) {
  return localStorage.getItem(role+'AuthId');
}

/**
 * 
 * @param {*} role 角色
 * @param {*} token 登录的token
 * @returns 
 */
 export function getLoginToken(role) {
  const token =  localStorage.getItem(`${role}LoginToken`);
  return token;
}
export function setLoginToken(role, token) {
  localStorage.setItem(`${role}LoginToken`, token);
}

/**
 * 访问地址存本地，方便token过期后，拦截器跳转到对应的入口
 * 
 */
export function setEntranceUrl(entrancePath) {
  localStorage.setItem('entrancePath', entrancePath);
}
export function getEntranceUrl(entrancePath) {
  return localStorage.getItem('entrancePath');
}

// 用户是都需要初始评估
export function setLocalNeedFirstEvaluationStatus(status) {
  return localStorage.setItem('firstEvaluationStatus', status);
}
export function getLocalNeedFirstEvaluationStatus(role) {
  return localStorage.getItem('firstEvaluationStatus');
}


