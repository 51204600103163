import React from 'react';
import { InfiniteScroll, PullToRefresh, List } from 'antd-mobile'

/**
 * 
 * @param {*} dataSource 数据源
 * @param {*} renderItem item样式
 * @param {*} onRefresh 下拉刷新方法
 * @param {*} hasMore 是否还有更多数据，可根据loadMore，处理传入
 * @param {*} loadMore 加载新的数据方法
 * @param {*} minHeight, 最小高度，默认100vh
 * @returns 
 */
function ListView({ dataSource, renderItem, onRefresh, hasMore, loadMore, minHeight = '100vh', onItemClick, onItemClickShow = true }) {

  function renderListItem() {
    if (onItemClickShow === true) {
      return (dataSource || []).map((item, index) => (
        <List.Item
          key={index}
          onClick={() => { onItemClick(item) }}
        >
          {
            renderItem(item)
          }
        </List.Item>
      ))
    }
    else {
      return (dataSource || []).map((item, index) => (
        <List.Item
          key={index}
        >
          {
            renderItem(item)
          }
        </List.Item>
      ))
    }
  }

  return (
    <>
      <PullToRefresh
        onRefresh={() => onRefresh()}
      >
        <List style={{ minHeight: minHeight }}>
          {
            renderListItem()
          }
        </List>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={100} />
      </PullToRefresh>
    </>
  )
}

export default ListView;