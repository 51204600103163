// 用于练习页面的ActionSheet组件
import React from 'react';
import { ActionSheet } from 'antd-mobile';


export const ExerciseActionSheet = ({ visible, onClose, onReset }) => {
  const actions = [
    {
      text: '重置',
      key: 'reset',
      onClick: onReset,
    },
    {
      text: '取消',
      key: 'cancel',
      onClick: onClose,
    },
  ];

  return (
    <>
      <ActionSheet 
        visible={visible}
        actions={actions}
        onClose={onClose}
      />
    </>
  )
}