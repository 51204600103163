import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoginComponents from '@/components/Login';
import './login.less';
import { loginByDoctor, sendDoctorVerifyCode } from '@/services/api';
import {
  getLoginToken,
  setLoginToken,
} from '@/utils/localStorage'
import {checkTokenIsValid, decodeToken} from '@/utils/utils';
import {Toast} from 'antd-mobile';
import CONFIG from 'GlobalConfigFile';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { save_doctor_info, register_socket_event } from '@/actions'

function Login() {
  const ROLE = 'doctor'
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const navUrl = location?.state?.from || '/therapist/home/calendar';

  useEffect(() => {
    const token = getLoginToken(ROLE);
    const isTokenValid = checkTokenIsValid(token);
    if(isTokenValid) {
      const {authId} = decodeToken(token)
      registerSocketAndConnect(authId)
      navigate(navUrl);
    }
  }, [])
  
  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect (id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, {autoConnect: false, reconnection: false,  forceNew: false, transports: ['websocket', 'polling']});
    socket.open();
    dispatch(register_socket_event(socket));
  }

  async function handleSendVerifyCode (cellphone) {
    const res = await sendDoctorVerifyCode(cellphone);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '短信发送成功',
        key: 'success'
      });
    }
  }

  async function onDoctorLogin (params) {
    let res = await loginByDoctor(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    } 
    const token = res.result.token;
    const authId = _.get(res, ['result', 'authId'], '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    // 本地存authId token,是否填写基础信息
    // setLocalAuthId(authId, ROLE);
    setLoginToken(ROLE, token);
    dispatch(save_doctor_info(_.get(res, ['result'], {})));
    // 跳转到登陆拦截前的页面
    navigate(navUrl);
  }
  return (
    <LoginComponents 
      theme={ROLE}
      loginTitle="舒辅EAP"
      onLogin={onDoctorLogin}
      onSendVerifyCode={handleSendVerifyCode}
    />
  )
}

export default Login;