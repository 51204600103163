// 该页面用于展示咨询师审核页面的
import React, { useEffect, useState } from "react";
import _ from "lodash";
import './index.less';
import TitleBar from "@/components/TitleBar";
import ConsultantInfoComponent from "@/components/UserInfo/ConsultantInfo";
import NavigationHint from '@/components/NavigationHint';
import Comment from '@/components/UserInfo/ConsultantInfo/comment';
import { getLoginToken } from '@/utils/localStorage';
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';
import { useLocation } from "react-router-dom";

function SelfIntroAuditPage(params) {
  const location = useLocation();
  const [editType, setEditType] = useState('edit');
  console.log('editType', editType);
  const role = _.get(location, ["state", "role"], "");
  const type = _.get(location, ["state", "type"], "");
  const applyTime = _.get(location, ["state", "applyTime"], "");
  const refuseReason = _.get(location, ["state", "refuseReason"], "") || '暂无评论';
  const applyId = _.get(location, ["state", "applyId"], "");
  const applyInfo = _.get(location, ["state", "applyInfo"], {});
  const status = _.get(location, ['state', 'status'], '');
  const lob = getLobFromToken(getLoginToken(role));
  const isNeedShowQrcode = lob=== lobType.CONSULTANT;

  function renderComment() {
    if (status === 'NOT_APPROVED') {
      return (
        <div className="comment-content">
          <div className="commet-title">管理员评语</div>
          <Comment commentText={refuseReason} />
        </div>
      )
    }
    return null
  }

  function callBackEditType(editType) {
    setEditType(editType)
  }
  
  return (
    <>
      <TitleBar title='个人介绍审核' isShowBack={true} />
      {
        editType === 'editing'
          ? null
          : <NavigationHint status={status} applyId={applyId} applyTime={applyTime} />
      }
      {
        renderComment()
      }
      <ConsultantInfoComponent 
        doctorInfo={applyInfo}
        role={role}
        type={type}
        isNeedShowQrcode={isNeedShowQrcode}
        editCB={() => _doctorGetSelfInfo(consultantId)}
        callBackEditType={callBackEditType}
        editType={editType}
      />
    </>
  )
}

export default SelfIntroAuditPage;