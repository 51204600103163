import React from 'react';
import './index.less';
import { Toast } from 'antd-mobile';
import FirstSetting from '@/components/FirstSetting';
import { useLocation } from 'react-router-dom';
import { setPatientBaseInfo } from '@/services/api';
import { setIsFirstTimeLogin } from '@/utils/localStorage'
import _ from 'lodash';
import { save_patient_info } from '@/actions'
import { useDispatch, useSelector } from 'react-redux';

function ExternalFirstSetting(props) {
  const ROLE = 'patient';
  const location = useLocation();
  const dispatch = useDispatch();
  const patientInfo = useSelector((state) => state.baseInfo[ROLE]);
  const { authId, navUrl, organizationId } = _.get(location, ['state'], {});

  async function handleSetting(params) {
    params['authId'] = authId
    params['organizationId'] = organizationId
    let res = await setPatientBaseInfo(params);
    if (res.errorCode) {
      setIsFirstTimeLogin(ROLE, true)
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      const info = { ...patientInfo, roleInfo: res.result }
      setIsFirstTimeLogin(ROLE, false)
      dispatch(save_patient_info(info))
      window.location.href = navUrl
    }
  }

  return (
    <>
      <FirstSetting handleSetting={handleSetting} />
    </>
  )
}

export default ExternalFirstSetting;