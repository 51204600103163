// 查看到当前所有坐席的情况
import React, { useCallback, useState, useEffect } from 'react';
import { Toast, Card, Input } from "antd-mobile";

import './index.less';
import TitleBar from '@/components/TitleBar';
import { adminGetAllExterns, adminUpdateExtern } from '@/services/api';

export default function ExternsInfo() {

  const [externsInfoList, setExternsInfoList] = useState([]);
  const [phoneNum, setPhoneNum] = useState('');

  const _adminGetAllExterns = useCallback(async () => {
    const res = await adminGetAllExterns();
    if (!res.error) {
      setExternsInfoList(res.result);
    }
    else {
      Toast.show({
        content: res.error,
        duration: 2000,
        icon: 'fail'
      })
    }
  }, []);

  useEffect(() => {
    _adminGetAllExterns();
  }, [_adminGetAllExterns]);


  return (
    <>
      <TitleBar
        title="咨询师排班信息"
        isShowBack={true}
      />
      <div className='externs-info-list'>
        {
          externsInfoList.map((item, index) => {
            return (
              <Card
                style={{ borderRadius: '16px' }}
                title={`${item.displayName}-${item.extern}`}
                key={index}
                className='externs-info-card'
              >
                <Input
                  defaultValue={item.mobile}
                  onChange={val => {
                    console.log('val', val);
                    setPhoneNum(val)
                  }}
                  onBlur={async () => {
                    let params = {
                      mobile: phoneNum,
                      extern: item.extern,
                    }
                    const res = await adminUpdateExtern(params);
                    if (!res.error) {
                      Toast.show({
                        content: '修改成功',
                        duration: 2000,
                        icon: 'success'
                      })
                    }
                    else {
                      Toast.show({
                        content: res.error,
                        duration: 2000,
                        icon: 'fail'
                      })
                    }
                  }}
                >
                  {item.mobile}
                </Input>
              </Card>
            )
          })
        }
      </div>
    </>
  )
}