/**
 * 查询用户信息列表
 * @param {*} props 
 * @returns 
 */

import React, { useCallback, useState, useEffect } from 'react';
import './index.less';
import _ from 'lodash';
import TitleBar from '@/components/TitleBar';
import { useNavigate } from 'react-router-dom';
import { Badge, SearchBar } from 'antd-mobile';
import { UserOutline, RightOutline } from 'antd-mobile-icons';
import { adminSearchAuth } from '@/services/api';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { sav_admin_query_staff_list } from '@/actions';

function UserSearch(props) {
  const role = 'doctor';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staffList = useSelector(state => state.adminQueryStaffList.staffList);

  // 接口查询用户列表
  const _adminSearchAuth = async (searchKeyword) => {
    const res = await adminSearchAuth(searchKeyword);
    dispatch(sav_admin_query_staff_list(res.result));
  }

  // 转换性别
  function renderGender(gender) {
    switch (gender) {
      case 'MALE':
        return '男';
      case 'FEMALE':
        return '女';
      default:
        return '保密';
    }
  }
  // 跳转详情页
  function goDetail (staff) {
    const staffInfo = {...staff, ...staff.authRoleInfo};
    navigate('/admin/staff-detail', {state: {staffInfo}})
  }

  function renderConsultantBlock(staffInfo, index) {
    const {authRoleInfo, account, tenantName, ownerName} = staffInfo
    return <div className='consultant-block' key={index} onClick={() =>{goDetail(staffInfo)}}>
      {
        authRoleInfo.avatar ? <img src={authRoleInfo.avatar} alt="" /> : <UserOutline className={`consultant-head border-color-${role}`} />
      }
      <div className='consultant-info'>
        <div className='consultant-info-title'>
          <div className='consultant-info-name'>{authRoleInfo.name}</div>
          <div className='consultant-info-tag'>{authRoleInfo.tagList || [].join('，')}</div>
        </div>
        <div className='consultant-info-content'>
          渠道：{tenantName}
          &nbsp;&nbsp;&nbsp;&nbsp;
          公司：{ownerName}  
        </div>
        <div className='consultant-info-content'>
          <div>账号：{account}</div>
        </div>
      </div>
      <div className={`list-right-icon tx-color-${role}`}><RightOutline /></div>
    </div>
  }

  // 管理员搜索来访者
  function handleSearch(val) {
    if (val) {
      let params = {
        searchText: val
      };
      _adminSearchAuth(params);
    }
  }

  return (
    <>
      <TitleBar title='来访者查询' />
      <div className='audit-consultant-page-content'>
        <SearchBar
          className='search-bar'
          placeholder='输入来访者姓名或者账号'
          onSearch={(val) => {
            handleSearch(val);
          }}
        />
        {staffList && staffList.map((staffInfo, index) => renderConsultantBlock(staffInfo, index))}
      </div>
    </>
  )
}
export default UserSearch;