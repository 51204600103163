// 该页面用于展示来访者全部附件的
import React, { useState } from "react";
import TitleBar from "@/components/TitleBar";
import { List, ImageViewer } from 'antd-mobile';
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { RightOutline } from "antd-mobile-icons";

export default function AttachmentList(props) {
  const location = useLocation();
  const state = _.get(location, ['state']);
  const [imgViewer, setImgViewer] = useState(false);
  const [imgUrl, setImgUrl] = useState();

  return (
    <>
      <TitleBar title="附件" isShowBack={true} />
      <List>
        {
          state.map((element, index) => {
            return (
              <List.Item 
                key={index}
                clickable
                description={MomentDiffZone(element?.startTime).format("YYYY年MM月DD日")}
                extra={element?.resourceType}
                arrow={
                  <div style={{
                    color: '#FFAA00',
                    fontWeight: 'bold',
                  }}>
                    <RightOutline />
                  </div>
                }
                onClick={() => {
                  if (element?.resourceType === 'PICTURE') {
                    setImgViewer(true);
                    setImgUrl(element.url);
                  }
                }}
                >
                {element?.attachmentName}
              </List.Item>
            )
          })
        }
      </List>
      <ImageViewer 
        image={imgUrl}
        visible={imgViewer}
        onClose={() => {
          setImgViewer(false)
        }}
      />
    </>
  )
}
