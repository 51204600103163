
import axios from 'axios';
import { getUrls } from '@/utils/getUrls';
import { getLoginToken } from '../utils/localStorage.js';
import { Toast } from 'antd-mobile';
import changeName from './changeName.js';
import { getEntranceUrl } from '@/utils/localStorage';

const { BASE_URL } = getUrls();
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 10000
})
const httpExternal = axios.create({
  timeout: 10000
})

const createHistory = require('history').createBrowserHistory;
let history = createHistory();

const entry = getEntranceUrl();

// 请求拦截器
http.interceptors.request.use(config => {
  // console.log('config::', config)
  const locationType = changeName(history.location.pathname.split('/')[1]);
  // add header
  config.headers = { ...config.headers, Authorization: getLoginToken(locationType) }

  return config;
}, err => { })

// 响应拦截器
// 给相应拦截器增加白名单，跳过某些特定地址的拦截
const whitelist = ['/eventDot/create'];

// 白名单的url与实际请求的url不一致，所以需要对比
const isWhiteUrl = (url, whitelist) => {
  const pureUrl = url.split('?')[0];
  return whitelist.some(item => item === pureUrl);
}

http.interceptors.response.use(res => {
  // console.log('res:interceptors', res)
  // check Authorization is valid
  // const locationType = changeName(history.location.pathname.split('/')[1]);
  if (!isWhiteUrl(res.config.url, whitelist)) {
    const { error, errorCode, success } = res.data;
    if (!success && errorCode === 'INVALID_TOKEN') {
      history.replace(entry);
      window.location.reload();
      return res;
    } else if (errorCode && errorCode !== 'BIZ_CHECK_FAIL' && errorCode !== 'SUBMIT_CONSULT_FAIL_ALREADY_END') {
      console.error('errorCode', errorCode);
      Toast.show({ icon: 'fail', content: error });
      return res;
    }
  }
  return res;
}, err => {
  const res = {
    data: {
      errorCode: '888',
      error: err.toString(),
      result: {
        data: null
      }
    }
  }
  return res
})

const httpClient = {
  async get(url, params, ex) {
    if (ex) {
      const res = await httpExternal.get(url, params);
      return res.data;
    }
    const res = await http.get(url, params);
    return res.data;
  },
  async post(url, data, ex, options) {
    if (ex) {
      const res = await httpExternal.post(url, data, options);
      return res.data;
    }
    const res = await http.post(url, data, options);
    return res.data;
  },
  async put(url, data) {
    const res = await http.put(url, data);
    return res.data;
  },
  async delete(url, data) {
    const res = await http.delete(url, data);
    return res.data;
  }
}

export default function request(options) {
  const { method, data, params, type, ex } = options;
  let { url } = options;
  if (url.includes('?')) {
    url += `&t=${Date.now()}`;
  } else {
    url += `?t=${Date.now()}`;
  }
  if (method === 'GET') {
    return httpClient.get(url, params, ex);
  }
  if (type === 'upload') {
    return httpClient[method.toLowerCase()](url, data, ex, { headers: { 'Content-Type': 'multipart/form-data' } });
  }
  return httpClient[method.toLowerCase()](url, data, ex);
}
