/**
 * 咨询师列表页
 */
import React, {useState, useEffect, useCallback} from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import {List, Toast } from 'antd-mobile';
import {bindList} from '@/services/api';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

function ConsultantList (props) {
  const testData = [
    {
      consultantId:'1',
      name: '普通精神科'
    },
    {
      consultantId:'2',
      name: '老年精神科'
    },
    {
      consultantId:'3',
      name: '临床心理科'
    },
    {
      consultantId:'4',
      name: '营养科'
    },
    {
      consultantId:'5',
      name: '专家门诊'
    }
  ]
  const navigate = useNavigate();

  // 咨询师列表
  const [consultantList, setConsultantList] = useState([]);

  const getBindList = useCallback(async() => {
    let res = await bindList();
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: '获取绑定咨询师列表失败',
      })
    } else {
      let appointList = _.get(res, ['result'], []);
      setConsultantList(appointList);
    }
  }, [])

  useEffect(() =>{
    getBindList();
  }, [getBindList])

  function renderConsultantList() {
    return (
      <List className='consultant-list' header='请选择您想要预约的门诊'>
        {
          consultantList.map(item => {
            return (
              <List.Item key={item.consultantId} onClick={() =>{
                navigate('/client/calendar', {state: {consultantId: item.consultantId, consultName: item.name}})
              }}>{item.name}</List.Item>
            )
          })
        }
      </List>
    )
  }
	return(
		<>
			<TitleBar isShowBack={true} title="选择科室"></TitleBar>
      {renderConsultantList()}
		</>
	)
}

export default ConsultantList;