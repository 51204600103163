// 减压视频
import React, {useState, useEffect} from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { useNavigate, useLocation} from 'react-router-dom';
import { Button, List } from 'antd-mobile';


function Jysp() {
  const navigate = useNavigate();
	const location = useLocation();
	const videoList = [
		{
			title: '正念练习',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/mindfulness.mp4'
		},
		{
			title: '八段锦',
			videoUrl: 'https://image.siuvo.com.cn/union_baduanjin/baduanjin_intro.mp4'
		},
		{
			title: '正念慈心练习',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/cixin.mp4'
		},
		{
			title: '情绪释放技术',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/ReleaseEmotion.mp4'
		},
		{
			title: '肌肉放松训练',
			videoUrl: 'https://image.siuvo.com.cn/fafuli/relax.mp4'
		},
	]

	function onVideoPlay(item) {
		navigate('/client/ex-video-play', {state: {videoInfo: item}})
	}

  function renderVideoList() {

    return (
			<List>
				{
					videoList.map((item,index) => {
						return(
							<List.Item key={index} onClick={() => onVideoPlay(item)}>
								<div className='video-item-content'>
									<div>{index+1}、{item.title}</div>
									<Button size='mini' color='primary'>点击播放</Button>
								</div>
							</List.Item>
						)
					})
				}
			</List>
    )
  }

	

  return (
    <>
    <TitleBar title="减压视频"  isShowBack={true}/>
    {
      renderVideoList()
    }
    </>
  )
}

export default Jysp;