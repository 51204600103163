// 国药专属关爱服务
import React, { useState } from "react";
import './index.less';
import _ from 'lodash';
import TitleBar from "@/components/TitleBar";
import { Button, Checkbox, Space, Radio, Toast } from 'antd-mobile';
import { addStaffCareFor } from "@/services/api";
import { useNavigate, useLocation } from "react-router-dom";

export default function ExclusiveLove() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { reportUrl, taskId, text } = state;

  const [isNeedService, setIsNeedService] = useState(false);
  const [isNeedPublic, setIsNeedPublic] = useState(false);
  const [weekdaysTImeBucket, setWeekdaysTImeBucket] = useState([]);
  const [weekendTImeBucket, setWeekendTImeBucket] = useState([]);

  const questionList = [
    {
      id: 'service',
      type: 'checkbox',
      question: '我们将由专属员工关爱师与您联系，为您提供报告解读服务，给你提供针对性建议和心理健康保健指导。您是否需要这项服务?',
      option: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ],
      defaultValue: true
    },
    {
      type: 'checkbox',
      question: ' 您是否愿意公开您的测评报告给关爱顾问?',
      option: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ],
      defaultValue: true,
      askedif: {
        id: 'service',
        value: true
      }
    },
    {
      type: 'instruction',
      question: '您可以直接在对话框中与我们的咨询师联系，提出您的问题，咨询师将在12小时内回复您的问题。',
      askedif: {
        id: 'service',
        value: true
      }
    },
    {
      type: 'checkbox',
      question: '工作日:',
      option: [
        {
          label:  '9:00 - 12:00',
          value:  '9:00 - 12:00',
        },
        {
          label: '12:00 - 15:00',
          value: '12:00 - 15:00'
        },
        {
          label:  '15:00 - 18:00',
          value:  '15:00 - 18:00'
        },
        {
          label:  '18:00 - 21:00',
          value:  '18:00 - 21:00'
        }
      ],
      askedif: {
        id: 'service',
        value: true
      }
    },
    {
      type: 'checkbox',
      question: '休息日:',
      option: [
        {
          label:  '9:00 - 12:00',
          value:  '9:00 - 12:00',
        },
        {
          label: '12:00 - 15:00',
          value: '12:00 - 15:00'
        },
        {
          label:  '15:00 - 18:00',
          value:  '15:00 - 18:00'
        },
        {
          label:  '18:00 - 21:00',
          value:  '18:00 - 21:00'
        }
      ],
      askedif: {
        id: 'service',
        value: true
      }
    },
    {
      type: 'instruction-section',
      title: '驻场关爱咨询',
      desc: '与心理健康专家面对面咨询，范围包括但不限于心理健康保健方案、亲子教育、轻松减压、婚恋家庭、职业发展、人际关系等'
    },
    {
      type: 'link',
      text: '预约入口链接',
      url: 'https://www.wjx.top/vm/PQSgzzo.aspx#'
    }
  ]

  const TimeBucket = [
    '9:00 - 12:00',
    '12:00 - 15:00',
    '15:00 - 18:00',
    '18:00 - 21:00',
  ];

  async function handleConfirm() {
    if (!weekdaysTImeBucket.length && !weekendTImeBucket.length && isNeedService) {
      Toast.show({
        icon: 'fail',
        content: '请选择服务时间',
      })
      return;
    };

    let params = {
      isNeedService,
      isNeedPublic,
      weekdaysTImeBucket,
      weekendTImeBucket,
      taskId,
      reportUrl,
    }
    let res = await addStaffCareFor(params);
    Toast.show({
      icon: 'success',
      content: '提交成功',
      duration: 1000,
      afterClose: () => {
        navigate('/client/home-v2/main');
      }
    })
  }

  function renderCheckbox() {

    return (
      <>
        <div className="content-item">
          <div className="content-item-question">
            您是否愿意公开您的测评报告给关爱顾问?
          </div>
          <div className="content-item-audio-answer">
            <Radio.Group
              value={isNeedPublic}
              onChange={(val) => {
                setIsNeedPublic(val);
              }}
            >
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="content-item">
          <div className="content-item-question">
            您可以直接在对话框中与我们的咨询师联系，提出您的问题，咨询师将在12小时内回复您的问题。
          </div>
        </div>
        <div className="content-item">
          <div className="content-item-question">
            您也可以选择电话关爱的方式，我们的心理健康顾问将在您方便的时间跟您电话联系。请选择您方便的时间:
          </div>
          <div className="content-item-checkbox-answer">
            <Space direction='vertical'>
              <Checkbox
                indeterminate={weekdaysTImeBucket.length > 0 && weekdaysTImeBucket.length < TimeBucket.length}
                checked={weekdaysTImeBucket.length === TimeBucket.length}
                onChange={(checked) => {
                  if (checked) {
                    setWeekdaysTImeBucket(TimeBucket);
                  } else {
                    setWeekdaysTImeBucket([]);
                  }
                }}
              >
                工作日
              </Checkbox>
              <Checkbox.Group
                value={weekdaysTImeBucket}
                onChange={(val) => {
                  setWeekdaysTImeBucket(val);
                }}
                style={{ marginLeft: '2.5rem' }}
              >
                <Space direction='vertical' style={{ marginLeft: '2.5rem' }}>
                  {
                    TimeBucket.map((item, index) => {
                      return (
                        <Checkbox value={item} key={index}>{item}</Checkbox>
                      )
                    })
                  }
                </Space>
              </Checkbox.Group>
            </Space>
            <Space direction='vertical' style={{ marginTop: '10px' }}>
              <Checkbox
                indeterminate={weekendTImeBucket.length > 0 && weekendTImeBucket.length < TimeBucket.length}
                checked={weekendTImeBucket.length === TimeBucket.length}
                onChange={(checked) => {
                  if (checked) {
                    setWeekendTImeBucket(TimeBucket);
                  } else {
                    setWeekendTImeBucket([]);
                  }
                }}
              >
                休息日
              </Checkbox>
              <Checkbox.Group
                value={weekendTImeBucket}
                onChange={(val) => {
                  setWeekendTImeBucket(val);
                }}
                style={{ marginLeft: '2.5rem' }}
              >
                <Space direction='vertical' style={{ marginLeft: '2.5rem' }}>
                  {
                    TimeBucket.map((item, index) => {
                      return (
                        <Checkbox value={item} key={index}>{item}</Checkbox>
                      )
                    })
                  }
                </Space>
              </Checkbox.Group>
            </Space>
          </div>
        </div>
      </>
    )
  }

  function renderExcusiveLove() {
    return (
      <div className="exclusive-love-content">
        <div className="content-item">
          <div className="content-item-question">
            我们将由专属员工关爱师与您联系，为您提供报告解读服务，给你提供针对性建议和心理健康保健指导。您是否需要这项服务?
          </div>
          <div className="content-item-audio-answer">
            <Radio.Group
              value={isNeedService}
              onChange={(val) => {
                setIsNeedService(val);
              }}
            >
              <Radio value={true} >是</Radio>
              <Radio value={false} >否</Radio>
            </Radio.Group>
          </div>
        </div>
        {
          isNeedService
            ? renderCheckbox()
            : null
        }
        <div className="content-item">
          <div className="content-item-title">
            驻场关爱咨询
          </div>
          <div className="content-item-question">
            与心理健康专家面对面咨询，范围包括但不限于心理健康保健方案、亲子教育、轻松减压、婚恋家庭、职业发展、人际关系等
          </div>
          <a className="content-item-url" href='https://www.wjx.top/vm/PQSgzzo.aspx#'>
            预约入口链接
          </a>
        </div>
        <div className="button-submit-content">
          <Button
            color="primary"
            fill="solid"
            size="middle"
            className="confrim-button"
            onClick={() => {
              handleConfirm();
            }}
          >
            确认提交
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <TitleBar
        title="国药专属关爱服务"
        isShowBack={true}
        rightContent='测评报告'
        rightContentOnclick={() => {
          navigate("/client/form-task-list");
        }}
      />
      {
        renderExcusiveLove()
      }
    </>
  )
}