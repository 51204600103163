import React, { useState, useEffect } from "react";
import _ from "lodash";
import TitleBar from "@/components/TitleBar";
import StaffBaseInfoComponent from "@/components/UserInfo/StaffBaseInfo";
import { doctorGetPatientInfo } from "@/services/api";
import { getLoginToken } from '@/utils/localStorage.js';
import { lobType } from '@/Constant';
import { getRoleFromLocation } from '@/utils/commonUtils';
import changeName from '@/utils/changeName';
import { getPageQuery, getLobFromToken } from '@/utils/utils';

function PatientOnlyBaseInfo(props) {
  const role = changeName(getRoleFromLocation());
  const [patientInfo, setPatientInfo] = useState({});
  const lob = getLobFromToken(getLoginToken(role));
  const {authId} = getPageQuery();


  useEffect(() => {
    _doctorGetPatientInfo(authId);
  }, []);

  async function _doctorGetPatientInfo(authId) {
    let params = {
      authId,
      groupReportBy: 'groupByForm',
    }
    let res = await doctorGetPatientInfo(params);
    setPatientInfo(_.get(res, "result", {}));
  }

  return (
    <>
      <TitleBar title="来访者信息" isShowBack={true}></TitleBar>
      <StaffBaseInfoComponent patientInfo={patientInfo} role='doctor' />
    </>
  );
}

export default PatientOnlyBaseInfo;
