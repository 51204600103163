import React from 'react';
import './index.less';
import _ from 'lodash';
import { SpinLoading } from 'antd-mobile'
import TitleBar from '@/components/TitleBar';

function IframeComponent(props) {

  function loadinfo() {

    return (
      <div className='spin-loading' id='loading'>
        <SpinLoading style={{ '--size': '48px' }} />
      </div>
    )
  }

  function deleteLoadingElement() {
    const loading = document.getElementById('loading');

    if (loading) {
      loading.remove();
    }
  }

  return (
    <>
      <TitleBar
        title={props.title ? props.title : null}
        isShowBack={true}
        barCancel={props.barCancel ? props.barCancel : null}
      />
      <iframe
        allow={props.permission}
        title="iframe"
        className="iframe"
        src={props.src}
        onLoad={deleteLoadingElement()}
      />
      {loadinfo()}
    </>
  )
}
export default IframeComponent;