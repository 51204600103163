import React, { useEffect, useState, useCallback } from 'react';
import './index.less';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast, Dialog } from 'antd-mobile';

import TitleBar from '@/components/TitleBar';
import WithTracking from '@/components/WithTracking';
import { funcList, commonConstant } from '@/Constant';
import { decodeToken } from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';
import { getStaffTagList } from '@/services/api';
import PeibanComponent from '@/components/PeibanComponent';
import ZixunComponent from '@/components/ZixunComponent';
import YuyueComponent from '@/components/YuyueComponent';
import ZizhuchengzhangComponent from '@/components/ZizhuchengzhangComponent';
import ZiyuanComponent from '@/components/ZiyuanComponent';
import CepingComponent from '@/components/CepingComponent';
import BannerComponent from '@/components/BannerComponent';

const ROLE = 'patient';

function Main() {
  // 解析token
  const navigate = useNavigate();
  const location = useLocation();
  const { tenantId } = decodeToken(getLoginToken(ROLE))
  const staffId = useSelector(state => state.baseInfo[ROLE]?.roleInfo?.staffId);

  const { pageTitle = commonConstant.pageTitle, funcs, hotLine, appointCall, trainingCall } = funcList[tenantId] || funcList.default;

  // // 初始化查询用户tag
  // useEffect(() => {
  //   _getStaffTagList()
  // }, [])

  // // 查询用户tag列表--目前查询tag未用到
  // async function _getStaffTagList () {
  //   const res = await getStaffTagList();
  //   if (res.errorCode) {
  //     Toast.show({
  //       content: res.error
  //     })
  //     return;
  //   }
  //   const tags = _.get(res, ['result', 'data'], [])
  // }

  // 渲染区 |
  // 自定义dialog
  function showConfirmDialog(title, content, onConfirm = () => { }) {
    return (
      Dialog.confirm({
        title,
        content,
        onConfirm
      })
    )
  }

  function renderTitleRight() {
    return (
      <div onClick={() => {
        navigate('/client/setting');
      }}>
        <img style={{ width: '2.4rem' }} alt="" src={require('@/assets/icon/patient_setting.svg').default} />
      </div>
    )
  }

  // 点击心理健康报告
  function handleHealthReportClick () {
    navigate('/client/health-report');
  }

  //渲染功能
  function renderFuncs() {
    return (
      <>
        <BannerComponent funcAuthList={funcs} onHealthReportClick={handleHealthReportClick}/>
        <PeibanComponent funcAuthList={funcs} />
        <ZixunComponent funcAuthList={funcs} hotLine={hotLine} />
        <YuyueComponent funcAuthList={funcs} showConfirmDialog={showConfirmDialog} appointCall={appointCall}/>
        <ZizhuchengzhangComponent funcAuthList={funcs} tenantId={tenantId}/>
        <CepingComponent funcAuthList={funcs} staffId={staffId} tenantId={tenantId}/>
        <ZiyuanComponent funcAuthList={funcs} />
      </>
    )
  }
  return (
    <>
      <TitleBar title={pageTitle} isShowBack={false} rightContent={renderTitleRight()} />
      <div className='patient-homeV2'>
        {
          renderFuncs()
        }
      </div>
    </>
  )
}

export default WithTracking(
  Main,
  {
    name: 'visit:homepage',
    props: {
      eventName: '进入首页'
    }
  }
);