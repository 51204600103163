import React, { useState } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { setLoginToken, setAuthId, getLoginToken } from '@/utils/localStorage.js';
import { Button, List, Toast, Picker } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@/components/Avatar';
import { commonConstant } from '@/Constant';
import { disconnect_socket } from '@/actions';
import { getLobFromToken, getTenantIdFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';
import { decodeToken } from '../../../utils/utils';
import useEntryPath from '@/hooks/useEntryPath';
import useDarkmode from '@/hooks/useDarkmode';
import subAccountBlackList from './subAccountBlackList';

function UserSetting(props) {
  const token = getLoginToken('patient');
  const lob = getLobFromToken(token);
  const tenantId = getTenantIdFromToken(token);
  const {isChild} = decodeToken(token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getEntryPath } = useEntryPath();
  const role = 'patient';
  const baseInfo = useSelector(state => {
    return state.baseInfo[role].roleInfo || {}
  });
  const entryPath = getEntryPath();
  const [timeZoneDialog, setTimeZoneDialog] = useState(false);  // 时区选择弹框
  const [timeZone, setTimeZone] = useState('Asia/Shanghai');  // 时区
  const DarkmodeContent = useDarkmode();

  // 时区选择项
  const timeZoneList = [
    [
      { label: '中国', value: 'Asia/Shanghai' },
      { label: '美国', value: 'America/New_York' },
    ],
  ];

  let funcMenu = [];
  lob === lobType.SFDA
    ? funcMenu = [
      {
        label: '基本资料',
        action: () => { navigate('/client/self-info', { state: { role } }) }
      },
      {
        label: '量表报告',
        action: () => { navigate('/client/form-task-list') }
      },
      // {
      //   label: '账户管理',
      //   action: ''
      // },
      // {
      //   label: '语言设置',
      //   action: ''
      // },
      // {
      //   label: '提醒设置',
      //   action: ''
      // },
    ]
    : funcMenu = [
      {
        label: '基本资料',
        action: () => { navigate('/client/self-info', { state: { role } }) }
      },
      {
        label: '量表报告',
        action: () => { navigate('/client/form-task-list') }
      },
      {
        label: '我的评价',
        action: () => { navigate('/client/my-evaluation') }
      },
      {
        label: '时区选择',
        action: () => { setTimeZoneDialog(true) }
      },
      {
        label: '提醒设置',
        action: () => {navigate('/client/reminder-setting')}
      }
    ]
    // 黑名单中的，不开放创建子账号，，，isChild子账号也不能创建
    if (subAccountBlackList.indexOf(tenantId) < 0 && !isChild) {
      funcMenu.push(
        {
          label: '查看子账户',
          action: () => { navigate('/client/sub-account') }
        }
      )
    }

  function renderHead() {
    return (
      <div className='header-content darkmode-ignore'>
        <Avatar avatarUrl={baseInfo.avatarUrl} />
        <div className='user-name'>{baseInfo.name || '来访者'}</div>
      </div>
    )
  }

  function renderMenu() {
    return (
      <List className='menu-list'>
        {funcMenu.map((item, index) => {
          return (
            <List.Item
              key={item?.label}
              className='menu-list-item'
              arrow={true}
              onClick={() => {
                if (item.action) {
                  item.action();
                } else {
                  Toast.show({
                    content: commonConstant.INCOMPLETE_FUNC_HINT
                  })
                }
              }}
            >
              {item.label}
            </List.Item>
          )
        })}
      </List>
    )
  }

  function logout () {
    dispatch(disconnect_socket())
    // setLoginToken(role, '');
    // setAuthId(role, '');
    // localStorage.removeItem('firstEvaluationStatus');
    navigate('/client/login-with-register', { replace: true })
    localStorage.clear();
    window.location.reload();
  }
  const logoutThrottled = _.throttle(logout, 3000, { trailing: false })

  return (
    <div className='patient-setting'>
      <TitleBar
        isShowBack={true}
        title="个人中心"
        // rightContent={DarkmodeContent}
      />
      <div className='user-setting-content'>
        <div className='temp'>
          {renderHead()}
          {renderMenu()}
        </div>
        <Button className="setting-logout" onClick={logoutThrottled}>
          退出登录
        </Button>
        <Picker
          visible={timeZoneDialog}
          columns={timeZoneList}
          onClose={() => {
            setTimeZoneDialog(false);
          }}
          value={timeZone}
          onConfirm={(val, extend) => {
            setTimeZone(val[0]);
            localStorage.setItem('timeZone', val[0]);
          }}
        />
      </div>
    </div>
  )
}

export default UserSetting;