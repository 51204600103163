// 用户自测量表的iframe
import React, { useEffect } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import IframeComponent from '@/components/Iframe';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';

function UserFormIframe(props) {
  const { handleAddEventDotClick } = props;
  const location = useLocation();
  const title = _.get(location, ['state', 'title']);
  const url = _.get(location, ['state', 'url']);

  useEffect(() => {
    handleAddEventDotClick && handleAddEventDotClick({
      name: 'enter:psychological/test',
      props: {
        event: `心理测评:${title}`
      }
    })
  }, [handleAddEventDotClick, title])

  return (
    <>
      <IframeComponent title={title} src={url} />
    </>
  )
}

export default WithTrackingForMedia(UserFormIframe);