import { SAVE_DOCTOR_INFO, SAVE_PATIENT_INFO, SAVE_ADMIN_INFO } from "@/actions/actionType";

const baseInfoReducer = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case SAVE_DOCTOR_INFO:
      newState = { ...state, doctor: action.info }
      return newState;
    case SAVE_PATIENT_INFO:
      newState = { ...state, patient: action.info }
      return newState;
    case SAVE_ADMIN_INFO:
      newState = { ...state, admin: action.info }
      return newState;
    default:
      return state;
  }
}

export default baseInfoReducer