import React, { useState, useEffect, useCallback, useRef } from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useLocation, useNavigate } from 'react-router-dom';
import { CalendarOutline } from 'antd-mobile-icons'
import { getPatientBaseInfo } from '@/services/api'
import CustomDatePicker from '@/components/CustomDatePicker';
import { isMoreThan24Hours } from '@/utils/commonUtils'
import { emitterEvent } from '@/Constant';
import EventEmitter from '@/utils/eventEmitter';
import { getLoginToken } from '@/utils/localStorage'
import { getLobFromToken } from '@/utils/utils';
import {lobType} from '@/Constant';
import { getPageQuery } from '@/utils/utils';

function CalendarComponent(props) {
  let calendarRef = useRef();
  let location = useLocation();
  const navigate = useNavigate();
  const {consultName, consultantId} = _.get(location, ['state'], '') || getPageQuery();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(new Date());
  const [selectAppointTimeStatus, setSelectAppointTimeStatus] = useState('');
  const [selectId, setSelectId] = useState('');
  const [selectStartAppointTime, setSelectStartAppointTime] = useState('');
  const [selectEndAppointTime, setSelectEndAppointTime] = useState('');
  const [selectStaffName, setSelectStaffName] = useState('');
  const [selectAppointInfo, setSelectAppointInfo] = useState({});

  // 角色的颜色区分
  const roleStyleColor = props.role == 'doctor' ? '#FFB92E' : '#2979FF';
  const lob = getLobFromToken(getLoginToken(props.role));

  // 个人咨询师及来访者，可预约时间为全天
  const canAppointStartTime = lob === lobType.CONSULTANT ? '01:00' : '09:00'
  const canAppointEndTime = lob ===  lobType.CONSULTANT ? '24:00' : '22:00'

  // 接收notification，刷新当前日历
  useEffect(() => {
    const updateCalendarData = () => {
      handleCalendarRef()
    }
    EventEmitter.on(emitterEvent.APPOINT_UPDATE, updateCalendarData);
    return () => {
      EventEmitter.removeListener(emitterEvent.APPOINT_UPDATE, updateCalendarData);
    }
  }, [])


  useEffect(() => {
    handleCalendarRef();
  }, [props.refreshCalendar]);

  function handleCalendarRef() {
    // 隐藏日历下的事件
    setSelectAppointTimeStatus('');
    let calendarApi = calendarRef.current.getApi();
    const fromTime = _.get(calendarApi, ['currentDataManager', 'data', 'viewApi', 'activeStart'], '').getTime();
    const toTime = _.get(calendarApi, ['currentDataManager', 'data', 'viewApi', 'activeEnd'], '').getTime();
    _getConsultantScheduling(consultantId, { consultantId, fromTime, toTime });
  }

  // 根据type
  function getTitleAndStyleByStatus(type, isSelfAppoint, timeslot) {
    let title = '';
    let className = '';
    // 超过24小时的，才显示可预约，已过期的显示已越约满
    if (props.isMoreThanSpecificHours) {
      if (!props.isMoreThanSpecificHours(timeslot, props.limitHour))
      return {
        title: '已约满',
        className: 'eventColorBusy'
      }
    }
    switch (type) {
      case 'APPOINT':
        if (props.role === 'doctor') {
          title = '已预约';
          className = 'eventColorBusy';
        } else if (props.role === 'patient') {
          if (isSelfAppoint) {
            title = '已预约';
            className = 'eventColorAppointed';
          } else {
            title = '已约满';
            className = 'eventColorBusy';
          }
        }
        break;
      case 'CAN_APPOINT':
        title = '可预约';
        className = 'eventColorFree';
        break;
      case 'SCHEDULING':
        title = '排班';
        className = 'eventColorScheduling';
        break;
      default:
        break;
    }
    return { title, className };
  }

  function convertCalendarData(sourceData) {
    let targetData = sourceData.reduce((pre, cur) => {
      let targetItem = { title: '', start: '', end: '', className: '', extendedProps: {} };
      const { title, className } = getTitleAndStyleByStatus(cur.type, cur.isSelfAppoint, cur.timeslot);
      if ((props.role === 'doctor' || props.role === 'patient') && cur.type === 'APPOINT') {
        targetItem.id = _.get(cur, ['appointInfo', 'appointId'], '');
        targetItem.extendedProps.appointInfo = _.get(cur, ['appointInfo'], {});
        // 咨询师或者员工，可预约，id标识为时段id
      } else if ((props.role === 'doctor' || props.role === 'patient') && cur.type === 'CAN_APPOINT') {
        targetItem.id = cur.timeslotId
      } else if (props.role === 'patient' && cur.type === 'SCHEDULING') {
        return pre;
      }
      targetItem.title = title;
      targetItem.className = className;
      targetItem.start = MomentDiffZone(cur.timeslot).format('YYYY-MM-DD HH:mm:00');
      targetItem.end = MomentDiffZone(cur.timeslot).add(props.appointInterval, 'H').format('YYYY-MM-DD HH:mm:00');
      targetItem.type = cur.type;
      pre.push(targetItem)
      return pre;
    }, [])
    return targetData;
  }

  async function _getConsultantScheduling(consultantId, data) {
    if (props.getConsultantScheduling) {
      let res = await props.getConsultantScheduling(consultantId, data);
      const result = _.get(res, 'result', []);
      setCalendarEvents(convertCalendarData(result));
    }
  }



  function handleEventClick(params) {
    const id = params.event.id || '';
    const title = params.event.title;
    setSelectAppointTimeStatus(params.event.classNames[0]);
    setSelectId(id);
    setSelectStartAppointTime(params.event.start);
    setSelectEndAppointTime(params.event.end);
    const appointInfo = params.event.extendedProps.appointInfo;
    if (appointInfo) {
      setSelectAppointInfo(appointInfo);
      setSelectStaffName(appointInfo?.staffName);
    } else {
      setSelectStaffName('');
    }
  }

  function calendarPrev() {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    handleCalendarRef();
  }

  function calendarNext() {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    handleCalendarRef();
  }

  function updateDatePicker(date) {
    setDatePickerValue(date);
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(MomentDiffZone(date).format('YYYY-MM-DD'));
    handleCalendarRef();
  }

  function renderOperateButton() {
    if (props.role === 'patient') {
      if (selectAppointTimeStatus === 'eventColorFree') {
        return <div className='appointment-button' onClick={() => {
          if (props.openAppointmentModal) {
            props.openAppointmentModal(consultantId, consultName, selectId, selectStartAppointTime, selectEndAppointTime);
          }
        }}>预约</div>
      } else if (selectAppointTimeStatus === 'eventColorAppointed') {
        // if (new Date(selectStartAppointTime).getTime() - new Date().getTime() > 1000 * 60 * 60 * 24) {
        if (isMoreThan24Hours(selectStartAppointTime)) {
          return <div className='appointment-button' onClick={() => {
            if (props.openCancelAppointModal) {
              props.openCancelAppointModal(selectId, consultName, selectStartAppointTime, selectEndAppointTime);
            }
          }}>取消</div>
        }
      }
    } else if (props.role === 'doctor') {
      if (selectAppointTimeStatus === 'eventColorBusy') {
        return null;
        // return <div className='appointment-button' onClick={() => {
        //   if (props.openCancelAppointModal) {
        //     props.openCancelAppointModal('cancel', selectId, consultName, selectStartAppointTime, selectEndAppointTime);
        //   }
        // }}>取消</div>
      } else if (selectAppointTimeStatus === 'eventColorFree') {
        return <div className='appointment-button' onClick={() => {
          if (props.openCancelAppointModal) {
            props.openCancelAppointModal('delete', selectId, consultName, selectStartAppointTime, selectEndAppointTime);
          }
        }}>删除该时间段</div>
      }
    }
    return null;
  }

  function renderCalendarDayFormat(e) {
    const date = e.date;
    const day = e.text.split('周')[1]
    const weekday = MomentDiffZone(date).get('date');
    return <div>
      <div className='date-formate-line1'>{day}</div>
      <div className='date-formate-line2'>{weekday}</div>
    </div>
  }

  return (
    <div
      className='calendar-page'
      style={{
        color: roleStyleColor,
      }}
    >
      {/* <div className=''>
        <div onClick={() => setDatePickerVisible(true)} className='calendar-date-picker'>
          <div className='calendar-date-value'>
            {MomentDiffZone(datePickerValue).format('YYYY-MM-DD')}
            <div className='calendar-date-icon'><CalendarOutline color='blue' /></div>
          </div>
        </div> */}
      <CustomDatePicker
        title='时间选择'
        visible={datePickerVisible}
        value={datePickerValue}
        showWeek={true}
        onClose={() => setDatePickerVisible(false)}
        onConfirm={val => updateDatePicker(new Date(val))}
      />
      {/* </div> */}
      <div className='calendar-change-page'>
        <div className={`calendar-change-button color-${props.role}`} onClick={() => calendarPrev()}>&nbsp;<LeftOutline fontSize={18} />&nbsp;</div>
        <div className='calendar-change-block' onClick={() => setDatePickerVisible(true)}></div>
        <div className={`calendar-change-button color-${props.role}`} onClick={() => calendarNext()}>&nbsp;<RightOutline fontSize={18} />&nbsp;</div>
      </div>
      <div className='calendar-block'>
        <FullCalendar
          ref={calendarRef}
          initialView="timeGridWeek"
          timeZone="local"
          slotMinTime={canAppointStartTime}
          scrollTime={canAppointStartTime}
          slotMaxTime={canAppointEndTime}
          locale="zh-cn"
          firstDay="1"
          weekNumberCalculation="ISO"
          allDaySlot={false}
          eventTimeFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }}
          headerToolbar={{ left: '', center: 'title', right: '' }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          events={calendarEvents}
          eventClick={e => handleEventClick(e)}
          // 头部时间格式
          dayHeaderContent={(e) => {
            return renderCalendarDayFormat(e);
          }}
          // 左侧时间格式
          slotLabelFormat={{
            hour: '2-digit',
            // 时间是否显示分种
            // minute: '2-digit',
            // meridiem: 'lowercase',
            hour12: false,
          }}
        />
      </div>
      {selectAppointTimeStatus ? <div className={'appointment-block appointment-block-' + selectAppointTimeStatus}>
        <div className='appointment-time'>
          <div>
            <div className='appointment-start-time'>{selectStartAppointTime ? MomentDiffZone(selectStartAppointTime).format('HH:mm') : ''}</div>
            <div className='appointment-end-time'>{selectEndAppointTime ? MomentDiffZone(selectEndAppointTime).format('HH:mm') : ''}</div>
          </div>
          {props.role === 'doctor' ? <div className='appoint-staff-name' onClick={() => {
            navigate(`/therapist/client-info?authId=${selectAppointInfo.staffId}`)
          }}>{selectStaffName}</div> : null}
        </div>
        {renderOperateButton()}
      </div> : null}
    </div>
  )
}

export default CalendarComponent;