// 全局的监听事件
import React, { useEffect, useRef } from "react";
import _ from 'lodash';
import { useLocation, useNavigate } from "react-router-dom";
import EventEmitter from '@/utils/eventEmitter';
import { getLoginToken } from '@/utils/localStorage';
import { getLobFromToken } from '@/utils/utils';
import message_receive from '@/assets/audio/message_receive.mp3';

export default function Listener() {
  const location = useLocation();
  const pathname = _.get(location, ['pathname'], '');
  // 判断是否处于聊天页面
  const isDialoguePage = pathname.indexOf('/dialogue') !== -1;
  const chatAuthId = _.get(location, ['state', 'chatAuthId'], '');       // 来访者获取咨询师id
  const authId = _.get(location, ['state', 'staffInfo', 'authId'], '');  // 咨询师获取来访者id
  const chatOppositeAuthId = chatAuthId || authId;
  const navigate = useNavigate();
  const audioRef = useRef(null);

  let throttled = _.throttle(() => {
    audioRef.current.play();
  }, 5000, { leading: true, trailing: false });

  useEffect(() => {
    // 判断是否是微信浏览器
    const isWeixin = () => {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') !== -1;
    }
    const isWeixinBrowser = isWeixin();
    if (isWeixinBrowser) {
      // 微信浏览器自动播放音频
      document.addEventListener('WeixinJSBridgeReady', function () {
        const audioElement = document.getElementById('audio-div');
        // audioElement.volumn = 1;
        audioElement.muted = false;
      }, false);
    }
    else {
      // 非微信浏览器自动播放音频
      document.body.addEventListener('touchend', function () {
        const audioElement = document.getElementById('audio-div');
        // audioElement.volumn = 1;
        audioElement.muted = false;
      }, false);
    }
  }, [])

  // 监听事件
  useEffect(() => {
    const handleFormEnd = (val) => {
      const lob = getLobFromToken(getLoginToken('patient'));
      // 国药用户跳转到专属关爱页面
      if (lob === 'SFDA') {
        navigate('/client/sfda-exclusive-love', { state: val });
      }
    }

    EventEmitter.on('STAFF_FORM_TASK_SUCCESS', handleFormEnd);
    return () => {
      EventEmitter.removeListener('STAFF_FORM_TASK_SUCCESS', handleFormEnd);
    }
  }, [])

  /**
   *提示音的播放逻辑
    * 1. 当前页面是聊天页面，不播放提示音
    * 2. 当前页面不是聊天页面，播放提示音
    * 3. 当前页面不是聊天页面，但是有未读消息，播放提示音
    * 4. 如果收到不同设备，但是是同一个用户发来的消息，不播放提示音
   */
  // 增加来消息的提示音
  useEffect(() => {
    const handleNewMessage = (data) => {
      const { messageFromWhere } = data;
      if (isDialoguePage && messageFromWhere === 'messageFromOpposite') {
        audioRef.current.pause();
      }
      else if (messageFromWhere === 'messageFromSelf') {
        audioRef.current.pause();
      }
      else {
        throttled();
      }
    }
    EventEmitter.on('UPDATE_UNREAD_MESSAGE', handleNewMessage);
    return () => {
      EventEmitter.removeListener('UPDATE_UNREAD_MESSAGE', handleNewMessage);
    }
  }, [chatOppositeAuthId, isDialoguePage])

  return (
    <audio src={message_receive} muted={true} id="audio-div" ref={audioRef}></audio>
  )
}

