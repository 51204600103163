import React, { useEffect, useState } from "react";
import "./index.less";
import TitleBar from "@/components/TitleBar";
import _, { set } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Input, TextArea, ImageUploader, Checkbox, Space, Button } from "antd-mobile";
import { bindConsultant, updateConsultantInfo, consultantInfoApply } from "@/services/api";
import { startUploadFile } from '@/utils/upload';
import { QRCodeCanvas } from 'qrcode.react';
import { getUrls } from '@/utils/getUrls';

function ConsultantInfoComponent(props) {
  const { BASE_HOST } = getUrls();
  const location = useLocation();
  const navigate = useNavigate();
  const callBackEditType = props?.callBackEditType;
  const editType = props?.editType;
  const [editData, setEditData] = useState({});
  // 擅长领域多选框的值
  const [skilledFieldValue, setSkilledFieldValue] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const doctorInfo = _.get(props, "doctorInfo", {});
  const role = _.get(props, "role", 'doctor');
  const type = _.get(props, "type", '');
  const isNeedShowQrcode = _.get(props, "isNeedShowQrcode", false);
  const qrcodeUrl = BASE_HOST + `client/loginAndBind?consultantId=${doctorInfo.consultantId}`;
  const skilledFieldList = [
    '恋爱/情感',
    '家庭',
    '婚姻',
    '人际关系/社交',
    '个人成长',
    '职业生涯规划',
    '职业/工作类问题',
    '成瘾类',
    '心理症状',
    '身体健康',
    '青少年/儿童',
    '成人关怀',
    '长辈关怀',
    '管理者咨询',
    '危机事件/创伤'
  ];

  function renderGender(gender) {
    switch (gender) {
      case "MALE":
        return "男";
      case "FEMALE":
        return "女";
      default:
        return "保密";
    }
  }

  function _bindConsultant() {
    const modal = Modal.show({
      content: (
        <>
          <div className="modal-title">提示</div>
          <div>
            您确定要绑定{_.get(doctorInfo, "name", "该")}
            咨询师吗？以后都将由这位咨询师为您提供咨询服务。（绑定之后不可自行解绑，如需解绑请联系客服）
          </div>
          <div className="modal-buttons">
            <div
              className="modal-button modal-cancel"
              onClick={() => modal.close()}
            >
              取&nbsp;&nbsp;消
            </div>
            <div
              className="modal-button modal-confirm"
              onClick={async () => {
                submitBindConsultant();
                modal.close();
              }}
            >
              确&nbsp;&nbsp;认
            </div>
          </div>
        </>
      ),
    });
  }

  async function submitBindConsultant() {
    await bindConsultant(doctorInfo.authId);
    const modal = Modal.show({
      content: (
        <>
          <div className="modal-title">提示</div>
          <div>
            {_.get(doctorInfo, "name", "该")}
            咨询师已正式成为您的专属咨询师，您可以自由地和他进行<font color='#f23030'>非实时</font>的文字和语音沟通，也可以预约视频/语音咨询，您要现在开始预约吗？
          </div>
          <div className="modal-buttons">
            <div
              className="modal-button modal-cancel"
              onClick={() => {
                modal.close();
                navigate("/client/home-v2");
              }}
            >
              取&nbsp;&nbsp;消
            </div>
            <div
              className="modal-button modal-confirm"
              onClick={() => {
                modal.close();
                navigate("/client/calendar", {
                  state: { consultantId: doctorInfo.authId },
                });
              }}
            >
              是&nbsp;&nbsp;的
            </div>
          </div>
        </>
      ),
    });
  }

  function renderOperateButton() {
    if (type === "readonly") {
      return null;
    } else if (type === "bind") {
      return (
        <div className={"btn-submit " + role + "-theme-btn-submit"} onClick={() => _bindConsultant()}>
          绑定
        </div>
      );
    } else if (type === "edit") {
      if (editType === 'editing') {
        return (
          <div
            className={"btn-submit " + role + "-theme-btn-submit"}
            onClick={() => {
              Modal.show({
                content: (
                  <div>
                    修改内容已提交管理员审核，审核通过后将更新个人介绍页面
                  </div>
                ),
                closeOnMaskClick: true,
                closeOnAction: true,
                actions: [
                  {
                    key: 'confirm',
                    text: '确认提交信息',
                    onClick: () => {
                      // _updateConsultantInfo({ ...editData, consultantId: doctorInfo.authId });
                      _addConsultantInfo({ ...editData, consultantId: doctorInfo.authId });
                      callBackEditType('edit')
                      props.editCB();
                    },
                    style: {
                      color: '#FFAA00',
                    }
                  }
                ],
              })
            }}
          >
            提交个人信息
          </div>
        );
      } else {
        return (
          <div className={"btn-submit " + role + "-theme-btn-submit"} onClick={() => {
            setEditData({});
            callBackEditType('editing');
          }}>
            编辑个人信息
          </div>
        );
      }
    }
  }

  async function _updateConsultantInfo(data) {
    let res = await updateConsultantInfo(data);
  }

  // 咨询师申请修改资料
  async function _addConsultantInfo(infoData) {
    let res = await consultantInfoApply(infoData);
  }

  return (
    <div className="doctor-block">
      <div className="doctor-info-block">
        <div className="doctor-info-data">
          <div className="doctor-info">
            <div className="doctor-title">
              <div className={"doctor-head " + role + "-theme-head"}>
                {
                  editType === 'editing'
                    ? <ImageUploader beforeUpload={file => startUploadFile(file, res => setEditData({ ...editData, avatarUrl: _.get(res, ['result', 'url'], '') }))}>
                      <img src={_.get(editData, "avatarUrl") || _.get(doctorInfo, "avatarUrl", "")} alt="" />
                    </ImageUploader>
                    : <img src={_.get(doctorInfo, "avatarUrl", "")} alt="" />
                }
              </div>
              <div className="doctor-data">
                {
                  role === 'doctor' ?
                    <div className="doctor-name">
                      {_.get(doctorInfo, "name", "")}
                    </div> : null
                }
                <div className="doctor-other-info">
                  昵称&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {
                    editType === 'editing'
                      ? <TextArea
                        rows={1}
                        placeholder="请输入你的昵称"
                        defaultValue={_.get(doctorInfo, "nickName", "") || ""}
                        onChange={(val) => {
                          setEditData({ ...editData, nickName: val });
                        }}
                      />
                      : <div className="doctor-long-info-content">{(_.get(doctorInfo, "nickName", ""))}</div>
                  }
                </div>
                <div className="doctor-other-info">
                  性别&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{renderGender(_.get(doctorInfo, "gender", ""))}
                </div>
                {/* <div className="doctor-other-info">
                  年龄&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{_.get(doctorInfo, "age", "-")}岁
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="doctor-every-info doctor-every-info-double">
            <div className={"doctor-info-title " + role + "-theme-info-title"}>从业资历</div>
            <div className="doctor-info-content">{_.get(doctorInfo, ["consultantInformation", "seniority"], "-")}</div>
          </div>
          <div className="doctor-every-info doctor-every-info-double">
            <div className={"doctor-info-title " + role + "-theme-info-title"}>最高职位</div>
            <div className="doctor-info-content">{_.get(doctorInfo, ["consultantInformation", "position"], "-")}</div>
          </div> */}
          <div className="doctor-long-info">
            <div className={"doctor-long-info-title " + role + "-theme-long-info-title"}>一句话介绍</div>
            {
              editType === 'editing'
                ? <TextArea placeholder='请输入内容，最大字数50字' showCount maxLength={50} autoSize={{ minRows: 2 }} defaultValue={_.get(doctorInfo, ["consultantInformation", "simpleIntroduce"]) || ""} onChange={val => setEditData({ ...editData, simpleIntroduce: val })} />
                : <div className="doctor-long-info-content">{_.get(doctorInfo, ["consultantInformation", "simpleIntroduce"], "-")}</div>
            }
          </div>
          <div className="doctor-every-info" style={{ display: 'flex', flexDirection: 'column', }}>
            <div className={"doctor-info-title " + role + "-theme-info-title"}>个案总时长</div>
            {
              editType === 'editing'
                ? <Input defaultValue={_.get(doctorInfo, ["consultantInformation", "serviceTimeLong"], "-")} type='number' style={{ width: '50%' }} onChange={(val) => setEditData({ ...editData, serviceTimeLong: val })} />
                : <div className="doctor-info-content">{_.get(doctorInfo, ["consultantInformation", "serviceTimeLong"], "-")}</div>
            }
          </div>
          <div className="doctor-every-info" style={{ display: 'flex', flexDirection: 'column', }}>
            <div className={"doctor-info-title " + role + "-theme-info-title"}>擅长领域</div>
            {
              editType === 'editing'
                ? (
                  <>
                    <Checkbox.Group
                      style={{
                        width: '50%',

                      }}
                      value={skilledFieldValue}
                      onChange={(val) => {
                        setEditData({ ...editData, tags: val })
                        setSkilledFieldValue(val)
                      }}
                      defaultValue={_.get(doctorInfo, ["consultantInformation", "tags"], [])}
                    >
                      <Space wrap style={{ '--gap-horizontal': '50px' }}>
                        {
                          skilledFieldList.map((item, index) => {
                            return (
                              <Checkbox key={index} value={item}>
                                {item}
                              </Checkbox>
                            )
                          })
                        }
                      </Space>
                    </Checkbox.Group>
                  </>
                )
                : (
                  <div className="doctor-info-content">{_.get(doctorInfo, ["consultantInformation", "tags"], []).join(',')}</div>
                )
            }
          </div>
          <div className="doctor-long-info">
            <div className={"doctor-long-info-title " + role + "-theme-long-info-title"}>个人介绍</div>
            {
              editType === 'editing'
                ? <TextArea placeholder='请输入内容' autoSize={{ minRows: 2 }} defaultValue={_.get(doctorInfo, ["consultantInformation", "introduce"]) || ""} onChange={val => setEditData({ ...editData, introduce: val })} />
                : <div className="doctor-long-info-content">{_.get(doctorInfo, ["consultantInformation", "introduce"], "-")}</div>
            }
          </div>
          {isNeedShowQrcode &&
            <div className="doctor-long-info">
              <div className={"doctor-long-info-title " + role + "-theme-long-info-title"}>我的二维码</div>
              <div className="doctor-qrcode">
                <QRCodeCanvas value={qrcodeUrl} />,
              </div>
            </div>
          }
        </div>
      </div>
      {renderOperateButton()}
    </div>
  );
}

export default ConsultantInfoComponent;
