// 用于规范和整合打点的时间名称和参数

export const eventDotList = {
  'visit:accompany/personalized-psychologist': {
    name: 'visit:accompany/personalized-psychologist',
    props: {
      eventName: '陪伴：访问心理专属顾问'
    }
  },
  'visit:accompany/ai-psychological-counselor': {
    name: 'visit:accompany/ai-psychological-counselor',
    props: {
      eventName: '陪伴：访问AI心理辅导师'
    }
  },
  'visit:consult/24-text-consult': {
    name: 'visit:consult/24-text-consult',
    props: {
      eventName: '咨询：访问咨询师聊天'
    }
  },
}