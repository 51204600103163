import React, { useState, useEffect } from 'react';
import LoginComponents from '@/components/Login';
import './index.less';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getLoginToken,
  setLoginToken,
  setIsFirstTimeLogin,
} from '@/utils/localStorage'
import { checkTokenIsValid, decodeToken } from '@/utils/utils';
import { Toast } from 'antd-mobile';
import CONFIG from 'GlobalConfigFile';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { loginByPatient, sendPatientVerifyCode } from '@/services/api';
import { register_socket_event, save_patient_info } from '@/actions'
import { getPageQuery } from '@/utils/utils';
import WithTracking from '@/components/WithTracking';

function LoginAndBind() {
  const ROLE = 'patient'; //此声明只是UI上的区分
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { consultantId } = getPageQuery();
  const loginTitle = '舒辅EAP';
  const navUrl = location?.state?.from || '/client/home-v2/main';

  useEffect(() => {
    const token = getLoginToken(ROLE);
    const isTokenValid = checkTokenIsValid(token);
    if (isTokenValid) {
      const { authId } = decodeToken(token)
      registerSocketAndConnect(authId)
      navigate(navUrl);
    }
  }, [])

  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect(id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    dispatch(register_socket_event(socket));
  }

  async function handleSendVerifyCode(cellphone) {
    const res = await sendPatientVerifyCode(cellphone);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '短信发送成功',
        key: 'success'
      });
    }
  }

  async function onPatientLogin(params) {
    params['consultantId'] = consultantId;
    let res = await loginByPatient(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    const token = res.result.token;
    const firstTime = res.result.firstTime;
    const authId = _.get(res, ['result', 'authId'], '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    setIsFirstTimeLogin(ROLE, firstTime)
    dispatch(save_patient_info(_.get(res, ['result'], {})))
    if (firstTime) {
      const pageParams = {
        authId,
      }
      pageParams['consultantId'] = consultantId
      navigate('/client/first-setting', { state: pageParams });
    } else {
      navigate(navUrl);
    }
  }

  return (
    <LoginComponents
      theme={ROLE}
      loginTitle={loginTitle}
      onLogin={onPatientLogin}
      onSendVerifyCode={handleSendVerifyCode}
    />
  )
}

export default WithTracking(
  LoginAndBind,
  {
    name: 'visit:LoginAndBind',
    props: {
      eventName: '登录'
    },
    userId: 'anonymous'
  }
);