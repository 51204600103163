// 舒小辅查看排版信息
import React, { useCallback, useEffect, useState, useRef } from "react";
import TitleBar from '@/components/TitleBar';
import { Toast, List, FloatingBubble, DatePicker, Input, Button} from "antd-mobile";
import moment from "moment/moment";
import ListView from '@/components/ListView';
import { sleep } from 'antd-mobile/es/utils/sleep'

import './index.less';
import { adminGetLastTenTriggers, adminUploadScheduling } from '@/services/api';
import { AddOutline } from 'antd-mobile-icons'

const PAGE_SIZE = 10;
export default function ScheduleInfo() {
  const [scheduleInfoList, setScheduleInfoList] = useState([]);
  const [hasMore, setHasMore] = useState(false); //是否需要加载更多
  const currentPageRef = useRef(1); //当前加载第几页的数据
  const timeRef = useRef(); //时间筛选
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  // 文件上传
  const fileInputRef = useRef(null);

  // 接口查询
  async function _adminGetLastTenTriggers (isFresh) {
    const params = {
      pageNo: currentPageRef.current,
      pageSize: PAGE_SIZE,
      time: timeRef.current
    }
    const res = await adminGetLastTenTriggers(params);
    if (res.error) {
      Toast.show({
        content: res.error,
        duration: 2000,
        icon: 'fail'
      })
    } else {
      const result = _.get(res, ['result', 'data']) || [];
      let combineList;
      if (isFresh) {
        combineList = result;
      } else {
        combineList = [...scheduleInfoList, ...result]
      } 
      setScheduleInfoList(combineList);
      if (result.length < PAGE_SIZE) {
        setHasMore(false)
      } else {
        setHasMore(true)
      }
    }
  };

  // 初始化
  useEffect(() => {
    _adminGetLastTenTriggers();
  }, []);

  async function onRefresh () {
    timeRef.current = '';
    setHasMore(false);
    currentPageRef.current = 1;
    await _adminGetLastTenTriggers(true);
  }

  // 时间修改后，调用接口查询
  async function onTimeSelect () {
    setHasMore(false);
    currentPageRef.current = 1;
    await _adminGetLastTenTriggers(true);
  }

  async function loadMore () {
    await sleep(1000)
    currentPageRef.current++;
    await _adminGetLastTenTriggers();
  }

  // 文件选择回调
  const onChangeHandler = event => {
    onFileUpload(event.target.files[0])
  };

  // 上传文件
  const onFileUpload = (selectedFile) => {
    const params = new FormData();
    params.append('file', selectedFile);
    // const file = formData.get('file');
    adminUploadScheduling(params).then(res => {
      if (!res.error) {
        Toast.show({
          content: '上传成功',
          duration: 2000,
          icon: 'success',
          onClose: () => {
            setScheduleInfoList(res.result);
          }
        })
      }
      else {
        Toast.show({
          content: res.error,
          duration: 2000,
          icon: 'fail'
        })
      }
    })
  };

  // 自定义渲染时间选择的名称
  function labelRenderer (type, data) {
    switch (type) {
      case 'year':
        return data + '年'
      case 'month':
        return data + '月'
      case 'day':
        return data + '日'
      case 'hour':
        return data + '时'
      case 'minute':
        return data + '分'
      case 'second':
        return data + '秒'
      default:
        return data
    }
  }

  // 渲染时间选择
  function renderDatePicker () {
    const hours = [0, 6, 12, 18]
    // 当前日期
    const now = new Date();
    // 时间过滤
    const filter = {
      hour: (val, extend) => {
        const {date} = extend;
        const hour = date.getHours()
        return hours.indexOf(hour) > -1
      }
    }
    return (
      <DatePicker
        title="选择时间"
        precision='hour'
        visible={datePickerVisible}
        onClose={() => {
          setDatePickerVisible(false)
          onTimeSelect()
        }}
        defaultValue={now}
        renderLabel={labelRenderer}
        filter={filter}
        onConfirm={(val) => {
          const dateTime = moment(val).format('YYYYMMDDHH');
          timeRef.current = dateTime;
        }}
      />
    )
  }
  // 上传文件
  function uploadScheduleSheet() {
    return (
      <FloatingBubble
      style={{
        '--initial-position-bottom': '50px',
        '--initial-position-right': '24px',
      }}
      onClick={() => {fileInputRef.current.click();}}
    >
      <AddOutline fontSize={24}/>
      <input type="file" name="file" onChange={onChangeHandler} ref={fileInputRef} style={{display: 'none'}}/>
    </FloatingBubble>
    )
  }

  // listview item
  function renderListItem (item) {
    return (
      <div className="schedule-list-item">
        <div>
          <div> {item.displayName}-{item.extern}</div>
          <div className="item-gray-text">{item.mobile}</div>
        </div>
        <div className="item-gray-text">{item.time}</div>
      </div>
    )
  }

  // 时间筛选器
  function renderTimeSearchBar () {
    return (
      <div className="schedule-search-bar">
        <div>起始时间：</div>
        <Input placeholder='按照时间筛选' value={timeRef.current} readOnly className="schedule-time-input"/>
        <Button className="schedule-btn" color='primary' size='small' onClick={() => {setDatePickerVisible(true)}}>选择时间</Button>
      </div>
    )
  }

  return (
    <>
      <TitleBar
        title="排班信息"
        isShowBack={true}
      />
      {renderTimeSearchBar()}
       <ListView
            dataSource={scheduleInfoList}
            renderItem={renderListItem}
            onRefresh={onRefresh}
            hasMore={hasMore}
            loadMore={loadMore}
            onItemClickShow={false}
            minHeight= '80vh'
            />
      {uploadScheduleSheet()}
      {renderDatePicker()}
    </>
  )
}