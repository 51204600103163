// 浮动气泡组件
import React from 'react';
import { FloatingBubble } from 'antd-mobile';

import './index.less';

const FloatBubble = (props) => {
  const { children, handleClick, bubbleStyle, axis } = props;

  return (
    <div className="float">
      <FloatingBubble
        onClick={handleClick}
        axis={axis}
        magnetic="x"
        style={{ ...bubbleStyle }}
      >
        {children}
      </FloatingBubble>
    </div>
  );
};

export default FloatBubble;
