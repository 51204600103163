// 主要用于渲染练习页面的互动问答
import React, { useState, useEffect } from 'react';
import { Button, TextArea, Checkbox, Input } from 'antd-mobile';

import './index.less';

/**
 *
 * @param {} props
 * title: 问题标题
 * content: 问题内容
 * type: 问题类型 (单选、多选、填空)
 */
const QuestionAndAnswerBoard = (props) => {
  const title = props.title || '';
  const content = props.content || [];
  const type = props.type || 'single';
  const speechText = props.speechText || '';
  const handleClick = props.handleClick || (() => { });
  const handleFocus = props.handleFocus || (() => { });
  const handleBlur = props.handleBlur || (() => { });
  const disable = props.disable;

  const [blankAnswer, setBlankAnswer] = useState('');
  const [multipleAnswer, setMultipleAnswer] = useState([]);
  const [isShowOtherInput, setIsShowOtherInput] = useState(false);
  const [otherInputVal, setOtherInputVal] = useState('');

  useEffect(() => {
    setBlankAnswer(speechText);
  }, [speechText]);

  const renderQuestion = () => {
    switch (type || 'single') {
      case 'single':
        return renderSingleQuestion();
      case 'multiple':
        return renderMultipleQuestion();
      case 'blank':
        return renderBlankQuestion();
      case 'boolean':
        return renderYesNoQuestion();
      default:
        return null;
    }
  };

  const renderSingleQuestion = () => {
    return (
      <div className="single-question">
        {content &&
          content.map((option, index) => {
            return (
              <div
                className="single-option"
                key={index}
                onClick={() => {
                  handleClick(option.value);
                }}
              >
                {option.label}
              </div>
            );
          })}
      </div>
    );
  };

  const renderMultipleQuestion = () => {

    return (
      <>
        <div className='multiple-question'>
          {content &&
            <>
              <Checkbox.Group
                onChange={(checkedValue) => {
                  // 如果选中了其他，需要展示出Input框
                  checkedValue.includes('__OTHER__') ? setIsShowOtherInput(true) : setIsShowOtherInput(false);
                  // 需要对被选中的答案按照题目顺序进行排序
                  const answer = [];
                  content.forEach((item) => {
                    if (checkedValue.includes(item.value)) {
                      answer.push(item.value);
                    }
                  });
                  setMultipleAnswer(answer);
                }}
              >
                {
                  content.map((option, index) => {
                    return (
                      <div
                        className="multiple-option"
                        key={index}
                      >
                        <Checkbox
                          className="multiple-option-checkbox"
                          value={option.value}
                          onChange={(e) => {
                          }}
                        >
                          {option.label}
                        </Checkbox>
                      </div>
                    );
                  })
                }
                {
                  isShowOtherInput && (
                    <Input
                      className="multiple-option"
                      placeholder="请输入其他答案"
                      onChange={(e) => {
                        setOtherInputVal(e);
                      }}
                    />
                  )
                }
              </Checkbox.Group>
              <div

                onClick={() => {
                  if (isShowOtherInput && otherInputVal) {
                    // 如果有其他答案，需要将其他答案放在最后
                    multipleAnswer.splice(-1, 1);
                    multipleAnswer.push(otherInputVal);
                  }
                  handleClick(multipleAnswer);
                }}
                className="multiple-option"
                style={{ 
                  background: '#2979ff',
                  color: '#fff', 
                  width: '100%', 
                  textAlign: 'center', 
                  padding: '0.5rem 0',
                }}
              >
                确定
              </div>
            </>
          }
        </div>
      </>
    )
  };

  const renderBlankQuestion = () => {

    return (
      <div className="blank-question">
        <TextArea
          autoSize={{ minRows: 2 }}
          value={blankAnswer}
          defaultValue=""
          placeholder="请输入文字"
          onChange={(e) => {
            setBlankAnswer(e);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={disable}
        />
        <Button
          className="blank-question-button"
          color="primary"
          size="mini"
          onClick={() => {
            // 对blankAnswer进行深拷贝处理传出去
            const blankAnswerClone = JSON.parse(JSON.stringify(blankAnswer));
            handleClick(blankAnswerClone);
            setBlankAnswer('');
          }}
        >
          确定
        </Button>
      </div>
    );
  };

  const renderYesNoQuestion = () => {
    return (
      <div className="yes-no-question">
        <div
          className="yes-no-option"
          onClick={() => {
            handleClick('true');
          }}
        >
          是
        </div>
        <div
          className="yes-no-option"
          onClick={() => {
            handleClick('false');
          }}
        >
          否
        </div>
      </div>
    );
  };

  return (
    <div className="exercise-question-and-answer-board">
      <div className="board-title">{title}</div>
      <div className="board-question">{renderQuestion()}</div>
    </div>
  );
};

export default QuestionAndAnswerBoard;
