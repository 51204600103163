import { HANDLE_NOTIFICATION, SHOW_GLOBAL_DIALOG, HIDE_GLOBAL_DIALOG } from "@/actions/actionType";

const notificationReducer = (state = {},  action) => {
  switch (action.type) {
    case HANDLE_NOTIFICATION:
      return {...state, message: action.content};
    case SHOW_GLOBAL_DIALOG:
      return {...state, globalDialogState: true, message: action.content, };
    case HIDE_GLOBAL_DIALOG:
      return {...state, globalDialogState: false, message: null};
    default:
      return state;
  }
}

export default notificationReducer