// 多模态心理测评量表列表
import phq9 from '@/assets/images/patient-multi-modal/phq9.png';
import gad7 from '@/assets/images/patient-multi-modal/gad7.png';
import ais from '@/assets/images/patient-multi-modal/ais.png';
import pss from '@/assets/images/patient-multi-modal/pss.png';
import { getUrls } from '@/utils/getUrls';
import formAI from '@/assets/images/form_AI.png';

const { AI_BASE_URL } = getUrls();

export const sfdaMoltiModalList = [
  {
    name: 'PHQ-9',
    img: phq9,
    url: 'https://virtual-doctor-chatbot-uat.siuvo.cn/?type=phqInventory&providerId=demo',
  }
];

export const fenglinMoltiModalList = [
  {
    name: 'PHQ-9',
    img: phq9,
    url: 'https://virtual-doctor-chatbot.siuvo.cn/?type=phqResults&providerId=fenglin',
  },
  {
    name: 'GAD-7',
    img: gad7,
    url: 'https://virtual-doctor-chatbot.siuvo.cn/?type=gad7Results&providerId=fenglin',
  },
  {
    name: 'PSS',
    img: pss,
    url: 'https://virtual-doctor-chatbot.siuvo.cn/?type=pssResults&providerId=fenglin',
  },
  {
    name: 'AIS',
    img: ais,
    url: 'https://virtual-doctor-chatbot.siuvo.cn/?type=aisResults&providerId=fenglin',
  },
];

export const ModalList = {
  fafuli: [
    {
      name: '抑郁状况评估',
      img: phq9,
      url: AI_BASE_URL + '/login?type=phqResults&providerId=fafuli',
    },
    {
      name: '焦虑状况评估',
      img: gad7,
      url: AI_BASE_URL + '/login?type=gad7Results&providerId=fafuli',
    },
    {
      name: '睡眠质量评估',
      img: ais,
      url: AI_BASE_URL + '/login?type=aisResults&providerId=fafuli',
    },
  ],
  default: [
    {
      name: 'AI多模态',
      img: phq9,
      url: AI_BASE_URL + '/login?type=pinganFiveInOne&providerId=fafuli',
    }
  ]
}