import { SAVE_CURRENT_CHAT_AUTH_ID} from "@/actions/actionType";

const chatReducer = (state = {},  action) => {
  let newState = {};
  switch (action.type) {
    case SAVE_CURRENT_CHAT_AUTH_ID:
      newState = {...state, chatAuthId:action.chatAuthId }
      return newState;
    default:
      return state;
  }
}

export default chatReducer;