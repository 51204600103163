import { SAVE_PSYCHOLOGICAL_TEST_TAB_LIST } from "@/actions/actionType";

const psychologicalTestTabListReducer = (state = [], action) => {
  switch (action.type) {
    case SAVE_PSYCHOLOGICAL_TEST_TAB_LIST:
      return action.list;
    default:
      return state;
  }
}
export default psychologicalTestTabListReducer