import { SAVE_ADMIN_QUERY_STAFF_LIST } from "@/actions/actionType";

const staffListReducer = (state = {}, action) => {
  let newState = {};
  switch (action.type) {
    case SAVE_ADMIN_QUERY_STAFF_LIST:
      newState = {...state, staffList: action.list} 
      return newState;
    default:
      return state;
  }
}

export default staffListReducer;