import React, { useState, useEffect } from "react";
import _ from "lodash";
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { useLocation, useNavigate } from "react-router-dom";
import { RightOutline, AddOutline } from "antd-mobile-icons";
import "./index.less";
import { useSelector } from 'react-redux';
import { getQuestionnaireUrl } from '@/services/api';
import { getLoginToken } from '@/utils/localStorage.js';
import { getLobFromToken } from '@/utils/utils';
import { lobType } from '@/Constant';

function StaffInfoInfoComponent(props) {
  const navigate = useNavigate();
  const patientInfo = _.get(props, "patientInfo", {});
  const role = _.get(props, "role", 'patient');
  const doctorBaseInfo = useSelector(state => state.baseInfo['doctor']);
  const lob = getLobFromToken(getLoginToken(role));

  /* 
    @params 
      title
      data
      keyname
      renderSubButton
      navigateUrl: 查看全部的点击事件的跳转路由
  */
  function renderFormItem(title, data, keyName, navigateUrl, renderSubButton = function () { },) {
    /* 该表格只展示前三条数据，所以对data值进行一个截取 */
    let showData;
    data && data.length > 3
      ? showData = data.slice(0, 3)
      : showData = data || []
    if (keyName == '_timeSlot' || keyName == 'formName' || keyName == 'taskName') {
      return (
        <div className="form-item">
          <div className="form-item-title">
            <div className="form-item-text">{title}</div>
            <div
              className={"show-all " + role + "-show-all"}
              onClick={() => {
                navigate(navigateUrl, { state: { list: data, staffId: patientInfo.staffId } });
              }}
            >
              查看全部
            </div>
          </div>
          {renderSubButton()}
          {
            showData.map((item, index) => {
              let dataDate = MomentDiffZone(_.get(item, "time", "")).format(
                "YYYY年MM月DD日"
              );
              let dataName = _.get(item, keyName, "");
              if (keyName === "_timeSlot") {
                dataDate = MomentDiffZone(_.get(item, "startTime", "")).format(
                  "YYYY年MM月DD日"
                );
                dataName =
                  MomentDiffZone(_.get(item, "startTime", "")).format("HH:mm")
              }
              return (
                <div
                  className="data-item"
                  key={index}
                  onClick={() => {
                    const url = _.get(item, "url", null);
                    if (url) {
                      navigate('/therapist/form-iframe', { state: { url, type: 'readonly', title: '咨询记录' } });
                    }
                  }}
                >
                  <div className="item-section">
                    <div className="item-info">
                      <span className="span-time">{dataDate}</span>
                      <span className="span-title">{dataName}</span>
                    </div>
                    <div className={"item-icon " + role + "-item-icon"}>
                      <RightOutline fontSize={15} />
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      );
    }
    else {
      return (
        <div className="form-item">
          <div className="form-item-title">
            <div className="form-item-text">{title}</div>
          </div>
          {renderSubButton()}
          {
            data && data.map((item, index) => {
              let dataDate = MomentDiffZone(_.get(item, "time", "")).format(
                "YYYY年MM月DD日"
              );
              let dataName = _.get(item, keyName, "");
              return (
                <div
                  className="data-item"
                  key={index}
                >
                  <div className="item-section">
                    <div className="item-info">
                      <span className="span-time">{dataDate}</span>
                      <span className="span-title">{dataName}</span>
                    </div>
                    <div className={"item-icon " + role + "-item-icon"}>
                      <RightOutline fontSize={15} />
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      );
    }
  }

  // 咨询记录表
  function renderConsultListSubButton() {
    if (role === 'doctor') {
      return <div className="sub-buttons">
        <div className="sub-button" onClick={() => { selectConsultListSubButton('first', '初次咨询记录') }}>
          <div className="sub-button-icon"><AddOutline /></div>
          <div className="sub-button-text">首次表</div>
        </div>
        <div className="sub-button" onClick={() => { selectConsultListSubButton('mid', '咨询过程记录') }}>
          <div className="sub-button-icon"><AddOutline /></div>
          <div className="sub-button-text">过程表</div>
        </div>
        <div className="sub-button" onClick={() => { selectConsultListSubButton('end', '咨询结案记录') }}>
          <div className="sub-button-icon"><AddOutline /></div>
          <div className="sub-button-text">结案表</div>
        </div>
        <div className="sub-button" onClick={() => {navigate('/therapist/record-detail', { state: { recordStatus: 'START', staffId: patientInfo.staffId } })}}>
          <div className="sub-button-icon"><AddOutline /></div>
          <div className="sub-button-text">咨询记录表</div>
        </div>
      </div>
    } else {
      return null;
    }
  }

  async function selectConsultListSubButton(type, title) {
    let res = await getQuestionnaireUrl({
      stage: type + 'ConsultRecode',
      consultantId: _.get(doctorBaseInfo, 'authId', ''),
      staffId: _.get(patientInfo, 'staffId', ''),
    });
    navigate('/therapist/form-iframe', {
      state: {
        url: _.get(res, ['result', 'redirectUrl'], ''),
        consultId: _.get(res, ['result', 'consultId'], ''),
        title: title
      }
    });
  }

  function renderGender(gender) {
    switch (gender) {
      case "MALE":
        return "男";
      case "FEMALE":
        return "女";
      default:
        return "-";
    }
  }

  function renderBseInfo() {
    switch (lob) {
      case lobType.CONSULTANT:
      case lobType.COMPANY:
        return (
          <>
            <div className="basic-info-content">
              <div>婚恋情况：</div>
              <div>{_.get(patientInfo, ["staffInformation", "married"], "")}</div>
            </div>
            <div className="basic-info-content">
              咨询议题：
              {_.get(patientInfo, ["staffInformation", "tags"], []).join(",")}
            </div>
          </>
        )
      case lobType.EAP:
        return (
          <>
            <div className="basic-info-content">
              <div>身份类型：</div>
              <div>员工</div>
            </div>
            <div className="basic-info-content">
              <div>职员工号：</div>
              <div>{_.get(patientInfo, ["staffInformation", "staffNo"], "")}</div>
            </div>
            <div className="basic-info-content">
              <div>婚恋情况：</div>
              <div>{_.get(patientInfo, ["staffInformation", "married"], "")}</div>
            </div>
            <div className="basic-info-content">
              <div>薪资收入：</div>
              <div>{_.get(patientInfo, ["staffInformation", "income"], "")}</div>
            </div>
            <div className="basic-info-content">
              <div>职位等级：</div>
              <div>{_.get(patientInfo, ["staffInformation", "positionDesc"], "")}</div>
            </div>
            <div className="basic-info-content">
              <div>在职年限：</div>
              <div>{_.get(patientInfo, ["staffInformation", "seniority"], "") || '-'}年</div>
            </div>
            <div className="basic-info-content">
              <div>总 工 龄：</div>
              <div>{_.get(patientInfo, ["staffInformation", "totalSeniority"], "-")}年</div>
            </div>
            <div className="basic-info-content">
              咨询议题：
              {_.get(patientInfo, ["staffInformation", "tags"], []).join(",")}
            </div>
            {/* <div className="basic-info-content">
                希望匹配咨询师的性别：
                {renderGender(
                  _.get(staffInfo, ["staffInformation", "gender"], "")
                )}
              </div> */}
          </>
        )
      default:
        break;
    }
  }

  return (
    <div className="patient-infos">
      <div className="patient-info">
        <div
          className="patient-head"
          style={{
            borderColor: role == 'doctor' ? '#FFB92E' : '#2979FF'
          }}
        >
          <img src={_.get(patientInfo, "avatarUrl", "")} alt="" />
        </div>
        <div className="patient-data">
          <div className="patient-name">{_.get(patientInfo, "name", "")}</div>
          <div className="patient-other-info">
            {renderGender(_.get(patientInfo, "gender", ""))}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {_.get(patientInfo, "age", "") || MomentDiffZone().diff(patientInfo.birthday, 'years')}岁
          </div>
          <div className="patient-other-info">
            渠道：{patientInfo.tenantName}
            &nbsp;&nbsp;&nbsp;&nbsp;
            公司：{patientInfo.ownerName}
          </div>
        </div>
      </div>
      <div className="basic-info-title">基本资料</div>
      <div className="basic-info">
        {_.get(patientInfo, ["staffInformation", "role"]) === "family" ? (
          <>
            <div className="basic-info-content">身份类型：亲属</div>
            <div className="basic-info-content">
              希望匹配咨询师的性别：
              {renderGender(
                _.get(patientInfo, ["staffInformation", "gender"], "")
              )}
            </div>
            <div className="basic-info-content">
              咨询的问题：
              {_.get(patientInfo, ["staffInformation", "tags"], []).join(",")}
            </div>
            <div className="basic-info-content">
              关联员工的手机号：
              {_.get(patientInfo, ["staffInformation", "relatedStaffTe"], "")}
            </div>
            <div className="basic-info-content">
              关联员工的姓名：
              {_.get(patientInfo, ["staffInformation", "relatedStaff"], "")}
            </div>
            <div className="basic-info-content">
              和员工的关系：
              {_.get(patientInfo, ["staffInformation", "relation"], "")}
            </div>
          </>
        ) : null}
        {renderBseInfo()}
      </div>
      {/* 如果是来访者的角色，那么不显示下面这些东西 */}
      {
        role == 'doctor' && lob === lobType.EAP
          ? renderFormItem(
            "咨询记录表",
            _.get(patientInfo, "consultList", []),
            "_timeSlot",
            '/therapist/consult-list',
            () => renderConsultListSubButton(),
          )
          : null
      }
      {
        role == 'doctor'
          ? renderFormItem(
            "量表数据",
            _.get(patientInfo, "formList", []),
            "formName",
            "/therapist/form-list",
          )
          : null
      }
      {
        role == 'doctor'
          ? renderFormItem(
            "任务作业",
            _.get(patientInfo, "staffTaskList", []),
            "taskName",
            "/therapist/task-list",
          )
          : null
      }
      {
        role == 'doctor'
          ? renderFormItem(
            "附件",
            _.get(patientInfo, "attachmentList", []),
            "attachmentName",
            "/therapist/attachment-list",
          )
          : null
      }
    </div>
  );
}

export default StaffInfoInfoComponent;