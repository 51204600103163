import React from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
// import Informations from './Information';
import greenChannelDesc from '@/assets/images/green_channel.jpg'

// 怡安绿通服务静态界面
const GreenChannelService = (props) => {

  // 根据item类型渲染--text
  function renderText(text, index) {
    return (
      <div key={index}>
        {
          text.map((item, i) => {
            return (
              <div key={i} dangerouslySetInnerHTML={{__html: item}}></div>
            )
          })
        }
      </div>
    )
  }

  // 渲染图片
  function renderImg (imgs, index) {
    return (
      <div key={index}>
        {
          imgs.map((img, i) => {
            return (
              <img alt='' key={i} src={img} />
            )
          })
        }
      </div>
    )
  }
 

  return (
    <>
    <TitleBar
      title='<font color="#2979ff">舒辅就医绿通</font>'
    />
    <div className='green-channel-service-page'>
      {/* {
        Informations.map((info, index) => {
          console.log('********************type:::', info.type)
          switch(info.type) {
            case 'text':
              return renderText(info.text, index);
            case 'img':
              return renderImg(info.img, index);
            default:
              return null;
          }
        })
      } */}
      <img alt='' src={greenChannelDesc} />
    </div>
    </>
  )
}

export default GreenChannelService;