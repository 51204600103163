import React from 'react';
import { hide_global_dialog } from '@/actions';
import { useDispatch, useSelector } from 'react-redux';
import CustomConfirmDialog from '@/components/CustomConfirmDialog';
import { useNavigate } from 'react-router-dom';

const GlobalDialog = () => {
  const {globalDialogState, message={}} = useSelector(state => state.notificationInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navPath = _.get(message, ['navPath'], '');
  const text =  _.get(message, ['text'], '');
  return (
    <CustomConfirmDialog
      visible={globalDialogState}
      content={text}
      onCancel={() => {
        dispatch(hide_global_dialog())
      }}
      onConfirm={() => {
        dispatch(hide_global_dialog());
        navigate(navPath);
      }}
    />
  )
};

export default GlobalDialog
