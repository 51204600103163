import React, { useState, useRef, useEffect } from "react";
import './index.less';
import TitleBar from '@/components/TitleBar';
import { SearchBar, Tabs, Swiper, List, Button, Toast } from 'antd-mobile';
import moment from "moment";
import { getConsultationRecord } from '@/services/api';
import ListView from '@/components/ListView';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { useNavigate } from 'react-router-dom';

const tabsList = ['待填写', '已完成'];
const PAGE_SIZE = 10;

// 舒辅心理健康咨询记录
function HealthRecordList(props) {

  const swiperRef = useRef(null); // 侧滑
  const currentPageRef = useRef(1); //当前加载第几页的数据
  const [hasMore, setHasMore] = useState(false); //是否需要加载更多
  const [activeIndex, setActiveIndex] = useState(0);
  const [recordList, setRecordList] = useState([]);
  const navigate = useNavigate();
  const recordStatus = activeIndex === 0 ? 'START' : 'SUCCESS'

  useEffect(() => {
    _getConsultationRecord(true)
  }, [activeIndex, ])

  // 上拉加载更多
  async function loadMore() {
    await sleep(1000)
    currentPageRef.current++;
    await _getConsultationRecord();
  }

  // 下拉刷新
  async function onRefresh() {
    setHasMore(false);
    currentPageRef.current = 1;
    await _getConsultationRecord(true);
  }

  // 查询咨询记录list
  async function _getConsultationRecord(isFresh, filter={}) {
    const params = {
      pageSize: PAGE_SIZE,
      pageNo: currentPageRef.current,
      recordStatus,
      ...filter
    }
    const res = await getConsultationRecord(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    const result = _.get(res, ['result', 'data']) || [];
    let combineList;
    if (isFresh) {
      combineList = result;
    } else {
      combineList = [...recordList, ...result]
    }
    setRecordList(combineList);
    if (result.length < PAGE_SIZE) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }

  function handleSearch(staffTelephone) {
    const params = {
      staffTelephone
    }
    // 接口查询咨询记录
    _getConsultationRecord(true, params)
  }
  // 渲染搜索框
  function renderSearchBar() {
    return (
      <SearchBar
        className='search-bar'
        placeholder='请输入手机号搜索'
        onSearch={(val) => {
          handleSearch(val);
        }}
        onClear={() => {
          // 清空查询默认
          onRefresh();
        }}
      />
    )
  }
  // 暂无数据
  function renderNone() {

    return (
      <>
        <List>
          <List.Item
            style={{
              textAlign: 'center'
            }}
          >
            暂无数据
          </List.Item>
        </List>
      </>
    )
  }

  // 渲染列表item
  function renderListItem(item, index) {
    // 渲染待填写列表
    if (recordStatus === 'START') {
      return (
        <div key={index} className="item-content">
          <div className="item-record-name">{item.recordName}</div>
          <Button className="record-view-btn" style={{ '--background-color': '#FFAA00' }}>填写</Button>
        </div>
      )
    }
    // 渲染已完成列表item
    return (
      <div key={index} className="item-content">
        <div className="item-record-name">{item.recordName}</div>
        <Button className="record-view-btn" style={{ '--background-color': '#FFAA00' }}>查看</Button>
      </div>
    )
  }

  // item点击
  function handleItemClick(item) {
    // 获取item中的recordId
    const { recordId } = item
    navigate('/therapist/record-detail', { state: { recordId, recordStatus } });
  }

  // 渲染List
  function renderCompleteList() {
    if (recordList.length > 0) {
      return (
        <div className="list-view-content">
          <ListView
            dataSource={recordList}
            renderItem={renderListItem}
            onRefresh={onRefresh}
            hasMore={hasMore}
            loadMore={loadMore}
            onItemClick={handleItemClick}

          />
        </div>
      )
    }
    return renderNone();
  }

  // 渲染咨询记录列表
  function renderRecorList() {
    return (
      <div>
        <Tabs
          activeKey={tabsList[activeIndex]}
          onChange={(keyVal) => {
            let index = tabsList.findIndex(item => item === keyVal);
            setActiveIndex(index);
            swiperRef.current?.swipeTo(index);
          }}
        >
          {
            tabsList.map((item, index) => {
              return (
                <Tabs.Tab
                  title={item}
                  key={item}
                >
                </Tabs.Tab>
              )
            })
          }
        </Tabs>
        <Swiper
          direction='horizontal'
          loop={true}
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={index => {
            setActiveIndex(index)
          }}
        >
          {
            tabsList.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  {renderCompleteList(index)}
                </Swiper.Item>
              )
            })
          }
        </Swiper>
      </div>
    )
  }
  // 渲染titlebar 右侧新增按钮
  function renderTitleRight () {
    return(
      <div onClick={() => navigate('/therapist/record-detail', { state: { recordStatus: 'START'} })}>
        <img alt="" src={require('@/assets/icon/dialogue/add.svg').default}/>
    </div>
    )
  }

  return (
    <div className="health-record-page">
      <TitleBar title='咨询记录表' isShowBack={true} rightContent={renderTitleRight()}/>
      <div>
        {renderSearchBar()}
        {renderRecorList()}
      </div>
    </div>
  )
}

export default HealthRecordList;