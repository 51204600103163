import React, { useState } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { setLoginToken, setAuthId } from '@/utils/localStorage.js';
import { Button, List, Toast, Input, Picker } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@/components/Avatar';
import { commonConstant } from '@/Constant';
import { disconnect_socket, save_doctor_info } from '@/actions';
import { updateConsultantInfo, doctorGetSelfInfo } from "@/services/api";
import CustomConfirmDialog from '@/components/CustomConfirmDialog';
import { consultantAnonymousCall } from '@/services/api';
import makingCall from '@/utils/makingCall';
import CONFIG from 'GlobalConfigFile';
import useDarkmode from '@/hooks/useDarkmode';

function UserSetting(props) {
  const role = 'doctor';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseInfo = useSelector(state => state.baseInfo[role].roleInfo || {});
  const [virtualCallDialog, setVirtualCallDialog] = useState(false);  // 虚拟拨号的弹框
  const [phoneNoA, setPhoneNoA] = useState(baseInfo.telephone);  // 咨询师手机号
  const [phoneNoB, setPhoneNoB] = useState('');  // 拨打的手机号
  const [timeZoneDialog, setTimeZoneDialog] = useState(false);  // 时区选择弹框
  const [timeZone, setTimeZone] = useState('Asia/Shanghai');  // 时区
  const DarkmodeContent = useDarkmode();

  // 时区选择项
  const timeZoneList = [
    [
      { label: '中国', value: 'Asia/Shanghai' },
      { label: '美国', value: 'America/New_York' },
    ],
  ];

  function returnMenu() {
    const funcMenu = [
      {
        label: '七陌用户查询',
        action: () => { navigate('/therapist/qimo-user-info') }
      },
      {
        label: '我的介绍',
        action: () => { navigate('/therapist/self-info', { state: { role, type: 'edit' } }) }
      },
      {
        label: '审核记录',
        action: () => { navigate('/therapist/approved-memo') }
      },
      {
        label: '虚拟拨号',
        action: () => { setVirtualCallDialog(true) }
      },
      {
        label: '时区选择',
        action: () => { setTimeZoneDialog(true) }
      },
      {
        label: '我的钱包',
        action: ''
      },
      {
        label: '账号管理',
        action: ''
      },
      {
        label: '提醒设置',
        action: ''
      },
      {
        label: '客户评价',
        action: ''
      },
      {
        label: '咨询记录表',
        action:  () => { navigate('/therapist/health-record-list') }
      },
    ];
    if (baseInfo.authId === CONFIG.SiuvoManagerId) {
      return [
        {
          label: '我的介绍',
          action: () => { navigate('/therapist/self-info', { state: { role, type: 'edit' } }) }
        },
        {
          label: '审核记录',
          action: () => { navigate('/therapist/approved-memo') }
        },
        {
          label: '虚拟拨号',
          action: () => { setVirtualCallDialog(true) }
        },
        {
          label: '排班信息',
          action: () => { navigate('/therapist/scheduleInfo') }
        },
        {
          label: '排班咨询师信息',
          action: () => { navigate('/therapist/externsInfo') }
        },
      ]
    }
    else {
      return funcMenu;
    }
  }

  /**
   * 虚拟拨号
   */
  async function virtualCall() {
    if (!phoneNoA || phoneNoA.length !== 11) {
      Toast.show('请检查确认您的手机号码');
      return;
    }
    if (!phoneNoB || phoneNoB.length !== 11) {
      Toast.show('请检查确认您想拨打的手机号码');
      return;
    }
    let params = {
      phoneNoA,
      phoneNoB
    };
    let res = await consultantAnonymousCall(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      setVirtualCallDialog(false);
      makingCall(res.result);
    }
  }

  function renderHead() {
    return (
      <div className='header-content darkmode-ignore'>
        {/* <ImageUploader beforeUpload={file => startUploadFile(file, res => {
          console.log(res);
          _updateConsultantInfo({ avatarUrl: _.get(res, ['result', 'url'], '') });
          setDoctorSelfInfo();
        })}> */}
        <Avatar avatarUrl={baseInfo.avatarUrl} />
        {/* </ImageUploader> */}
        <div className='user-name'>{baseInfo.name || '咨询师'}</div>
      </div>
    )
  }

  async function _updateConsultantInfo(data) {
    let res = await updateConsultantInfo(data);
  }

  async function setDoctorSelfInfo() {
    let res = await doctorGetSelfInfo(baseInfo.authId);
    dispatch(save_doctor_info(_.get(res, ['result'], {})));
  }

  function renderMenu() {
    return (
      <List className='menu-list'>
        {returnMenu().map((item, index) => {
          return (
            <List.Item
              key={item.label}
              className='menu-list-item'
              arrow={true}
              onClick={() => {
                if (item.action) {
                  item.action();
                } else {
                  Toast.show({
                    content: commonConstant.INCOMPLETE_FUNC_HINT
                  })
                }
              }}
            >
              {item.label}
            </List.Item>
          )
        })}
      </List>
    )
  }
  // 虚拟号码绑定，拨打
  function renderAnonymousBindContent() {
    return (
      <>
        <div className='anonymous-hint'>请确认您绑定的手机号码（可修改）</div>
        <div>
          <Input placeholder='请输入您想绑定的手机号码' value={phoneNoA} type='number' onChange={val => {
            setPhoneNoA(val)
          }} />
        </div>
        <div className='anonymous-hint'>请输入您想拨打的电话号码</div>
        <div>
          <Input placeholder='请输入您要拨打的手机号码' value={phoneNoB} type='number' onChange={val => {
            setPhoneNoB(val)
          }} />
        </div>
      </>
    )
  }

  return (
    <div className='doctor-setting'>
      <TitleBar
        isShowBack={true}
        title="个人中心"
        rightContent={DarkmodeContent}
      />
      <div className='user-setting-content'>
        <div className='temp'>
          {renderHead()}
          {renderMenu()}
        </div>
        <Button className="setting-logout" onClick={() => {
          setLoginToken(role, '');
          // setAuthId(role, '');
          dispatch(disconnect_socket());
          navigate('/therapist/login', { replace: true })
          window.location.reload();
        }}> 退出登录</Button>
      </div>
      <CustomConfirmDialog
        visible={virtualCallDialog}
        content={renderAnonymousBindContent()}
        onCancel={() => {
          setVirtualCallDialog(false);
        }}
        onConfirm={() => {
          virtualCall()
        }}
      />
      <Picker
        visible={timeZoneDialog}
        columns={timeZoneList}
        onClose={() => {
          setTimeZoneDialog(false);
        }}
        value={timeZone}
        onConfirm={(val, extend) => {
          setTimeZone(val[0]);
          localStorage.setItem('timeZone', val[0]);
        }}
      />
    </div>
  )
}

export default UserSetting;
