import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { useLocation } from 'react-router-dom';
import { patientGetDoctorInfo } from "@/services/api";

function ConsultedEvaluation() {
  const location = useLocation();
  const { consultedInfo } = _.get(location, ['state'], {});
  const evaluation = consultedInfo?.evaluation;
  const [consultInfo, setConsultInfo] = useState({})

  // 根据咨询师id获取咨询师信息
  const _patientGetDoctorInfo = useCallback(async (consultantId) => {
    const res = await patientGetDoctorInfo(consultantId);
    const doctorInfo = _.get(res, "result", {});
    setConsultInfo(doctorInfo);
  }, [])

  useEffect(() => {
    _patientGetDoctorInfo(consultedInfo.consultantId)
  }, [_patientGetDoctorInfo]);

  return (
    <>
      <TitleBar
        title='我的评价'
        isShowBack={true}
      />
      <div className='consulted-evaluation'>
        <div className='evaluation-time'>
          咨询开始：{MomentDiffZone(evaluation?.startTime).format('YYYY-MM-DD HH:mm')}
          <br />
          咨询结束：{MomentDiffZone(evaluation?.endTime).format('YYYY-MM-DD HH:mm')}
        </div>
        <div className='consultant-name'>{consultInfo?.name}</div>
        <div className='consultant-item'>
          <div>总体满意度：{evaluation?.evaluationInfo?.count}</div>
          <div>再次使用意愿：{evaluation?.evaluationInfo?.reuseRate}</div>
          <div>对来访者理解度：{evaluation?.evaluationInfo?.understandingLevel}</div>
          <div>能帮助解决问题：{evaluation?.evaluationInfo?.solveProblem}</div>
          <div className='item-text'>{evaluation?.evaluationInfo?.text}</div>
        </div>
      </div>
    </>
  )
}

export default ConsultedEvaluation;