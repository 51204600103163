import React, { useState, useEffect, useRef, useCallback } from 'react';
import _, { set } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Input, ImageUploader, Button, ActionSheet, PullToRefresh, SpinLoading, Modal, Toast } from 'antd-mobile';
import { CloseCircleFill } from 'antd-mobile-icons';

import './index.less';
import '@/components/DialogueMessage/index.less';
import TitleBar from '@/components/TitleBar';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import {
  patientUploadChatFile,
  patientGetChatMsg,
  patientGetDoctorInfo,
  staffConsultCompleted,
  staffTrigger,
  clientClearChat,
  staffChatWithdraw
} from '@/services/api';
import { RenderMessageContent, uploadDialogueImage } from '@/components/DialogueMessage';
import {
  emit_message_to_server,
  message_get,
  message_update,
  message_add,
  message_withdraw,
  save_current_chat_auth_id,
  message_clear
} from '@/actions';
import { scrollMessageListToEnd, isWorkTime } from '@/utils/commonUtils';
import { FILE_UPLOAD, ROBOT_CHAT } from '@/actions/actionType';
import { getLoginToken } from '@/utils/localStorage'
import { getLobFromToken, decodeToken } from '@/utils/utils';
import { lobType } from '@/Constant';
import { emitterEvent } from '@/Constant';
import { eventDotList } from '@/Constant/eventDot';
import EventEmitter from '@/utils/eventEmitter';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { isMoreThan3Mins } from '@/utils/commonUtils';
import getUUID from '@/utils/getUUID';
import moment from 'moment';
import { funcList } from '@/Constant';
import CONFIG from 'GlobalConfigFile';

const ROLE = 'patient';
function Dialogue(props) {
  const { handleAddEventDotClick } = props;
  const { chatAuthId } = useParams();
  const lob = getLobFromToken(getLoginToken(ROLE));
  const { tenantId } = decodeToken(getLoginToken(ROLE))
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const chatMsg = useSelector(state => state.chatMsg);
  // const chatAuthId = _.get(location, ['state', 'chatAuthId'], '');
  const eventDotKey = _.get(location, ['state', 'eventDotKey'], '');
  const eventDotParam = eventDotList[eventDotKey] || {};
  const baseInfo = useSelector(state => state.baseInfo['patient']);
  const {funcs } = funcList[tenantId] || funcList.default;


  // 分页的页数
  const pageNo = useRef();
  // 来访者通过authId获取咨询师的信息，对于来访者而言，咨询师的authId就是他对话人Id,即chatAuthId
  const [staffInfo, setStaffInfo] = useState();
  // 每一页展示的消息数量
  const [pageSize, setPageSize] = useState(20);
  // 是否需要将scroll加载到最底部
  const [scrollEnd, setScrollEnd] = useState(true);
  const [functionsVisible, setFunctionsVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [actionSheetVisible, setActionSheetVisible] = useState(false)
  // 确认是否需要结束咨询的弹窗
  const [consultModalVisible, setConsultModalVisible] = useState(false);
  // 记录当前发送失败的消息的次数
  const [sendFailCount, setSendFailCount] = useState(0);
  // 咨询记录id
  const [consultRecodeId, setConsultRecodeId] = useState('');
  // 是否显示连续消息时候的loading
  const [isShowLoading, setIsShowLoading] = useState(false);
  const functionBlocks = [
    {
      icon: <ImageUploader beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/photo.svg').default} />
      </ImageUploader>, title: '照片'
    },
    {
      icon: <ImageUploader capture='camera' beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/camera.svg').default} />
      </ImageUploader>, title: '拍摄'
    },
    {
      icon:
        <img alt="" src={require('@/assets/icon/dialogue/appointment.svg').default}
          onClick={() => { navigate('/client/calendar', { state: { consultantId: chatAuthId } }) }} />,
      title: '咨询师预约'
    },
    {
      icon: <img alt="" src={require('@/assets/icon/dialogue/intro.svg').default}
        onClick={() => { navigate('/client/consultant-info', { state: { consultantInfo: staffInfo, role: 'patient', type: 'readonly' } }) }} />,
      title: '咨询师介绍'
    }
  ];
  const actionSheets = [
    {
      text: '咨询师预约',
      key: 'appoint',
      onClick: () => {
        navigate('/client/calendar', { state: { consultantId: chatAuthId } });
      }
    },
    {
      text: '咨询师介绍',
      key: 'intro',
      onClick: () => {
        navigate('/client/consultant-info', { state: { consultantInfo: staffInfo, role: 'patient', type: 'readonly' } })
      }
    },
    {
      text: '我的任务',
      key: 'mission',
      onClick: () => {
        navigate('/client/form-task-list');
      }
    }
  ];
  // 页面进入加载loading，防止渲染之前的历史记录
  const [loading, setLoading] = useState(true);
  // 被引用的文字
  const [quoteText, setQuoteText] = useState('');
  // 被撤回的文字
  const [withdrawText, setWithdrawText] = useState('');

  // 打点
  useEffect(() => {
    JSON.stringify(eventDotParam) !== '{}'
      ? handleAddEventDotClick(eventDotParam)
      : handleAddEventDotClick({
        name: 'visit:consult/dialogue',
        props: {
          eventName: '咨询：访问咨询师聊天'
        }
      });

  }, [])

  const _staffTrigger = useCallback(async () => {
    const params = {
      chatAuthId,
      triggerType: 'openChat',
      time: MomentDiffZone().valueOf()
    }
    await staffTrigger(params);
  }, [])

  // 清空未读消息
  async function clearMsg() {
    console.log('*******clearMsg**********')
    const res = await clientClearChat({ chatAuthId });
    if (res.errorCode) {
      console.log('clearMsg:', res.error)
    }
  }

  useEffect(() => {
    dispatch(message_clear())
    EventEmitter.on('SHOW_DOT_LOADING', (val) => {
      setIsShowLoading(val);
    })
  }, [])

  useEffect(() => {
    // _patientGetChatMsg();
    async function initChat() {
      await _patientGetChatMsg();
      await _staffTrigger();
    }
    initChat();
    dispatch(save_current_chat_auth_id(chatAuthId));
    return () => {
      clearMsg();
    }
  }, []);

  useEffect(() => {
    setFunctionsVisible(false);
    // 下拉加载到最底部
    scrollEnd && scrollMessageListToEnd();
    setScrollEnd(true);
    let lastMsg = _.last(chatMsg);
    if (lastMsg?.onSendSuccess == false) {
      setSendFailCount(sendFailCount + 1);
    };
  }, [chatMsg])

  // 监听事件是否通话挂断后需要进行弹窗的通知
  useEffect(() => {
    const consultantEndConfirm = (val) => {
      setConsultRecodeId(val?.consultRecodeId);
      if (val?.type === "CONSULT_END_CONFIRM") {
        setConsultModalVisible(true)
      }
      else {
        setConsultModalVisible(false)
      }
    }
    EventEmitter.on(emitterEvent.CONSULT_END_CONFIRM, consultantEndConfirm);
    return () => {
      EventEmitter.removeListener(emitterEvent.CONSULT_END_CONFIRM, consultantEndConfirm);
    }
  }, [])

  // 监听事件用户咨询完成待评价
  useEffect(() => {
    const consultantWaitEvaluation = (val) => {
      console.log('consultantWaitEvaluation', val);
    }
    EventEmitter.on(emitterEvent.CONSULT_WAIT_EVALUATE, consultantWaitEvaluation);
    return () => {
      EventEmitter.removeListener(emitterEvent.CONSULT_WAIT_EVALUATE, consultantWaitEvaluation);
    }
  }, [])

  // 消息中增加非工作时间的提示
  function updateMessageWithWorkNotification(messages) {
    // 24小时热线id(部分渠道仅支持特定时间值班)
    const funcId = '24-text-consult';
    const {startWorTime, endWorkTime} = funcs.filter(item => item.id === funcId)[0]
    let notificationText = `当前非在线时间，请于${startWorTime}-${endWorkTime}联系专属向导，`;
    const isConsultantWork = isWorkTime(startWorTime, endWorkTime);
    const isManagerWork = isWorkTime('9:00', '21:00');
    if (chatAuthId === CONFIG.SiuvoManagerId && !isManagerWork) {
      // 客服舒小辅--默认时间是是9-21点工作
      // 非工作时间找舒小辅聊天 并且非咨询师工作时间
      if (isConsultantWork) {
        // 非工作时间找舒小辅聊天 但是咨询师工作时间
        notificationText += `您可以联系24小时热线或图文咨询师进行咨询，谢谢！`;
      } else {
        notificationText += '或返回首页与AI心理辅导师（24小时在线）沟通。';
      }
      messages.unshift({
        chatType: "chatToOne",
        fromUserId: chatAuthId,
        toUserId: baseInfo.authId,
        message: notificationText,
        messageType: "ROBOT_CHAT",
        sendTime: moment()
      })
    }
    // 非工作时间--咨询师
    if (chatAuthId !== CONFIG.SiuvoManagerId && !isConsultantWork) {
      notificationText += '或返回首页与AI心理辅导师（24小时在线）沟通。';
      messages.unshift({
        chatType: "chatToOne",
        fromUserId: chatAuthId,
        toUserId: baseInfo.authId,
        message: notificationText,
        messageType: "ROBOT_CHAT",
        sendTime: moment()
      })
    }
    return messages;
  }

  async function _patientGetChatMsg() {
    pageNo.current = 1;
    const res = await patientGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId: baseInfo.authId });
    if (!res.errorCode) {
      const data = _.get(res, ['result', 'data'], []);
      const messages = updateMessageWithWorkNotification(data);
      dispatch(message_get(messages.reverse()));
    }
    setLoading(false);
  }

  // 下拉更新数据
  async function _patientAddChatMsg() {
    const res = await patientGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId: baseInfo.authId });
    dispatch(message_add(_.get(res, ['result', 'data'], []).reverse()));
  }

  const _patientGetDoctorInfo = useCallback(async () => {
    const res = await patientGetDoctorInfo(chatAuthId);
    setStaffInfo(_.get(res, "result", {}))
  }, []);

  useEffect(() => {
    _patientGetDoctorInfo();
  }, [_patientGetDoctorInfo]);

  // 把setConsultModalVisible包一下传给子组件
  function setModalVisible(val) {
    setConsultModalVisible(val);
  }

  // 文件上传类型的消息
  function onSendFileToMessageList(url) {
    const messageObj = {
      chatType: FILE_UPLOAD,
      message: '',
      toUserId: chatAuthId,
      resourceType: 'PICTURE',
      url: url,
      fromUserId: baseInfo.authId,
      chatMsgId: getUUID(),
    }
    dispatch(message_update(messageObj));
  }

  async function uploadDialogueFile(file) {
    uploadDialogueImage(file, chatAuthId, async params => {
      params.append('chatMsgId', getUUID());
      const res = await patientUploadChatFile('PICTURE', params);
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        })
      } else {
        const url = res.result.url || '';
        onSendFileToMessageList(url);
      }
    });
  }

  function hasClass(element, className) {
    const regExp = new RegExp("\\b" + className + "\\b", "gi");
    return regExp.test(element.className);
  }

  // 发送消息
  function onMessageSend(msg) {
    if (msg) {
      let messageObj = null;
      // 获取最后一条消息
      const lastMessage = chatMsg.slice(-1)[0];
      let message;
      if (quoteText) {
        message = JSON.stringify({ answer: msg, quote: quoteText });
      } else {
        message = JSON.stringify({ answer: msg });
      };
      // 判断最后一条消息是否为自动回复的消息,（非本人回复的消息）
      if (lastMessage && lastMessage.messageType === ROBOT_CHAT && lastMessage.toUserId === baseInfo.authId) {
        messageObj = {
          // 回复机器人的消息，暂且不作引用，因为后端需要额外处理
          message: msg,
          chatType: 'chatToOne',
          messageType: ROBOT_CHAT,
          toUserId: chatAuthId,
          toRobotId: lastMessage.fromRobotId,
          robotQuestionId: lastMessage.robotQuestionId,
          chatMsgId: getUUID(),
        }
      } else {
        messageObj = {
          chatType: 'chatToOne',
          message: message,
          toUserId: chatAuthId,
          fromUserId: baseInfo.authId,
          chatMsgId: getUUID(),
        }
      }
      // EMIT_MESSAGE_TO_SERVER
      dispatch(emit_message_to_server(messageObj))
      setInputValue('');
      setQuoteText('');
    }
  }

  function renderTitleRight() {
    return (
      <div onClick={() => {
        setActionSheetVisible(true)
      }}>
        <img alt="" src={require('@/assets/icon/more.svg').default} />
      </div>
    )
  }

  // 定义下拉加载时的自定义提示
  const statusRecord = {
    pulling: '下拉获取更多消息',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
  }

  function onFormClick(params) {
    console.log('');
  }

  // 来访者咨询完成确认
  const _consultCompleted = async (submitEnd) => {
    let params = {
      submitEnd,
      recodeId: consultRecodeId,
    };
    const res = await staffConsultCompleted(params);
  }

  // 引用消息
  const quoteMessage = (message) => {
    setQuoteText(message);
  }

  // 撤回消息
  const handleWithdrawMessage = async (message) => {
    // 只能撤回自己的消息
    if (message.fromUserId !== baseInfo.authId) {
      Toast.show({
        icon: 'fail',
        content: '不能撤回他人消息',
      })
      return;
    }
    // 机器人的消息不能撤回
    if (message.fromRobotId) {
      Toast.show({
        icon: 'fail',
        content: '不能撤回回复机器人的话',
      })
      return;
    }
    // 超过三分钟的话不能撤回
    if (isMoreThan3Mins(message.sendTime)) {
      Toast.show({
        icon: 'fail',
        content: '超过三分钟的消息不能撤回',
      })
      return;
    }
    let params = {
      chatMsgId: message.chatMsgId,
    };
    const res = await staffChatWithdraw(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      dispatch(message_withdraw({ ...message, withdrawFlag: true }))
      setWithdrawText(message)
    }
  }

  const handleReEdit = (withdrawText) => {
    const { message } = withdrawText;
    const { answer } = JSON.parse(message);
    setInputValue(answer || message);
    setWithdrawText(null);
  }

  function renderLoading() {
    return <div className='spin-loading' id='loading'>
      <SpinLoading style={{ '--size': '32px' }} />
      <div>加载中...</div>
    </div>
  }

  return (
    <>
      <TitleBar
        title={staffInfo?.nickName}
        isShowBack={true}
        rightContent={renderTitleRight()}
      // barCancel={() => {
      //   navigate('/client/home-v2/main')
      // }}
      />
      <div className='dialogue-style' >
        <div className='dialogue-blocks' id='messageList-dialogue-blocks'>
          {
            loading ? renderLoading() :
              <PullToRefresh
                onRefresh={() => {
                  pageNo.current += 1;
                  _patientAddChatMsg();
                  setScrollEnd(false);
                }}
                refreshingText={(text) => {
                  return (
                    // 此处的return需要带上圆括号，否则会报warning：functions are not valid as a React child
                    <div>{statusRecord[text]}</div>
                  )
                }}
              >
                <RenderMessageContent
                  sendFailCount={sendFailCount}
                  messageList={chatMsg}
                  role='patient'
                  roleInfo={staffInfo}
                  onFormClick={onFormClick}
                  consultModalVisible={consultModalVisible}
                  setModalVisible={setModalVisible}
                  _consultCompleted={_consultCompleted}
                  isShowLoading={isShowLoading}
                  handleQuoteMessage={quoteMessage}
                  handleWithdrawMessage={handleWithdrawMessage}
                />
              </PullToRefresh>
          }
          {
            withdrawText && (
              <div className='message-withdraw-content'>
                <div>你撤回了一条消息
                  <span
                    className='withdraw-text'
                    onClick={() => {
                      handleReEdit(withdrawText);
                    }}
                  >
                    重新编辑
                  </span>
                </div>
              </div>
            )
          }
        </div>
        <div className='bottom-block'>
          <div className='dialogue-input' style={{ flexDirection: 'row' }}>
            <div className='input-block'>
              <Input
                placeholder='请输入内容'
                style={{ '--font-size': '1.6rem', backgroundColor: 'white', height: '100%' }}
                value={inputValue}
                onChange={val => setInputValue(val)}
                onEnterPress={() => {
                  onMessageSend(inputValue)
                }}
                onFocus={() => {
                  setFunctionsVisible(false);
                }}
              />
            </div>
            {!inputValue ? <div className='input-function' onClick={() => {
              setFunctionsVisible(!functionsVisible)
              scrollMessageListToEnd()
            }}>
              <img alt="" src={require('@/assets/icon/dialogue/add.png')} />
            </div> :
              <Button disabled={inputValue.trim().length <= 0} className='send-msg-btn' onClick={() => onMessageSend(inputValue)}>发送</Button>}
          </div>
          {
            quoteText && (
              <div className='quoted-content'>
                <div className='quote-text'>
                  {quoteText}
                </div>
                <CloseCircleFill
                  fontSize={24}
                  onClick={() => {
                    setQuoteText('');
                  }}
                />
              </div>
            )
          }
          {functionsVisible ? <div className='function-blocks'>
            {functionBlocks.map((functionBlock, index) => <div className='function-block' key={index} onClick={() => { if (functionBlock.func) functionBlock.func() }}>
              <div className='function-block-icon'>{functionBlock.icon}</div>
              <div className='function-block-title'>{functionBlock.title}</div>
            </div>)}
          </div> : null}
        </div>
      </div>
      <ActionSheet
        className='patient-detail-action-sheet'
        visible={actionSheetVisible}
        actions={actionSheets}
        onClose={() => setActionSheetVisible(false)}
      />
    </>
  )
}

// export default Dialogue;

export default WithTrackingForMedia(Dialogue);