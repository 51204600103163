import React from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { Button } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { initMatchQuestionnaireUrl } from '@/services/api';
import _ from 'lodash';
import heartIcon from '@/assets/images/service-desc/icon-heart.png';
import logo from '@/assets/images/service-desc/logo.png';
import handIcon from '@/assets/images/service-desc/hand-heart.png';
import talking from '@/assets/images/service-desc/talking.png';
import tree from '@/assets/images/service-desc/tree.png';

function ConsultIntroduction(props) {
  const navigate = useNavigate();

  const consultServiceDesc = [
    {
      type: 'text',
      content: '欢迎您使用舒辅智慧EAP咨询平台！舒辅致力于协助组织创建舒心、幸福的事业环境。'
    },
    {
      type: 'text',
      content: '本平台咨询师均持有国家许可的专业资质，且具备提供EAP咨询服务丰富的个案咨询经验。'
    },
    {
      type: 'complex-text',
      content: '咨询服务内容：包括职场情绪压力、人际沟通、职业发展、婚姻家庭、亲子教育、恋爱情感等常见困扰和问题。',
      icon: heartIcon
    },
    {
      type: 'complex-text',
      content: '咨询方式：图文/语音/视频咨询',
      icon: heartIcon
    },
    {
      type: 'complex-text',
      content: '服务时间：9:00-21:00',
      icon: heartIcon
    },
    {
      type: 'text',
      content: '您内心的困扰，在这里可以找到答案。舒辅智慧EAP竭诚为您服务！'
    },

  ]

  function renderDescText (content) {
    return (
      <div className='desc-p'>{content}</div>
    )
  }
  function renderDescCompleteText (content, icon) {
    return (
      <div className='complex-text-content'>
        <img className='complex-text-icon' alt="" src={icon}/>
        <div className='desc-p'>{content}</div>
      </div>
    )
  }

  function renderDescItem (item) {
      let descNode = null
      switch(item.type) {
        case 'text':
          descNode = renderDescText(item.content);
          break;
        case 'complex-text': 
          descNode = renderDescCompleteText(item.content, item.icon);
          break;
        default:
        break;
      }
      return descNode;
  }

  function renderHeader () {
    return (
      <>
        <div className="head-logo-content">
          <img className='head-logo' alt="" src={logo}/>
        </div>
        <div className='head-about-content'>
          <div className='about-text'>关于我们</div>
          <img className='about-icon' alt="" src={heartIcon}/>
        </div>
        <div className='hand-heart-content'>
          <img className='hand-icon' alt="" src={handIcon}/>
        </div>
      </>
    )
  }

  // 咨询服务介绍
  function renderIntro() {
    return (
      <div className="consult-intro-content">
        {
          renderHeader()
        }
        <div className='intro-bg'>
          <div className='intro'>
            <div className='line'></div>
            {
              consultServiceDesc.map((item, index) => {
                return <div key={index}>
                    {renderDescItem(item)}
                  </div>
              })
            }
            <div className='line'></div>
            <div className='intro-footer'>
              <img className='intro-footer-left' alt="" src={tree}/>
              <img className='intro-footer-right' alt="" src={talking}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  async function _initMatchQuestionnaireUrl() {
    let res = await initMatchQuestionnaireUrl();
    const url = _.get(res, ['result'], '');
    navigate('/client/consult-iframe', { state: { url } });
  }

  function renderConsultBtn() {
    return (
      <div className="consult-btn-content">
        <Button
          className="consult-btn"
          color='primary'
          onClick={() => _initMatchQuestionnaireUrl()}
        >开始完善个人信息</Button>
      </div>
    )
  }

  return (
    <>
      <TitleBar title="咨询服务介绍" isShowBack={true} />
      <div className="consult-content">
        {renderIntro()}
        {renderConsultBtn()}
      </div>
    </>

  )
}
export default ConsultIntroduction;