import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import _ from 'lodash';
import { Tabs, Swiper, Image } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import { save_resource_list } from '@/actions';
import { getStaffAllRecommendList, getStaffRecommendList } from '@/services/api';
import { getUrls } from '@/utils/getUrls';
import PositiveThinkingComponent from '@/components/PositiveThinkingComponent'
import JyspComponent from '@/components/JyspComponent'
import { funcList } from '@/Constant';
import { decodeToken } from '@/utils/utils';
import { getLoginToken } from '@/utils/localStorage.js';

const { IMAGE_OSS_URL } = getUrls();
const defaultPic = 'https://uploads.sxlcdn.com/static/backgrounds/abstract/76.jpg';
const ROLE = 'patient';
// 心理科普文章
const ResourceList = (props) => {
  const { handleAddEventDotClick } = props;
  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [recommendResources, setRecommendResources] = useState([]);
  // 所有文章
  const [allResources, setAllResources] = useState({});
  const { tenantId } = decodeToken(getLoginToken(ROLE))
  const staffId = useSelector(state => state.baseInfo[ROLE]?.roleInfo?.staffId);
  // 菜单功能
  const { funcs } = funcList[tenantId] || funcList.default;

  // 文章资源类型
  let tabItems = [ '焦虑','抑郁', '亲子', '睡眠', '情感', '其他'];

  // 获取是否有正念练习和减压视频
  const {submenus} = funcs.filter(item => item.id === 'resource')[0]
  if (submenus) {
    tabItems = submenus.reduce((pre, cur) => {
      if (cur === 'znlx') {
        pre.unshift('正念')
      }
      if (cur === 'jysp')  {
        pre.unshift('减压')
      }
      return pre;
    }, [...tabItems])
  }

  useEffect(() => {
    const init = async () => {
      const recommendResourcesTemp = await _getStaffRecommendList();
      setRecommendResources(recommendResourcesTemp);
    };
    // 打断
    handleAddEventDotClick({
      name: 'visit:selfGrowth/resource',
      props: {
        eventName: '自助成长：访问资源文章（更多）'
      }
    });
    init();
  }, [])

  useEffect(() => {
    // 根据tag查询资源
    async function getResourceByTag () {
      const allResourcesTemp = await _getStaffAllRecommendList(tabItems[activeIndex]);
      const result = {
        ...allResources,
        [activeIndex]: allResourcesTemp
      }
      setAllResources(result)
    }
    // 如果state中没有对应index的文章，则接口请求
    if (!allResources[activeIndex]) {
      getResourceByTag();
    }

  }, [allResources, activeIndex])

  // 查询推荐
  async function _getStaffRecommendList() {
    const params = {
      topN: '1'
    }
    const res = await getStaffRecommendList(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    }
    const recommendResourcesTemp = _.get(res, ['result'], [])
    return recommendResourcesTemp;
  }

  async function _getStaffAllRecommendList(tag) {
    const params = {
      tag
    }
    const res = await getStaffAllRecommendList(params);
    const allResourcesTemp = _.get(res, ['result'], []);
    return allResourcesTemp;
  }

  function concatPicURL(resourceId) {
    return IMAGE_OSS_URL + 'articles/cover/' + resourceId + '_cover.png';
  }

  // 推荐资源
  function renderRecommendResource() {
    return (
      <div className='recommend-block'>
        <div className='res-section-title'>推荐资源</div>
        {
          recommendResources && recommendResources.length > 0 && recommendResources.map((item, index) => {
            return (
              <div
                className='recommend-item'
                key={index}
                onClick={() => {
                  window.location.href = item.blogUrl;
                  // 打点
                  handleAddEventDotClick({
                    name: `read:resource/${item.resourceId}`,
                    props: {
                      eventName: `阅读${item.resourceId}文章`
                    }
                  });
                }}
              >
                <img id={item.resourceId} alt='' src={concatPicURL(item.resourceId)} onError={() => handleImageError(item.resourceId)} />
                <div className='recommend-item-title'>{item.articleZh}</div>
              </div>
            )
          })
        }
      </div>
    )
  }
  // 资源库
  function renderResourceLibrary() {
    return (
      <div className='res-lib'>
        <div className='res-section-title'>资料库</div>
        {renderResourceLibraryTabContent()}
      </div>
    )
  }

  // 渲染文章（根据tag）
  function renderArticles(index) {
    const articles = allResources[activeIndex];
    // const articles = allResources && allResources.length > 0 && allResources.filter(item => item.mainTag === tabItems[index]) || [];
    return articles && articles.length > 0 && articles.map((item, i) => {
      return (
        <div
          key={i}
          className='swiper-item-content'
          onClick={() => {
            window.location.href = item.blogUrl;
            // 打点
            handleAddEventDotClick({
              name: `read:resource/${item.resourceId}`,
              props: {
                eventName: `阅读${item.articleZh}`
              }
            });
          }}
        >
          <Image src={concatPicURL(item.resourceId)} className='swiper-img-background' fallback={<img className='swiper-img-background' alt="" src={defaultPic}/>} />
          <div className='swiper-text'>{item.articleZh}</div>
        </div>
      )
    })
  }

  function renderSwiperContent(item, index) {
    switch (item) {
      case '正念': 
        return <PositiveThinkingComponent/>
      case '减压': 
        return <JyspComponent/>
      default: 
        return renderArticles(index)
    }
  }

  // 资源库tab
  function renderResourceLibraryTabContent() {
    return (
      <div className='res-tabs-content'>
        {
          tabItems.length > 1 && <Tabs
            activeKey={tabItems[activeIndex]}
            onChange={key => {
              const index = tabItems.findIndex(item => item === key)
              setActiveIndex(index)
              swiperRef.current?.swipeTo(index)
            }}
          >
            {
              tabItems.map((item, index) => {
                return (
                  <Tabs.Tab
                    title={item}
                    key={item}
                  >
                  </Tabs.Tab>
                )
              })
            }
          </Tabs>
        }
        <Swiper
          className='swiper-content'
          // 水平方向循环
          direction='horizontal'
          // 可以循环
          loop={true}
          // 无指示器
          indicator={() => null}
          // 挂在当前的面板的index
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={index => {
            setActiveIndex(index)
          }}
        >
          {
            tabItems.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <div className='swiper-item'>
                    {
                      renderSwiperContent(item, index)
                    }
                  </div>
                </Swiper.Item>
              )
            })
          }
        </Swiper>
      </div>
    )
  }

  return (
    <>
      <TitleBar
        title='资源库'
        isShowBack={true}
      />
      <div className='resource-list-page'>
        {renderRecommendResource()}
        {renderResourceLibrary()}
      </div>
    </>
  )
}

export default WithTrackingForMedia(ResourceList);