import React, { useCallback, useEffect, useState, useRef } from 'react';
import _, { set } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Badge, InfiniteScroll, DotLoading, SearchBar } from 'antd-mobile';
import { UserOutline, RightOutline, MessageOutline } from 'antd-mobile-icons';
import { useDispatch, useSelector } from 'react-redux';

import '@/components/DialogueMessage/index.less';
import TitleBar from '@/components/TitleBar';
import CONFIG from 'GlobalConfigFile';
import { doctorGetStaffListPage, getAdminList } from '@/services/api';
import { getRoleFromLocation } from '@/utils/commonUtils';
import changeName from '@/utils/changeName';
import { save_admin_info } from '@/actions';
import EventEmitter from '@/utils/eventEmitter';
import { getLoginToken } from '@/utils/localStorage'
import { getLobFromToken, checkTelephone } from '@/utils/utils';
import { lobType } from '@/Constant';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import SocketDisconnect from '@/utils/socketDisconnect';

function DialogueList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const pageNo = useRef(1);
  const [staffList, setStaffList] = useState([]);
  const role = changeName(getRoleFromLocation());
  const baseInfo = useSelector(state => state.baseInfo.doctor);
  const admin = useSelector(state => state.baseInfo?.admin);
  let adminInfo;
  admin ? adminInfo = admin[0] : adminInfo = {};
  const lob = getLobFromToken(getLoginToken(role));
  // 搜索条件--姓名
  const [searchName, setSearchName] = useState('');

  const _doctorGetStaffListPage = async () => {
    const params = {
      pageNo: 1,
      // pageNo: pageNo.current || 1,
      pageSize,
    };
    // 咨询师聊天列表
    const res = await doctorGetStaffListPage(params);
    let staffListRes = _.get(res, ['result', 'staffList'], []);
    setStaffList([...staffList, ...staffListRes]);
    pageNo.current = 1;
  };

  // 下拉加载更多
  const loadMore = useCallback(async () => {
    console.log('*************loadMore***********')
    const params = {
      pageNo: pageNo.current || 1,
      pageSize,
    };
    if (checkTelephone(searchName)) {
      params.telephone = searchName
    } else {
      params.name = searchName
    }
    const res = await doctorGetStaffListPage(params);
    let staffListRes = _.get(res, ['result', 'staffList'], []);
    setStaffList([...staffList, ...staffListRes]);
    if (staffListRes.length < pageSize) {
      setHasMore(false);
    }
    else {
      pageNo.current = pageNo.current + 1;
    }
  }, [staffList, pageSize, searchName]);

  const _getAdminList = useCallback(async () => {
    // 管理员信息
    const admin = await getAdminList();
    dispatch(save_admin_info(admin?.result));
  }, [])

  const updateUnreadMessageListener = useCallback(() => {
    _doctorGetStaffListPage();
    _getAdminList();
  }, [])

  // 现在是每次切换页面都会重新请求一遍数据，需要进行相关的优化
  useEffect(() => {
    // _doctorGetStaffListPage();
    _getAdminList();
    EventEmitter.on('UPDATE_UNREAD_MESSAGE', updateUnreadMessageListener)
    return () => {
      EventEmitter.removeListener('UPDATE_UNREAD_MESSAGE', updateUnreadMessageListener)
    }
  }, [_getAdminList, updateUnreadMessageListener]);

  
  // 搜索
  async function handleSearch (name) {
    console.log('*************handleSearch***********')
    pageNo.current = 1
    setSearchName(name)
    const params = {
      pageNo: pageNo.current,
      pageSize,
    };
    if (checkTelephone(name)) {
      params.telephone = name
    } else {
      params.name = name
    }
    const res = await doctorGetStaffListPage(params);
    let staffListRes = _.get(res, ['result', 'staffList'], []);
    if (staffListRes.length < pageSize) {
      setHasMore(false);
    }
    setStaffList([...staffListRes]);
  }


  function renderGender(gender) {
    switch (gender) {
      case 'MALE':
        return '男';
      case 'FEMALE':
        return '女';
      default:
        return '保密';
    }
  }

  // TitleBar右上角的运营信息
  function operatingInformation() {
    return (
      <div
        className='operating'
        onClick={() => {
          navigate('/therapist/operating-information');
        }}
      >
        运营信息
      </div>
    )
  }

  // TitleBar对话icon
  function dialogueIcon() {

    return (
      <div className='right-content'
        onClick={() => {
          navigate(`/therapist/dialogue?chatAuthId=${adminInfo.authId}&isAdmin=true`)
        }}
      >
        {/* <Badge content={Badge.dot}> */}
        <MessageOutline fontSize={24} />
        {/* </Badge> */}
        <div className='right-content-title'>管理员</div>
      </div>
    )
  }

  function renderPatientBlock(staffInfo, index) {
    const {ownerName, tenantName} = staffInfo;
    // ownerName和tenantName相同时只显示一个
    const otCombineName = ownerName === tenantName ? tenantName : (ownerName + '-' +tenantName);

    return <div className='patient-block' key={index} onClick={() => navigate(`/therapist/dialogue?chatAuthId=${staffInfo.authId}`)}>
      {
        staffInfo.avatar ? <img src={staffInfo.avatar} alt="" /> : <UserOutline className={`patient-head border-color-${role}`} />
      }
      {/* <Badge content={Badge.dot} style={{ '--right': '90%', '--top': '10%' }}>
      </Badge> */}
      <div className='patient-info'>
        <div className='patient-info-title'>
          <div className='patient-info-name'>{staffInfo.name}</div>
          <div className='patient-info-lastTime'>{staffInfo?.lastMessageTime ? MomentDiffZone(staffInfo.lastMessageTime).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
          <div className='patient-info-tag'>{staffInfo.tagList || [].join('，')}</div>
        </div>
        <div className='patient-info-content'>{renderGender(staffInfo.gender)}，{staffInfo.age}岁，{otCombineName}</div>
        <div className='patient-info-content'>学号/工号：{ _.get(staffInfo, ['workerId'], '') || ''}</div>
      </div>
      <>
        <Badge content={staffInfo.unreadMessageCount || null} />
        <div className={`list-right-icon tx-color-${role}`}><RightOutline /></div>
      </>
    </div>
  }

  // 根据类型显示咨询师用户管理界面title：C端咨询师显示XXX工作室
  function renderTitle() {
    if (lob === lobType.CONSULTANT) {
      const consultantName = _.get(baseInfo, ['roleInfo', 'name'], '');
      return consultantName + '工作室';
    }
    return '对话'
  }
  return (
    <>
      <TitleBar
        title={renderTitle()}
        rightContent={baseInfo?.authId === CONFIG.SiuvoManagerId ? operatingInformation() : dialogueIcon()}
      />
      {
        SocketDisconnect()
      }
      <SearchBar
        placeholder='请输入姓名或者手机号'
        onSearch={handleSearch}
      />
      <div className='patient-blocks'>
        {staffList && staffList.map((staff, index) => renderPatientBlock(staff, index))}
      </div>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        threshold={50}
      />
    </>
  )
}

export default DialogueList;