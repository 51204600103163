// 消息item.message可能既有json格式也有非json,所以需要检测一下
/**
 * json格式的消息目前有两种用途，如有增加请在此处添加
 * 1. AI咨询师返回{"itemId":"exercise5_1_v2","reason":"提升行动效率，有效应对工作压力"}需要render出对应练习的视频
 * 2. AI咨询师返回{"answer":"你好，我是你的AI咨询师，很高兴为你服务。","quote":"你好，我是你的AI咨询师，很高兴为你服务。"}需要render出对应的quote
 */
import recommendChatItems from '@/Constant/recommendChatItems';

function isJsonAndReturnObj(str) {
  if (typeof str == 'string') {
    try {
      let obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        // 如果有itemId，需要做额外解析
        if (obj.itemId) {
          let answerHtml = '';
          const itemIdContent = recommendChatItems.find(item => item.itemId === obj.itemId);
          const { url, media } = itemIdContent;
          if (obj.itemId.includes('exercise')) {
            answerHtml = `<div class="exercise-video">
                              <video
                              controls="controls"
                              width="100%"
                              height="200px"
                              style={{ borderRadius: '8px' }}
                              playsInline={true}
                              webkit-playsinline='true' 
                              >
                                <source src="${media}" type="video/mp4" />
                              </video>
                            </div>`;
          }
          if (obj.itemId.includes('positive')) {
            answerHtml = `<a href="${url}">点击进行正念练习</a>\r\n`;
          }
          return { answer: answerHtml, quote: obj.quote };
        }
        return { answer: obj.answer, quote: obj.quote };
      } else {
        return { answer: str };
      }
    } catch (e) {
      return { answer: str };
    }
  }
  else {
    return { answer: str };
  }
}

export default isJsonAndReturnObj;