import React, { useState, useEffect, useRef } from 'react';
import './login.less';
import { Modal, Checkbox, Input, Toast, Button } from 'antd-mobile';
import { sendPatientVerifyCode, sendDoctorVerifyCode } from '@/services/api';
import { Tabs } from 'antd-mobile/es/components/tabs/tabs';
import checkedIcon from '@/assets/icon/checked.png'

function Login(props) {
  const { loginTitle, theme, onLogin, onSendVerifyCode, type, registerCodeAvailable, handleCheckRegisterCode, handleTelephoneCheck } = props;
  const [agreeType, setAgreeType] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verifyCodeInterval, setVerifyCodeInterval] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const timerID = useRef(null);
  // 注册逻辑
  // 注册码
  const [registerCode, setRegisterCode] = useState('');
  // tab切换的key
  const tabRef = useRef(0);


  // 发送短信倒计时
  useEffect(() => {
    if (verifyCodeInterval > 0) {
      timerID.current = setTimeout(() => {
        setVerifyCodeInterval(verifyCodeInterval - 1)
      }, 1000)
      timerID.current = null;
    }
    return () => {
      timerID.current = null;
    }
  }, [verifyCodeInterval])

  // 发送短信验证码  
  async function handleVerifyCode() {
    if ( tabRef.current === 0 || registerCodeAvailable) {
      if (!cellphone || cellphone.length !== 11) {
        Toast.show('请先输入手机号')
      } else {
        onSendVerifyCode(cellphone);
        setVerifyCodeInterval(30);
      }
    } else {
      Toast.show('请先输入注册码进行验证。')
    }
  }

  function checkAgree() {
    !agreeType && Toast.show({
      content: '请阅读并同意《舒辅EAP服务协议》',
      key: 'login'
    })
    return agreeType
  }

  function checkPhoneAndVerifyCode(isRegister) {
    if (isRegister && !registerCode) {
      Toast.show({
        content: '请先输入注册码',
        key: 'registerCode'
      });
      return false
    }
    if (!cellphone || cellphone.length !== 11) {
      Toast.show({
        content: '请检查您的手机号是否正确',
        key: 'cellphone'
      });
      return false
    }
    if (verificationCode.length === 4 || verificationCode.length === 6) {
      return true
    }
    Toast.show({
      content: '请正确填写验证码',
      key: 'verificationCode'
    });
    return false;
  }

  // 登录
  function onCellphoneLogin() {
    // const agreement = checkAgree();
    // 先注释掉同意eap协议的选项
    const agreement = true;
    const inputCorrect = checkPhoneAndVerifyCode(false);
    if (agreement && inputCorrect) {
      const params = {
        telephone: cellphone,
        verifyCode: verificationCode,
      }
      onLogin(params);
    }
  }
  // 注册界面---校验手机号
  function onCheckCellphone () {
    const inputCorrect = checkPhoneAndVerifyCode(true);
    if (inputCorrect) {
      const params = {
        telephone: cellphone,
        verifyCode: verificationCode,
        registerCode
      }
      handleTelephoneCheck(params);
    }
  }

  function renderCellphoneLogin() {
    return (
      <>
        <div className="login-info">
          <div className={`info-title tx-color-${theme}`}>手机号</div>
          <Input className="phone-input" size="large" placeholder="请输入手机号" onChange={value => setCellphone(value)} />
          <div className={`info-title tx-color-${theme}`}>验证码</div>
          <div className="phone-code-content">
            <Input className="phone-input phone-code" placeholder="请输入验证码" onChange={value => setVerificationCode(value)} />
            {
              verifyCodeInterval === 0 ? <Button className={`phone-send-code-btn bg-theme-${theme}`} color='primary' onClick={(handleVerifyCode)}>获取验证码</Button> :
                <Button className="phone-send-code-btn" style={{ '--background-color': '#aaa', color: '#fff' }}>{verifyCodeInterval}s重新发送</Button>
            }
          </div>
        </div>
        <div className='login-btn-content'>
          <Button className={`btn-login bg-theme-${theme}`} onClick={onCellphoneLogin}>一键登录</Button>
        </div>
      </>
    )
  }

  // 渲染注册码样式--可输入，不可输入样式区分
  function renderRegisterCode() {
    if (!registerCodeAvailable) {
      return (
          <Input className="phone-input" size="large" placeholder="请输入注册码" disabled={registerCodeAvailable} onChange={value => setRegisterCode(value)} onBlur={() => {
            // 只有在注册的时候失去焦点才做判断，tab切换不调用接口
            setTimeout(() => {
              if (tabRef.current === '1' && registerCode !== '') {
                handleCheckRegisterCode(registerCode);
              }
            }, 0)
          }}/>
      )
    }
    return (
      <div className='register-code-checked-content'>
        <div className='reg-code'>{registerCode}</div>
        <img alt="" src={checkedIcon}/>
      </div>
    )
  }

  function renderRegister() {
    return (
      <>
        <div className='login-info'>
          <div className={`info-title tx-color-${theme}`}>注册码</div>
          {renderRegisterCode()}
          <div className={`info-title tx-color-${theme}`}>手机号</div>
          <Input className={`phone-input ${registerCodeAvailable ? '' : 'input-disabled'} `} size="large" placeholder="请输入手机号" disabled={!registerCodeAvailable} onChange={value => setCellphone(value)} />
          <div className={`info-title tx-color-${theme}`}>验证码</div>
          <div className="phone-code-content">
            <Input className={`phone-input phone-code ${registerCodeAvailable ? '' : 'input-disabled'}`} placeholder="请输入验证码"  disabled={!registerCodeAvailable} onChange={value => setVerificationCode(value)} />
            {
              verifyCodeInterval === 0 ? <Button className={`phone-send-code-btn bg-theme-${theme}`} color='primary' onClick={(handleVerifyCode)}>获取验证码</Button> :
                <Button className="phone-send-code-btn" style={{ '--background-color': '#aaa', color: '#fff' }}>{verifyCodeInterval}s重新发送</Button>
            }
          </div>
        </div>
        <div className='login-btn-content'>
          <Button className={`btn-login bg-theme-${theme}`} onClick={onCheckCellphone}>注册</Button>
        </div>
      </>
    )
  }

  // 渲染登录和注册组件
  function renderLoginAndRegister() {
    return (
      <Tabs className='login-register-tabs' onChange={key => {
        tabRef.current = key;
      }}>
        <Tabs.Tab title='登录' key='0'>
          {renderCellphoneLogin()}
        </Tabs.Tab>
        <Tabs.Tab title='注册' key='1'>
          {renderRegister()}
        </Tabs.Tab>
      </Tabs>
    )
  }

  return (
    <>
      <div className="login">
        <div className="login-main-content">
          {
            theme === 'patient' ?
              <img className="logo" alt="" src={require('@/assets/icon/logo.bmp')} />
              :
              <img className="logo" alt="" src={require('@/assets/icon/logo.svg').default} />
          }
          <div className="title">欢迎使用</div>
          <div className="title">{loginTitle}助手</div>
          {
            renderLoginAndRegister()
          }
        </div>
      </div>
      <div className={showModal ? "shade-modal" : "shade-modal show-none"}>
        <div className="shade"></div>
        <div className="modal">
          <div className="title">{loginTitle}助手</div>
          <div className="content">为了确保您的就医安全，需要您输入您的微信手机号</div>
          <div className="btn" onClick={() => {
            setShowModal(false);
          }}>去授权</div>
        </div>
      </div>
      <Modal
        visible={agreementModal}
        transparent
        closeOnMaskClick={true}
        showCloseButton={true}
        title={`${loginTitle}协议`}
        onClose={() => {
          setAgreementModal(false)
        }}
        footer={[{ text: '确认', onPress: () => { setAgreementModal(false) } }]}
      >
        <p style={{ padding: '1rem' }}>请详细阅读以下协议内容</p>
      </Modal>
    </>
  )
}

export default Login;