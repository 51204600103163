import React from 'react'
import { Navigate, useLocation } from "react-router-dom";

import {
  getLoginToken,
} from '@/utils/localStorage';
import changeName from '@/utils/changeName';
import { checkTokenIsValid } from '@/utils/utils';

// 不同端的登录路由
const loginRoute = {
  client: '/client/login-with-register',
  therapist: '/therapist/login',
  admin: '/admin/login'
}

const RouteGuard = (props) => {
  const location = useLocation();
  const { pathname, search } = location;
  // 拿到子组件,router的参数
  const { role, children } = props

  // 获取token，校验token是否有效，路由是否需要拦截
  const token = getLoginToken(changeName(role));
  const isTokenValid = checkTokenIsValid(token);
  if (!isTokenValid) {
    return <Navigate to={loginRoute[role]} state={{ from: `${pathname}${search}` }}></Navigate>
  }

  return <>{children}</>
}
export default RouteGuard
