import { message_update, handle_notification, message_withdraw, show_global_dialog } from '@/actions'
import { CHAT_TO_ONE, FILE_UPLOAD, NOTIFICATION, CALL_TO_ONE, LOGOUT_CHAT_CLOSE } from '@/actions/actionType'
import CONFIG from 'GlobalConfigFile';
import { getAuthId } from '@/utils/localStorage';
import changeName from '@/utils/changeName';
import _ from 'lodash';
import EventEmitter from '@/utils/eventEmitter';
import { emitterEvent } from '@/Constant';
import { CONSULT_END_CONFIRM } from '../actions/actionType';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { Dialog } from 'antd-mobile';

// 获取id，重新创建socket
function reCreateSocket(store, authId) {
  const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
  // authId = _.get(store.getState(), ['baseInfo', 'authId', '']) || authId;
  if (authId) {
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + authId, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    return socket;
  }
}

// 是否是消息的是接受者,发送的消息来源是不是当前聊天的对象
function isNeedUpdateMessage(store, data, role) {
  const baseInfo = store.getState().baseInfo;
  const chatAuthId = store.getState().chatInfo.chatAuthId;
  const authId = baseInfo[role] && baseInfo[role].authId;
  if (authId === data.toUserId && chatAuthId === data.fromUserId) {
    return 'messageFromOpposite'
  }
  else if (authId === data.fromUserId && chatAuthId === data.toUserId) {
    return 'messageFromSelf'
  }
  else {
    return false;
  }
  // return authId === data.toUserId && chatAuthId === data.fromUserId;
}

function handleNotification(history, message, next) {
  const { type } = message
  console.log('handleNotification', type);
  switch (type) {
    case 'APPOINT_ROOM_SUCCESS':
      let roomUrl = message.roomUrl;
      if (roomUrl) {
        history.push(roomUrl)
        window.location.reload();
      }
      break;
    case 'APPOINT_ADD':
    case 'APPOINT_TIMESLOT_ADD':
    case 'APPOINT_TIMESLOT_REMOVE':
    case 'APPOINT_CANCEL':
      EventEmitter.emit(emitterEvent.APPOINT_UPDATE);
      break;
    case 'CONSULT_END_CONFIRM':
      EventEmitter.emit(emitterEvent.CONSULT_END_CONFIRM, message);
      break;
    case 'CONSULT_WAIT_EVALUATE':
      EventEmitter.emit(emitterEvent.CONSULT_WAIT_EVALUATE, message);
      break;
    case 'STAFF_FORM_TASK_SUCCESS':
      EventEmitter.emit(emitterEvent.STAFF_FORM_TASK_SUCCESS, message);
      break;
    case 'EVALUATION_REPORT_TO_STAFF':
      const navPath = '/client/health-report';
      next(show_global_dialog({...message, navPath}))
      break;
    default:
      break;
  }
}
let timer = null;
// 服务端收到的消息
const receiveMessageList = [];
// 心跳检测
let heartBeatTimer = null;
// 心跳间隔
const HEART_BEAT_INTERVAL = 1000 * 10;

function createSocketMiddleware(role, authId) {
  console.log('**********8createSocketMiddleware****', authId)
  let eventFlag = false;
  let socket = null;
  const createHistory = require('history').createBrowserHistory;
  let history = createHistory();

  // 监听网页关闭
  window.onbeforeunload = function () {
    console.log('网页关闭')
    socket && socket.close();
  }

  return store => next => action => {
    clearInterval(timer);
    clearInterval(heartBeatTimer);
    timer = setInterval(() => {
      if (receiveMessageList.length > 0) {
        next(message_update(receiveMessageList.shift()))
        EventEmitter.emit('SHOW_DOT_LOADING', true);
      }
      if (receiveMessageList.length === 0) {
        EventEmitter.emit('SHOW_DOT_LOADING', false);
      }
    }, 1500)
    heartBeatTimer = setInterval(() => {
      if (socket?.connected) {
        EventEmitter.emit('DISCONNECT_SOCKET', false);
      }
      else {
        EventEmitter.emit('DISCONNECT_SOCKET', true);
      }
    }, HEART_BEAT_INTERVAL)

    if (action.type === 'REGISTER_SOCKET_EVENT') {
      socket = action.socket;
    }
    if (!eventFlag && socket) {
      console.log('register socket event listener', socket.listeners())
      eventFlag = true;
      socket.on('connect', () => {
        console.log('socket connect success!!!!')
        EventEmitter.emit('DISCONNECT_SOCKET', false);
      })
      console.log('register socket event', action.socket);
      socket.on(CHAT_TO_ONE, (data) => {
        console.log('chatToOne:', data, socket.id)
        // 根据fromUserId判断 当前接受到的消息属于不同设备发出去的消息还是属于接受到的设备
        const messageFromWhere = isNeedUpdateMessage(store, data, changeName(role));
        if (messageFromWhere === false) {
          return;
        }
        else {
          // 机器人的消息
          if (data.messageType === 'ROBOT_CHAT') {
            receiveMessageList.push(data);
          }
          // 需要撤回的消息
          else if (data.messageType === 'WITHDRAW') {
            next(message_withdraw({ ...data, withdrawFlag: true }))
          }
          else {
            next(message_update(data))
          }
        }
        EventEmitter.emit('UPDATE_UNREAD_MESSAGE', {...data, messageFromWhere});
      })
      socket.on(CALL_TO_ONE, (data) => {
        console.log('callToOne:', data)
        const callStatus = JSON.parse(data.message).callStatus;
        (callStatus === 'HANG_UP') && EventEmitter.emit(emitterEvent.HANG_UP);
        const messageFromWhere = isNeedUpdateMessage(store, data, changeName(role));
        messageFromWhere && next(message_update(data));
      })
      socket.on(NOTIFICATION, (data) => {
        console.log('NOTIFICATION:', data)
        let message = JSON.parse(data.message);
        handleNotification(history, message, next);
        next(handle_notification(message))
      })
      socket.on(FILE_UPLOAD, (data) => {
        console.log('notification:', data)
        next(message_update(data));
        const messageFromWhere = isNeedUpdateMessage(store, data, changeName(role));
        EventEmitter.emit('UPDATE_UNREAD_MESSAGE', {...data, messageFromWhere});
      })

      socket.on('disconnect', (reason) => {
        console.log('disconnect', reason);
        // 断连接增加状态
        EventEmitter.emit('DISCONNECT_SOCKET', true);
        socket && socket.open();
      })
      console.log('register socket event listener', socket.listeners(CHAT_TO_ONE))
    }
    if (socket) {
      if (!socket?.connected) {
        socket.open();
      }
      switch (action.type) {
        case CHAT_TO_ONE:
          console.log('CHAT TO ONE:', action.value)
          console.warn('emit call flag to server, socket', socket.connected, socket)
          socket.emit(CHAT_TO_ONE, action.value, (ack) => {
            if (ack && ack === 'success') {
              next(message_update({ ...action.value, onSendSuccess: true }))
              EventEmitter.emit('UPDATE_UNREAD_MESSAGE', {...action.value, messageFromWhere: 'messageFromSelf'});
            }
            else {
              // 发送消息失败，可能的原因是socket物理连接可能还在，但是因为刷新后，后端的socket.id已经变了，所以发送消息失败
              next(message_update({ ...action.value, onSendSuccess: false }));
            }
          })
          break;
        case CALL_TO_ONE:
          console.log('emit call flag to server', action.value)
          socket.emit(CALL_TO_ONE, action.value, (ack) => console.log("callToOne ack data: ", ack))
          break;
        case LOGOUT_CHAT_CLOSE:
          socket.close();
          eventFlag = false;
          console.log('logout and disconnect socket!', socket, eventFlag)
          // socket.off();
          socket = null;
          break;
        default:
          break;
      }
    } else if (!socket) {
      const newSocket = reCreateSocket(store, authId)
      socket = newSocket;
    }
    return next(action);
  }
}

export default createSocketMiddleware
