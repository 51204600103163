// 创建子账号页面
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Toast, Form, Input, Space, Picker, Dialog } from 'antd-mobile';
import { CloseCircleFill } from 'antd-mobile-icons';
import moment from 'moment';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTracking from '@/components/WithTracking';
import { sendPatientVerifyCode, staffGetSubList, checkTelephone, staffDeleteSubAccount } from '@/services/api';

const SubAccount = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [pickerVisible, setPickerVisible] = useState(false);
  const baseInfo = useSelector(state => state.baseInfo['patient']);
  // 已绑定的子账号列表
  const [subAccountList, setSubAccountList] = useState([]);

  // 查询子账号
  const _staffGetSubList = async () => {
    const res = await staffGetSubList();
    setSubAccountList(res?.result?.list);
  };

  const _staffDeleteSubAccount = async (params) => {
    const res = await staffDeleteSubAccount(params);
    if (res.error) {
      Toast.show({
        icon: 'fail',
        content: res.error
      })
    } else {
      Toast.show({
        icon: 'success',
        content: '删除成功',
        afterClose: () => {
          _staffGetSubList();
        }
      })
    }
  }

  useEffect(() => {
    _staffGetSubList();
  }, []);

  // 校验手机号用于检测该手机号是新注册的号还是已有账号
  const _checkTelephone = async (phoneNumber) => {
    const params = {
      telephone: phoneNumber
    };
    const res = await checkTelephone(params);
    return res?.result?.authList;
  };

  const columns = [
    [
      { label: '父母', value: 'PARENTS' },
      { label: '配偶', value: 'SPOUSE' },
      { label: '子女', value: 'CHILDREN' },
    ]
  ];

  // 根据relationShip获取对应的label
  const getLabelByValue = (value) => {
    switch (value) {
      case 'PARENTS':
        return '父母';
      case 'SPOUSE':
        return '配偶';
      case 'CHILDREN':
        return '子女';
      default:
        return '暂无信息';
    }
  }

  async function handleSendVerifyCode(cellphone) {
    const res = await sendPatientVerifyCode(cellphone);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '短信发送成功',
        key: 'success'
      });
    }
  };



  const renderSubAccountList = () => {
    const account = subAccountList.length;
    const remainAccount = 3 - account;

    return (
      <>
        <Card title={`您已存在${account}个子账号，还可以创建${remainAccount > 0 ? remainAccount : 0}个`}>
          {
            subAccountList && subAccountList.length > 0 && subAccountList.map((item, index) => {
              if (index > 2) {
                return null;
              }
              return (
                <div key={index} className='card-content'>
                  <Card >
                    <div style={{ fontSize: '15px' }}>账号: {item.account || '暂无信息'}</div>
                    <div style={{ fontSize: '15px' }}>姓名: {item.name || '暂无信息'}</div>
                    <div style={{ fontSize: '15px' }}>手机号: {item.telephone || '暂无信息'}</div>
                    <div style={{ fontSize: '15px' }}>注册渠道: {item.createdBy || '暂无信息'}</div>
                    <div style={{ fontSize: '15px' }}>与父账号关系: {getLabelByValue(item.relationship)}</div>
                    <div style={{ fontSize: '15px' }}>创建时间: {moment(item.createTime).format('YYYY-MM-DD') || '暂无信息'}</div>
                  </Card>
                  <CloseCircleFill
                    fontSize={24}
                    color='#ff3141'
                    onClick={() => {
                      Dialog.show({
                        content: '确认删除该子账号吗？',
                        closeOnAction: true,
                        actions: [
                          [
                            {
                              key: 'cancel',
                              text: '取消',
                            },
                            {
                              key: 'delete',
                              text: '删除',
                              bold: true,
                              danger: true,
                              onClick: async () => {
                                await _staffDeleteSubAccount({ authId: item.authId })
                              }
                            },
                          ],
                        ]
                      })
                    }}
                  />
                </div>
              )
            })
          }
          <div className='footer'>
            <Button
              color='primary'
              onClick={() => {
                const cardContent = document.querySelector('.card-animation');
                cardContent?.classList.add('card-animation-active');
              }}
              disabled={account > 2}
            >
              创建子账号
            </Button>
          </div>
        </Card>
      </>
    )
  }

  return (
    <>
      <TitleBar title="创建子账号" isShowBack={true} />
      <div className='sub-account-content'>
        <Space direction='vertical' block>
          {renderSubAccountList()}
          <br />
          <Card title="创建子账号" bodyStyle={{ padding: 0, margin: 0 }} className='card-animation'>
            <Form
              mode='card'
              layout='vertical'
              form={form}
              footer={
                <Button block type='submit' color='primary' size='large'>
                  创建
                </Button>
              }
              onFinish={async () => {
                const formVal = form.getFieldsValue();
                const params = {
                  telephone: formVal.phoneNumber,
                  verifyCode: formVal.verificationCode,
                  relationship: formVal.subRelationship[0],
                  authId: baseInfo?.authId,
                };
                console.log('params', params);
                //  TODO: 调用接口确认该手机号是否是新号
                const authList = await _checkTelephone(formVal.phoneNumber);
                navigate('/client/subAccount-register', { state: { ...params, authList } });
              }}
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <Form.Item
                name='phoneNumber'
                label={<strong>手机号</strong>}
                style={{
                  padding: 0,
                  margin: 0,
                }}
                rules={[{ required: true, message: '手机号不能为空' }]}
              >
                <Input placeholder='请输入' />
              </Form.Item>
              <Form.Item
                name='verificationCode'
                label={<strong>短信验证码</strong>}
                style={{
                  padding: 0,
                  margin: 0,
                }}
                rules={[{ required: true, message: '短信验证码不能为空' }]}
              >
                <div className='verification-content'>
                  <Input placeholder='请输入' style={{ maxWidth: '55%' }} />
                  <Button
                    color='primary'
                    fill='solid'
                    size='middle'
                    shape='rounded'
                    style={{
                      marginLeft: '20px',
                      maxWidth: '40%',
                    }}
                    onClick={() => {
                      const phoneNumber = form.getFieldValue('phoneNumber');
                      if (phoneNumber) {
                        handleSendVerifyCode(phoneNumber);
                      } else {
                        Toast.show({
                          icon: 'fail',
                          content: '请输入手机号'
                        })
                      }
                    }}
                  >
                    发送验证码
                  </Button>
                </div>
              </Form.Item>
              <Form.Item
                name='subRelationship'
                label={<strong>子账号关系</strong>}
                trigger='onConfirm'
                onClick={() => setPickerVisible(true)}
                arrow={false}
                style={{
                  padding: 0,
                  margin: 0,
                }}
                rules={[{ required: true, message: '关系不能为空' }]}
              >
                <Picker
                  visible={pickerVisible}
                  columns={columns}
                  onCancel={() => setPickerVisible(false)}
                  onConfirm={(value, value2) => {
                    form.setFieldValue('subRelationship', value);
                    setPickerVisible(false);
                  }}
                  value={form.getFieldValue('subRelationship')}
                >
                  {(pickerColumn, actions) => {
                    return (
                      <Input
                        placeholder='请选择'
                        value={pickerColumn[0]?.label}
                      />
                    )
                  }}
                </Picker>
              </Form.Item>
            </Form>
          </Card>
        </Space>
      </div>
    </>
  )
}

// export default SubAccount;
export default WithTracking(
  SubAccount,
  {
    name: 'visit:subAccountPage',
    props: {
      eventName: '进入查看子账号页面'
    }
  }
)