import React, { useEffect, useState, useCallback } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import Pic1 from '@/assets/images/pic-1.jpg'
import Pic2 from '@/assets/images/pic-2.jpeg'

// 资源详情界面
function ResourceDetail (props) {
  const location = useLocation();
  const resources = [
    {
      id: 'Best Natural Options for Anxiety Relief',
      title: '缓解焦虑的天然选择',
      banner: Pic1,
      text: `有时会提供处方药来帮助人们应对焦虑，但如果你想选择自然调整的方法且在有条件的情况下，可以尝试一些家庭疗法。<br><br>

      虽然每个自然调整方法听起来都是安全的，但是还是与医疗保健专业人员一起讨论一下为好。这样，他们可能为你提供一些具体的指导。<br><br>
      
      并非所有治疗焦虑的自然疗法都对每个人都有效。由于自身的特定情况，你可能会发现一些方法对自己有效，但不一定对其他人有效。此外，在某些情况下，只有与其他治疗方案结合使用，它们才能帮助缓解焦虑症状。这取决于许多因素。<br>
      <br><br>
      
      如何自然缓解焦虑？<br>
      如果你患有焦虑症，你可以尝试一些自然疗法来降低压力水平，提高应对能力。<br><br>
      
      其中一些治疗焦虑的自然疗法包括草药和补充剂，但大多数可能需要改变你的一些常规和生活习惯。<br><br>
      
      在用药物或补充剂时，强烈建议您咨询医疗保健专业人员，以便了解禁忌情况、副作用和药物间的相互影响。<br><br>
      
      还需要知道的是，有许多补充剂和天然产品（如百花草），可能没有足够的证据支持所声称的作用。它们可能对某些人有效，而对其他人则无效。<br><br>
      
      以下是一些有助于自然缓解焦虑的方法，这些方法对许多人都有效：<br><br>
      
      锻炼<br>
      你以前听过，这是因为它确实有效。体育锻炼对健康有多种好处。<br><br>
      
      研究表明，无论是绕街道散步还是跑五公里，运动都有助于减轻焦虑症状，尤其是与心理治疗结合使用的情况下。在某些情况下，锻炼还可以与药物结合使用。<br><br>
      
      运动如何缓解焦虑？首先，它有助于分散你注意力，减少对焦虑的关注。更重要的是，体育锻炼会促进内啡肽的分泌，这种内啡肽被非正式地称为“快乐荷尔蒙”。<br><br>
      
      内啡肽是天然的止痛药，也能增强愉悦感。它们可以帮助你睡得更好，并对未来有积极的憧憬，以此来缓解焦虑。<br><br>
      
      如果你不喜欢剧烈运动，可以尝试瑜伽，它也可以减轻压力，缓解焦虑和抑郁。<br><br>
      
      减少咖啡因摄入<br>
      咖啡因是一种兴奋剂，但如果你有焦虑症，它会让你感到更加紧张。此外，它还可能引起一些身体症状，如心跳加快和颤抖，这可能会让你觉得自己正在焦虑与恐慌。<br><br>
      
      研究还表明，在一些患有心理健康障碍的人中，咖啡因也可能增加焦虑症状。<br><br>
      
      如果你习惯于大量饮用咖啡因，建议慢慢限制咖啡因的摄入量，以避免出现突然戒断时常发的戒断症状。<br><br>
      
      限制或停止吸烟<br>
      有研究证明，香烟烟雾中的尼古丁和其他化学物质会改变大脑中与焦虑有关的通路。<br><br>
      
      2013年的一项研究还表明，越早开始吸烟的人，以后患焦虑症的可能性就越大。<br><br>
      
      更多的笑声<br>
      幽默和笑声对你的身心健康有很多好处，包括减轻你的压力和焦虑程度。<br><br>
      
      笑声和幽默可以帮助你降低压力荷尔蒙的水平，包括皮质醇、多巴胺和肾上腺素。同时，它可能会促进内啡肽的产生。<br><br>
      
      笑声还可以增加产生抗体的细胞的数量，并有助于使你的注意力回到现实。这可能会让你减少对未来的烦扰和焦虑。<br><br>
      
      事实上，笑疗法被证明可以自然地缓解焦虑。<br><br>
      
      维生素D<br>
      当你缺乏足够的维生素D时，你的血清素水平就会下降。5-羟色胺（血清素）是一种促进情绪的激素，可以让你感到更加平静和专注。<br><br>
      
      研究表明，焦虑症和抑郁症可能与维生素D缺乏有关。<br><br>
      
      你可能需要与医疗保健的专业人员讨论测试项目，以检查目前血液水平的维生素D含量。<br><br>
      
      户外活动是提高维生素D水平的最好方法。你可以在中午享受15到30分钟的阳光，如果可能的话，每周至少4次。可以用防晒霜来防止晒伤，它不会影响维生素D的产生。<br><br>
      
      其他增加维生素D的自然方法包括食用脂肪含量丰富的鱼、蘑菇和相关保健食品。<br><br>
      
      喝茶<br>
      洋甘菊和薰衣草是两种以缓解焦虑而闻名的茶。<br><br>
      
      洋甘菊通常被称为温和的镇静剂和助眠剂，因为它含有一种叫做芹菜素的抗氧化剂。芹菜素会与大脑中的相关受体结合，以此减少焦虑与助眠。<br><br>
      
      几个世纪以来，薰衣草一直被用作减少焦虑的天然药物，因为它可以缓解紧张的情绪。<br><br>
      
      许多其他研究表明，薰衣草也能有效减少：焦虑症状、烦躁不安、睡眠障碍紧张、抑郁症的症状等。<br><br>
      
      检查镁含量<br>
      镁是人体内重要的矿物质。它在300多种酶反应中发挥作用，有助于维持肌肉和神经功能、血压和免疫系统。<br><br>
      
      许多中国人在饮食中摄入的镁含量不足。<br><br>
      
      还有几项研究发现，镁补充剂可以帮助减轻焦虑症状，包括与躯体症状（PMS）有关的症状。<br><br>
      
      与维生素D一样，你可能需要在补充之前检查目前体内的镁水平。<br><br>
      
      你可以通过吃以下食物自然获得镁：全麦、黑豆、菠菜、藜麦、杏仁、腰果、黑巧克力等；<br>
      你也可以服用补充剂，但可能需要先询问医疗保健的专业人员。因为一些补充剂可能会有嗜睡和腹泻等副作用。专业人士可能会告诉你补充镁的最佳选择以及其的服用量。<br><br>
      
      ω-3脂肪酸<br>
      ω-3脂肪酸在大脑的整体健康中起着重要作用，但你的身体并不能自己制造这一脂肪酸。不过，你可以通过饮食（如食用鱼或亚麻籽等食物）获得所需的ω-3脂肪酸。<br><br>
      
      2018年相关研究发现，鱼油补充剂形式的ω-3脂肪酸可以帮助缓解焦虑。<br><br>
      
      如果你想服用ω-3脂肪酸补充剂，可能需要告诉你的医生。因为它们有时会干扰某些处方药，例如血液稀释剂。因此患有某些疾病的人不建议服用。<br><br>
      
      L-茶氨酸补充剂<br>
      茶氨酸是绿茶或红茶中的一种氨基酸。有证据表明它是一种温和的镇静剂或抗焦虑剂，可以帮助降低你的压力反应和皮质醇水平。<br><br>
      
      维生素B复合物<br>
      B族维生素是由八种不同的营养素组成，共同作用于你的身体，以管理身体过程，包括压力反应。<br><br>
      
      大多数人可以通过各种食物获得足够的这些维生素，但如果你是素食主义者，你可能很难获得维生素B12和B2（核黄素）。<br><br>
      
      2017年的一项研究表明，血液中维生素B12水平低的人更容易出现焦虑或抑郁症状。这就是为什么你可能要考虑补充这些维生素的原因。<br><br>
      
      市场上有不同类型的B族维生素补充剂。医疗保健专业人员可能会指导您哪些药物对您的焦虑症状最为有效。此外，你也可以考虑食用富含这些维生素的食物。<br><br>
      
      例如，马迈酱（Marmite）和维吉麦（vegemite）酱都含有B族维生素，一项研究发现，吃了它们的人压力和焦虑程度较低。<br><br>
      
      缓解焦虑的补充疗法<br>
      西医和心理治疗可能是治疗焦虑症的常见选择，但有证据表明其他干预措施也有效。<br><br>
      
      以下是一些可以自然缓解焦虑的补充疗法：<br><br>
      
      动物疗法<br>
      抚摸动物不仅仅是动物爱好者的乐趣。有证据表明，养宠物或与动物相处可以降低焦虑水平，有助于心理健康。<br><br>
      
      2018年的一项研究发现，动物疗法可以帮助老年人缓解焦虑。此外，2015年的一项研究表明，抚摸宠物狗的体验会使身体释放催产素，从而改善你的压力水平和提升幸福感。<br><br>
      
      几十年来，宠物狗也被证明可以降低人们的心率，而养宠物狗可以减轻儿童和成人的压力和焦虑。<br><br>
      
      2015年，另一位研究人士发现，梳理马匹和与马匹相处也可以降低与创伤相关的焦虑和压力。<br><br>
      
      并非只有毛茸茸的动物才能减轻焦虑。2015年的一项研究发现，照顾蟋蟀可以改善老年人的心理健康。<br><br>
      
      芳香疗法<br>
      芳香疗法使用芳香精油来促进健康。一些精油可以直接吸入或涂抹在皮肤上，而有些精油则需要添加到浴缸或扩散器中才可以获得最佳效果。<br><br>
      
      另外，有研究证明，芳香疗法可以帮助人们放松，睡眠，降低心率和血压，特别是某些气味（如薰衣草）。<br><br>
      
      然而，关于精油的功效和安全性的研究仍然有限。<br><br>
      
      我国目前推荐使用以下减轻焦虑的香味剂：薰衣草、鼠尾草、橙花、洋甘菊、玫瑰；<br><br>
      如果你决定尝试芳香疗法，最好记住：<br>
      食品与药品监督管理局不管理精油。<br>
      如果局部涂抹精油，建议使用精油的载体油。此外，使用前你还需要在皮肤上做补丁测试。<br>
      建议在使用任何精油之前向医疗保健专业人员咨询，因为许多精油是有毒的。<br><br>
      
      正念<br>
      正念减压（MBSR）是20世纪70年代开发的一种结合冥想和瑜伽的治疗方法，其目的在于处理可能产生压力的无意识想法、感受和行为。<br><br>
      
      研究表明，我们可以通过学习如何解决问题、调节情绪和正念来提升个人的适应力。<br><br>
      
      2009年一项研究表明，专注于正念的练习可能会缓解焦虑，也会增加同理心。其他研究表明，它甚至可以预防抑郁症和助眠。<br><br>
      
      催眠疗法<br>
      有很多原因可以解释治疗师为何将催眠作为治疗的一种方法。首先，它可以帮助你达到放松的状态。此外，它也可以帮助你处理一些可能与焦虑症状有关的生活事件。<br><br>
      
      一些研究表明，催眠疗法可以帮助缓解压力、恐惧和焦虑症状。最后，它还可以帮助人们应对恐慌症的症状。例如，它常被用来帮助治疗广场恐怖症患者。<br><br>
      
      针灸<br>
      针灸是一种古老的做法，将针头插入身体的不同穴位。长期以来，它一直是治疗焦虑症的常用替代疗法。<br><br>
      
      针灸的疗效有据可依。例如，2015年一项研究发现，针灸可以改善对其他治疗法无反应的人的广泛性焦虑症症状。<br><br>
      
      然而，关于针灸如何帮助焦虑症状的研究仍然有限。你可能需要自己尝试一下，看看它是否对你有帮助。<br><br>
      
      森林浴<br>
      自20世纪80年代以来，森林浴一直被用于治疗抑郁症，焦虑症，炎症和其他健康问题。<br><br>
      
      森林浴即花时间在户外，在练习呼吸和集中注意力的同时仔细观察大自然。<br><br>
      
      研究表明，这种疗法可能：降低心率、降低血压、改善你的情绪、缓解压力和抑郁、减少疲劳和焦虑可靠的来源。<br>
      研究表明，即使你没有很多时间在外面度过，你仍然可以感受到好处。因为在自然环境中只要10分钟就能减轻你的压力。<br><br>
      
      
      让我们回顾一下<br>
      如果你想自然地平息焦虑，你可以做很多事情。<br><br>
      
      考虑对你的习惯做一些基本的改变，例如：<br><br>
      
      尽量多睡觉。<br>
      尽可能多地锻炼。<br>
      努力将某些食物纳入饮食。<br>
      一些补充剂也可能有助于减轻焦虑。<br><br>
      
      补充疗法也被证明是治疗焦虑症的自然疗法。<br><br>
      
      为了确保不会损害身体健康，在使用任何非处方补充剂或尝试任何尚未经过充分研究的替代疗法之前，请务必向医护人员咨询。<br>`
    },
    {
      id: 'How Can OCD Make you Doubt your Reality',
      title: '强迫症如何让你对现实产生怀疑',
      banner: Pic2,
      text: `强迫症（也称为“怀疑障碍”）会让你对5分钟前确定的事情产生疑问。<br><br>

      强迫症（OCD）是一种心理健康状况,通常是无法控制的焦虑想法，并对这些想法做出频繁的强迫反应。<br><br>
      
      如果你患有强迫症，你可能会发现自己专注于一件事，比如确保盘子干净。即使在确认盘子已经洗过之后，你也可能会开始怀疑自己是否已经检查过盘子，或者怀疑自己洗得是否正确。你可以回去重新洗刷盘子——只是为了确定一下。<br><br>
      
      强迫症也被称为‘怀疑障碍’，强迫症中强迫的驱动力之一是长期的怀疑。你的大脑诱使你认为某些事情被忽视了，这种恐惧驱使个体再次重复这种行为。怀疑是这种疾病的标志，它压倒了一个人可能拥有的任何逻辑感或智力。<br><br>
      <br>
      
      强迫症和怀疑有什么联系？<br>
      强迫症的核心是怀疑自己的记忆力。试图记住是否发生了什么事情，或者你是否正确地做了什么，很快就会成为一种困扰。这种程度的痴迷会干扰你的生活，并迅速消耗你的注意力。<br>
      强迫症患者们通常担心如果记忆错误会发生什么。<br>
      这导致了强迫症的强迫因素，在这种情况下，你会觉得有必要验证和反复检查某些东西，以暂时缓解压力和焦虑。<br><br>
      
      随着时间的推移，强迫症带来的疑虑也会真正开始影响你的家庭和工作生活。<br><br>
      
      例如，如果你正试图完成一项工作任务，但却痴迷于考虑自己的安全，并怀疑自己是否锁上了前门，那么你可能会停止工作，同时仔细考虑开门可能产生的所有负面后果。<br><br>
      
      你可能会失去更多的工作时间，因为你会迫不及待地行动，离开电脑，检查门。<br><br>
      
      <br>
      强迫症患者如何应对怀疑<br>
      如果你认为自己可能正在经历强迫症的症状，那么与专门研究强迫症和其他相关疾病的心理健康专业人员交谈可能会对你有所帮助。他们可以帮助制定有效的治疗计划。<br><br>
      
      与治疗师交谈<br>
      在与心理健康专业人士谈论你的经历后，他们可以为你提供专业的诊断和治疗的方案。<br><br>
      
      强迫症可以由心理健康专家通过访谈来诊断，你可以回答有关病史和症状的问题。<br><br>
      
      与专业人士交谈也很重要，因为强迫症的症状可以与其他疾病共存，这意味着虽然你的症状可能表现为强迫症，但它们可能来自不同的或共病的疾病。<br><br>
      
      例如，如果一个人也患有其他疾病，例如焦虑症或抑郁症，则可能存在强迫症，并且它可能存在于具有神经发育差异的个体中，例如多动症和自闭症。<br><br>
      
      治疗选择<br>
      治疗师有几种方法可以帮助你控制强迫症症状。强迫症治疗最常用的方法之一是认知行为疗法。这种疗法旨在解决并帮助你重新思考消极行为，从而减少他们对你生活的控制。<br><br>
      
      最成功的方法之一是暴露和反应预防。2019年的一份综述来源得出结论，暴露和反应预防是强迫症最有效的治疗选择之一。<br><br>
      
      通过暴露和反应预防，你产生了一种刺激，可以触发强迫行为的欲望，可以阻止进行强迫行为，这通过向大脑显示不需要强迫来减轻压力和焦虑。<br><br>
      
      虽然认知行为疗法不能完全治愈强迫症，但它可以使强迫症的症状更容易控制。<br>
      强迫症患者也可以从认知行为疗法以外的其他方向的传统谈话疗法中受益。强迫症也有药物选择。<br><br>
      
      支持小组<br>
      治疗费用对一些人来说往往是遥不可及的，这意味着你可能需要考虑低成本的替代方案。当你预算紧张时，最好的替代疗法之一是参加支持小组。<br><br>
      
      虽然它不能替代一对一疗法，但你可以在听别人的故事和分享自己的故事中找到很多安慰。<br><br>
      
      此外，你与强迫症患者的联系越多，你就越不可能独自面对强迫症的挑战。<br><br>
      
      国际强迫症基金会提供了一个支持小组的列表，您可以通过筛选找到与您最接近的会议。此外，还可以选择参加在线会议。<br><br><br>
      
      
      接下来的步骤<br>
      强迫症的病因尚不清楚，但常见的危险因素是遗传，大脑结构，大脑功能以及儿童期创伤的存在。<br><br>
      
      换句话说，使你面临强迫症风险的因素今天都是你无法控制的。<br><br>
      
      强迫症是一种非常真实的疾病，不容易通过说服自己摆脱它或决定停止怀疑自己来“修复”。<br><br>
      
      重要的是要记住，强迫症是一种复杂的心理健康障碍，如果它开始占用你一天的时间，就不应该被忽视。要知道，你不必独自与强迫症作斗争，你可以选择帮助。<br>
      `
    }
  ]
  function renderDetail () {
    const id = _.get(location, ['state', 'id'])
    const res = resources.filter(item => item.id === id)[0]
    return (
      <div className='res-page'>
        <div className='banner-title'>
          <img alt src={res.banner}/>
          <div className='title'>{res.title}</div>
        </div>
        <div className='res-text' dangerouslySetInnerHTML={{__html: res.text}}></div>
      </div>
    )
  }

  return (
    <>
     <TitleBar
        title='舒辅心理资源库'
        isShowBack={true}
      />
    {renderDetail()}
    </>
  )
}

export default ResourceDetail;