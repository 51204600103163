// 该页面主要用于展示咨询师和舒小辅的聊天界面
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import TitleBar from '@/components/TitleBar';
import CustomConfirmDialog from '@/components/CustomConfirmDialog';
import './index.less';
import '@/components/DialogueMessage/index.less';
import Icon from '@/components/Icon';
import { adminUpload, adminGetChatMsg, adminClearChat } from '@/services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, ImageUploader, Button, Toast, PullToRefresh } from 'antd-mobile'
import { AudioOutline, AddOutline, CameraOutline, PictureOutline, PhonebookOutline, VideoOutline, UnorderedListOutline } from 'antd-mobile-icons'
import { RenderMessageContent, uploadDialogueImage } from '@/components/DialogueMessage';
import { useDispatch, useSelector } from 'react-redux';
import { emit_message_to_server, message_get, message_update, message_add, save_current_chat_auth_id, emit_call_flag_to_server } from '@/actions';
import { CHAT_TO_ONE, FILE_UPLOAD, CALL_TO_ONE } from '@/actions/actionType';
import { scrollMessageListToEnd } from '@/utils/commonUtils';
import CONFIG from 'GlobalConfigFile';

function Dialogue(params) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const chatMsg = useSelector(state => state.chatMsg);
  const baseInfo = useSelector(state => state.baseInfo.admin);
  const staffInfo = _.get(location, ['state', 'staffInfo'], {});
  const chatAuthId = staffInfo.authId;
  const authName = staffInfo.name;
  const pageNo = useRef();
  const [pageSize, setPageSize] = useState(20);
  const [scrollEnd, setScrollEnd] = useState(true);
  const [functionsVisible, setFunctionsVisible] = useState(false);
  // const [consultants, setConsultants] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const initData = [
    {
      icon: <ImageUploader beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/photo.svg').default} />
      </ImageUploader>, title: '照片'
    },
    {
      icon: <ImageUploader capture='camera' beforeUpload={file => uploadDialogueFile(file)}>
        <img alt="" src={require('@/assets/icon/dialogue/camera.svg').default} />
      </ImageUploader>, title: '拍摄'
    },
    // { icon: <img alt="" src={require('@/assets/icon/dialogue/phone_call.svg').default} />, title: '语音咨询', func: () => { videoConsult('audio'); onCallActionSend('AUDIO', 'CALL') } },
    // { icon: <img alt="" src={require('@/assets/icon/dialogue/video_call.svg').default} />, title: '视频咨询', func: () => { videoConsult('video'); onCallActionSend('VIDEO', 'CALL') } },
  ];
  const [functionBlocks, setFunctionBlocks] = useState(initData);

  useEffect(() => {
    _adminGetChatMsg();
    // 存当前聊天对象的id
    dispatch(save_current_chat_auth_id(chatAuthId));
    return () => {
      dispatch(message_get([]));
      clearMsg();
    }
  }, []);

  // 内容变动，自动滚动到底部
  useEffect(() => {
    scrollEnd && scrollMessageListToEnd();
    setScrollEnd(true);
  }, [chatMsg])

  async function _adminGetChatMsg() {
    pageNo.current = 1;
    const res = await adminGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId,  byAuthId: chatAuthId  });
    dispatch(message_get(_.get(res, ['result', 'data'], []).reverse()));
  }

  // 清空未读消息
  async function clearMsg() {
    const res = await adminClearChat({ chatAuthId });
    if (res.errorCode) {
      console.log('clearMsg:', res.error)
    }
  }

  // 下拉更新数据
  async function _doctorAddChatMsg() {
    const res = await adminGetChatMsg({ pageNo: pageNo.current, pageSize: pageSize, chatAuthId, byAuthId: chatAuthId  });
    dispatch(message_add(_.get(res, ['result', 'data'], []).reverse()));
  }

  // 创建房间， 发起音视频通话
  async function videoConsult(type) {
    const params = {
      // 这边的roomUrl后期需要更改
      roomUrl: `/client/patient-video-consult?channelId=${baseInfo.authId}&consultType=${type}&chatAuthId=${baseInfo.authId}`,
      toAuthId: chatAuthId
    }
    const res = await createAppointRoomSuccess(params);
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      })
    } else {
      navigate('/therapist/doctor-video-consult', { state: { channelId: baseInfo.authId, consultType: type, chatAuthId } })
    }
  }

  // 音视频通话操作发送至服务端
  function onCallActionSend(callType, callStatus) {
    const messageObj = {
      chatType: CALL_TO_ONE,
      toUserId: chatAuthId,
      sendTime: MomentDiffZone().valueOf(),
      message: JSON.stringify({
        callType: callType,
        callStatus: callStatus,
      })
    }
    dispatch(emit_call_flag_to_server(messageObj))
    dispatch(message_update(messageObj))
  }

  async function uploadDialogueFile(file) {
    uploadDialogueImage(file, chatAuthId, async params => {
      const res = await adminUpload('PICTURE', params);
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        })
      } else {
        const url = res.result.url || '';
        onSendFileToMessageList(url);
      }
    });
  }

  // 文字消息发送
  function onMessageSend(msg) {
    if (msg) {
      const messageObj = {
        chatType: CHAT_TO_ONE,
        message: msg,
        fromUserId: chatAuthId
      }
      // EMIT_MESSAGE_TO_SERVER
      dispatch(emit_message_to_server(messageObj))
      dispatch(message_update(messageObj))
      setInputValue('');
    }
  }
  // // 文字消息发送
  // function onMessageSend(msg) {
  //   const messageObj = {
  //     chatType: CHAT_TO_ONE,
  //     message: msg,
  //     toUserId: chatAuthId
  //   }
  //   // EMIT_MESSAGE_TO_SERVER
  //   dispatch(emit_message_to_server(messageObj))
  //   dispatch(message_update(messageObj))
  //   setInputValue('');
  // }

  // 文件上传类型的消息
  function onSendFileToMessageList(url) {
    const messageObj = {
      chatType: FILE_UPLOAD,
      message: '',
      toUserId: chatAuthId,
      resourceType: 'PICTURE',
      url: url
    }
    dispatch(message_update(messageObj));
  }

  // 定义下拉加载时的自定义提示
  const statusRecord = {
    pulling: '下拉获取更多消息',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
  }

  return (
    <>
      <TitleBar title={authName} isShowBack={true} />
      <div className='dialogue-style'>
        <div className='dialogue-blocks' id='messageList-dialogue-blocks'>
          <PullToRefresh
            onRefresh={() => {
              pageNo.current += 1;
              _doctorAddChatMsg();
              setScrollEnd(false);
            }}
            refreshingText={(text) => {
              return (
                <div>{statusRecord[text]}</div>
              )
            }}
          >
            {/* {renderMessageContent(chatMsg, 'admin', staffInfo)} */}
            <RenderMessageContent 
              messageList={chatMsg}
              role='admin'
              roleInfo={staffInfo}
            />
          </PullToRefresh>
        </div>
        <div className='bottom-block'>
          <div className='dialogue-input'>
            <div className='input-block'>
              <Input
                placeholder='请输入内容'
                style={{ '--font-size': '1.6rem', backgroundColor: 'white', height: '100%' }}
                value={inputValue}
                onChange={val => setInputValue(val)}
                onEnterPress={() => {
                  onMessageSend(inputValue)
                }}
                onFocus={() => {
                  setFunctionsVisible(false);
                }}
              />
            </div>
            {!inputValue ? <div className='input-function' onClick={() => {
              setFunctionsVisible(!functionsVisible);
              scrollMessageListToEnd()
            }}>
              <img alt="" src={require('@/assets/icon/dialogue/add.svg').default} />
            </div> :
              <Button className='send-msg-btn' disabled={inputValue.trim().length <= 0} onClick={() => onMessageSend(inputValue)}>发送</Button>}
          </div>
          {functionsVisible ? <div className='function-blocks'>
            {functionBlocks.map((functionBlock, index) => <div className='function-block' key={index} onClick={() => {
              functionBlock.func && functionBlock.func(consultants)
            }}>
              <div className='function-block-icon'>{functionBlock.icon}</div>
              <div className='function-block-title'>{functionBlock.title}</div>
            </div>)}
          </div> : null}
        </div>
      </div>
    </>
  )
}

export default Dialogue;