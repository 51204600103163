import { combineReducers } from 'redux';
import msgReducer from './msgReducer';
import baseInfoReducer from './baseInfoReducer';
import notificationReducer from './notificationReducer';
import chatReducer from './chatReducer';
import staffListReducer from './staffListReducer';
import adminQueryStaffListReducer from './adminQueryStaffListReducer';
import resourceListReducer from './resourceListReducer';
import psychologicalTestTabListReducer from './psychologicalTestTabListReducer';

export default combineReducers({
  chatMsg: msgReducer,
  baseInfo: baseInfoReducer,
  notificationInfo: notificationReducer,
  chatInfo: chatReducer,
  staffList: staffListReducer,
  adminQueryStaffList: adminQueryStaffListReducer,
  resourceList: resourceListReducer,
  psychologicalTestTabList: psychologicalTestTabListReducer
})