// fenglin测量量表
import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import TitleBar from "@/components/TitleBar";
import { getStaffQuestionnaireList, createStaffTask } from "@/services/api";
import { Image, Modal } from 'antd-mobile';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

export default function FenglinPsychologicalTest() {
  const navigate = useNavigate();
  const staffId = useSelector(state => state.baseInfo['patient']?.roleInfo?.staffId);
  const [formList, setFormList] = useState([]);

  const _getStaffQuestionnaireList = useCallback(async () => {
    let res = await getStaffQuestionnaireList();
    res?.result && setFormList(res.result[0].picks);
  }, [])

  useEffect(() => {
    _getStaffQuestionnaireList();
  }, [])

  function renderPsychologicalTestList() {
    return (
      <div className='psychological-test-content'>
        {
          formList.length > 0 && formList.map((form, index) => {
            return (
              <div
                className='psychological-test-item'
                key={index}
                onClick={() => {
                  // 点击先弹出隐私协议
                  Modal.confirm({
                    title: '隐私协议',
                    content: (
                      <ol style={{ fontSize: '14px' }}>
                        <li>
                          所填个人信息将用于生成测评结果
                        </li>
                        <li>
                          测评报告仅限您本人查阅
                        </li>
                      </ol>
                    ),
                    onAction: (action, index) => {
                      const { key } = action;
                      if (key === 'confirm') {
                        getFormUrl(form);
                      }
                      else if (key === 'cancel') {
                        console.log('cancel');
                      }
                    },
                  })
                }}
              >
                <Image src={form?.picture_url} fit='contain' className='img-background' />
                <div className='text'>{form['name_zh']}</div>
              </div>
            )
          })
        }
      </div>
    )
  }

  // 组装后台接口数据
  function combineWfVariablesData(selectList) {
    let wfVariablesResult = [];
    for (let i = 0; i < selectList.length; i++) {
      let wholeItems = [];
      wholeItems.push(selectList[i]);
      let wfVariablesData = {
        Forms: {
          wholeItems
        }
      }
      wfVariablesResult.push(wfVariablesData)
    }
    const result = wfVariablesResult.reduce((pre, cur) => {
      pre.push(JSON.stringify(cur))
      return pre;
    }, [])
    return result;
  }

  // 获取量表的url进行跳转
  async function getFormUrl(form) {
    const {formType, name_zh} = form;
    let selectForms = [];
    let convertedData;
    if (formType === 'combo') {
      selectForms = form.formDataList;
      convertedData = combineWfVariablesData(selectForms);
    } else {
      selectForms.push(form);
      convertedData = combineWfVariablesData(selectForms);
    }
    let params = {
      taskName: name_zh,
      staffId,
      taskType: 'STAFF_OPTIONAL',
      wfVariablesList: convertedData,
    };
    let res = await createStaffTask(params);
    if (res.errorCode) {
      Toast.show('量表信息生成失败，请重试')
    } else {
      navigate('/client/psychological-test-user-form', { state: { url: res?.result?.firstFormUrl, title: form?.name_zh } })
    }
  }

  return (
    <>
      <TitleBar
        title="心理测评"
        isShowBack={true}
        rightContent={'测评记录'} 
        rightContentOnclick={() => {
          navigate('/client/form-task-list')
        }}
      />
      {
        renderPsychologicalTestList()
      }
    </>
  )
}