// 该页面用于展示全部的咨询记录表
import React from "react";
import TitleBar from "@/components/TitleBar";
import { List } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from "lodash";
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { RightOutline } from "antd-mobile-icons";

export default function ConsultList(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const state = _.get(location, ['state', 'list']);
  
  function renderState() {
    if (state.length > 0) {
      return (
        <List>
          {
            state.map((element, index) => {
              return (
                <List.Item
                  key={index}
                  clickable
                  arrow={
                    <div style={{
                      color: '#FFAA00',
                      fontWeight: 'bold',
                    }}>
                      <RightOutline />
                    </div>
                  }
                  onClick={() => {
                    navigate('/therapist/form-iframe', {
                      state: {
                        url: element?.url,
                        consultId: element?.consultId,
                        type: 'readonly',
                        title: '咨询记录',
                      }
                    })
                  }}
                >
                  {MomentDiffZone(element?.startTime).format("YYYY年MM月DD日")}
                </List.Item>
              )
            })
          }
        </List>
      )
    }
    else {
      return (
        <List>
          <List.Item 
            style={{
              textAlign: 'center'
            }}
          >
            暂无数据
          </List.Item>
        </List>
      )
    }
  }
  
  return (
    <>
      <TitleBar title="咨询记录表" isShowBack={true} />
      {renderState()}
    </>
  )
}