const healthRecordQuestionary = {
  title: '舒辅心理健康咨询档案',
  sections: [
    {
      sectionId: 'staffItem',
      modelKey: 'staffItem',
      sectionTitle: '来访者基本信息',
      qList: [
        {
          id: 'organizationName',
          question: '学校/企业（必填）',
          type: 'input',
          required: true
        },
        {
          id: 'organizationWorkId',
          question: '学号/工号（必填）',
          type: 'input',
          required: true
        },
        {
          id: 'department',
          question: '学院/部门',
          type: 'input',

        },
        {
          id: 'organizationLevel',
          question: '年级/职级',
          type: 'input',

        },
        {
          id: 'staffTelephone',
          question: '手机号码（必填）',
          type: 'input',
          required: true
        },
        {
          id: 'name',
          question: '来访者称谓',
          type: 'input',

        },
        {
          id: 'age',
          question: '年龄',
          type: 'input',

        },
        {
          id: 'marriage',
          question: '婚姻状况',
          type: 'radio',
          options: [
            {
              label: '未婚',
              value: 'unmarried'
            },
            {
              label: '已婚',
              value: 'married'
            },
            {
              label: '离异',
              value: 'divorced'
            }
          ]
        },
        {
          id: 'gender',
          question: '性别（必填）',
          type: 'radio',
          required: true,
          options: [
            {
              label: '男',
              value: 'male'
            },
            {
              label: '女',
              value: 'female'
            }
          ]
        },
        {
          id: 'consultationCount',
          question: '咨询次数',
          type: 'input',
        },
      ]
    },
    {
      sectionId: 'consultant',
      modelKey: '',
      sectionTitle: '咨询信息',
      qList: [
        {
          id: 'consultant-name',
          question: '咨询师',
          type: 'text',
          required: true
        },
        {
          id: 'consultationDay',
          question: '咨询日期（必填）',
          type: 'date',
          required: true
        },
        {
          id: 'consultationStartTime',
          question: '开始时间（必填）',
          type: 'date',
          precision: 'minute',
          required: true
        },
        {
          id: 'consultationEndTime',
          question: '结束时间（必填）',
          precision: 'minute',
          type: 'date',
          required: true
        },
        {
          id: 'consultationTimeLong',
          question: '咨询时长（分钟）',
          type: 'timeCalculate',
          timeKeys: ['consultationStartTime', 'consultationEndTime']
        },
        {
          id: 'consultationType',
          question: '咨询方式（必填）',
          type: 'radio',
          required: true,
          options: [
            { label: '面谈', value: 'face-to-face' },
            { label: '视频', value: 'video' },
            { label: '热线', value: 'hotline' },
            { label: '文字', value: 'text' },
          ],
        }
      ]
    },
    {
      sectionId: 'consultationInfo',
      modelKey: 'consultationInfo',
      sectionTitle: '*来访者问题类型（单选）',
      qList: [
        {
          type: 'collapse',
          id: 'questionMap',
          required: true,
          panels: [
            {
              question: '情绪困扰',
              options: ['睡眠问题', '情绪不佳（抑郁焦虑愤怒等）', '身体健康', '自我成长', '心理健康状态', '突发事件导致', '其它']
            },
            {
              question: '职场问题',
              options: ['管理咨询', '同事关系', '与上司的关系', '工作压力', '职业生涯规划', '工作生活平衡', '职场适应', '职业倦怠', '就业压力', '骚扰', '领导力', '工作场所暴力', '失业', '其它']
            },
            {
              question: '人际关系',
              options: ['同性恋爱情感', '异性恋爱情感', '寝室关系', '同学关系', '师生关系', '人际交往困难', '其它']
            },
            {
              question: '家庭问题',
              options: ['与父母的关系', '与配偶的关系', '亲子关系', '青少年问题', '与其它家庭成员的关系', '家庭变故', '家庭暴力', '其它']
            },
            {
              question: '症状类',
              options: ['双相及相关障碍', '抑郁障碍', '焦虑障碍', '强迫及相关障碍', '创伤及应激相关障碍', '进食障碍', '精神病性障碍', '其它症状']
            },
            {
              question: '学业问题',
              options: ['学业压力', '考试焦虑', '专业选择', '其它']
            },
            {
              question: '成瘾问题',
              options: ['酒精', '毒品', '药品', '香烟', '赌博', '网络', '游戏', '其它']
            },
            {
              question: '创伤与危机事件',
              options: ['个人不幸', '亲人去世', '应激事件', '其它']
            },
            {
              question: '危机干预',
              options: ['危机干预']
            },
            {
              question: '其它问题',
              options: ['法律问题', '财务问题', '健康问题', '热线文字咨询使用问题', '面谈咨询相关问题']
            }
          ]
        }
      ]
    },
    {
      sectionId: 'seriousProblem',
      modelKey: 'consultationInfo',
      sectionTitle: '如有危机、精神病史、或其他特殊情况，请填写以下信息',
      notes: ['任何“需”或“疑似”的情况，咨询师必须联系项目经理及机构督导，并且备案！'],
      qList: [
        {
          id: 'crisisIntervention',
          question: '需危机干预',
          type: 'parentQuestion',
          subQuestion: [
            {
              question: '自我危害',
              id: '自我危害',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' },
                { label: '疑似', value: '疑似' }
              ]
            },
            {
              question: '危害他人',
              id: '危害他人',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' },
                { label: '疑似', value: '疑似' }
              ]
            },
            {
              question: '重大危机后',
              id: '重大危机后',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' },
                { label: '疑似', value: '疑似' }
              ]
            },
            {
              id: '其他情况',
              question: '其他情况',
              type: 'textArea'
            }
          ]
        },
        {
          id: 'longTermTreatment',
          question: '需长程治疗',
          type: 'parentQuestion',
          subQuestion: [
            {
              question: '来访者期望',
              id: '来访者期望',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' }
              ]
            },
            {
              question: '有明显症状',
              id: '有明显症状',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' }
              ]
            },
            {
              id: '其他情况',
              question: '其他情况',
              type: 'textArea'
            }
          ]
        },
        {
          id: 'referral',
          question: '需转介',
          type: 'parentQuestion',
          subQuestion: [
            {
              question: '咨询师能力外',
              id: '咨询师能力外',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' }
              ]
            },
            {
              question: '需药物治疗',
              id: '需药物治疗',
              type: 'radio',
              options: [
                { label: '无', value: '无' },
                { label: '需', value: '需' }
              ]
            },
            {
              id: '其他情况',
              question: '其他情况',
              type: 'textArea'
            }
          ]
        }
      ]
    },
    {
      sectionId: 'basicAssessment',
      sectionTitle: '基本咨询评估',
      modelKey: 'consultationInfo',
      qList: [
        {
          id: 'previousAssessment',
          question: '进行过普通咨询/危机/精神病/药物成瘾治疗史评估：',
          type: 'radio',
          options: [
            { label: '是', value: 'other' },
            { label: '否', value: 'previousAssessmentHistoryNo' },
          ],
          otherQList: [
            {
              id: '具体内容',
              question: '',
              type: 'textArea'
            },
          ]
        },
        {
          id: 'supporterAssessment',
          question: '进行过来访者的支持者评估，支持系统为：',
          type: 'textArea'
        },
        {
          id: 'mainConsultationPurpose',
          question: '来访者主要咨询目的：',
          type: 'textArea'
        }
      ]
    },
    {
      sectionId: 'consultationRecord',
      sectionTitle: '咨询记录',
      modelKey: 'consultationInfo',
      qList: [
        {
          id: 'mainComplaint',
          question: '主诉内容',
          type: 'textArea'
        },
        {
          id: 'currentSession',
          question: '本次咨询处理/建议',
          type: 'textArea'
        },
      ]
    },
    {
      sectionId: 'riskAssessment',
      sectionTitle: '风险评估',
      modelKey: 'consultationInfo',
      notes: ['注：若来访者有风险请及时报备给舒辅管理人员。'],
      qList: [
        {
          id: 'hasRisk',
          question: '是否有风险？（风险指来访者本人有潜在的/有计划的/已实施的自伤、自杀、伤人、杀人或突发危机事件、其他特殊情况等）',
          type: 'radio',
          options: [
            { label: '是', value: 'other' },
            { label: '否', value: '否' }
          ],
          otherQList: [
            {
              question: '风险等级',
              id: 'riskLevel',
              type: 'radio',
              options: [
                { label: '低', value: '低' },
                { label: '中', value: '中' },
                { label: '高', value: '高' }
              ]
            },
            {
              id: 'riskAssessmentList',
              question: '评估依据（若有风险一定要填依据）',
              type: 'textArea'
            }
          ]
        }
      ]
    }
  ]
}
export default healthRecordQuestionary