import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

const useSSE = (url, callback) => {
  const [data, setData] = useState(null);
  const isFirstRender = useRef(true);


  // 获取到的messgae发生了变化，回调数据
  useLayoutEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
    data && callback(data);
  }, [data])

  useEffect(() => {
    let eventSource;
    // 检查是否支持sse
    if (!!window.EventSource) {
      eventSource = new EventSource(url);
  
      eventSource.onopen = (event) => {
        console.log('sse on open', event)
      }
      eventSource.onmessage = (event) => {
        const newData = JSON.parse(event.data);
        setData(newData);
      };
  
      eventSource.onerror = (error) => {
        console.error('SSE连接发生错误:', error);
      };
    }

    return () => {
      eventSource && eventSource.close();
    };
  }, []);

};

export default useSSE;
