// 正念练习的列表
import React, {useState, useEffect} from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { useNavigate, useLocation} from 'react-router-dom';
import { Button, List } from 'antd-mobile';


function ExVideoList() {
  const navigate = useNavigate();
	const location = useLocation();
  const isShowBack = _.get(location, ['state', 'isShowBack'], false);
	const videoList = [
		{
			title: '呼吸觉察',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_1.mp4'
		},
		{
			title: '身体扫描',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_2.mp4'
		},
		{
			title: '正念行走',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_3.mp4'
		},
		{
			title: '正念饮食',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_4.mp4'
		},
		{
			title: '山的冥想',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_5.mp4'
		},
		{
			title: '树的冥想',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_6.mp4'
		},
		{
			title: '慈心冥想',
			videoUrl: 'https://image.siuvo.com.cn/eap_mindfulness_c/mindfulness_7.mp4'
		}
	]

	function onVideoPlay(item) {
		navigate('/client/ex-video-play', {state: {videoInfo: item}})
	}

  function renderVideoList() {

    return (
			<List>
				{
					videoList.map((item,index) => {
						return(
							<List.Item key={index} onClick={() => onVideoPlay(item)}>
								<div className='video-item-content'>
									<div>{index+1}、{item.title}</div>
									<Button size='mini' color='primary'>点击播放</Button>
								</div>
							</List.Item>
						)
					})
				}
			</List>
    )
  }

	

  return (
    <>
    <TitleBar title="正念课程"  isShowBack={isShowBack}/>
    {
      renderVideoList()
    }
    </>
  )
}

export default ExVideoList;