// 管理员查看咨询记录表页面
import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import TitleBar from '@/components/TitleBar';
import ListView from '@/components/ListView';
import ListCollapseView from './collapse';
import { Toast, Dropdown, List, Collapse } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
import { getConsultantByTime, getConsultantByGroup } from '@/services/api';

function ConsultationRecordForm() {
  const PAGE_SIZE = 10;
  const sortOptions = [
    { sortType: 'byTimeDesc', title: '按时间排序' },
    { sortType: 'byClientDesc', title: '按来访者排序' }
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const consultantInfo = _.get(location, ['state', 'consultantInfo'], {});
  const [formList, setFormList] = useState();
  const [hasMore, setHasMore] = useState(false); //是否需要加载更多
  const currentPageRef = useRef(1); //当前加载第几页的数据
  const sortKeyRef = useRef(sortOptions[0]);
  const dropdownRef = useRef(null);

  // 按照时间排序获取接口
  const _getConsultantByTime = useCallback(async () => {
    let params = {
      consultantId: consultantInfo?.consultantId,
    }
    const res = await getConsultantByTime(params);
    return res?.result?.data
  }, []);

  // 按照来访者分组获取接口
  const _getConsultantByGroup = useCallback(async () => {
    let params = {
      consultantId: consultantInfo?.consultantId,
    }
    const res = await getConsultantByGroup(params);
    return res?.result
  }, []);

  // 接口获取待审核列表
  async function getApplyList() {
    if (sortKeyRef.current.title === '按时间排序') {
      const consultantByTime = await _getConsultantByTime();
      setFormList(consultantByTime);
    }
    else {
      const consultantByGruop = await _getConsultantByGroup();
      setFormList(consultantByGruop);
    }
  }

  useEffect(() => {
    getApplyList();
  }, [])

  // 咨询列表的渲染(按照时间排序)
  function renderListItemByTime(item) {

    return (
      <div className='item-content'>
        <div className='item-time'>
          {MomentDiffZone(item.startTime).format('YYYY年MM月DD日')}
        </div>
        <div className='item-texy'>
          {item.consultType}
        </div>
      </div>
    )
  }

  // 咨询列表的渲染(按照来访者分组)
  function renderListItemByGroup(item) {

    return (
      <div>
        <Collapse >
          <Collapse.Panel
            key={item.authId}
            title={item.name}
          >
          </Collapse.Panel>
        </Collapse>
      </div>
    )
  }


  // 下拉菜单更改筛选条件
  async function handleSortChange(sortItem) {
    sortKeyRef.current = sortItem;
    dropdownRef.current?.close();
    await getApplyList();
  }

  // 下拉刷新
  async function onRefresh() {
    setHasMore(false);
    currentPageRef.current = 1;
    await getApplyList();
  }

  // 下拉选项
  function renderSortOptions() {

    return (
      <List>
        {
          sortOptions.map((item, index) => {
            return (
              <List.Item
                key={index}
                onClick={() => {
                  handleSortChange(item);
                }}
              >
                {item.title}
              </List.Item>
            )
          })
        }
      </List>
    )
  }

  // 点击列表每个item触发的事件
  function handleItemClick(item) {
    console.log('handleItemClick');
  }

  return (
    <>
      <TitleBar title='咨询记录表' isShowBack={true} />
      <div>
        <Dropdown ref={dropdownRef}>
          <Dropdown.Item title={sortKeyRef.current.title} key='sorter'>
            {renderSortOptions()}
          </Dropdown.Item>
        </Dropdown>
      </div>
      {
        sortKeyRef.current.title === '按时间排序'
          ? (
            <div className='list-view-content'>
              <ListView
                dataSource={formList}
                renderItem={renderListItemByTime}
                onRefresh={onRefresh}
                hasMore={hasMore}
                onItemClick={handleItemClick}
              />
            </div>
          )
          : (
            <ListCollapseView
              dataSource={formList}
              renderItem={renderListItemByTime}
              onItemClick={handleItemClick}
              onRefresh={onRefresh}
              hasMore={hasMore}
            />
          )
      }

    </>
  )
}

export default ConsultationRecordForm;