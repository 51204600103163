import { uploadFile } from "@/services/api";
import Compressor from 'compressorjs';
import { Toast } from 'antd-mobile'

export async function startUploadFile(file, cb) {
  new Promise((resolve, reject) => {
    if (!file) {
      reject('文件为空')
    }
    new Compressor(file, {
      quality: 0.4,
      success: (result) => {
        if (result.size > 1024 * 1024) {
          Toast.show('请选择小一点的图片');
          reject('上传文件压缩后依然太大');
          return;
        }
        resolve(uploadIMG(result, cb));
      }
    })
  });
}
async function uploadIMG(file, cb) {
  const params = new FormData();
  params.append('file', file);
  // params.append('fileName', file.name);
  const res = await uploadFile(params);
  if (res.errorCode) {
    Toast.show({
      icon: 'fail',
      content: res.error,
    })
  } else {
    cb(res);
  }
}