import React, { useEffect, useState, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Popup, Toast } from 'antd-mobile';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MoreOutline } from 'antd-mobile-icons';

import './index.less';
import TitleBar from '@/components/TitleBar';
import WithTrackingForMedia from '@/components/WithTracking/withTrackingForMedia';
import Chat from "@/components/Chat";
import QuestionAndAnswerBoard from '@/components/ExerciseBoard';
import FloatBubble from '@/components/FloatBubble';
import { ExerciseActionSheet } from '@/components/ExerciseActionSheet';
import ChatGptStream from './ChatgptStream';
import { getUrls } from '@/utils/getUrls';
import ExerciseCombined from './ExerciseCombined';
import { staffGetChatMsg, clearChatRecord } from '@/services/api';
import recommendChatItems from '@/Constant/recommendChatItems';
import { eventDotList } from '@/Constant/eventDot';

const PAGE_SIZE = 20;
// AI咨询师ID
const AIID = '90001';

const RecommendCombinedExercise = (props) => {
  const { handleAddEventDotClick } = props;
  const location = useLocation();
  const eventDotKey = _.get(location, ['state', 'eventDotKey'], '');
  const eventDotParam = eventDotList[eventDotKey] || {};
  const { BASE_URL } = getUrls();
  // 对话的轮数
  // const CONVERSATION_ROUNDS = 5;
  // 每一轮对话有统一的uuid作为sessionId
  const sessionIdRef = useRef(uuidv4() + '_nlp_response');
  const [chatList, setChatList] = useState([]);
  const role = 'patient';
  const baseInfo = useSelector(state => {
    return state.baseInfo[role].roleInfo || {}
  });
  const [popUpVisible, setPopUpVisible] = useState(true);
  // stepByStep结束，开始推荐练习
  const [isChatgptStreamOver, setIsChatgptStreamOver] = useState(false);
  const [questionType, setQuestionType] = useState('blank');
  const [questionContent, setQuestionContent] = useState([]);
  // 返回的itemID对应的信息
  const [itemIdInfo, setItemIdInfo] = useState({});
  // 构建chatgpt的   参数以及url
  const [chatgptParamsObj, setChatgptParamsObj] = useState({
    chatgptParams: null,
    requestUrl: `${BASE_URL}ai/stepByStep/v2`,
  });
  // 计数器，到了第十轮对话的时候，调用接口拿到推荐的练习
  const countRef = useRef(0);
  // 当前页面是推荐还是练习
  const [commendOrExercise, setCommendOrExercise] = useState('commend');
  // 传入到被连接的练习页面的参数
  const [exerciseParams, setExerciseParams] = useState({});
  // 当前消息页数
  const pageNo = useRef(1);
  const [exerciseActionVisible, setExerciseActionVisible] = useState(false);

  // 如果消息超出了屏幕，自动滚动到最底部
  const scrollMessageListToEnd = useCallback(() => {
    const messagesShowContent = document.querySelector('.messages-show-content');
    // 给显示答案的部分动态增加高度
    setTimeout(() => {
      const exerciseBoard = document.querySelector('.exercise-board');
      const popupBodyContent = document.querySelector('.adm-popup-body');
      if (exerciseBoard && popupBodyContent && popUpVisible === true) {
        exerciseBoard.style.height = popupBodyContent.clientHeight + 'px';
      }
      if (exerciseBoard && popupBodyContent && popUpVisible === false) {
        exerciseBoard.style.height = 0 + 'px';
      }
      if (messagesShowContent) {
        messagesShowContent.scrollTop = messagesShowContent.scrollHeight - messagesShowContent.clientHeight;
      }
    }, 300);
  }, [popUpVisible])

  // 打点
  useEffect(() => {
    JSON.stringify(eventDotParam) !== '{}'
      ? handleAddEventDotClick(eventDotParam)
      : handleAddEventDotClick({
        name: 'enter:recommend-chat-page',
        props: {
          eventName: '进入智能推荐页面',
        },
      });
  }, []);

  // 将数组平铺
  const flat = (arr) => {
    return arr.reduce((pre, cur) => {
      return pre.concat(Array.isArray(cur) ? flat(cur) : cur)
    }, [])
  };

  // 初始化获取历史最近的20条消息记录
  useEffect(() => {
    async function init() {
      const msgListTemp = await getMessageList(Infinity);
      const msgList = flat(msgListTemp);
      const chatStorage = JSON.parse(localStorage.getItem('recommendChat'));
      if (chatStorage) {
        const { sessionId, count, commendOrExercise } = chatStorage;
        sessionIdRef.current = sessionId;
        countRef.current = count;
        if (commendOrExercise === 'commend') {
          // 推荐--不做操作

        } else {
          // 练习
          setExerciseParams({
            exerciseInstanceId: chatStorage.exerciseInstanceId,
            formContent: chatStorage.formContent,
          });
          setCommendOrExercise('exercise')
        }
      }
      if (!chatStorage && msgList.length === 0) {
        msgList.push({
          title_zh: '您好，我是智能AI咨询师，今天您有什么想倾诉的吗？',
          fromUserId: AIID,
          messageType: "AI_CHAT"
        })
      }
      setChatList([...msgList])
    }
    init();
  }, []);

  const handleAnswerClick = async (answer) => {
    setPopUpVisible(false);
    setChatList([...chatList, { text: answer, fromUserId: baseInfo.authId }]);
    if (answer === '是') {
      setPopUpVisible(true);
      const { itemId, formContent } = itemIdInfo;
      setExerciseParams({
        exerciseInstanceId: itemId,
        formContent,
      });
      setCommendOrExercise('exercise');
    }
    if (answer === '否') {
      // setPopUpVisible(false);
      // setChatList([...chatList, { text: answer, _id: sessionIdRef.current }]);
      // 回答完“否”后，可以有退出等操作
    }
    else {
      let params = {
        sessionId: sessionIdRef.current,
        messages: [`来访者:${answer}`],
        chatAuthId: AIID,
      };
      setChatgptParamsObj({
        ...chatgptParamsObj,
        chatgptParams: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        }
      });
    }
  };

  const handleSetChatList = (data) => {
    setChatList(data);
    // 当收到chatgpt返回的答案后相当于这一次的对话结束了，所以计数器加一
    countRef.current += 1;
    chatgptParamsObj.requestUrl.includes('ai/stepByStep/v2') && setPopUpVisible(true);
    // if (countRef.current < CONVERSATION_ROUNDS) {
    //   chatgptParamsObj.requestUrl.includes('ai/stepByStep/v2') && setPopUpVisible(true);
    // }
    // if (countRef.current === CONVERSATION_ROUNDS) {
    //   getSuggest();
    // }
  };

  const handleSetItemIdInfo = (data) => {
    setItemIdInfo(data);
  }

  const handleIsChatgptStreamOver = (data) => {
    setIsChatgptStreamOver(data);
  }

  // 点击推荐的视频后 开始出现作业的提示 只触发一次
  // commendOrExercise为commend的时候，说明还是推荐的状态，此时点击视频，出现问题是否开始练习
  const handleVideoClick = () => {
    const isDuplicateItem = chatList.find(item => item.title_zh === '是否开始练习');
    if (commendOrExercise === 'commend' && !isDuplicateItem) {
      setChatgptParamsObj({});
      setIsChatgptStreamOver(false);
      // 增加问题
      setQuestionType('single');
      setChatList([...chatList, { title_zh: '是否开始练习', fromUserId: AIID }]);
      setQuestionContent([
        {
          label: '是',
          value: '是',
        },
        {
          label: '否',
          value: '否',
        }
      ]);
      setPopUpVisible(true);
    }
  }

  // 当推荐的练习结束后，需要将chatgpt的接口改为suggest接口
  const getSuggest = useCallback(() => {
    setChatgptParamsObj({
      ...chatgptParamsObj,
      chatgptParams: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      requestUrl: `${BASE_URL}ai/suggest/v2?sessionId=${sessionIdRef.current}`
    });
    // setPopUpVisible(false);
    countRef.current = 0;
    // 清除本地缓存
    // localStorage.removeItem('recommendChat');
  }, [BASE_URL])

  useEffect(() => {
    if (isChatgptStreamOver) {
      getSuggest();
    }
  }, [isChatgptStreamOver, BASE_URL]);

  useEffect(() => {
    scrollMessageListToEnd();
  }, [chatList, popUpVisible, scrollMessageListToEnd]);

  // 进入界面查询消息记录或者下拉获取之前的记录
  async function _patientGetChatMsg(params) {
    const res = await staffGetChatMsg(params);
    const msgList = _.get(res, ['result', 'data'], []).reverse();
    if (!res.errorCode) {
      if (msgList.length === PAGE_SIZE) {
        pageNo.current++;
      }
      return msgList;
    } else {
      Toast.show('消息记录获取失败')
      return [];
    }
  }

  // 接口获取历史消息记录
  async function getMessageList() {
    const params = {
      pageNo: pageNo.current,
      pageSize: PAGE_SIZE,
      chatAuthId: AIID,
      fromAuthId: baseInfo.authId
    }
    const msgListTemp = await _patientGetChatMsg(params);
    // 对从后端拿到的消息进行二次解析
    const msgList = msgListTemp.map((item, index) => {
      if (item.message.includes('{') || item.message.includes('}')) {
        // 对message进行抽取，提取出{...}中的内容
        let beforeBraceContent = item.message.split('{')[0];
        let afterBraceContent = item.message.match(/\{(.*)\}/)[0];
        const braceContentInner = JSON.parse(afterBraceContent);
        const itemId = braceContentInner.itemId;
        const itemIdContent = recommendChatItems.find(item => item.itemId === itemId);
        setItemIdInfo({ ...itemIdContent, itemId })
        const { url, media } = itemIdContent;
        if (itemId.includes('exercise')) {
          if (beforeBraceContent) {
            return [{
              ...item,
              title_zh: beforeBraceContent,
            },
            {
              ...item,
              videoUrl: media
            }]
          } else {
            return {
              ...item,
              videoUrl: media
            }
          }
        }
        if (itemId.includes('positive')) {
          return {
            ...item,
            message: `<a href="${url}">点击进行正念练习</a>\r\n`
          }
        }
      }
      // 从后端获取到messageList,如果出现[OVER]，去掉不显示
      if (item.message.includes('[OVER]')) {
        item.message = item.message.replace(/\[OVER\]/g, '');
        return {
          ...item,
          message: item.message,
        }
      }
      return item;
    })
    return msgList
  }

  // 下拉加载更多历史记录
  async function onloadMore() {
    const msgListTemp = await getMessageList();
    const msgList = flat(msgListTemp);
    setChatList(
      [
        ...chatList,
        ...msgList
      ]
    )
  }

  const renderRecommendOrExercise = () => {

    if (commendOrExercise === 'commend') {
      return (
        <>
          <Chat
            messageList={chatList}
            isShowLoading={false}
            isDisplayLoading={false}
            handleVideoClick={handleVideoClick}
            onloadMore={onloadMore}
            authId={baseInfo.authId}
          />
          {
            chatgptParamsObj.chatgptParams &&
            <ChatGptStream
              chatgptParamsObj={chatgptParamsObj}
              chatList={chatList}
              setChatList={handleSetChatList}
              setItemIdInfo={handleSetItemIdInfo}
              sessionId={sessionIdRef.current}
              scrollMessageListToEnd={scrollMessageListToEnd}
              setChatgptStreamOver={handleIsChatgptStreamOver}
              fromUserId={AIID}
            />
          }
          <div
            className="exercise-board"
            style={{
              height: popUpVisible ? '10vh' : '0vh',
            }}
          >
            <Popup
              visible={popUpVisible}
              onMaskClick={() => {
                setPopUpVisible(false);
              }}
              bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                maxHeight: '60vh',
                overflow: 'scroll',
              }}
              onClose={() => {
                setPopUpVisible(false);
              }}
              mask={false}
            >
              <QuestionAndAnswerBoard
                content={questionContent}
                disable={false}
                type={questionType}
                handleClick={handleAnswerClick}
                // 处理一下键盘弹起遮挡页面的问题
                handleFocus={() => {
                }}
              />
            </Popup>
          </div>
        </>
      )
    }
    else {
      return (
        <ExerciseCombined
          chatList={chatList}
          exerciseInstanceId={exerciseParams?.exerciseInstanceId}
          formContent={exerciseParams?.formContent}
          sessionId={sessionIdRef.current}
          count={countRef.current}
          onloadMore={onloadMore}
          AIID={AIID}
          authId={baseInfo.authId}
          fromUserId={AIID}
        />
      )
    }

  }

  return (
    <>
      <TitleBar title="智能推荐" isShowBack={true} />
      <div className="exercises-content">
        <div className="messages-show-content">
          {
            renderRecommendOrExercise()
          }
        </div>
        <div className="exercise-float-bubble">
          <FloatBubble
            handleClick={() => {
              setExerciseActionVisible(true);
            }}
            bubbleStyle={{
              '--initial-position-top': '230px',
              '--initial-position-right': '0px',
            }}
            axis="xy"
            children={
              <div className="float-bubble-children">
                <MoreOutline fontSize={32} />
              </div>
            }
          />
        </div>
        <ExerciseActionSheet
          visible={exerciseActionVisible}
          onClose={() => { setExerciseActionVisible(false) }}
          onReset={async () => {
            setExerciseActionVisible(false);
            // 重置练习 清除掉当前缓存的练习
            localStorage.removeItem(`shufuFormChat_${exerciseParams?.exerciseInstanceId}`);
            localStorage.removeItem('recommendChat');
            await clearChatRecord({ sessionId: sessionIdRef.current });
            window.location.reload();
          }}
        />
      </div>
    </>
  )
}
// export default RecommendCombinedExercise;
export default WithTrackingForMedia(RecommendCombinedExercise);