// 该页面用于展示任务作业的全部信息的
import React from "react";
import TitleBar from "@/components/TitleBar";
import { List } from 'antd-mobile';
import { useLocation } from 'react-router-dom';
import _ from "lodash";
import MomentDiffZone from '@/utils/useMomentDiffZone';

export default function TaskList(props) {
  const location = useLocation();
  const state = _.get(location, ['state', 'list']);

  return (
    <>
      <TitleBar title="任务作业" isShowBack={true} />
      <List>
        {
          state.map((element, index) => {
            return (
              <List.Item 
              key={index}
              style={{
                padding: '0rem 2rem',
                justifyContent: 'space-between',
              }}
                >
                {MomentDiffZone(element?.startTime).format("YYYY年MM月DD日")}
              </List.Item>
            )
          })
        }
      </List>
    </>
  )
}