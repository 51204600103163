// 运营信息页面
import React from 'react';
import { ArrowDownCircleOutline } from 'antd-mobile-icons';
import { List } from 'antd-mobile';
import TitleBar from "@/components/TitleBar";
import { getUrls } from '@/utils/getUrls';

function OperatingInformation(params) {
  const { BASE_URL } = getUrls();  
  const downloadExcel = [
    {
      title: '所有来访者及绑定咨询师信息下载:',
      url: BASE_URL+'admin/downloadExcelForStaffRel',
      fileName: '所有来访者及绑定咨询师信息',
    },
    {
      title: '所有员工未来2周内绑定咨询师及预约信息下载:',
      url: BASE_URL+'admin/downloadExcelForStaffAppoints',
      fileName: '所有员工未来2周内绑定咨询师及预约信息',
    },
    {
      title: '所有咨询师未来2周内可预约时间信息下载:',
      url: BASE_URL+'admin/downloadExcelForConsultAvailableTime',
      fileName: '所有咨询师未来2周内可预约时间',
    },
    {
      title: '最近2周内更新的咨询师信息的下载:',
      url: BASE_URL+'admin/downloadLastModifiedConsults',
      fileName: '最近2周内更新的咨询师信息',
    },
    {
      title: '最近2周内注册的来访者信息的下载:',
      url: BASE_URL+'admin/downloadLastCreatedStaffs',
      fileName: '最近2周内注册的来访者信息',
    }
  ];

  return (
    <>
      <TitleBar title="运行信息下载" isShowBack={true} />
      <List>
        {
          downloadExcel?.map((element, index) => {
            return (
              <List.Item
                key={index}
                arrow={false}
                prefix={ <ArrowDownCircleOutline color='var(--adm-color-primary)' fontSize={36} /> }
                // onClick={() => {
                //   window.location.href = element.url;
                // }}
              >
                <div>{element.title}</div>
                <br />
                <a 
                  style={{
                    lineBreak: 'anywhere',
                  }}
                  href={element.url}
                  download={element.fileName}
                  target="_blank"
                >
                  {element.fileName}
                </a>
              </List.Item>
            )
          })
        }
      </List>
    </>
  )
}

export default OperatingInformation;
