import React, {useState} from 'react';
import {Dialog} from 'antd-mobile';

function CustomConfirmDialog (props) {
  return(
    <>
    <Dialog
      title={props.title}
      visible={props.visible}
      content={props.content}
      actions={[
          [
            {
              key: 'close',
              text: '取消',
              onClick: () => {
                props.onCancel()
              }
            },
            {
              key: 'confirm',
              text: props.confirmText || '确定',
              onClick: () => {
                props.onConfirm()
              }
            }
          ]
        ]}
      />
    </>
  )
}

export default CustomConfirmDialog;