import React, { useEffect, useRef, useState } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import IatRecorderClass from '@/utils/IatRecorderClass';
import { TextArea, Button } from 'antd-mobile';
import ChatGptStream from '@/components/ChatGptStream';
import ttsRecorder from '@/utils/speechRecognition/TTSRecorder';

function IatToChatGpt() {

  // 语音识别的答案
  const [speechText, setSpeechText] = useState('');
  // 语音识别的状态
  const [iatStatus, setIatStatus] = useState(false);
  const iatRecorderRef = useRef(null);
  const chatGptStreamRef = useRef();
  const [buttonText, setButtonText] = useState('开启识别');
  const [chatgptParams, setChatgptParams] = useState(null);

  useEffect(() => {
    iatRecorderRef.current = new IatRecorderClass();
    iatRecorderRef.current.onTextChange = (text) => {
      setSpeechText(text);
    };
  }, []);

  function onRecStart() {
    if (iatStatus) {
      setButtonText('开启识别');
      iatRecorderRef.current.stop();
      handleChatGptRec();
    } else {
      setButtonText('关闭识别');
      iatRecorderRef.current.start();
    }
    setIatStatus(!iatStatus);
  }

  function handleChatGptRec() {
    // 清空上一次的识别结果
    chatGptStreamRef.current.clearCahtGptResult();
    // 自动转换chatGpt
    speechText && setChatgptParams({ prompt: speechText, reset_conversation: '1' })
  }

  // 功能区
  function renderChunkContent() {
    return (
      <div className='iat-to-gpt-page'>
        <TextArea
          placeholder='请输入内容'
          value={speechText}
          rows={5}
          onChange={val => {
            setSpeechText(val)
          }}
        />
        <Button size='middle' color='primary' onClick={onRecStart}>{buttonText}</Button>
        <Button style={{ marginLeft: '20px' }} size='middle' color='primary' onClick={handleChatGptRec}>chatGpt转换</Button>
      </div>
    )
  }

  return (
    <>
      <TitleBar title='iat to chatgpt' isShowBack={false} />
      {
        renderChunkContent()
      }
      <ChatGptStream
        ref={chatGptStreamRef}
        params={chatgptParams}
        onMessageSend={(message) => {
          // 选择识别结果播报
          ttsRecorder.setParams({ text: message });
          ttsRecorder.start();
        }}
      />
    </>
  )
}
export default IatToChatGpt;