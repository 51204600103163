// 监听是否断开socket连接
import React, { useEffect, useState } from "react";
import { NoticeBar } from "antd-mobile";
import EventEmitter from '@/utils/eventEmitter';

export default function SocketDisconnect() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleSocketDisconnect = (val) => {
      setShow(val);
    }
    EventEmitter.on('DISCONNECT_SOCKET', handleSocketDisconnect);
    return () => {
      EventEmitter.removeListener('DISCONNECT_SOCKET', handleSocketDisconnect);
    }
  }, [])

  return (
    <>
      {
        show && <NoticeBar 
          style={{
            position: 'fixed',
            width: '100%',
            opacity: 0.8,
            zIndex: 2
          }}
          content="网络连接已断开，请刷新后重试"
          color="error"
        />
      }
    </>
  )
}


