import React, { useState, useEffect } from 'react';
import LoginComponents from '@/components/Login';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  getLoginToken,
  setLoginToken,
  setIsFirstTimeLogin,
  getIsFirstTimeLogin,
  setLocalNeedFirstEvaluationStatus,
  getLocalNeedFirstEvaluationStatus
} from '@/utils/localStorage'
import { checkTokenIsValid, decodeToken, getPageQuery } from '@/utils/utils';
import { Toast } from 'antd-mobile';
import CONFIG from 'GlobalConfigFile';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { loginByPatient, sendPatientVerifyCode, getNeedFirstEvaluation } from '@/services/api';
import { register_socket_event, save_patient_info } from '@/actions';
import WithTracking from '@/components/WithTracking';
import MigratedOrgId from './MigratedOrgId';

function Login() {
  const ROLE = 'patient'; //此声明只是UI上的区分
  const navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const { organizationId, tenantId, intake  } = getPageQuery();
  // toC端title区分
  const [loginTitle, setLoginTitle] = useState('');

  const navUrl = location?.state?.from || '/client/home-v2/main';

  useEffect(() => {
    // 初始化
    function init() {
      // 浏览器参数，如果该参数为1，则是C端特殊客户，部分功能不予显示（预约）
      if (organizationId === '1') {
        setLoginTitle('舒辅平台')
      } else {
        setLoginTitle('舒辅EAP')
      }
      const token = getLoginToken(ROLE);
      const isTokenValid = checkTokenIsValid(token);
      const isFirstTime = getIsFirstTimeLogin(ROLE);
      const { authId } = decodeToken(token)
      if (isTokenValid && isFirstTime === 'false') {
        registerSocketAndConnect(authId)
        navigate('/client/home-v2/main');
      }
    }
    // 新版首页初始化---先把医务工会迁移
    async function initForNew() {
      if (organizationId === '1') {
        setLoginTitle('舒辅平台')
      } else {
        setLoginTitle('舒辅EAP')
      }
      // 检查token是否存在及有效
      const token = getLoginToken(ROLE);
      const isTokenValid = checkTokenIsValid(token);
      const isFirstTime = getIsFirstTimeLogin(ROLE);
      // token无效或者第一次登录不做操作
      if (!isTokenValid || isFirstTime === 'true') {
        return;
      }
      // 链接socket
      const { authId } = decodeToken(token)
      registerSocketAndConnect(authId);
      if (intake === 'true') {
        navigate('/client/initial-evaluation');
        return
      }
      // 获取本地缓存的是否需要初始评估的状态
      let status = getLocalNeedFirstEvaluationStatus();
      if (status === null) {
        status = await _getNeedFirstEvaluation();
      }
      if (status) {
        navigate('/client/initial-evaluation');
        return
      }
      navigate(navUrl);
    }
    // 新版迁移
    if (MigratedOrgId.indexOf(organizationId) > -1 || MigratedOrgId.indexOf(tenantId) > -1) {
      initForNew()
    } else {
      init()
    }
  }, [])

  async function _getNeedFirstEvaluation() {
    try {
      const res = await getNeedFirstEvaluation();
      const needFirstEvaluation = _.get(res, 'result', true);
      setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
      return needFirstEvaluation
    } catch (e) {
      console.error('getNeedFirstEvaluation error', e)
      // 接口异常仍回主页
      return false
    }
  }

  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect(id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    dispatch(register_socket_event(socket));
  }

  async function handleSendVerifyCode(cellphone) {
    const res = await sendPatientVerifyCode(cellphone);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
    } else {
      Toast.show({
        content: '短信发送成功',
        key: 'success'
      });
    }
  }

  async function onPatientLogin(params) {
    organizationId && (params['organizationId'] = organizationId);
    tenantId && (params['tenantId'] = tenantId);
    let res = await loginByPatient(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    const token = res.result.token;
    const firstTime = res.result.firstTime;
    const authId = _.get(res, ['result', 'authId'], '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    setIsFirstTimeLogin(ROLE, firstTime)
    dispatch(save_patient_info(_.get(res, ['result'], {})))
    if (firstTime) {
      const pageParams = {
        authId,
      }
      organizationId && (pageParams['organizationId'] = organizationId);
      navigate('/client/first-setting', { state: pageParams });
    } else {
      navigate('/client/home-v2/main');
    }
  }

  // 新版迁移---医务工会
  async function onPatientLoginForNew(params) {
    organizationId && (params['organizationId'] = organizationId);
    tenantId && (params['tenantId'] = tenantId);
    let res = await loginByPatient(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    // const token = res.result.token;
    // const firstTime = res.result.firstTime;
    const { token, firstTime, needFirstEvaluation } = res.result;
    setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
    const authId = _.get(res, ['result', 'authId'], '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    setIsFirstTimeLogin(ROLE, firstTime)
    dispatch(save_patient_info(_.get(res, ['result'], {})))
    if (firstTime) {
      const pageParams = {
        authId,
      }
      organizationId && (pageParams['organizationId'] = organizationId);
      navigate('/client/first-setting-v2', { state: pageParams });
      return
    }
    if (intake === 'true') {
      navigate('/client/initial-evaluation');
      return
    }
    if (needFirstEvaluation) {
      navigate('/client/initial-evaluation');
      return
    }
    navigate(navUrl);
  }

  // 新增--判断是不是已迁移的，如果是，则更新登录逻辑
  function onPatientLoginCheck(params) {
    if ( MigratedOrgId.indexOf(organizationId) > -1 || MigratedOrgId.indexOf(tenantId) > -1) {
      onPatientLoginForNew(params)
    } else {
      onPatientLogin(params)
    }
  }

  return (
    <LoginComponents
      theme={ROLE}
      loginTitle={loginTitle}
      onLogin={onPatientLoginCheck}
      onSendVerifyCode={handleSendVerifyCode}
    />
  )
}

export default WithTracking(
  Login,
  {
    name: 'visit:login',
    props: {
      eventName: '登录',
    },
    userId: 'anonymous'
  }
);