import React, { useState, useEffect, useCallback } from 'react';
import './index.less';
import AliyunRTC from '@/components/AliyunRTC';
import { getDoctorRTCToken, consultantLeaveRoom, doctorGetPatientInfo } from '@/services/api';
import { Toast } from 'antd-mobile';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

function DoctorVideoConsult(props) {

  const [token, setToken] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const channelId = _.get(location, ['state', 'channelId'], '');
  const chatAuthId = _.get(location, ['state', 'chatAuthId'], '');
  const consultType = _.get(location, ['state', 'consultType'], 'video');
  const staffInfo = _.get(location, ['state', 'staffInfo'], {});
  const [chatAuthAvatarUrl, setChatAuthAvatarUrl] = useState('');

  const getUserRTCToken = useCallback(
    async (channelId) => {
      const params = {
        channelType: 'CHAT',
        channelId: channelId
      }
      let res = await getDoctorRTCToken(params);
      console.log('getRTCToken::', res)
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        });
      } else {
        setToken(res.result);
      }
    },
    [],
  )

  // 获取患者信息,获取头像
  const _getChatAuthInfo = useCallback(async () => {
    const res = await doctorGetPatientInfo({authId: chatAuthId});
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      });
    } else {
      setChatAuthAvatarUrl(res.result.avatarUrl);
    }
  }, []);

  useEffect(() => {
    getUserRTCToken(channelId);
    _getChatAuthInfo();
  }, [channelId, getUserRTCToken, _getChatAuthInfo])

  function _consultantLeaveRoom() {
    let params = {
      consultantId: token.userid,
      authRole: 'patient',
      authId: chatAuthId,
    };
    const res = consultantLeaveRoom(params);
    navigate('/therapist/dialogue', { state: { staffInfo } });
  }

  return (
    <>
      <AliyunRTC
        role='dcotor'
        chatAuthId={chatAuthId}
        authInfo={token}
        channelId={channelId}
        consultType={consultType}
        onLeaveRoom={_consultantLeaveRoom}
        chatAuthAvatarUrl={chatAuthAvatarUrl}
      />
    </>
  )
}

export default DoctorVideoConsult;