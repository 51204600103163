// 咨询师管理
import React, { useCallback, useState, useEffect } from 'react';
import './index.less';
import _ from 'lodash';
import TitleBar from '@/components/TitleBar';
import { useNavigate } from 'react-router-dom';
import { Badge, SearchBar } from 'antd-mobile';
import { UserOutline, RightOutline } from 'antd-mobile-icons';
import { getConsultantList, adminSearchConsultant } from '@/services/api';

function ConsultantsManagement() {
  const role = 'doctor';
  const navigate = useNavigate();
  const [consultantList, setConsultantList] = useState([]);

  const _getConsultantList = async (searchParams = {}) => {
    const res = await getConsultantList(searchParams);
    setConsultantList(res.result || []);
  }

  const _adminSearchConsultant = async (searchKeyword) => {
    const res = await adminSearchConsultant(searchKeyword);
    setConsultantList(res.result || []);
  }

  useEffect(() => {
    _getConsultantList();
  }, [])

  function renderGender(gender) {
    switch (gender) {
      case 'MALE':
        return '男';
      case 'FEMALE':
        return '女';
      default:
        return '保密';
    }
  }

  function renderConsultantBlock(staffInfo, index) {
    return <div className='consultant-block' key={index} onClick={() => navigate('/admin/consultant-detail-info', { state: { staffInfo, role } })}>
      {
        staffInfo.avatar ? <img src={staffInfo.avatar} alt="" /> : <UserOutline className={`consultant-head border-color-${role}`} />
      }
      <div className='consultant-info'>
        <div className='consultant-info-title'>
          <div className='consultant-info-name'>{staffInfo.name}</div>
          <div className='consultant-info-tag'>{staffInfo.tagList || [].join('，')}</div>
        </div>
        <div className='consultant-info-content'>{renderGender(staffInfo.gender)}，{staffInfo.age}岁</div>
      </div>
      <>
        {/* <Badge content={3} /> */}
        <Badge content={staffInfo.unreadMessageCount || null} />
        <div className={`list-right-icon tx-color-${role}`}><RightOutline /></div>
      </>
    </div>
  }

  // 管理员搜索咨询师
  function handleSearch(val) {
    if (val) {
      let params = {
        searchKeyword: val
      };
      _adminSearchConsultant(params);
    }
    else {
      _getConsultantList();
    }
  }

  return (
    <>
      <TitleBar title='咨询师管理' />
      <div className='audit-consultant-page-content'>
        <SearchBar
          className='search-bar'
          placeholder='输入咨询师姓名'
          onSearch={(val) => {
            handleSearch(val);
          }}
          onClear={() => {
            _getConsultantList();
          }}
        />
        {consultantList && consultantList.map((staff, index) => renderConsultantBlock(staff, index))}
      </div>
    </>
  )
}
export default ConsultantsManagement;