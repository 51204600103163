import React, { useState, useEffect, useCallback, useRef } from 'react';
import './index.less';
import AliyunRTC from '@/components/AliyunRTC';
import { getDoctorRTCToken, consultantLeaveRoom, doctorGetPatientInfo } from '@/services/api';
import { Toast } from 'antd-mobile';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import IatRecorderClass from '@/utils/IatRecorderClass';
import { TextArea, Button } from 'antd-mobile';
import ChatGptStream from '@/components/ChatGptStream';
import ttsRecorder from '@/utils/speechRecognition/TTSRecorder';

function DoctorVideoConsultChatgpt(props) {

  const [token, setToken] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const channelId = _.get(location, ['state', 'channelId'], '');
  const chatAuthId = _.get(location, ['state', 'chatAuthId'], '');
  const consultType = _.get(location, ['state', 'consultType'], 'video');
  const staffInfo = _.get(location, ['state', 'staffInfo'], {});
  const [chatAuthAvatarUrl, setChatAuthAvatarUrl] = useState('');

  // 语音识别的答案
  const [speechText, setSpeechText] = useState('');
  // 语音识别的状态
  const [iatStatus, setIatStatus] = useState(false);
  const iatRecorderRef = useRef(null);
  const chatGptStreamRef = useRef();
  const [buttonText, setButtonText] = useState('开启识别');
  const [chatgptParams, setChatgptParams] = useState(null);

  const getUserRTCToken = useCallback(
    async (channelId) => {
      const params = {
        channelType: 'CHAT',
        channelId: channelId
      }
      let res = await getDoctorRTCToken(params);
      console.log('getRTCToken::', res)
      if (res.errorCode) {
        Toast.show({
          icon: 'fail',
          content: res.error,
        });
      } else {
        setToken(res.result);
      }
    },
    [],
  )

  // 获取患者信息,获取头像
  const _getChatAuthInfo = useCallback(async () => {
    const res = await doctorGetPatientInfo({authId: chatAuthId});
    if (res.errorCode) {
      Toast.show({
        icon: 'fail',
        content: res.error,
      });
    } else {
      setChatAuthAvatarUrl(res.result.avatarUrl);
    }
  }, []);

  // 创建语音识别实例
  useEffect(() => {
    iatRecorderRef.current = new IatRecorderClass();
    iatRecorderRef.current.onTextChange = (text) => {
      setSpeechText(text);
    };
  }, []);

  useEffect(() => {
    getUserRTCToken(channelId);
    _getChatAuthInfo();
  }, [channelId, getUserRTCToken, _getChatAuthInfo])

  function _consultantLeaveRoom() {
    let params = {
      consultantId: token.userid,
      authRole: 'patient',
      authId: chatAuthId,
    };
    const res = consultantLeaveRoom(params);
    navigate('/therapist/dialogue', { state: { staffInfo } });
  }

  function  onRecStart () {
    if (iatStatus) {
      setButtonText('开启识别');
      iatRecorderRef.current.stop();
      handleChatGptRec();
    } else {
      setButtonText('关闭识别');
      iatRecorderRef.current.start();
    }
    setIatStatus(!iatStatus);
  }

  function handleChatGptRec() {
    // 清空上一次的识别结果
    chatGptStreamRef.current.clearCahtGptResult();
    // 自动转换chatGpt
    speechText && setChatgptParams({prompt: speechText,reset_conversation: '1'})
  }

  // 功能区
  function renderChunkContent () {
    return (
      <div className='iat-to-gpt-page'>
         <TextArea
          placeholder='请输入内容'
          value={speechText}
          rows={5}
          onChange={val => {
            setSpeechText(val)
          }}
        />
         <Button size='middle' color='primary' onClick={onRecStart}>{buttonText}</Button>
         <Button style={{marginLeft: '20px'}} size='middle' color='primary' onClick={handleChatGptRec}>chatGpt转换</Button>
      </div>
    )
  }
  // 监听音频回调
  function handleMediaStreamUpdate(mediaStream) {
    console.log('mediaStream:', mediaStream)
  }


  return (
    <>
      <AliyunRTC
        role='dcotor'
        chatAuthId={chatAuthId}
        authInfo={token}
        channelId={channelId}
        consultType={consultType}
        onLeaveRoom={_consultantLeaveRoom}
        chatAuthAvatarUrl={chatAuthAvatarUrl}
        onMediaStreamUpdate={handleMediaStreamUpdate}
      />
      <div>
        {
          renderChunkContent()
        }
        <ChatGptStream 
          ref={chatGptStreamRef}
          params={chatgptParams}
          onMessageSend={(message) => {
            // 选择识别结果播报
            ttsRecorder.setParams({text: message});
            ttsRecorder.start();
          }}
        />
        </div>
    </>
  )
}

export default DoctorVideoConsultChatgpt;