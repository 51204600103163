// 返回stream
import React, { useCallback, useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import useStream from '@/utils/hooks/useStream';
import { Checkbox } from 'antd-mobile'
import './index.less';

const ChatGptStream = (props, ref) => {
  // params: { prompt, reset_conversation }
  const { params, onMessageSend } = props;
  const [chatgptAnswerList, setChatgptAnswerList] = useState([]);
  const answerDataRef = useRef('');
  const [paramsBody, setParamsBody] = useState(null);

  useImperativeHandle(ref, () => ({
    clearCahtGptResult
  }));

  useEffect(() => {
    if (params) {
      setParamsBody(
        {
          method: 'POST',
          body: JSON.stringify(params),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
    }
  }, [params])

  // 清空识别结果
  function clearCahtGptResult () {
    setChatgptAnswerList([])
  }


  const getChatGptStream = useCallback(async res => {
    let data = await res.body.getReader().read();
    data.output = new TextDecoder('utf-8').decode(data.value);
    answerDataRef.current += data.output;
    setChatgptAnswerList([...chatgptAnswerList, { message: answerDataRef.current, isChecked: false }]);
    if (data.output.includes('<br/><br/>')) {
      answerDataRef.current = answerDataRef.current.replace(/<br\/>/g, '');
      // answerDataRef.current相当于一个bucket, 用来存储每次的数据, 直到遇到<br/><br/>才会把数据存到chatgptAnswerList中
      chatgptAnswerList.push({ message: answerDataRef.current, isChecked: false });
      answerDataRef.current = '';
    }
  }, [setChatgptAnswerList]);

  const onFirst = useCallback(async res => {
    const chatKey = res.get('chat-key');
  });

  useStream(`https://chatgpt-uat.siuvo.cn/chat_stream_html`, {onFirst, onNext: getChatGptStream, fetchParams: paramsBody });


  return (
    <div className='chatgpt-component'>
      {
        chatgptAnswerList && chatgptAnswerList.map((item, index) => {
          return (
            <div className='chatgpt-answer-item' key={index}>
              <div className='item-adopt'
                style={{ backgroundColor: item.isChecked ? '#fa0' : '#e9e9e9' }}
                onClick={() =>{
                  item.isChecked = !item.isChecked;
                  setChatgptAnswerList([...chatgptAnswerList]);
                  let needSendData = chatgptAnswerList.map((item, index) => {
                    if (item.isChecked) {
                      return item.message + '\n';
                    }
                  })  
                  onMessageSend(needSendData.join(''));
                }}
              >
                播报
              </div>
              <div
                className='item-message'>
                {item.message}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default forwardRef(ChatGptStream);