// 拨打电话
export default function makingCall(tel) {
  // 获取当前设备
  const device = navigator.userAgent;
  console.log('device', device);
  // 判断当前设备是否为移动端
  if (device.match(/(iPhone|iPod|Android|ios)/i)) {
    // 如果是移动端，直接拨打电话
    window.location.href = `tel:${tel}`;
  }

}