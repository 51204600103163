// 音频播放器组件
import React, { useState, useRef, useEffect } from "react";
import './index.less';
import backgroundImg from '@/assets/images/positive-thinking/backgroundImg.png';

function AudioPlayerComponent(props) {
  const { title, audioUrl, background, subhead, description } = props;
  const audioRef = useRef();
  // 歌曲是否播放
  const [isPlay, setIsPlay] = useState(false);
  // 获取audio元素
  const audio = audioRef;
  // 获取音频总时长
  const duration =  audio?.current?.duration || 1;
  // 进度条宽度
  const [barWidth, setBarWidth] = useState();
  // 定时器标志
  const [Timer, setTimer] = useState();
  const fastForward = require('@/assets/icon/audioPlayer/Fast Forward.svg').default;
  const rewind = require('@/assets/icon/audioPlayer/rewind.svg').default;
  const pause = require('@/assets/icon/audioPlayer/pause.svg').default;
  const play = require('@/assets/icon/audioPlayer/play.svg').default;

  // 定时获取当前时间
  useEffect(() => {
    let timer = setTimeout(() => {
      setTimer(timer);
      progressBarWidth();
    }, 100);
    // 清除定时器
    return () => {
      clearTimeout(timer)
    }
  }, [Timer])

  // 进度条宽度
  function progressBarWidth() {
    let currentTime = audio.current.currentTime;
    let width = Math.round(currentTime / duration * 302);
    setBarWidth(width);
  }

  // 音乐播放
  function onPlay() {
    setIsPlay(!isPlay);
    if (isPlay == true) {
      audio.current.play();
    }
    else if (isPlay == false) {
      audio.current.pause();
    }
  };

  // 回退15秒种
  function handleRewind() {
    let currentTime = audio.current.currentTime;
    currentTime - 15 > 0
      ? audio.current.currentTime = currentTime - 15
      : audio.current.currentTime = 0
  };

  // 前进15秒
  function handleFast(params) {
    let currentTime = audio.current.currentTime;
    currentTime + 15 > duration
      ? audio.current.currentTime = duration - 1
      : audio.current.currentTime = currentTime + 15
  };

  // Controls控制播放组件
  function Controls() {
    return (
      <div className='controls'>
        <div className="rewind" >
          <img
            className="icon-rewind"
            src={rewind}
            onClick={handleRewind}
          >
          </img>
        </div>
        <div className="play" >
          {
            isPlay == true
              ? <img className='icon-play' src={pause} onClick={onPlay} />
              : <img className='icon-play' src={play} onClick={onPlay} />
          }
          
        </div>
        <div className="fast" >
          <img
            className="icon-fast" 
            src={fastForward}
            onClick={handleFast}
          >
          </img>
        </div>
      </div>
    )
  };

  // 进度条
  function progressBar() {
    return (
      <div className="progressBar-position">
        <div 
          className="progressBar"
          style={{
            width: barWidth
          }}
        >
        </div>
      </div>
    )
  }

  return (
    <div 
      style={{
        backgroundImage: `url(${backgroundImg})`,
      }}
      className="audioContent"
    >
      <div className="title-position">
        <div className="title-name">{subhead}</div>
        <div className="title-description">
          {
            description.map((element, index) => {
              return (
                <span key={index}>{element}</span>
              )
            })
          }
        </div>
      </div>
      <div className="audio-position">
        {
          progressBar()
        }
        {
          Controls()
        }
        <audio id="audio" src={audioUrl} ref={audioRef} autoPlay ></audio>
      </div>
    </div>
  )
}

export default AudioPlayerComponent;