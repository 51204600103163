import formContent from '@/assets/form/mentalHealthTraining_firstAssessment.json';
import formContentForStudent from '@/assets/form/mentalHealthTraining_firstAssessment_for_student.json';
import {studentOrgId} from '@/Constant';

/**
 * 
 * @param {*} orgId 机构号
 * @returns 
 */
export default function getIntakeForm (orgId) {
  if (!orgId) {
    return formContent;
  }
  const isStudent = studentOrgId.indexOf(orgId) > -1;
  if (isStudent) {
    return formContentForStudent;
  }
  return formContent;
}