import React, { useEffect, useState } from "react";
import TitleBar from "@/components/TitleBar";
import ConsultantInfoComponent from "@/components/UserInfo/ConsultantInfo";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { patientGetDoctorInfo } from "@/services/api";

function ConsultantInfo() {
  const location = useLocation();
  const navigate = useNavigate();
  const consultantInfo = _.get(location, ["state", "consultantInfo"], []);
  const role = _.get(location, ["state", "role"], "");
  const type = _.get(location, ["state", "type"], "");
  const [doctorInfo, setDoctorInfo] = useState({});

  useEffect(() => {
    _patientGetDoctorInfo(_.get(consultantInfo, "consultantId", ""));
  }, []);

  async function _patientGetDoctorInfo(consultantId) {
    let res = await patientGetDoctorInfo(consultantId);
    setDoctorInfo(_.get(res, "result", {}));
  }

  return (
    <>
      <TitleBar
        title="咨询师"
        isShowBack={true}
        rightContent='查看排班'
        rightContentOnclick={() => {
          // type为bind时，说明来访者是从匹配页跳转进去的，此时跳转到日历页面不需要任何操作
          if (type === "bind") {
            navigate("/client/calendar-read", {
              state: { consultantId: doctorInfo.consultantId },
            });
          }
          else {
            navigate("/client/calendar", {
              state: { consultantId: doctorInfo.consultantId },
            });
          }
        }}
      />
      <ConsultantInfoComponent
        doctorInfo={doctorInfo}
        role={role}
        type={type}
      ></ConsultantInfoComponent>
    </>
  );
}

export default ConsultantInfo;
