/* 
  有些地方根据xxxx.split('/')[1]来截取字符串用于判断角色
  但是因为doctor和patient这两者之间的关系词语很敏感，所以分别用therapist和client来进行替换
  
*/
export default function changeName(params) {
  if (params === 'therapist' || params === 'doctor') {
    return params = 'doctor';
  }
  else if (params === 'client' || params === 'patient') {
    return params = 'patient';
  }
  else {
    return params;
  }
}