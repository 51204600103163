// 心理晴雨表的iframe
import React from 'react';
import IframeComponent from '@/components/Iframe';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

function PsychologyStatusIframe() {
  const location = useLocation();
  const title = _.get(location, ['state', 'title']);
  const url = _.get(location, ['state', 'url']);

  return (
    <>
      <IframeComponent title={title} src={url} ></IframeComponent>
    </>
  )
}
export default PsychologyStatusIframe;