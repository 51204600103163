import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSocketMiddleware from './redux_middleware'
import rootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react'
import VConsole from 'vconsole';
import { getRoleFromLocation } from '@/utils/commonUtils';
import changeName from '@/utils/changeName';
import alifeLogger from '@/utils/alifeLogger.js';
import {
  getLoginToken,
  setAuthId
} from '@/utils/localStorage';
import { checkTokenIsValid, decodeToken } from '@/utils/utils';

const persistConfig = {
  key: 'root',
  storage: storage
}
// 绑定阿里云监控
alifeLogger();
// 获取角色
// const createHistory = require('history').createBrowserHistory;
// const history = createHistory();
const role = getRoleFromLocation();
// token过期，清空缓存
const token = getLoginToken(changeName(role));
const { authId } = decodeToken(token);
// const isTokenValid = checkTokenIsValid(token);
// !isTokenValid && setAuthId(changeName(role), '')
// 初始化
const MypersistReducer = persistReducer(persistConfig, rootReducer)
const socketMiddleware = createSocketMiddleware(role, authId)
const store = createStore(MypersistReducer, applyMiddleware(socketMiddleware));
const persistor = persistStore(store);

if (process.env.DEFAULT_SERVER === 'uat') {
  const vconosle = new VConsole();
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
