import React from 'react';
import './index.less';
import { setLoginToken, setAuthId } from '@/utils/localStorage.js';
import TitleBar from '@/components/TitleBar';
import { BellOutline, UserOutline, UserContactOutline, EditSOutline } from 'antd-mobile-icons';
import { useNavigate } from 'react-router-dom';

function Main(props) {
  const role = 'admin';
  const navigate = useNavigate();

  const funcs = [
    { icon: <EditSOutline />, name: '审核', handler: auditUserInfo },
    { icon: <UserOutline />, name: '咨询师管理', handler: consultantManagement },
    // {icon: <UserContactOutline/>, name: '来访者管理', handler: auditUserInfo},
    { icon: <BellOutline />, name: '咨询记录管理', handler: evaluationManagement },
    { icon: <UserContactOutline />, name: '咨询记录表', handler: evaluationTable },
  ]

  // 审核
  function auditUserInfo() {
    navigate('/admin/audit-list')
  }

  // 咨询师管理
  function consultantManagement() {
    navigate('/admin/home/consultants')
  }

  // 咨询咨询记录管理
  function evaluationManagement() {
    navigate('/admin/evaluation-management')
  }

  // 咨询记录表
  function evaluationTable() {
    navigate('/admin/health-record-list')
  }

  return (
    <>
      <TitleBar
        title="首页"
        rightContent={
          <div onClick={() => {
            setLoginToken(role, '');
            // setAuthId(role, '');
            navigate('/admin/login', { replace: true })
            window.location.reload();
          }}>
            退出
          </div>
        }
      />
      <div className='admin-main-page-content'>
        <div className='main-func-content'>
          {
            funcs.map((item, index) => {
              return (
                <div key={index} onClick={item.handler} className="func-item">
                  {/* <img src={item.icon} alt=""/> */}
                  {item.icon}
                  <div>{item.name}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
}
export default Main;