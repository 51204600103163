import React from 'react';
import MainLayout from '@/components/MainLayout';


function Home (props) {
  const tabs = [
    { 
      key: '/therapist/home/user-manage',
      title: active => <div className={`tab-txt-margin-top ${active ? 'tx-color-doctor' : ''}`}>用户管理</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/user/activate.svg' : '@/assets/icon/doctorHome/user/inactivate.svg').default} />,
    },
    {
      key: '/therapist/home/calendar',
      title:  active => <div className={active ? 'tx-color-doctor' : ''}>排班日历</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/list/activate.svg' : '@/assets/icon/doctorHome/list/inactivate.svg').default} />,
    },
    {
      key: '/therapist/home/user-setting',
      title: active => <div className={active ? 'tx-color-doctor' : ''}>设置</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/setting/activate.svg' : '@/assets/icon/doctorHome/setting/inactivate.svg').default} />,
    }
  ]
  return (
    <MainLayout
      tabs={tabs}
    />
  )
}

export default Home;