import _ from 'lodash';

/* "model": {
  "Intro": {
      "intro": true
  },
  "S1": {
      "S1A1": true,
      "S1Q1": "relationships",
      "S1Q1R1": true,
      "S1Q2": "chronic",
      "S1Q2R2": true,
      "S1Q2C": true
  },
  "S2": {
      "S2A2": true,
      "S2Q2": "behavioral"
  },
  "S3": {
      "S3A1": true,
      "S3Q1": "largeEmotionalFluctuations",
      "S3Q1R1": true,
      "S3A2": true,
      "S3Q2": "dontKnow",
      "S3A3": true,
      "S3Q3": "insomnia",
      "S3Q3C": true
  },
  "Conclusion": {
      "conclusion": true
  }
} */

/**
 * 解析jsonFormData和model，返回课程的数据
 * @param {*} formData
 * @param {*} model
 * @returns
 */

function parseJsonFormToLessonData(formData, model) {
  let modelList = [];
  let questionList = [];
  // for in获取key值
  for (const modelKey in model) {
    // modelKey的对象不为空对象，则继续遍历
    let modelItem = JSON.stringify(model[modelKey]);
    if (modelItem !== '{}') {
      for (const key in model[modelKey]) {
        if (typeof model[modelKey][key] == 'string') {
          modelList.push({ [modelKey]: { [key]: model[modelKey][key] } });
        }
      }
    }
  }
  // 将拿到的题目去匹配form里面的值
  modelList.map((element, index) => {
    for (const modelListKey in element) {
      // S1
      for (const modelItemkey in element[modelListKey]) {
        // S1Q1
        let questionItem = _.get(
          formData,
          [modelListKey, 'properties', modelItemkey],
          {}
        );
        // 判断questionItem是否为空对象
        if (JSON.stringify(questionItem) !== '{}') {
          let question = questionItem.title_zh;
          let answer, questionType;
          if (questionItem.enum) {
            // 单选题
            let optionsKey = Object.keys(questionItem.enum);
            let options = [];
            optionsKey.map((optionsItem) => {
              options.push(questionItem.enum[optionsItem]);
            });
            let answerKey = element[modelListKey][modelItemkey];
            answer = questionItem.enum[answerKey];
            questionType = 'radio';
            questionList.push({ question, options, answer, questionType });
          } else {
            questionType = 'input';
            answer = element[modelListKey][modelItemkey];
            questionList.push({ question, answer, questionType });
          }
        }
      }
    }
  });
  return questionList;
}

export default parseJsonFormToLessonData;
