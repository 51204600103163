import React, { useEffect, useState } from 'react';
import './index.less';
import _ from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { useNavigate } from 'react-router-dom';
import TitleBar from '@/components/TitleBar';
import { doctorGetConsultantScheduling, doctorDeleteAppointTimeslot, doctorDeleteAppoint } from '@/services/api';
import { Popup, Modal, Toast } from 'antd-mobile'
import AddAppoint from './addAppoint';
import AddAppointTimeSlot from './addAppointTimeSlot';
import CalendarComponent from '@/components/Calendar';

function Calendar() {
  const navigate = useNavigate();
  const [popupVisible, setPopupVisible] = useState(false);
  const [refreshCalendar, setRefreshCalendar] = useState(false);
  const [calendarStatus, setCalendarStatus] = useState('list'); // list: 列表信息页 add:添加预约时间 match: 添加匹配用户


  function openCancelAppointModal(type, setSelectId, consultName, selectStartAppointTime, selectEndAppointTime) {
    let typeText = type === 'cancel' ? '取消' : '删除';
    const modal = Modal.show({
      content: (
        <>
          <div className="modal-title">{typeText}确定</div>
          <div>您要{typeText}{MomentDiffZone(selectStartAppointTime).format('YYYY年MM月DD日')}{MomentDiffZone(selectStartAppointTime).format('HH:mm')}-{MomentDiffZone(selectEndAppointTime).format('HH:mm')}的预约</div>
          <div className='modal-buttons'>
            <div className='modal-button modal-cancel' onClick={() => modal.close()}>取&nbsp;&nbsp;消</div>
            <div className='modal-button modal-confirm' onClick={() => {
              modal.close();
              if (type === 'cancel') {
                _doctorDeleteAppoint(setSelectId);
              } else if (type === 'delete') {
                _doctorDeleteAppointTimeslot(setSelectId);
              }
            }}>确&nbsp;&nbsp;认</div>
          </div>
        </>
      ),
    })
  }

  async function _doctorDeleteAppointTimeslot(timeslotId) {
    const res = await doctorDeleteAppointTimeslot(timeslotId);
    Toast.show({ icon: 'success', content: '删除成功' });
    setRefreshCalendar(!refreshCalendar);
  }

  async function _doctorDeleteAppoint(appointId) {
    const res = await doctorDeleteAppoint(appointId);
    Toast.show({ icon: 'success', content: '取消成功' });
    setRefreshCalendar(!refreshCalendar);
  }

  function renderTitleRight () {
    return(
      <div className='btn-create-popup' onClick={() => setPopupVisible(true)}>
        <img alt="" src={require('@/assets/icon/dialogue/add.svg').default} className='btn-create-popup-icon'/>
        {/* <div className='btn-create-popup-text'>日程管理</div> */}
    </div>
    )
  }

  return (
    <>
      {
        calendarStatus === 'list' ?
          <>
            <TitleBar title="预约" rightContent={renderTitleRight()}></TitleBar>
            <Popup
              visible={popupVisible}
              onMaskClick={() => setPopupVisible(false)}
            >
              <div>
                <div className='popup-item' onClick={() => setCalendarStatus('add')}>添加可预约时间</div>
                <div className='popup-item' onClick={() => setCalendarStatus('match')}>添加来访预约</div>
              </div>
            </Popup>
            <CalendarComponent
              role='doctor'
              refreshCalendar={refreshCalendar}
              getConsultantScheduling={doctorGetConsultantScheduling}
              openCancelAppointModal={openCancelAppointModal}
            />
          </>
          :
          <>
            <TitleBar isShowBack={true} barCancel={() => setCalendarStatus('list')} title={calendarStatus === 'add' ? '添加可预约时段':'添加来访者预约'}></TitleBar>
            {
              calendarStatus === 'add'
                ? <AddAppointTimeSlot backToCalendar={() => {
                  setPopupVisible(false);
                  setCalendarStatus('list');
                  setRefreshCalendar(!refreshCalendar);
                }} />
                : <AddAppoint backToCalendar={() => {
                  setPopupVisible(false);
                  setCalendarStatus('list');
                  setRefreshCalendar(!refreshCalendar);
                }} />
            }
          </>
      }
    </>
  )
}

export default Calendar;