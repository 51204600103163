import React, { useState, useEffect, useRef } from 'react';
import './index.less';
import { Input, Radio, Button, Toast, Card, Space } from 'antd-mobile';
import TitleBar from '@/components/TitleBar';
import { useNavigate, useLocation } from 'react-router-dom';
import { setPatientBaseInfo, sendPatientVerifyCode, checkVerifyCode, updateStaffInfo } from '@/services/api';
import { setLoginToken, setIsFirstTimeLogin, getLocalNeedFirstEvaluationStatus } from '@/utils/localStorage'
import moment from 'moment'
import _ from 'lodash';
import CustomDatePicker from '@/components/CustomDatePicker';
import { save_patient_info } from '@/actions'
import { useDispatch, useSelector } from 'react-redux';
import MomentDiffZone from '@/utils/useMomentDiffZone';

function RegisterComponents(props) {
  const ROLE = 'patient';
  const { authList = [], onLinkAccount = (params) => {console.log('**********link user with****', params)}, onRegisterNewUser = (params) => {console.log('**********register user with****', params)} } = props;
  const dispatch = useDispatch();
  // 表单用户信息
  const [userInfo, setUserInfo] = useState({});
  const [visible, setVisible] = useState(false)
  // 是否是创建新用户
  const [isCreateNewUser, setIsCreateNewUser] = useState(false);
  // 已有账户
  const [account, setAccount] = useState(null);

  // 提交信息
  async function submitBaseInfo() {
    if (!userInfo.name) {
      Toast.show({
        content: '请填写姓名',
        key: 'name'
      });
      return;
    }
    if (!userInfo.gender) {
      Toast.show({
        content: '请填写性别',
        key: 'gender'
      });
      return;
    }
    if (!userInfo.birthday) {
      Toast.show({
        content: '请选择生日',
        key: 'birthday'
      });
      return;
    }
    onRegisterNewUser(userInfo)
  }

  // 选择已有账户
  function reuseAccount() {
    if (!account) {
      Toast.show({
        content: '请选择一个现有账户',
        key: 'account'
      });
      return
    }
    onLinkAccount(account.authId);
  }

  function renderUpdateRegisterInfo() {
    return (
      <div className="info">
        <div className="info-content">
          <div className="info-title">姓名</div>
          <Input size="large" placeholder="请输入您的姓名" value={userInfo.name} onChange={val => setUserInfo({...userInfo, name: val})} />
          <div className="info-title">性别</div>
          <Radio.Group
            onChange={val => setUserInfo({...userInfo, gender: val})}
            size="large"
            value={userInfo.gender}
            className="info-radio"
          >
            <Radio value="MALE" style={{ marginRight: '2rem' }}>男</Radio>
            <Radio value="FEMALE">女</Radio>
          </Radio.Group>
          <div className="info-title">生日</div>
          <div onClick={() => {
            setVisible(true);
          }}>
            <Input size="large" placeholder="请输入您的生日" disabled={visible} value={userInfo.birthday && MomentDiffZone(userInfo.birthday).format('YYYY-MM-DD')} />
          </div>
          <CustomDatePicker
            title='出生日期'
            visible={visible}
            onClose={() => {
              setVisible(false)
            }}
            defaultValue={MomentDiffZone('1990').toDate()}
            onConfirm={val => {
              setUserInfo({...userInfo, birthday: moment(val).valueOf()})
            }}
          />
        </div>
        <Button size="large" className="info-btn-submit" onClick={() => {
          submitBaseInfo()
        }}>注册</Button>
        {
          authList.length > 0 && <div className='reuse-account' onClick={() => {setIsCreateNewUser(false)}}>使用已有账户</div>
        }
      </div>
    )
  }

  // 存在账号时，展示账号列表
  function renderAuthList() {
    return (
      <div className='list-content'>
        <div className='list-title'>查询到您有以下账户信息</div>
        <div className='list-radio-group'>
          <Radio.Group onChange={(val) => {
            setAccount(authList[val])
          }} >
            {
              authList.map((item, index) => {
                const { staffInfo } = item;
                return (
                  <Space direction='vertical' block className='list-radio-group-space' key={index}>
                    <Radio value={index}>
                      <div className='list-radio'>
                        <div>姓名：{staffInfo.name}</div>
                        <div>性别：{staffInfo.gender}</div>
                        <div>创建时间：{moment(item.createdTime).format('YYYY-MM-DD')}</div>
                        <div>渠道名称：{item.tenantName}</div>
                      </div>
                    </Radio>
                  </Space>
                )
              })
            }
          </Radio.Group>
        </div>
        <Space className='list-footer' justify='around'>
          <Button color='primary' onClick={reuseAccount}>使用已有账号</Button>
          <Button color='success' onClick={() => {
            setIsCreateNewUser(true)
          }}>注册新账号</Button>
        </Space>
      </div>
    )
  }

  // 注册信息填写还是复用账号
  function renderRegisterContent() {
    if (authList.length > 0 && !isCreateNewUser) {
      return renderAuthList();
    } else {
      return renderUpdateRegisterInfo();
    }
  }

  return (
    <div className='register-page'>
      {renderRegisterContent()}
    </div>
  )
}

export default RegisterComponents;