import React from 'react';
import { SpinLoading } from 'antd-mobile';
import './index.less';

function AnonymousLoginComponent(props) {
  const { isLoading } = props;

  function renderLoading() {
    return (
      <div className="enter-loading">
        <SpinLoading color="primary" style={{ '--size': '48px' }} />
        <div className="loading-text">资源加载中</div>
      </div>
    );
  }
  return <>{isLoading ? renderLoading() : null}</>;
}

export default AnonymousLoginComponent;
