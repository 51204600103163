// 用于提取出message中的{}中的内容,匹配出响应的需要播放的视频
import recommendChatItems from '@/Constant/recommendChatItems';

export default function contractVideoOrPositive(message) {
  if (message.includes('{') || message.includes('}')) {
    let beforeBraceContent = message.split('{')[0];
    let afterBraceContent = message.match(/\{(.*)\}/)[0];
    if (afterBraceContent !== "{{ borderRadius: '8px' }}") {
      const braceContentInner = JSON.parse(afterBraceContent);
      const itemId = braceContentInner.itemId;
      const itemIdContent = recommendChatItems.find(item => item.itemId === itemId);
      const { url, media } = itemIdContent;
      if (itemId.includes('exercise')) {
        if (beforeBraceContent) {
          return (
            `
              <span>${beforeBraceContent}</span>
              <video
                controls
                width="100%"
                height="200px"
                style={{ borderRadius: '8px' }}
                playsInline={true}
                webkit-playsinline='true'
                onPlay={() => {
                }}
              >
                <source src=${media} type="video/mp4" />
              </video>
            `
          )
        } else {
          return (
            `
              <video
                controls
                width="100%"
                height="200px"
                style={{ borderRadius: '8px' }}
                playsInline={true}
                webkit-playsinline='true'
                onPlay={() => {
                }}
              >
                <source src=${media} type="video/mp4" />
              </video>
            `
          )
        }
      }
      if (itemId.includes('positive')) {
        return (
          `
            <a href=${url}>点击进行正念练习</a>
          `)
      }
    }
  }
  if (message.includes('[OVER]')) {
    message = message.replace(/\[OVER\]/g, '');
    return message
  }
  return message
}