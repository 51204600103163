import React, { useEffect, useState } from 'react';

import { getOriginalIdByShortId } from '@/services/api';
import { getPageQuery } from '@/utils/utils';

export function useChatAuthId() {
  const [chatAuthIdTemp, setChatAuthIdTemp] = useState(null);
  const { chatAuthId, shortChatAuthId } = getPageQuery();

  const _getOriginalIdByShortId = async (shortChatAuthId) => {
    const res = await getOriginalIdByShortId(shortChatAuthId);
    if (res) {
      return res.result;
    }
  }

  useEffect(() => {
    const fetchChatAuthId = async () => {
      if (shortChatAuthId) {
        const originalId = await _getOriginalIdByShortId(shortChatAuthId);
        setChatAuthIdTemp(originalId);
      } else {
        setChatAuthIdTemp(chatAuthId);
      }
    }
    fetchChatAuthId();
  }, [chatAuthId, shortChatAuthId]);

  // chatAuthIdTemp有值时，返回chatAuthIdTemp，否则不做处理
  if (chatAuthIdTemp) {
    return { chatAuthId: chatAuthIdTemp }
  }
  return { chatAuthId }
}