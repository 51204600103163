import React, { useState, useEffect } from "react";
import "./index.less";
import TitleBar from "@/components/TitleBar";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Badge, Modal } from "antd-mobile";
import Avatar from "@/components/Avatar";
import { useSelector } from "react-redux";
import { bindConsultant } from "@/services/api";

function Matching() {
  const location = useLocation();

  const navigate = useNavigate();
  const allConsultants = _.get(
    location,
    ["state", "suggestConsultants"],
    []
  );
  const [consultantPage, setConsultantPage] = useState(0);
  const [suggestConsultants, setSuggestConsultants] = useState(allConsultants.slice(0, 3));
  console.log("suggestConsultants:::::::", allConsultants.slice(0, 3));
  console.log("suggestConsultants:::::::", allConsultants.slice(3, 6));
  console.log("suggestConsultants:::::::", suggestConsultants);
  const baseInfo = useSelector((state) => state.baseInfo);
  const [consultants, setConsultants] = useState([1]);

  useEffect(() => { }, []);

  function getOnLineState(onLineState) {
    switch (onLineState) {
      case "OFFLINE":
        return "";
      case "ONLINE":
        return "在线";
      case "BUSY":
        return "忙线";
      default:
        return "123";
    }
  }

  // 渲染匹配的咨询师
  function renderConsultantCard() {
    return (
      <div className="consultant-card-content">
        <div className="consultant-card">
          <div>
            根据您的资料，我们为您精心筛选了{suggestConsultants.length}
            位咨询师，他们都有着丰富的心理咨询实战经验
          </div>
          <div className="card-grid-items">
            {suggestConsultants.map((item, index) => {
              let badgeText = "",
                badgeColor = "red",
                submitText = "开始咨询";
              if (item.onLineState === "OFFLINE") {
                badgeText = "离线";
                submitText = "绑定";
              } else if (item.onLineState === "ONLINE") {
                badgeText = "在线";
                submitText = "开始咨询";
              } else if (item.onLineState === "BUSY") {
                badgeText = "忙线";
                badgeColor = "orange";
                submitText = "开始咨询";
              }
              return (
                <div className="card-grid-item">
                  <div className="card-grid-item-info" key={index} onClick={() => { navigate('/client/consultant-info', { state: { consultantInfo: item, role: 'patient', type: 'bind' } }) }}>
                    {/* <Badge content={badgeText} style={{ '--right': '120%', '--color': badgeColor }}> */}
                    <Avatar
                      avatarUrl={item.avatarUrl}
                    />
                    {/* </Badge> */}
                    <div className="card-doctor-name">{item.nickName}</div>
                    <div className="card-doctor-intro">{item.consultantInformation.simpleIntroduce || '暂无介绍'}</div>
                  </div>
                  <div className="card-consult-btn" onClick={() => startConsultingService(item)}>{submitText}</div>
                </div>
              );
            })}
          </div>
          <div className="change-suggest-consultants" onClick={() => {
            setSuggestConsultants(consultantPage === 0 ? allConsultants.slice(3, 6) : allConsultants.slice(0, 3));
            setConsultantPage(consultantPage === 0 ? 1 : 0);
          }}>{consultantPage === 0 ? "换三位看看" : "返回查看"}</div>
        </div>
        {renderBottomRecommenddation()}
      </div>
    );
  }

  // 开始咨询
  async function startConsultingService(item) {
    let title = "",
      content = "";
    // submitFunc = function () {};
    if (item.onLineState === "OFFLINE" || !item.onLineState) {
      // submitFunc = function () {
      //   navigate("/client/calendar", { state: { consultantId: item.authId } });
      // };
      content =
        "您确定要绑定" + _.get(item, "name", "该") + "咨询师吗？以后都将由这位咨询师为您提供咨询服务。（绑定之后不可自行解绑，如需解绑请联系客服）";
    } else if (item.onLineState === "ONLINE") {
      navigate(`/client/dialogue/${item.authId}`);
      return;
    } else if (item.onLineState === "BUSY") {
      title = "确认等待";
      content =
        "您所选择的咨询师正在服务其他来访者，您是否确认等待？请您确认电话能联系到您？" +
        _.get(item, "cellphone", "");
    }
    const modal = Modal.show({
      content: (
        <>
          <div className="modal-title">{title}</div>
          <div>{content}</div>
          <div className="modal-buttons">
            <div
              className="modal-button modal-cancel"
              onClick={() => modal.close()}
            >
              取&nbsp;&nbsp;消
            </div>
            <div
              className="modal-button modal-confirm"
              onClick={async () => {
                let res = await bindConsultant(item.authId);
                submitFunc(item);
                modal.close();
              }}
            >
              确&nbsp;&nbsp;认
            </div>
          </div>
        </>
      ),
    });
  }

  function submitFunc(item) {
    if (item.onLineState === "OFFLINE" || !item.onLineState) {
      const modal = Modal.show({
        content: (
          <>
            <div className="modal-title">提示</div>
            <div>
              {_.get(item, "name", "该")}
              咨询师已正式成为您的专属咨询师，您可以自由地和他进行<font color='#f23030'>非实时</font>的文字和语音沟通，也可以预约视频/语音咨询，您要现在开始预约吗？
            </div>
            <div className="modal-buttons">
              <div
                className="modal-button modal-cancel"
                onClick={() => {
                  modal.close();
                  navigate("/client/home");
                }}
              >
                取&nbsp;&nbsp;消
              </div>
              <div
                className="modal-button modal-confirm"
                onClick={() => {
                  modal.close();
                  navigate("/client/calendar", { state: { consultantId: item.authId } });
                }}
              >
                是&nbsp;&nbsp;的
              </div>
            </div>
          </>
        ),
      });
    } else if (item.onLineState === "ONLINE" || item.onLineState === "BUSY") {
      navigate(`/client/dialogue/${item.authId}`, { state: { chatAuthId: item.authId } });
    }
  }

  // 渲染底部推荐提示内容
  function renderBottomRecommenddation() {
    return (
      <div className="bottom-hint" onClick={() => {
        const staffInfo = {
          authId: '45666668888',
          name: '舒小辅',
          nickName: '舒小辅'
        }
        navigate(`/client/dialogue/${staffInfo.authId}`, { state: { chatAuthId: staffInfo.authId } });
      }}>
        如果您对推荐的咨询师都不满意， 可以点击
        <span className="manual-service">人工客服</span>
        ，由我们的客服咨询师来为您解决
      </div>
    );
  }

  return (
    <>
      <TitleBar title="咨询师" isShowBack={true} />
      {renderConsultantCard()}
    </>
  );
}

export default Matching;
