import React, { useCallback, useEffect, useState } from 'react';
import TitleBar from '@/components/TitleBar';
import './index.less';
import _ from "lodash";
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { consultantSearchInfoApply } from "@/services/api";

function ApprovedMemo() {
  const navigate = useNavigate();
  const baseInfo = useSelector(state => state.baseInfo['doctor']);
  const consultantId = _.get(baseInfo, "authId", "");
  const [infoApplyList, setInfoApplyList] = useState();

  const applyStatus = {APPROVING: '审核中', PASSED: '审核已通过', NOT_APPROVED: '审核未通过', REVOKED: '审核已撤销'}
  const _searchInfoApply = useCallback(async() => {
    let params = {
      consultantId
    };
    let res = await consultantSearchInfoApply(params);
    setInfoApplyList(_.get(res, ['result', 'data'], []));
  }, [])

  useEffect(() => {
    _searchInfoApply();
  }, [_searchInfoApply])

  const approvedMemoList = [
    {
      // 审核中
      status: 'approving',
      time: '2022年11月11日',
      text: '【个人介绍修改】',
    },
    {
      // 审核未通过
      status: 'not-approved',
      time: '2022年11月11日',
      text: '【个人介绍修改】',
    },
    {
      // 已通过
      status: 'passed',
      time: '2022年11月11日',
      text: '【个人介绍修改】',
    },
    {
      // 已撤销
      status: 'revoked',
      time: '2022年11月11日',
      text: '【个人介绍修改】',
    },
  ]

  function renderApprovedList() {

    return (
      <div className='approved-list-content'>
        {
          infoApplyList && infoApplyList.map((item, index) => {
            switch (item.applyStatus) {
              case 'APPROVING':
                return (
                  <div
                    className='approved-item'
                    key={index}
                    onClick={() => {
                      navigate('/therapist/self-intro-audit', { state: { status: item.applyStatus, role: 'doctor', type: 'edit', isShowBusinessCard: false, applyId: item?.applyId, applyTime: MomentDiffZone(item.applyTime).format('YYYY-MM-DD'), applyInfo: item?.applyInfo } })
                    }}
                  >
                    <div className='item-time'>
                      {MomentDiffZone(item.applyTime).format('YYYY-MM-DD')}
                    </div>
                    <div className='item-text'>
                      {item.operatorDesc}
                    </div>
                    <div className='item-status' style={{ background: '#EA9E40' }}>
                      {applyStatus[item.applyStatus]}
                    </div>
                  </div>
                )
              case 'NOT_APPROVED':
                return (
                  <div
                    className='approved-item'
                    key={index}
                    onClick={() => {
                      navigate('/therapist/self-intro-audit', { state: { status: item.applyStatus, role: 'doctor', isShowBusinessCard: false, applyId: item?.applyId, refuseReason: item?.refuseReason, applyInfo: item?.applyInfo }})
                    }}
                  >
                    <div className='item-time'>
                      {MomentDiffZone(item.applyTime).format('YYYY-MM-DD')}
                    </div>
                    <div className='item-text'>
                      {item.operatorDesc}
                    </div>
                    <div className='item-status' style={{ background: '#BE2D1F' }}>
                      {applyStatus[item.applyStatus]}
                    </div>
                  </div>
                )
              case 'REVOKED':
                return (
                  <div
                    className='approved-item'
                    key={index}
                  >
                    <div className='item-time'>
                      {MomentDiffZone(item.applyTime).format('YYYY-MM-DD')}
                    </div>
                    <div className='item-text'>
                      {item.operatorDesc}
                    </div>
                    <div className='item-status' style={{ background: '#9B9B9B' }}>
                      {applyStatus[item.applyStatus]}
                    </div>
                  </div>
                )
              case 'PASSED':
                return (
                  <div
                    className='approved-item'
                    key={index}
                    onClick={() => {
                      navigate('/therapist/self-intro-audit', { state: { status: item.applyStatus, role: 'doctor', isShowBusinessCard: false, applyId: item?.applyId, applyInfo: item?.applyInfo  } })
                    }}
                  >
                    <div className='item-time'>
                      {MomentDiffZone(item.applyTime).format('YYYY-MM-DD')}
                    </div>
                    <div className='item-text'>
                      {item.operatorDesc}
                    </div>
                    <div className='item-status' style={{ background: '#82B434' }}>
                      {applyStatus[item.applyStatus]}
                    </div>
                  </div>
                )
              default:
                return (
                  <></>
                )
            }
          })
        }
      </div>
    )
  }

  return (
    <div>
      <TitleBar title={'信息审核'} isShowBack />
      {
        renderApprovedList()
      }
    </div>
  )
}

export default ApprovedMemo