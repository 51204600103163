// fenglin的登录页面
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  setLoginToken,
  setLocalNeedFirstEvaluationStatus,
} from '@/utils/localStorage'
import { Toast } from 'antd-mobile';
import CONFIG from 'GlobalConfigFile';
import _, { replace } from 'lodash';
import { useDispatch } from 'react-redux';
import { loginByDataEnc, getNeedFirstEvaluation } from '@/services/api';
import { register_socket_event, save_patient_info } from '@/actions'
import { getPageQuery } from '@/utils/utils';
import TitleBar from '@/components/TitleBar';
import AnonymousLoginComponent from '@/components/AnonymousLoginComponent';

function AnonymousLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ROLE = 'patient'; 
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const { dataEnc, organizationId } = getPageQuery();
    onPatientLogin({dataEnc, organizationId})
  }, [])

  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect(id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    dispatch(register_socket_event(socket));
  }

  async function onPatientLogin(params) {
    let res = await loginByDataEnc(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    const { token, needFirstEvaluation } = res.result;
    setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
    const authId = _.get(res, ['result', 'authId'], '');
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    dispatch(save_patient_info(_.get(res, ['result'], {})))
    setIsLoading(false);
    if (needFirstEvaluation) {
      navigate('/client/initial-evaluation');
      return
    }
    navigate('/client/home-v2/main', {replace: true});
  }

  
  async function _getNeedFirstEvaluation() {
    try {
      const res = await getNeedFirstEvaluation();
      const needFirstEvaluation = _.get(res, 'result', true);
      setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
      return needFirstEvaluation
    } catch (e) {
      console.error('getNeedFirstEvaluation error', e)
      // 接口异常仍回主页
      return false
    }
  }

  return (
    <>
    <TitleBar title="登录" />
    <AnonymousLoginComponent isLoading={isLoading} />
  </>
  )
}

export default AnonymousLogin;