import React from 'react';
import MainLayout from '@/components/MainLayout';


function Home (props) {
  const tabs = [
    { 
      key: '/admin/home/main',
      title: active => <div className={`tab-txt-margin-top ${active ? 'tx-color-doctor' : ''}`}>首页</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/user/activate.svg' : '@/assets/icon/doctorHome/user/inactivate.svg').default} />,
    },
    {
      key: '/admin/home/consultants',
      title:  active => <div className={active ? 'tx-color-doctor' : ''}>咨询师</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/list/activate.svg' : '@/assets/icon/doctorHome/list/inactivate.svg').default} />,
    },
    {
      key: '/admin/home/visitors',
      title:  active => <div className={active ? 'tx-color-doctor' : ''}>来访者</div>,
      icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/list/activate.svg' : '@/assets/icon/doctorHome/list/inactivate.svg').default} />,
    },
    // {
    //   key: '/admin/home/user-setting',
    //   title: active => <div className={active ? 'tx-color-doctor' : ''}>设置</div>,
    //   icon: active => <img alt="" src={require(active ? '@/assets/icon/doctorHome/setting/activate.svg' : '@/assets/icon/doctorHome/setting/inactivate.svg').default} />,
    // }
  ]
  return (
    <MainLayout
      tabs={tabs}
    />
  )
}

export default Home;