import React from "react";
import './index.less';
import { Button } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import {staffTrigger} from '@/services/api';

function Xlyy (props) {
  const {consultantId, onCustomServiceCallback} = props;
  const navigate = useNavigate();
  const FUNC_LIST = [
    {
      text: '报告解读',
      clickEvent: () => {
        navigate('/client/calendar', { state: { consultantId } });
      }
    },
    {
      text: '心理疏导',
      clickEvent: () => {
        navigate('/client/calendar', { state: { consultantId } });
      }
    },
    {
      text: '人工客服',
      clickEvent: () => {startCustomService()}
    }
  ]

  async function startCustomService () {
    try {
      const res = await staffTrigger({triggerType: 'needArtificialServices'});
      onCustomServiceCallback();
    } catch (error) {
      console.log('startCustomService error', error)
    }
  }

  return (
    <div className="xlyy-page">
      <div className="func-text">您好，我是客服舒小辅，请选择您需要的服务：</div>
      <div className="func-btn-content">
        {
          FUNC_LIST.map((item, index) => {
            return <Button className="func-btn" key={index} onClick={item.clickEvent}>{item.text}</Button>
          })
        }
      </div>
    </div>
  )
}

export default Xlyy;
