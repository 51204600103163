import React, { useCallback, useEffect, useState, useRef } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import { BellOutline } from 'antd-mobile-icons'
import { Modal, Dropdown, List } from 'antd-mobile';
import { adminGetRecord, adminConsultRecodeCompleted } from '@/services/api';
import ListView from '@/components/ListView';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { useSelector } from 'react-redux';


function EvaluationManagement() {
  const PAGE_SIZE = 10;
  const sortOptions = [
    { sortType: 'uncompleted', title: '咨询未确认结束' },
    { sortType: 'completed', title: '咨询已完成' }
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [evaluationList, setEvaluationList] = useState([]);
  const [hasMore, setHasMore] = useState(false); //是否需要加载更多
  const currentPageRef = useRef(1); //当前加载第几页的数据
  const clickedItemRef = useRef(null);
  const sortKeyRef = useRef(sortOptions[0]);
  const dropdownRef = useRef(null);

  // 请求未结束咨询的记录
  async function _adminGetRecord () {
    let params = {};
    let resList = [];
    if (sortKeyRef.current.title === '咨询未确认结束') {
      params = {
        pageNo: currentPageRef.current,
        pageSize: PAGE_SIZE,
        recodeOperate: 'APPOINT_AUTO_START',
      }
      let res = await adminGetRecord(params);
      resList = res.result.data;
    }
    else if (sortKeyRef.current.title === '咨询已完成') {
      params = {
        pageNo: currentPageRef.current,
        pageSize: PAGE_SIZE,
        recodeStatusList: 'CONSULT_SUCCESS,EVALUATE_SUCCESS',
      }
      let res = await adminGetRecord(params);
      resList = res.result.data;
    }
    setEvaluationList([...evaluationList, ...resList]);
    if (resList.length < PAGE_SIZE) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }

  // 咨询确认
  const _adminConsultRecodeCompleted = useCallback(async (params) => {
    await adminConsultRecodeCompleted(params);
    // 重新请求数据，刷新页面
    _adminGetRecord();
  }, [])

  useEffect(() => {
    _adminGetRecord();
  }, [])


  function formatContent(item) {
    // startTime-endTime xx咨询师与yy来访者进行的咨询，尚未进行咨询结束确认。你是否能确认本次咨询已结束？
    const starTimeStr = MomentDiffZone(item.startTime).format('YYYY年MM月DD日 HH:mm');
    const endTimeStr = MomentDiffZone(item.startTime).add(1, 'h').format('HH:mm');
    if (sortKeyRef.current.title === '咨询未确认结束') {
      return `${starTimeStr}-${endTimeStr}, ${item.consultantName}咨询师和${item.authName}来访者进行的咨询，尚未进行咨询结束确认。你是否能确认本次咨询已结束？`
    }
    else {
      return `${starTimeStr}-${endTimeStr}, ${item.consultantName}咨询师和${item.authName}来访者进行的咨询，已经确认结束。`
    }
  }

  function renderListItem(item) {
    if (item.recodeOperate === 'APPOINT_AUTO_START') {
      return <div className='evaluation-list-item'>
        <div className='item-icon'>
          <BellOutline fontSize={22} />
        </div>
        <div className='text-item'>
          <div className='item-title'>
            <div>未确认咨询结束</div>
            <div>{MomentDiffZone(item.startTime).format('YYYY年MM月DD日 HH:mm')}</div>
          </div>
          <div className='item-content'>{formatContent(item)}</div>
          <div className='item-confirm'
          >
            确认结束咨询
          </div>
        </div>
      </div>
    } else {
      return <div
        className='evaluation-list-item'
        style={{
          padding: '0.5rem 1rem'
        }}
      >
        <div className='text-item'>
          <div className='item-title'>
            <div >确认咨询结束</div>
            <div>{MomentDiffZone(item.startTime).format('YYYY年MM月DD日 HH:mm')}</div>
          </div>
          <div className='item-content'>{formatContent(item)}</div>
        </div>
      </div>
    }
  }

  function handleItemClick(item) {
    setModalVisible(true)
    clickedItemRef.current = item;
  }
  // 下拉刷新
  async function onRefresh() {
    setHasMore(false);
    currentPageRef.current = 1;
    await _adminGetRecord();
  }

  // 加载更多
  async function loadMore() {
    await sleep(1000)
    currentPageRef.current++;
    await _adminGetRecord();
  }

  function initializeEvaluationList() {
    setEvaluationList((x) => {
      x.length = 0;
      return x
    })
  }

  // 下拉菜单更改筛选条件
  async function handleSortChange(sortItem) {
    initializeEvaluationList();
    sortKeyRef.current = sortItem;
    dropdownRef.current?.close();
    onRefresh();
  }

  // 下拉选项
  function renderSortOptions() {

    return (
      <List>
        {
          sortOptions.map((item, index) => {
            return (
              <List.Item
                key={index}
                onClick={() => {
                  handleSortChange(item);
                }}
              >
                {item.title}
              </List.Item>
            )
          })
        }
      </List>
    )
  }

  return (
    <div className='evaluation-page-content'>
      <TitleBar
        title='咨询记录管理'
        isShowBack={true}
      />
      <div>
        <Dropdown ref={dropdownRef}>
          <Dropdown.Item title={sortKeyRef.current.title} key='sorter'>
            {renderSortOptions()}
          </Dropdown.Item>
        </Dropdown>
      </div>
      {
        sortKeyRef.current.title === '咨询未确认结束'
          ? <ListView
            dataSource={evaluationList}
            renderItem={renderListItem}
            onRefresh={onRefresh}
            hasMore={hasMore}
            loadMore={loadMore}
            onItemClick={handleItemClick}
          />
          : <ListView
            dataSource={evaluationList}
            renderItem={renderListItem}
            onRefresh={onRefresh}
            hasMore={hasMore}
            loadMore={loadMore}
            onItemClickShow={false}
          />
      }
      <Modal
        visible={modalVisible}
        content={
          clickedItemRef.current
            ? <>您确定{clickedItemRef.current.consultantName}咨询师与{clickedItemRef.current.authName}来访者进行的咨询已结束？</>
            : <></>
        }
        actions={[
          {
            key: 'confirm',
            text: '确认',
            primary: true,
            onClick: () => {
              const params = {
                // 不确认结束的原因
                // notEndReason: '',
                recodeId: clickedItemRef.current.consultRecodeId,
                // 是否结束
                submitEnd: true
              }
              _adminConsultRecodeCompleted(params)
              setModalVisible(false);
            }
          },
          {
            key: 'cancel',
            text: '取消',
            onClick: () => {
              setModalVisible(false);
            }
          }
        ]}
      />
    </div>
  )
}

export default EvaluationManagement;