// 多模态心理测评
import React from 'react';
import './index.less';
import TitleBar from "@/components/TitleBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Image } from 'antd-mobile';
import { getPageQuery } from '@/utils/utils';
import { addAIRecord } from '@/services/api';


function MultiModalTest() {
  const navigate = useNavigate();
  const location = useLocation();
  const moltiModalList = _.get(location, ['state', 'moltiModalList'], []);

  function renderMultiModalContent() {

    async function _addAIRecord(params) {
      let res = await addAIRecord(params);
      return res.result;
    }

    async function handleNavigate(item) {
      const { type } = getPageQuery(item.url);
      const { name } = item;
      let params = {
        type,
        name,
      };
      let res = await _addAIRecord(params);
      const { dataEnc, callbackUrl, organizationId } = res;
      navigate('/client/multi-modal-test-iframe', {
        state: {
          title: item.name,
          url: item.url + `&dataEnc=${dataEnc}&callbackUrl=${callbackUrl}&organizationId=${organizationId}`,
        }
      })
    }

    return (
      <div className="multi-modal-content">
        {
          moltiModalList && moltiModalList.map((item, index) => {
            return (
              <div
                key={index}
                className='list-item'
                onClick={() => {
                  handleNavigate(item);
                }}
              >
                <Image src={item.img} fit='contain' className='img-background' />
                <div className='item-text'>{item.name}</div>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <>
      <TitleBar
        title="多模态心理测评"
        isShowBack={true}
        // barCancel={() => {
        //   navigate('/client/home-v2/main')
        // }}
      />
      {renderMultiModalContent()}
    </>
  )
}

export default MultiModalTest;