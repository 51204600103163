import { MESSAGE_UPDATE, GET_MESSAGE, MESSAGE_ADD, MESSAGE_CLEAR, MESSAGE_WITHDRAW } from "@/actions/actionType";
import _ from "lodash";

const msgReducer = (state = [],  action) => {
  let newState = null;
  switch (action.type) {
    case GET_MESSAGE:
      newState = action.value;
      return newState;
    case MESSAGE_UPDATE:
      newState = _.cloneDeep(state);
      newState.push(action.value);
      return newState;
    case MESSAGE_ADD:
      newState = _.cloneDeep(state);
      newState.unshift(...action.value);
      return newState;
    case MESSAGE_WITHDRAW:
      newState = _.cloneDeep(state);
      newState.forEach((item, index) => {
        if (item.chatMsgId === action.value.chatMsgId) {
          newState.splice(index, 1, {
            ...item,
            withdrawFlag: action.value.withdrawFlag
          });
        }
      });
      console.log('newState', newState);
      return newState;
    case MESSAGE_CLEAR:
      return [];
    default:
      return state;
  }
}

export default msgReducer