import React, { useState, useEffect, useRef } from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import RegisterComponents from '@/components/RegisterComponents';
import { useNavigate, useLocation } from 'react-router-dom';
import { staffRegisterAccount, linkWithCode, getNeedFirstEvaluation } from '@/services/api';
import { setLoginToken, setIsFirstTimeLogin, getLocalNeedFirstEvaluationStatus, setLocalNeedFirstEvaluationStatus } from '@/utils/localStorage'
import moment from 'moment'
import _ from 'lodash';
import CustomDatePicker from '@/components/CustomDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import CONFIG from 'GlobalConfigFile';
import { Toast } from 'antd-mobile';
import { register_socket_event, save_patient_info } from '@/actions';
import WithTracking from '@/components/WithTracking';

function PatientRegister(props) {
  const ROLE = 'patient';
  let navigate = useNavigate();
  let location = useLocation();
  const {
    authList,
    registerCode,
    telephone,
    verifyCode,
    relationship
  } = _.get(location, 'state', {
    authList: [],
    registerCode: '',
    telephone: '',
    verifyCode: '',
    relationship: ''
  });
  const dispatch = useDispatch();
  const [name, setName] = useState();
  const [gender, setGender] = useState();
  const [birthdayTimestamp, setBirthdayTimestamp] = useState();
  const [visible, setVisible] = useState(false)

  // 获取本地存取的是否需要初始评估的状态
  let needFirstEvaluation = getLocalNeedFirstEvaluationStatus();

  async function _getNeedFirstEvaluation() {
    try {
      const res = await getNeedFirstEvaluation();
      const needFirstEvaluation = _.get(res, 'result', true);
      setLocalNeedFirstEvaluationStatus(needFirstEvaluation)
      return needFirstEvaluation
    } catch (e) {
      console.error('getNeedFirstEvaluation error', e)
      // 接口异常仍回主页
      return false
    }
  }


  // 注册
  async function handleRegister(userInfo) {
    const params = {
      ...userInfo,
      telephone,
      verifyCode,
      registerCode
    }
    const res = await staffRegisterAccount(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    // 更新token
    const { token, authId } = _.get(res, ['result'], {});
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    dispatch(save_patient_info(res.result))
    if (needFirstEvaluation === null) {
      needFirstEvaluation = await _getNeedFirstEvaluation();
    }
    if (needFirstEvaluation) {
      navigate('/client/initial-evaluation');
      return
    }
    navigate('/client/home-v2/main');
  }

  /**
   * 初始化连接socket
   * @param {*} id socket连接时的authId
   */
  function registerSocketAndConnect(id) {
    const DEFAULT_SERVER = process.env.DEFAULT_SERVER || CONFIG.Server;
    const socket = io(CONFIG.URLEnv[DEFAULT_SERVER].socketUrl + id, { autoConnect: false, reconnection: false, forceNew: false, transports: ['websocket', 'polling'] });
    socket.open();
    dispatch(register_socket_event(socket));
  }


  // 关联已有账号
  async function handleLinkAccount(currentAuthId) {
    const params = {
      authId: currentAuthId,
      telephone,
      verifyCode,
      registerCode,
      relationship
    }
    const res = await linkWithCode(params);
    if (res.errorCode) {
      Toast.show({
        content: res.error,
        key: 'error'
      });
      return;
    }
    // 更新token
    const { token, authId } = _.get(res, ['result'], {});
    // 登录成功后注册socket并连接
    registerSocketAndConnect(authId)
    setLoginToken(ROLE, token);
    dispatch(save_patient_info(res.result))
    if (needFirstEvaluation) {
      navigate('/client/initial-evaluation');
    } else {
      navigate('/client/home-v2/main')
    }
  }

  return (
    <>
      <TitleBar title="注册信息完善" isShowBack={true} />
      <RegisterComponents
        authList={authList}
        onRegisterNewUser={handleRegister}
        onLinkAccount={handleLinkAccount}
      />
    </>
  )
}

export default WithTracking(
  PatientRegister,
  {
    name: 'visit:register',
    props: {
      eventName: '有码注册'
    },
    userId: 'anonymous',
  }
);