// 该页面相对于./index多了一个折叠面板的展示
import React from 'react';
import { InfiniteScroll, PullToRefresh, List, Collapse } from 'antd-mobile'

/**
 * 
 * @param {*} dataSource 数据源
 * @param {*} renderItem item样式
 * @param {*} onRefresh 下拉刷新方法
 * @param {*} hasMore 是否还有更多数据，可根据loadMore，处理传入
 * @param {*} loadMore 加载新的数据方法
 * @param {*} minHeight, 最小高度，默认100vh
 * @returns 
 */
function ListCollapseView({ dataSource, renderItem, onRefresh, hasMore, loadMore, minHeight = '100vh', onItemClick }) {
  return (
    <>
      <PullToRefresh
        onRefresh={() => onRefresh()}
      >
        <Collapse>
          {
            (dataSource || []).map((item, index) => {
              return (
                <Collapse.Panel
                  key={index}
                  title={item.name}
                >
                  <List>
                    {
                      (item.consultList || []).map((consultListItem, consultListIndex) => (
                        <List.Item
                          key={consultListIndex}
                          onClick={() => { onItemClick(consultListItem) }}
                        >
                          {
                            renderItem(item)
                          }
                        </List.Item>
                      ))
                    }
                  </List>
                </Collapse.Panel>
              )
            })
          }
        </Collapse>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={10} />
      </PullToRefresh>
    </>
  )
}

export default ListCollapseView;