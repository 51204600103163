// 用于获取form的schema等一系列信息
import React, { useCallback, useEffect, useState, useRef } from 'react';
import _ from 'lodash';

import parseJsonFormToLessonData from '@/utils/parseJsonForm';
import useFormChat from './getFormChat';

const useFormInfo = (props) => {
  const { formContent, lessonInstanceId } = props;
  const shufuFormId = _.get(formContent, ['schema', 'shufuFormId'], '');
  // getFormChat只需要执行一次，否则每次都会new一个新的shufuFormChat
  const { shufuFormChat, questionList, sections } = useFormChat({
    formContent,
  });
  const [shufuFormChatState, setShufuFormChatState] = useState(
    shufuFormChat.getInitialState()
  );
  const [questionNumber, setQuestionNumber] = useState(
    JSON.parse(localStorage.getItem(`shufuFormChat_${lessonInstanceId}`))
      ?.currentQuestionNumber || 0
  );
  const question = questionList[questionNumber];
  const { viewType } = question;
  const currentQuestionId = questionList[questionNumber].questionId;
  const formSchema = formContent.schema;
  const [sectionKey, questionKey] = currentQuestionId.split('/');
  const questionSchema =
    formSchema?.properties[sectionKey]?.properties[questionKey];
  // 判断是否可以跳过
  const allowSkip = questionSchema?.allowSkip;
  // 判断是否需要chatGPT共情
  const getNLPResponse = questionSchema?.getNLPResponse;
  // 查看该问题的prompt，主要用途在和chatgpt共情的时候，区别对话的角色
  const prompt = questionSchema?.prompt;

  // 问题答案
  const [model, setModel] = useState({});
  // 看到的视频的最大的questionNumber
  const [maxQuestionNumber, setMaxQuestionNumber] = useState(0);

  // 流程是否开始
  const isProcessStart = currentQuestionId === 'start';
  // 流程是否结束
  const isProcessEnd = currentQuestionId === 'results';

  const prevShufuFormChatState = useRef(shufuFormChatState);
  useEffect(() => {
    prevShufuFormChatState.current = shufuFormChatState;
  }, [shufuFormChatState]); //run this code when the value of count changes

  // 使用shufuFormChat的setInitialState更新状态
  const saveFormState = useCallback((params) => {
    const newState = {
      ...prevShufuFormChatState.current,
      ...params,
    };
    setShufuFormChatState(newState);
    let nextQuestionId = newState.currentQuestionId;
    // 拿到新的questionId,更新视频
    if (nextQuestionId === 'SUBMIT') {
      setQuestionNumber(questionList.length - 1);
    } else {
      let nextQuestion = newState.messageObject[nextQuestionId];
      setQuestionNumber(nextQuestion.questionNumber);
    }
  }, [questionList.length]);

  // 初始化shufuFormChatState
  useEffect(() => {
    /**
     *
     * 本地存储的shufuFormChatState
     * {
     *   questionNumber,
     *   shufuFormChatState,
     *   model,
     * }
     */
    let shufuFormChatByLessonInstanceId = JSON.parse(
      localStorage.getItem(`shufuFormChat_${lessonInstanceId}`)
    );
    if (shufuFormChatByLessonInstanceId) {
      let { shufuFormChatState, model, maxQuestionNumber } =
        shufuFormChatByLessonInstanceId;
      setModel(model);
      setMaxQuestionNumber(maxQuestionNumber);
      saveFormState(shufuFormChatState);
      shufuFormChat.setInitialState(shufuFormChatState);
    }
  }, [lessonInstanceId, saveFormState]);

  // 监控shufuFormChatState变化，存储到本地
  // optimize： 待进一步优化，可在用户答题提交答案
  useEffect(() => {
    // 存下最大的questionNumber
    const storageMaxQuestionNumber = () => {
      if (questionNumber > maxQuestionNumber) {
        setMaxQuestionNumber(questionNumber);
        return questionNumber;
      }
      return maxQuestionNumber;
    };

    let currentMaxQuestionNumber = storageMaxQuestionNumber();
    let shufuFormChatByLessonInstanceId = {
      currentQuestionNumber: questionNumber,
      shufuFormChatState,
      // videoSource,
      model,
      maxQuestionNumber: currentMaxQuestionNumber,
    };
    // 答完的时候因为要清空缓存，所以不在缓存数据
    if (question.questionId !== 'results') {
      localStorage.setItem(
        `shufuFormChat_${lessonInstanceId}`,
        JSON.stringify(shufuFormChatByLessonInstanceId)
      );
    }
  }, [
    shufuFormChatState,
    model,
    lessonInstanceId,
    questionNumber,
    maxQuestionNumber,
    question
  ]);

  // 开启流程
  const onProcessStart = () => {
    setQuestionNumber(questionNumber + 1);
    onSubmitAnswer({
      key: 'start',
      value: true,
    });
  };

  // 跳转到大纲的视频
  const jumpToSection = (sectionItem) => {
    // 1. 找到sectionId对应的questionNumber
    // 2. 只有之前的视频看完的情况下才能跳转
    let newQuestionNumber = sectionItem.startQuestionNum;
    if (newQuestionNumber <= maxQuestionNumber) {
      setQuestionNumber(newQuestionNumber);
    }
  };

  // 用于更新messageShown的显示，存到到shufuFormChatState, 用于更新messageShownList
  const updateMessagesShownList = (messageShownList) => {
    saveFormState({ messagesShown: [...messageShownList] });
  };

  // 修改上一题答案
  const onModifyAnswer = () => {
    let questionAnswered = shufuFormChatState.questionsAnswered;
    let lastQuestionId = questionAnswered[questionAnswered.length - 1];
    let lastQuestionNumber = questionList.findIndex(
      (question) => question.questionId === lastQuestionId
    );
    if (lastQuestionNumber > 0) {
      const lastMessage = shufuFormChatState.messagesShown.find(
        (message) => message.questionId === lastQuestionId
      );
      // 把修改后的答案返回的状态，替换原始状态
      const newState = shufuFormChat.editAnswer(lastMessage);
      saveFormState(newState);
    }
  };

  // 提交答案
  const onSubmitAnswer = useCallback(
    ({ key, value, nlp_response }) => {
      console.log('onSubmitAnswer', key, value);
      let responseModel = { ...model };
      // 如果提交的题目的key不等于当前key
      if (key === 'start') {
        responseModel['Intro'] = {
          intro: true,
        };
        return;
      }
      if (key === 'Conclusion/conclusion') {
        responseModel['Conclusion'] = {
          conclusion: true,
        };
        setQuestionNumber(questionList.length - 1);
      } else {
        _.set(responseModel, [sectionKey, questionKey], value);

        const getNewState = shufuFormChat.getNewState(
          value,
          null,
          null,
          nlp_response
        );
        saveFormState(getNewState);
      }
      setModel(responseModel);
    },
    [model, questionList.length, sectionKey, questionKey, shufuFormChat, saveFormState]
  );

  // 从shufuFormChat拿到更新messageShown
  const updateMessageShown = (answer) => {
    const answerVal = shufuFormChat.getAnswerVal(question, answer);
    const currentMessage = shufuFormChatState.messagesShown.find(
      (message) => message.questionId === currentQuestionId
    );
    shufuFormChat.updateMessagesShownWithAnswer(
      currentQuestionId,
      answer,
      answerVal,
      shufuFormChatState.messageObject[currentQuestionId],
      shufuFormChatState.messagesShown,
      currentMessage
    );
  };

  // 用于更新model
  const updateModel = ({ answer }) => {
    shufuFormChat.updateModel(currentQuestionId, answer, model);
  };

  // 用于跳转到下一个问题
  const jumpToNextQuestion = () => {
    let nextQuestionId = shufuFormChat.getNext(currentQuestionId, model);
    saveFormState({ currentQuestionId: nextQuestionId });
  };

  // 解析form model用于提交到互动记录
  const getDataForInteractiveRecord = () => {
    const questionList = parseJsonFormToLessonData(
      formContent.schema.properties,
      model
    );
    return { questionList, model };
  };

  // 获取到问题的选项
  const getQuestionOptions = () => {
    switch (currentQuestionId) {
      case 'start':
        return null;
      // form的流程走到最后，确认结束
      case 'results':
        const options = [
          {
            label: '确认结束',
            value: 'confirm',
          },
        ];
        return options;
      default:
        if (!questionSchema.enum && !questionSchema.items) return null;
        if (questionSchema.enum) {
          const enums = questionSchema.enum;
          let enumOrder = questionSchema.order || Object.keys(enums);
          enumOrder = enumOrder.filter((enumKey) => enumKey !== 'no_hide');
          const options = enumOrder.map((enumKey) => ({
            label: enums[enumKey],
            value: enumKey,
          }));
          return options;
        }
        if (questionSchema.items) {
          const enums = questionSchema.items.enum;
          let enumOrder = questionSchema.order || Object.keys(enums);
          enumOrder = enumOrder.filter((enumKey) => enumKey !== 'no_hide');
          const options = enumOrder.map((enumKey) => ({
            label: enums[enumKey],
            value: enumKey,
          }));
          return options;
        }
    }
  };

  /**
   *  判断题目类型
   * 1. 选择题 拥有enums的是选择题选择题
   * 2. 填空题
   */
  const getQuestionType = () => {
    if (questionSchema) {
      const { type, enum: enums } = questionSchema;
      if (type === 'string') {
        if (enums) {
          return 'single';
        } else {
          return 'blank';
        }
      }
      if (type === 'array') {
        return 'multiple';
      }
    }
  };

  return {
    currentLesson: {
      isProcessStart,
      isProcessEnd,
      onProcessStart,
      jumpToSection,
      shufuFormId,
      messagesShown: shufuFormChatState.messagesShown,
      updateMessagesShownList,
      updateMessageShown,
      updateModel,
      jumpToNextQuestion,
      model
    },
    currentQuestion: {
      onSubmitAnswer,
      onModifyAnswer,
      viewType,
      questionSchema,
      currentQuestionId,
      questionContent: questionSchema?.title_zh,
      questionOptions: getQuestionOptions(),
      questionType: getQuestionType(),
      dataForInteractiveRecord: getDataForInteractiveRecord(),
      sections: sections,
      allowSkip: allowSkip,
      getNLPResponse: getNLPResponse,
      prompt: prompt,
    },
  };
};

export default useFormInfo;
