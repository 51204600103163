// 返回stream
import React, { useCallback, useState, useRef, useEffect } from 'react';
import useStream from '@/utils/hooks/useStream';
import { Checkbox } from 'antd-mobile';
import { getUrls } from '@/utils/getUrls';

const ChatGptStream = (props) => {
  // params: { prompt, reset_conversation }
  const { params, onMessageSend } = props;
  const [chatgptAnswerList, setChatgptAnswerList] = useState([]);
  const answerDataRef = useRef('');
  const { BASE_URL } = getUrls();
  const paramsRef = useRef(
    {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );


  const getChatGptStream = useCallback(async res => {
    let data = await res.body.getReader().read();
    data.output = new TextDecoder('utf-8').decode(data.value);
    if (data.output.includes('[DONE]')) {
      data.output = data.output.replace(/\[DONE\]/g, '');
    }
    answerDataRef.current += data.output;
    setChatgptAnswerList([...chatgptAnswerList, { message: answerDataRef.current, isChecked: false }]);
    if (data.output.includes('<br/><br/>')) {
      answerDataRef.current = answerDataRef.current.replace(/<br\/>/g, '');
      // answerDataRef.current相当于一个bucket, 用来存储每次的数据, 直到遇到<br/><br/>才会把数据存到chatgptAnswerList中
      chatgptAnswerList.push({ message: answerDataRef.current, isChecked: false });
      answerDataRef.current = '';
    }
  }, [setChatgptAnswerList]);

  const onFirst = useCallback(async res => {
    const chatKey = res.get('chat-key');
  });

  // 初代接入chatgpt的接口
  // useStream(`https://chatgpt-uat.siuvo.cn/chat_stream_html`, {onFirst, onNext: getChatGptStream, fetchParams: paramsRef.current });
  // 次代接入chatgpt的接口
  // useStream(`https://eap-uat.siuvo.cn/api/ai/suggest`, {onFirst, onNext: getChatGptStream, fetchParams: paramsRef.current })
  // 三代接入chatgpt的接口
  // useStream(`https://eap-uat.siuvo.cn/api/ai/stepByStep`, {onFirst, onNext: getChatGptStream, fetchParams: paramsRef.current })
  useStream(`${BASE_URL}ai/stepByStep`, {onFirst, onNext: getChatGptStream, fetchParams: paramsRef.current })

  return (
    <div className='chatgpt-amswer-block'>
      {
        chatgptAnswerList && chatgptAnswerList.map((item, index) => {
          return (
            <div className='chatgpt-answer-item' key={index}>
              <div className='item-adopt'
                style={{ backgroundColor: item.isChecked ? '#fa0' : '#e9e9e9' }}
                onClick={() =>{
                  item.isChecked = !item.isChecked;
                  setChatgptAnswerList([...chatgptAnswerList]);
                  let needSendData = chatgptAnswerList.map((item, index) => {
                    if (item.isChecked) {
                      return item.message + '\n';
                    }
                  })  
                  onMessageSend(needSendData.join(''));
                }}
              >
                采用
              </div>
              <div
                className='item-message'>
                {item.message}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default ChatGptStream;