import React, {useEffect, useState , useRef} from 'react';
import './index.less';
import TitleBar from '@/components/TitleBar';
import ListView from '@/components/ListView';
import {getInfoApply} from '@/services/api';
import { Toast, Dropdown, List  } from 'antd-mobile';
import _ from 'lodash';
import { sleep } from 'antd-mobile/es/utils/sleep'
import MomentDiffZone from '@/utils/useMomentDiffZone';
import {useNavigate} from 'react-router-dom';

function AduitList (props) {
  const PAGE_SIZE = 10;
  const sortOptions = [
    {sortType: 'byTimeDesc', title: '按时间排序'},
    {sortType: 'byStatusDesc', title: '按状态排序'},
    {sortType: 'byConsultantDesc', title: '按咨询师排序'}
  ]
  const navigate = useNavigate();
  const applyStatus = {APPROVING: '审核中', PASSED: '审核已通过', NOT_APPROVED: '审核未通过', REVOKED: '审核已撤销'}
  const [applyList, setApplyList] = useState([]) //审核列表
  const [hasMore, setHasMore] = useState(false); //是否需要加载更多
  const currentPageRef = useRef(1); //当前加载第几页的数据
  const sortKeyRef = useRef(sortOptions[0]); //当前加载第几页的数据
  const dropdownRef = useRef(null);

  // 接口获取待审核列表
  async function getApplyList( isFresh) {
    const params = {
      pageNo: currentPageRef.current,
      pageSize: PAGE_SIZE,
      sortType: sortKeyRef.current.sortType
    }
    const res = await getInfoApply(params)
    if (res.errorCode) {
      Toast.show({
        content: res.error
      })
      return;
    } 
    const result = _.get(res, ['result', 'data']) || [];
    let combineList;
    if (isFresh) {
      combineList = result;
    } else {
      combineList = [...applyList, ...result]
    } 
    setApplyList(combineList);
    if (result.length < PAGE_SIZE) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }

  useEffect(() => {
    getApplyList();
  }, [])
  
  // 上拉加载更多
  async function loadMore () {
    await sleep(1000)
    currentPageRef.current++;
    await getApplyList();
  }
  
  // 下拉刷新
  async function onRefresh () {
    setHasMore(false);
    currentPageRef.current = 1;
    await getApplyList(true);
  }

  // 下拉菜单更改筛选条件
  async function handleSortChange (sortItem) {
    sortKeyRef.current = sortItem;
    dropdownRef.current?.close();
    await getApplyList(true);
  }

  // 列表Item的渲染
  function renderListItem (item) {
    return (
      <div className={`apply-info-item ${item.applyStatus === 'APPROVING'? 'apply-approving' : ''}`} >
        <div>{MomentDiffZone(item.updatedTime).format('YYYY年MM月DD日')}</div>
        <div>{_.get(item, ['applyInfo', 'name'], '')}</div>
        <div className='apply-desc'>{item.operatorDesc}</div>
        <div>{applyStatus[item.applyStatus]}</div>
      </div>
    )
  }

  function renderSortOptions () {
    return (
      <List>
        {
          sortOptions.map((item, index) => {
            return(
              <List.Item 
                key={index}
                onClick={() => {
                  handleSortChange(item);
                }}
              >{item.title}</List.Item>
            )
          })
        }
      </List>
    )
  }

  function handleItemClick (item) {
    if (item.applyStatus !== 'REVOKED') {
      navigate('/admin/audit-page', { state: { applyInfo: item } })
    }
    // switch (item.applyStatus) {
    //   case 'APPROVING':
    //     navigate('/admin/audit-page', { state: { applyInfo: item } })
    //     break;
    //   default:
    //     break;
    // }
  }
  return (
    <div>
      <TitleBar title="审核列表" isShowBack={true}></TitleBar>
      <div className='audit-page-content'>
        <Dropdown ref={dropdownRef}>
          <Dropdown.Item title={sortKeyRef.current.title} key='sorter'>
            {renderSortOptions()}
          </Dropdown.Item>
        </Dropdown>
        <div className='list-view-content'>
          <ListView
            dataSource={applyList}
            renderItem={renderListItem}
            onRefresh={onRefresh}
            hasMore={hasMore}
            loadMore={loadMore}
            onItemClick={handleItemClick}
          />
        </div>
      </div>
    </div>
  )
}

export default AduitList;