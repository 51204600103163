// 个人介绍审核内容界面
import React from "react";
import './comment.less'

function Comment(props) {
  const commentText = props?.commentText;
  const commentStyle = props?.commentStyle;

  return (
    <div className="comment" style={commentStyle}>
      {commentText}
    </div>
  )
}

export default Comment;