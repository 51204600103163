import React, { useState, useEffect} from 'react';
import './index.less';
import _  from 'lodash';
import MomentDiffZone from '@/utils/useMomentDiffZone';
import {doctorAddAppointTimeslot } from '@/services/api';
import { Toast, Button } from 'antd-mobile'
import '@/components/Calendar/index.less';
import CustomDatePicker from '@/components/CustomDatePicker';

// 添加可预约时段
function AddCalendar(props) {
  const [dateVisible, setDateVisible] = useState(false); // 选择时间的picker
  const [dataIndex, setDataIndex] = useState(-1);
  const [timeslots, setTimeslots] = useState([]);
  const [hasConflict, setHasConflict] = useState(false);

  const addPointErrorText = ' 与已确定的值班/可预约时间/预约时间重合'

  useEffect(() => {
    setTimeslots([new Date()])
  }, []);

  // 添加可预约时间
  async function addAppointTimeSlot () {
    const params = {
      timeslotList: timeslots.map(item => MomentDiffZone(MomentDiffZone(item).format('YYYY-MM-DD HH:mm')).valueOf())
    }
    let res = await doctorAddAppointTimeslot(params);
    if (res.errorCode) {
      setHasConflict(true);
    } else {
      Toast.show({
        icon: 'success',
        content: '添加成功'
      })
      props.backToCalendar()
    }
  }
  // 添加可预约时段
  function addTimeSlot () {
    addAppointTimeSlot();
  }

  // 渲染时间选择
  function renderSelectTimeSlot () {
    return (
      <>
        {timeslots.map((t, i) => <div className='calendar-datetime-picker' key={i}>
          <div
            className='calendar-delete-picker'
            onClick={() => {
              const _timeslots = _.cloneDeep(timeslots);
              _timeslots.splice(i, 1);
              setTimeslots(_timeslots);
              setHasConflict(false);
            }}
          >
            <img alt="" src={require('@/assets/icon/delete.svg').default} />
          </div>
          <div className='calendar-date-picker' onClick={() => {
            setDateVisible(true);
            setDataIndex(i);
          }}>
            <div className='calendar-date-value'>
              {/* <div className='calendar-date-icon'><CalendarOutline color='blue' /></div> */}
              {MomentDiffZone(t).format('YYYY-MM-DD')}
            </div>
          </div>
          <div className='calendar-date-picker' onClick={() => {
            setDateVisible(true);
            setDataIndex(i);
          }}>
            <div className='calendar-date-value'>
              {MomentDiffZone(t).format('HH:mm')}
            </div>
          </div>
        </div>)
        }
      </>
    )
  }

  return (
    <>
      <div className="calendar-add-match-page">
        <div className="calendar-add-match-content">
          {renderSelectTimeSlot()}
          {
            hasConflict && (
              <div className='submit-error-hint'>
                <img 
                  alt=''
                  src={require('@/assets/icon/warning.svg').default} 
                />
                {addPointErrorText}
              </div>
              )
          }
          <div
            className='add-calendar-value'
            onClick={() => {
              const _timeslots = _.cloneDeep(timeslots);
              _timeslots.push(new Date());
              setTimeslots(_timeslots);
            }}
          >
            <img 
              alt=''
              src={require('@/assets/icon/dialogue/add.svg').default}
              className="add-icon"
            />
          </div>
        </div>
        <div className="bottom-btn">
          <Button
            color='warning'
            style={{
              width: '90%'
            }}
            onClick={() => {
              addTimeSlot()
            }}>
            确认添加
          </Button>
        </div>
        <CustomDatePicker
          visible={dateVisible}
          onClose={() => {
            setDateVisible(false);
          }}
          precision='minute'
          onConfirm={val => {
            const _timeslots = _.cloneDeep(timeslots);
            _timeslots.splice(dataIndex, 1, new Date(val));
            setTimeslots(_timeslots);
            setHasConflict(false);
          }}
        />
      </div>
    </>
  )
}

export default AddCalendar;