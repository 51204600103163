import React from 'react';
import './index.less';

function Avatar (props) {
  const {avatarUrl = 'http://5b0988e595225.cdn.sohucs.com/images/20181108/5ea56d5c8abb4740b29c75f420123268.jpeg', isRound = true, size=6} = props
  return (
    <div className='user-header' style={{width: size + 'rem', height: size + 'rem', borderRadius: isRound ? size/2 + 'rem' : 0}}>
      <img className="head-img" src={avatarUrl} alt=''/>
    </div> 
  )
}

export default Avatar;